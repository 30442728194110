import React from "react";
import { Router } from "../router";
import {BrowserRouter, useLocation} from "react-router-dom";
import {CHECKOUT_FLOW_ROUTES} from "../../utils/values";
import {BreadCrumbs} from "../breadcrumbs";

export const Main = () => {
  const location = useLocation();
  console.log('location', location.pathname)
  return (
    <>
      {location.pathname !=='/' &&  <BreadCrumbs/>}
      <div
        className={
          CHECKOUT_FLOW_ROUTES.findIndex(route => route === location.pathname) > -1
            ? "p5-grey-bodywrapper"
            : "bodywrapper"
        }
      >
        <div className="main-body">
          <Router />
        </div>
      </div>
    </>
    
  );
};
