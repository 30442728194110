import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import {get} from "../../../../api/api-caller";
import {END_POINTS} from "../../../../api";
import {ROUTES} from "../../../../utils/values";
import {Link} from "react-router-dom";

export const DetailsForm = ({
  handleSubmit,
  isSubmitting,
  }) => {
  const [details , setDetails] = useState({})

  const getDetails = () =>{
    get(`${END_POINTS.USER_DETAILS}`)
        .then((resp) => {
          setDetails(resp.data);
        })
        .catch()
  }
  useEffect(()=>{
    getDetails();
  }, [])

  return(

        <Formik
            initialValues={details}
         // validationSchema={validations}
            onSubmit={handleSubmit}
            enableReinitialize
        >
          <div className="p21-form-wrapper w-form">
            <Form
                id="email-form"
                name="email-form"
                data-name="Email Form"
                className="p21-form"
            >
              <div className="p21-first-name-wrapper w-row">
                <div className="p21-left-column w-col w-col-3">
                  <div className="p21-field-title">First Name</div>
                </div>
                <div className="p21-right-column w-col w-col-9">
                  <Field
                      type="text"
                      className="p21-first-name-field w-input"
                      maxLength="256"
                      name="first_name"
                      placeholder="Example User Data "
                      id="P21-Field-first_name"
                      required
                  />
                </div>
              </div>
              <div className="p21-second-name-wrapper w-row">
                <div className="p21-left-column w-col w-col-3">
                  <div className="p21-field-title">Last Name</div>
                </div>
                <div className="p21-right-column w-col w-col-9">
                  <Field
                      type="text"
                      className="p21-last-name-field w-input"
                      maxLength="256"
                      name="last_name"
                      data-name="P 21 Field 2"
                      placeholder="Example User Data "
                      id="P21-Field-2"
                      required
                  />
                </div>
              </div>
              <div className="p21-second-name-wrapper w-row">
                <div className="p21-left-column w-col w-col-3">
                  <div className="p21-field-title">Email Address</div>
                </div>
                <div className="p21-right-column w-col w-col-9">
                  <Field
                      type="email"
                      className="p21-email-field w-input"
                      maxLength="256"
                      name="email"
                      data-name="P 21 Field 2"
                      placeholder="Example User Data "
                      id="P21-Field-3"
                      required
                  />
                </div>
              </div>
              <div className="p21-second-name-wrapper w-row">
                <div className="p21-left-column w-col w-col-3">
                  <div className="p21-field-title">Phone No</div>
                </div>
                <div className="p21-right-column w-col w-col-9">
                  <Field
                      type="tel"
                      className="p21-phone-field w-input"
                      maxLength="256"
                      name="phone_number"
                      data-name="P 21 Field 2"
                      placeholder="Example User Data "
                      id="P21-Field-4"
                      required
                  />
                </div>
              </div>
              <div className="p21-second-name-wrapper w-row">
                <div className="p21-left-column w-col w-col-3">
                  <div className="p21-field-title">Company Name</div>
                </div>
                <div className="p21-right-column w-col w-col-9">
                  <Field
                      type="text"
                      className="p21-company-name-field w-input"
                      maxLength="256"
                      name="company_name"
                      placeholder="Example User Data "
                      id="P21-Field-5"
                  />
                </div>
              </div>
              <div className="p21-second-name-wrapper w-row">
                <div className="p21-left-column w-col w-col-3">
                  <div className="p21-field-title">Company Reg No</div>
                </div>
                <div className="p21-right-column w-col w-col-9">
                  <Field
                      type="text"
                      className="p21-company-reg-field w-input"
                      maxLength="256"
                      name="company_reg_no"
                      placeholder="Example User Data "
                      id="P21-Field-6"
                  />
                </div>
              </div>
              <div className="p21-second-name-wrapper w-row">
                <div className="p21-left-column w-col w-col-3">
                  <div className="p21-field-title">VAT No</div>
                </div>
                <div className="p21-right-column w-col w-col-9">
                  <Field
                      type="text"
                      className="p21-vat-field w-input"
                      maxLength="256"
                      name="vat_number"
                      placeholder="Example User Data "
                      id="P21-Field-7"
                      disabled
                  />
                </div>
              </div>
              <div className="p21-second-name-wrapper w-row">
                <div className="p21-left-column w-col w-col-3">
                  <div className="p21-field-title">Password</div>
                </div>
                <div className="p21-right-column w-col w-col-9">
                  <div className="p21-rest-password-link">
                    <Link to={ROUTES.CHANGE_PASSWORD} >
                      Change Password
                    </Link>
                  </div>
                </div>
              </div>
              {/* uncomment to add cookie consent option in user details
              <div className="p21-second-name-wrapper w-row">
                <div className="p21-left-column w-col w-col-3">
                  <div className="p21-field-title">Cookie Settings</div>
                </div>
                <div className="p21-right-column w-col w-col-9">
                  <div className="p21-rest-password-link">
                    <a href="#" id="changeCookieSettings">Change Settings</a>
                  </div>
                </div>
              </div>
              */}
              <div className="p21-second-name-wrapper w-row">
                <div className="p21-left-column w-col w-col-3">
                  <div className="p21-field-title">Account No</div>
                </div>
                <div className="p21-right-column w-col w-col-9">
                  <div className="p21-account-no-field">{details.account_no}</div>
                </div>
              </div>
              <input
                  type="submit"
                  value={isSubmitting ? 'Please wait...': 'Save' }
                  className="p21-save-button w-button"
              />
            </Form>
            <div className="w-form-done">
              <div>Thank you! Your details have been updated!</div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went wrong.</div>
            </div>
      </div>
        </Formik>


  )}

DetailsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.object,
}
