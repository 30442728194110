import React, { useEffect, useState } from "react";
import img from "../../assets/images/1Asset-28-100.jpg";
import { get } from "../../api/api-caller";
import { END_POINTS } from "../../api";
import { Link } from "react-router-dom";
import { ROUTES } from "../../utils/values";
import { formatDate } from "../../utils";

export const BlogFeed = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    get(`${END_POINTS.BLOG_CATEGORIES}/`)
      .then(({ results }) => {
        setCategories(results);
        results.length && setSelectedCategory(results[0].pk);
      })
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    selectedCategory &&
      get(`${END_POINTS.BLOG_CATEGORIES}/${selectedCategory}/`)
        .then((resp) => {
          setBlogs(resp.results);
        })
        .catch((err) => console.error(err))
        .finally(() => setIsLoading(false));
  }, [selectedCategory]);

  return (
    <div className="p9-blog-wrapper">
      <div className="p9-left-wrapper">
        <aside className="p9---side-bar seperator">
          <div className="p9---sidebar-block">
            <h4 className="p9-cat-title">Categories</h4>
            <div className="p9-left-menu">
              {categories.map(({ pk, name }) => (
                <a
                  href="#"
                  className="p9-side-menu-category-links"
                  onClick={() => {
                    setSelectedCategory(pk);
                    setIsLoading(true);
                  }}
                  style={{
                    color: selectedCategory === pk && '#333'
                  }}
                >
                  {name}
                </a>
              ))}
            </div>
            <div className="p9-left-contact-block" />
            <div className="p6-text-block-6">
              Doing something interesting?
              <br />
              We&#x27;d love to write about you! <br />
              <Link to={ROUTES.CONTACT_US}>Contact Us</Link>
            </div>
          </div>
        </aside>
      </div>
      <div className="p9-right-wrapper">
        <div className="div-block">
          <div className="div-block-2">
            <h1 className="p9-featured-title">
              All the Latest News, Insights, Guides, Customer Stories &amp;
              Product Reviews!
            </h1>
          </div>
        </div>
        <div className="w-layout-grid p9-grid">
          {!isLoading ? (
            blogs.map((blog) => (
              <div
                id="w-node-4ae4d1943ecc-1a75c61d"
                className="p9---blog-menu-frame"
              >
                <img src={blog.image} alt="" className="image-8" />
                <Link
                  className="p9---blog-title"
                  to={ROUTES.BLOG_POST + `/${blog.slug}`}
                  label={blog.title}
                  style={{marginLeft: 0}}
                >
                  {blog.title}
                </Link>
                <div className="p9---blog-date">
                  {formatDate(blog.created_at)}
                </div>
              </div>
            ))
          ) : (
            <div className="p13-trust-section-title">Loading...</div>
          )}
        </div>
      </div>
    </div>
  );
};
