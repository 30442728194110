import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo.svg";
import twitter from "../../assets/images/twitter-icon-dark.svg";
import $ from "jquery";
import { Link } from "react-router-dom";
import { ROUTES } from "../../utils/values";
import { get, post } from "../../api/api-caller";
import { END_POINTS } from "../../api";

export const FooterComponent = () => {
  const [categories, setCategories] = useState([]);
  const [email, setEmail] = useState("");
  const [isSuccess, setSuccess] = useState({ submit: false, isSuccess: false });

  useEffect(() => {
    get(END_POINTS.ROOT_CATEGORIES)
      .then((resp) => {
        setCategories(resp);
      })
      .catch((err) => console.error(err));
  }, []);
  return (
    <>
      <footer className="footer-2">
        <div className="container">
          <div className="footer-section">
            <div className="footer-row">
              <div className="footer-categories-wrapper">
                <a href="#" className="brand footer w-nav-brand">
                  <img src={logo} alt="" className="justbulklogofooter" />
                </a>
                <div className="footer-text">
                  Garden Studios
                  <br />71-75 Shelton Street,
                  <br />
                  Covent Garden,
                  <br />
                  London, WC2H 9JQ
                </div>
                <a href="tel:+4402080162108" className="footer-link-2">
                  0208 016 2108
                </a>
                <a
                  href="mailto:info@justbulk.co.uk?subject=Web%20Inquiry%20"
                  className="footer-link-2"
                >
                  info@justbulk.co.uk
                </a>
              </div>
              <div className="footer-category-wrapper">
                <div
                  data-w-id="e92daf0e-a586-ff86-4181-fccfb0e1c646"
                  className="footer-title"
                  onClick={() => {
                    $("#d1").slideToggle();
                  }}
                >
                  Categories +
                </div>
                <div
                  id="d1"
                  data-w-id="d731b55a-5e83-6c05-b76a-6ff9ceb15fa6"
                  className="footer-cat-accordian-content"
                >
                  {categories &&
                    categories.map((cat) => (
                      <Link
                        to={`${ROUTES.CATEGORIES}/${cat.slug}`}
                        className="footer-category-links"
                        key={`id-${cat.slug}`}
                      >
                        {cat.name}
                      </Link>
                    ))}
                </div>
              </div>
              <div className="footer-categories-wrapper">
                <div
                  data-w-id="38de1f43-4682-102e-2eab-26fc89b841d8"
                  className="footer-title"
                  onClick={() => {
                    $("#d2").slideToggle();
                  }}
                >
                  Useful Links +
                </div>
                <div id="d2" className="footer-cat-accordian-content">
                  <Link to={ROUTES.FAQS} className="footer-category-links">
                    FAQ&#x27;s
                  </Link>
                  <a href="#" className="footer-category-links">
                    About Us
                  </a>
                  <Link to={ROUTES.FAQS} className="footer-category-links">
                    Delivery &amp; Returns
                  </Link>
                  <Link
                    to={ROUTES.CONTACT_US}
                    className="footer-category-links"
                  >
                    Contact Us
                  </Link>
                  <Link to={ROUTES.CUSTOM_POUCHES} className="footer-category-links">
                    Custom Printing
                  </Link>
                  <Link
                    to={`${ROUTES.ROOT}terms`}
                    className="footer-category-links"
                  >
                    Terms &amp; Legal
                  </Link>
                  <Link to={ROUTES.BLOG_FEED} className="footer-category-links">
                    Blog
                  </Link>
                </div>
              </div>
            </div>
            <div className="footer-categories-wrapper col">
              <div className="footer-links">
                <div className="footer-title-newsletter">
                  Newsletter &amp; Offers
                </div>
              </div>
              <div className="form-block w-form">
                <form
                  id="wf-form-Newsletter"
                  name="wf-form-Newsletter"
                  data-name="Newsletter"
                  method="post"
                  redirect="/ThankyouNL"
                  data-redirect="/ThankyouNL"
                  className="newsletter-wrapper"
                >
                  <input
                    type="email"
                    className="search-input-field w-input"
                    maxLength="256"
                    placeholder="Enter your email..."
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <input
                    type="submit"
                    value="Submit"
                    data-wait="Please wait..."
                    className="filled-btn search-btn w-button"
                    onClick={() => {
                      email &&
                        post(END_POINTS.NEWS_LETTER, { email })
                          .then(({ results }) => {
                            setSuccess({ submit: true, isSuccess: true });
                          })
                          .catch((err) => {
                            setSuccess({ submit: true, isSuccess: false });
                          });
                    }}
                  />
                </form>
                {isSuccess.submit &&
                  (isSuccess.isSuccess ? (
                    <div className="succes-state w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                  ) : (
                    <div className="error-state w-form-fail">
                      <div>
                        Oops! Something went wrong while submitting the form.
                      </div>
                    </div>
                  ))}
              </div>
              <div className="footer-social-block">
                <a href="https://facebook.com/justbulk" className="social-block fb w-inline-block" target="_blank"/>
                <a href="https://twitter.com/justbulk" className="social-block twitter w-inline-block" target="_blank"/>
                <a href="https://instagram.com/justbulk" className="social-block insta w-inline-block" target="_blank"/>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-section">
          <div className="copyright-block">
            <div className="copyright-text">
              © 2020,JustBulk. All rights reserved.
            </div>
            <div className="copyright-text">
              Arent you nosy, if you&#x27;re reading this
            </div>
          </div>
          <div className="copyright-block align-right">
            <div className="stripe-block">
              <div className="stripe-text">Guaranteed secure payment with</div>
              <img
                src="https://uploads-ssl.webflow.com/5ec7f38ac0ca9b25efc04d51/5ec7f38b1681b8772c9b840c_stripe-logo.svg"
                alt=""
                className="stripe-logo"
              />
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
