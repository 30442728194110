/**
 * page 31 Mailer Bags
 * */
import React, {Fragment, useEffect, useRef, useState} from "react";
import MetaDecorator from "../../utils/meta-decorator";
import {QuoteForm} from "./qoute-form";
import {get, post} from "../../api/api-caller";
import {END_POINTS} from "../../api";
import {useParams} from "react-router-dom";
import {setRefreshCart} from "../../actions/ui/actions";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";

export const MailerBags = () => {
  
  const { productSlug } = useParams();
  const selectRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption] = useState(0);
  const [product, setProduct] = useState({});
  const [productList, setProductList] = useState([]);
  const [volumetricPrice, setVolumetricPrice] = useState({});
  const [addOn, setAddOn] = useState({});
  const product_ids = ['MBCBL0000', 'MBCLG0000', 'MBCYE0000', 'MBCWH0000', 'MBCLB0000', 'MBCRE0000', 'MBCPU0000',
                        'MBCPI0000', 'MBCOR0000', 'MBCOG0000', 'MBCGO0000', 'MBCBU0000', 'MBCBR0000']
  
  const dispatch = useDispatch();
  
  const addToBasket = (options, product) => {
    post(END_POINTS.ADD_TO_BASKET, options)
      .then((resp) => {
        window.dataLayer.push({...options, ...product, ...volumetricPrice, event:'add_to_cart'});
        toast.success("Product added successfully");
        dispatch(setRefreshCart())
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  
  const selectProduct = (product) => {
    get(`${END_POINTS.PRODUCT_DETAIL}/${product}/`)
      .then((result) => {
        setProduct(result);
        result.is_volumetric_pricing &&
        setVolumetricPrice({
          total:
            result.volumetric_prices[0].package_quantity *
            result.volumetric_prices[0].price_excl_tax,
          perUnit: result.volumetric_prices[0].price_excl_tax,
          qty: result.volumetric_prices[0].package_quantity,
          subtotal:
            result.volumetric_prices[0].package_quantity *
            result.volumetric_prices[0].price_excl_tax,
          id: result.volumetric_prices[0].id,
        });
      })
      .then(() => {
        selectRef.selectedIndex = 0;
      })
      .catch((err) => {
        console.error(err);
      });
  }
  
  useEffect(() => {
    get(`${END_POINTS.PRODUCTS}/mailer-bags?page_size=100`)
        .then(({results}) => {
          const filtered_list = results.filter((prod) => product_ids.includes(prod.sku) );
          setProductList(filtered_list);
          selectProduct(results[0].slug);
        })
        .catch((err) => {
          console.error(err);
        });
  }, [productSlug]);
  
  return (
    <React.Fragment>
      <MetaDecorator
        description="Printed Mailer Bags delivered in 10 Days! We Print in-house in the UK, Low MOQ’s, Instant Online Custom Mailer Bag Pricing, Fastest Turnaround Across the UK & Europe & We offer Free Artwork Support!"
        title="Custom Printed Mailer Bags & Poly Bags"
        robots="“index, follow”"
      />
      <div className="p28-content-wrapper">
        <div className="p28-header-wrapper">
          <div className="p28-page-title-wrapper">
            <div className="p28-title-hash">#CustomPolyBags</div>
            <h1 className="p31-h1">Custom Printed Mailer Bags</h1>
            <h1 className="p28-h2">100% Recyclable Mailer Bags Printed / Poly Bags and delivered to your door within 10
              Days, We&#x27;re proud to boat the fastest turnaround time across the UK &amp; Europe as we print in
              house!</h1>
            <h3 className="p28-h3">Instant Pricing Below! Scroll Down!!</h3>
          </div>
          <div className="p28-picture-container"><img
            src="https://justbulk.co.uk/media/uploaded-media/Just-Bulk-Custom-Printed-Poly-Bags.jpg" loading="lazy"
            width="800" height="386"
            srcSet="https://justbulk.co.uk/media/uploaded-media/Just-Bulk-Custom-Printed-Poly-Bags.jpg 500w, https://justbulk.co.uk/media/uploaded-media/Just-Bulk-Custom-Printed-Poly-Bags.jpg 800w"
            sizes="(max-width: 479px) 100vw, (max-width: 991px) 98vw, 52vw"
            alt="" className="p28-banner-image"/></div>
        </div>
        <div className="p29-strip-wrapper">
          <div className="p29-snippet-block">
            <div className="p28-bullet-point"></div>
            <div className="p28-snippet-text"><strong className="bold-text-2">Printed In house (UK)</strong></div>
          </div>
          <div className="p29-snippet-block">
            <div className="p28-bullet-point"></div>
            <div className="p28-snippet-text"><strong className="bold-text-2">Custom Print from 250 Units</strong></div>
          </div>
          <div className="p29-snippet-block">
            <div className="p28-bullet-point"></div>
            <div className="p28-snippet-text"><strong className="bold-text-2">Free Design Help</strong></div>
          </div>
          <div className="p29-snippet-block">
            <div className="p28-bullet-point"></div>
            <div className="p28-snippet-text"><strong className="bold-text-2">No Hidden Costs</strong></div>
          </div>
        </div>
        <div className="p29-content-wrapper-1">
          <div className="p29-c1-image-container">
            <img
              src="https://justbulk.co.uk/media/uploaded-media/Just-Custom-Printed-Mailer-Bags.png"
              loading="lazy"
              width="700"
              height="380"
              alt=""
              className="p29-c1-image"/>
          </div>
          <div className="p29-c1-text-wrapper">
            <h4 className="p28-h4">Our Service </h4>
            <div className="p28-c1-text">Within 10 Days! We usually do it quicker though, sometimes even 7! Nearly every
              other company has a minimum lead time of 4-6 weeks while they place your order with some person in china.
              We Print In House, No Duties to Pay, All Inclusive Pricing, Free Artwork Help and we can even send you our
              stock material for you to have feel if you want!
            </div>
            <div className="p28-accent-text"><strong>We maintain our quality &amp; service.</strong></div>
          </div>
        </div>
        <div className="p29-content-wrapper-2">
          <div className="p29-c2-wrapper">
            <div className="p29-c2-block-1">
              <div className="p29-c2-block-1-image-container">
                <img
                  src="https://justbulk.co.uk/media/uploaded-media/Just-Bulk-Printed-Poly-Bag.png"
                  loading="lazy"
                  width="495"
                  height="380"
                  alt="" className="p31-c2-block-1-image"
                />
              </div>
              <div className="p29-c2-block-1-text-wrapper">
                <h4 className="p28-h4">Our Product Quality </h4>
                <div className="p28-c1-text">We print using the highest quality plastisol inks to ensure your branding
                  doesn&#x27;t get scuffed while our for delivery. As well as that we print on 100% Virgin LDPE as
                  opposed to using mixed purity LDPE, this gives our printed mailer bags a nice smooth glossy finish as
                  opposed to it having a bin bag feel!<br/><br/>Both our stock and custom printed mailer bags feature a
                  50-55 Micron thickness, are 100% opaque and have a heat pressed strip seal on the open end.
                </div>
                <div className="p28-accent-text"><strong>Suitable for courier use!</strong></div>
              </div>
            </div>
            <div className="p29-c2-block-2">
              <div className="p29-c2-block-2-image-container">
                <img
                  src="https://justbulk.co.uk/media/uploaded-media/Just-Bulk-Printed-Mailer-Bag.png"
                  loading="lazy" width="495" height="250" alt=""
                  className="p31-c2-block-2-image"/>
              </div>
              <div className="p29-c2-block-1-text-wrapper">
                <h4 className="p28-h4">Free Artwork Support!</h4>
                <div className="p28-c1-text">Got your logo but not sure how to put it together in the right format? No
                  problem just send us a vectorised logo if you have and we&#x27;ll do the rest for you!
                </div>
                <div className="p28-accent-text"><strong>Just ask us, what you would like and we&#x27;ll try and do it
                  for you!</strong></div>
              </div>
            </div>
          </div>
        </div>
        <div className="p31-custom-mailer-pricing-title">
          <strong>Printed Mailer Bag / Poly Bag Pricing</strong>
        </div>
        <div className="p31-content-wrapper-3">
          <div className="p31-left-wrapper">
            <img
              src={product?.images ? product?.images[0]?.original  : "https://justbulk.co.uk/media/uploaded-media/Red-Mailer-Bag-Just-Bulk.png"}
              loading="lazy" alt=""
              className="p31-product-image"
            />
            <div className="p31-ink-title"><strong>Base Colours</strong></div>
          </div>
          <div className="p31-right-wrapper">
            <div className="p31-c3-product-selection-wrapper">
              <div className="p28-price-wrapper">
                <div className="p28-product-price">
                  <strong>£
                    {product.is_volumetric_pricing
                      ? parseFloat(volumetricPrice.subtotal).toFixed(2)
                      : product.price && parseFloat(product.price.incl_tax).toFixed(2)}
                  </strong>
                </div>
                <div className="p28-unit-cost">
                  <strong> {
                    product.is_volumetric_pricing && `£${( parseFloat(volumetricPrice.perUnit) + parseFloat(addOn.price || 0)).toFixed(2)}`}
                  </strong>
                </div>
                <div className="p28-unit-symbol">P/Unit</div>
              </div>
              <div className="p28-dropdown-wrapper">
                <div className="p28-dropdown-title">
                  <strong>Base Colour</strong>
                </div>
                <div data-hover="" data-delay="0" className="additional-options-wrapper-landing-pages">
                  <select
                    id="field"
                    name="format"
                    required=""
                    className="additional-options"
                    onChange={(e)=>{ selectProduct(e.target.value)}}
                  >
                    {productList.map((option) => (
                      <option
                        value={option.slug}
                        selected={option.slug === productList[0].slug}
                      >
                        {option.title}
                      </option>
                    ))}
                  </select>
                </div>
               
                  <Fragment>
                    <div className="p28-dropdown-title">
                      <strong>Size Variant </strong>
                    </div>
                    <div data-hover="" data-delay="0" className="additional-options-wrapper-landing-pages">
                      {(product.product_variants && product.product_variants.length) ? (
          
                        <select
                          value={selectedOption}
                          className="additional-options"
                          onChange={(e)=> selectProduct(e.target.value)}
                        >
                          <option className="w-dropdown-link" value="0" disabled selected>
                            {product.title}
                          </option>
                          {product.product_variants &&
                          product.product_variants.map((option) => (
                            <option className="w-dropdown-link" value={option.slug}>
                              {option.title}
                            </option>
                          ))}
                        </select>
        
                      ) : (
                        <select
                          value={selectedOption}
                          className="additional-options"
                          onChange={(e)=> selectProduct(e.target.value)}
                        >
                          <option className="w-dropdown-link" value="0" disabled selected>
                            none
                          </option>
                        </select>
                      ) }
                    </div>
                  </Fragment>
                
                  <Fragment>
                    <div className="p28-dropdown-title">
                      <strong>Ink Options</strong>
                    </div>
                    <div data-hover="" data-delay="0" className="additional-options-wrapper-landing-pages">
                      <select
                        className="additional-options"
                        onChange={({ target: { value } }) => {
                          if (value === "0") {
                            setAddOn({});
                            setVolumetricPrice({
                              ...volumetricPrice,
                              subtotal: volumetricPrice.total,
                            });
                            return;
                          }
                          const [price, id] = value.split(",");
                          setAddOn({
                            id,
                            price: parseFloat(price),
                          });
                          setVolumetricPrice({
                            ...volumetricPrice,
                            subtotal:
                              (volumetricPrice.perUnit + parseFloat(price)) *
                              volumetricPrice.qty,
                          });
                        }}
                      >
                        <option className="w-dropdown-link" value="0">
                          None
                        </option>
                        {product.additional_options &&
                        product.additional_options.map((option) => (
                          <option
                            className="w-dropdown-link"
                            value={`${option.additional_per_unit_price},${option.id}`}
                          >
                            {`${option.text} - £${option.additional_per_unit_price}`}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Fragment>
                
                  <Fragment>
                    <div className="p28-dropdown-title">
                      <strong>Quantity </strong>
                    </div>
                    <div data-hover="" data-delay="0" className="additional-options-wrapper-landing-pages">
                      <select
                        className="additional-options"
                        onChange={({ target: { value } }) => {
                          const parts = value.split(",");
                          setVolumetricPrice({
                            ...volumetricPrice,
                            total: parts[0] * parts[1],
                            perUnit: parseFloat(parts[1]),
                            qty: parseFloat(parts[0]),
                            subtotal:
                              parts[0] * (parseFloat(parts[1]) + (addOn.price || 0)),
                            id: parts[2],
                          });
                        }}
                      >
                        {product.volumetric_prices ?
                        product.volumetric_prices.map((option) => (
                          <option
                            className="w-dropdown-link"
                            value={`${option.package_quantity},${option.price_excl_tax},${option.id}`}
                          >
                            {`${option.name} - ${option.package_quantity} Units - £${option.price_excl_tax}`}
                          </option>
                        )) :
                          <option className="w-dropdown-link" value="0">
                            None
                          </option>
                        }
                      </select>
                    </div>
                  </Fragment>
                <div className="p31-button-wrapper">
                  <a
                    href="#"
                    onClick={() => {
                      const options = { url: product.url };
                      if (Object.keys(volumetricPrice).length) {
                        options.package_id = volumetricPrice.id;
                      }
                      if (Object.keys(addOn).length) {
                        options.additional_option_id = addOn.id;
                      }
                      setIsLoading(true);
                      addToBasket({ ...options }, product);
                    }}
                    className="p28-add-to-cart-button w-button"
                  >
                    {isLoading ? "Please wait..." : "Add to Cart"}
                  </a>
                  <a href="#custom_quote_form" className="p31-custom-quote-button w-button">Custom Quote / Query</a>
                </div>
              </div>
            </div>
            <div className="p31-ink-wrapper">
              <div className="p31-ink-title"><strong>Available Ink Colours: </strong></div>
              <div className="p31-ink-row-1">
                <div className="p31-lemon-yellow"></div>
                <div className="p31-bright-green"></div>
                <div className="p31-cerise"></div>
                <div className="p31-orange"></div>
                <div className="p31-deep-red"></div>
                <div className="p31-white"></div>
                <div className="p31-green"></div>
                <div className="p31-azure-blue"></div>
                <div className="p31-blue"></div>
                <div className="p31-violet"></div>
                <div className="p31-black"></div>
              </div>
              <div className="p31-ink-row-2">
                <div className="p31-green-row-2"></div>
                <div className="p31-azure-blue-row-2"></div>
                <div className="p31-blue-row-2"></div>
                <div className="p31-violet-row-2"></div>
                <div className="p31-black-row-2"></div>
              </div>
            </div>
            <div className="p31-c3-text-wrapper">
              <div className="p31-c3-block-1-text-wrapper">
                <div className="p31-accent-text-c3"><strong>If you have multiple designs, require higher quantities,
                  need a custom size, or simply want it cheaper and can commit to a future contract fill out our custom
                  quote form..</strong></div>
                <div className="p31-c3-text-2"><strong>- No Colour Fees - No Artwork Fees - No Setup Fees <br/>- No
                  Hidden Import Taxes/Fees - Produced In the UK</strong><br/><span className="text-span-3"><strong>- Manufactured &amp; Delivered within 10 Days<br/>- Fastest Turnaround in the UK &amp; Europe</strong></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p28-content-wrapper-4">
          <div className="p31-c4-title"><strong className="bold-text-3">The Process Ahead</strong></div>
          <div className="p28-c4-process-wrapper">
            <div className="p28-step-block">
              <div className="p28-c4-number">1.</div>
              <div className="p28-step-text">Select your Base Colour, Size, Ink Colour, Quantity and hit Checkout!</div>
            </div>
            <div className="p28-step-block">
              <div className="p28-c4-number">2.</div>
              <div className="p28-step-text">Simply Upload your Artwork in your Account with your order Ref and let us
                know what&#x27;s what!
              </div>
            </div>
            <div className="p28-step-block">
              <div className="p28-c4-number">3.</div>
              <div className="p28-step-text">We&#x27;ll email you a proof, once you accept, we&#x27;ll que your order
                for production.
              </div>
            </div>
          </div>
        </div>
        <div className="p29-content-wrapper">
          <div className="p28-c5-title"><strong className="bold-text-3">Why Custom Print your mailer bags..</strong>
          </div>
          <div className="p28-spec-text"><strong>With everyone becoming more remote, it&#x27;s becoming all the more
            difficult to add brand value! Luckily if you&#x27;re retailing online mailer bags provide a great cost
            efficient solution to deliver both your goods and brand name in a sleek and fashionable
            manner!<br/><br/>It&#x27;s all about goods being &quot;instagrammable&quot; or &quot;Snappable&quot; you
            want
            your customers to share pictures of their shipments with all their friends &amp; followers!<br/><br/>You may
            be thinking some people are already doing or you&#x27;re not sure but with our short print runs we can
            literally print you new designs for every month, displaying a new tale with your branding!<br/><br/>Consumers
            go out the way for new and exclusive content, this is also a great way to add value to your product
            subscriptions!</strong><br/></div>
        </div>
        <div className="p29-content-wrapper">
          <div className="p28-c5-title"><strong className="bold-text-3">Specifications </strong></div>
          <div className="p28-spec-text"><strong>Material: Printed on 55 Mic LDPE, 100% Recyclable, Recycling Code 4,
            Recyclable in most waste streams across the UK &amp; Europe.All material and ink has been tested for scratch
            resistance and exposure to harsh storage conditions during transport and is deemed suitable to be couriered
            locally.<br/><br/>Artwork: Maximum Print Size: 350 x 450mm, All artwork must be provided in a PDF or Ai file
            in a vector format. If you&#x27;re unsure of this get in touch and we&#x27;ll be more than happy to help.
            If you have your logo we can also create the template for you!Once you&#x27;ve made a purchase, head over
            to My Account and in the Artwork section upload your logo with the related order reference no and any
            additional notes.<br/><br/>If your artwork contains very fine details please send us your artwork before
            hand to ensure it&#x27;s printable due to the nature of the material lines finer than 3mm may appear
            distorted<br/><br/>Order: Minimum Print run is 500 Units Maximum is 2000 within our 10 Day Turnaround from
            the point of artwork approval. In most cases we aim to dispatch within 7 Days. We provide the fastest
            turnaround across the UK &amp; Europe If you would still like it faster please get in touch.<br/><br/>We
            supply nearly 40 partner/resellers across the UK alone, If you have ordered from one of them please
            note we do not offer the same lead time, MOQ or artwork service to them.<br/><br/>Colours: The Product
            Colour represents the base colour of the mailer bag and is available in 15 different shades.
            Please note colours may vary across monitors. Ink swatches online do not represent the shade of
            the actual ink and is listed solely for representative purposes to ease the selection process. Ink
            Shade may vary depending on the colour of the substrate printed on. We are able to mix your own
            pantone colour if required for an additional cost of £50 and a minimum print run of 1000
            Units.</strong><br/></div>
        </div>
        <div className="p31-content-wrapper-8-copy" id="custom_quote_form">
          <div className="p28-c8-title">
            <strong className="bold-text-3">Get a Custom Quote within 48 Hours!</strong>
          </div>
          <div className="p28-form w-form">
            <QuoteForm pageTitle="custom-printed-mailer-bags"/>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MailerBags;