import React from "react";

export default function ErrorMessage({ errors, name, touched }) {
  if ((errors && !errors[name]) ||( touched && !touched[name]) || !errors) {
    return null;
  }
  if(errors)
    return <p className="w-commerce-commercecheckoutlabel p5-field-label" style={{color: "red"}}>{errors[name]}</p>;
  
}
