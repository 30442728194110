import React from "react";
import { Link as ReactLink } from "react-router-dom";

export const Link = ({ breadcrumbs, setBreadCrumbs, label, to, children }) => (
  <ReactLink
    onClick={() =>
      setBreadCrumbs([ ...breadcrumbs, {
        label: children,
        to,
      }])
    }
    to={to}
  >
    {children}
  </ReactLink>
);
