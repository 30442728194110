import { object, string } from "yup";
import { withFormik } from "formik";
import { END_POINTS } from "../../../api";
import { CardElement } from "@stripe/react-stripe-js";
import { store } from "../../../store";
import { toast } from "react-toastify";
import { getShippingMethod } from "../../../utils";
import {
  getBillingFormValidations,
  getShippingFormValidations,
  requiredNullableString,
} from "./utils";

/*
 * formSchema serves both as the initial state to form as well
 * as set of validation rules to apply on form fields
 * */

const formSchema = object().shape({
  country: requiredNullableString(),
  first_name: requiredNullableString(),
  last_name: requiredNullableString(),
  phone_number: string()
    .nullable()
    .test(
      "internationalNumber",
      "Phone must be in international format",
      (val = " ") => val.charAt(0) === "+"
    )
    .required("Field is required"),
  email: string().email("Invalid Email").required("Field is required"),
  vat_number: string().nullable(),
  shipping_method_code: string().nullable().required("Field is required"),
  ...getShippingFormValidations(),
  ...getBillingFormValidations(),
});

/*
 * HOC to enhance our form with <Formik />
 * Helps us pull out logic from the generator-form.jsx component
 * */
export const formikEnhancer = withFormik({
  enableReinitialize: false,
  validationSchema: formSchema,
  validateOnChange: false,
  validateOnBlur: false,
  mapPropsToValues: ({
    account,
    addresses = {
      billing_address: {},
      shipping_address: {},
    },
    shippingMethods,
    basket,
  }) => {
    const shippingMethod =
      getShippingMethod(shippingMethods, addresses?.shipping_address?.country) ||
      {};
    /**
     * discount calculation
     * */
    const discount =  basket?.voucher_discounts?.length
      ? basket?.voucher_discounts.reduce((total, { amount }) => total + parseFloat(amount), 0)
      : 0;
    const initial_values_calculations = () => {
      return {
        ...addresses.shipping_address,
        shipping_method_code: shippingMethod?.code,
        shipping_price: shippingMethod?.price?.excl_tax,
        shipping_price_tax: shippingMethod?.price?.tax,
        title: account.company_name,
        first_name: account.first_name,
        last_name: account.last_name,
        email: account.email,
        phone_number: account.phone_number,
        billingAddress: true,
        recipient_name: `${addresses.shipping_address?.first_name || ""} ${
          addresses.shipping_address?.last_name || ""
        }`,
        billing_recipient_name: `${addresses.billing_address?.first_name || ""} ${
          addresses.billing_address?.last_name || ""
        }`,
        billing_line1: addresses.billing_address?.line1,
        billing_line2: addresses.billing_address?.line2,
        billing_state: addresses.billing_address?.state,
        billing_line3: addresses.billing_address?.line3,
        billing_postcode: addresses.billing_address?.postcode,
        billing_country: addresses.billing_address?.country,
        vat_number: account.vat,
        eori_number: account.eori_number,
        /**
         * calculation formula (Subtotal - Discounts) * 0.20
         * */
        all_inclusive_tax_amount: (basket?.total_excl_tax_excl_discounts - discount) * 0.20,
        tax_handling_method: "all_inclusive",
        basket_total: 0,
      };
    }
    
    return {...initial_values_calculations()}
  },
  handleSubmit: (
    values,
    {
      setSubmitting,
      setFieldError,
      props: { basket, onSubmit, stripe, elements, countries, account },
    }
  ) => {
    const intentRequestHeaders = {
      "Content-Type": "application/json",
    }
    if(store.getState().auth.account.token){
      intentRequestHeaders["Authorization"] = `Token ${store.getState().auth.account.token}`;
    }
    fetch(END_POINTS.PAYMENT_INTENT, {
      method: "POST",
      headers: intentRequestHeaders,
      body: JSON.stringify(
        {
          shipping_method: values.shipping_method_code,
          shipping_country: countries.find( country => country.url === values.country).iso_3166_1_a3,
          tax_handling_method: values.tax_handling_method,
          basket: basket.url,
       //   total: values.basket_total
        }),
    })
      .then((res) =>{
        if(res) {
          if(res.status == 200)
            return res.json();
          else {
            toast.error(`Error: ${res.statusText}`);
            setSubmitting(false);
          }
        }
      })
      .then(async (data) => {
        if(data){
          if (data.error) {
            toast.error(data.error);
            setSubmitting(false);
            return;
          }
          const payload = await stripe.confirmCardPayment(data.clientSecret, {
            payment_method: { card: elements.getElement(CardElement) },
          });
          if (payload.error) {
            setFieldError("payment", payload.error.message);
            setSubmitting(false);
          } else {
            const data = {
              basket: basket.url,
              shipping_method_code: values.shipping_method_code,
              guest_email: account?.token ? '' : values.email,
              password: account?.token ? '' : values.password,
              shipping_address: {
                ...values,
                phone_number: values.recipient_phone,
                is_default_for_shipping: true,
                is_default_for_billing: values.billingAddress
              },
            };
            if (values.billingAddress) {
              data.billing_address = { ...values };
            } else {
              data.billing_address = {
                recipient_name: values.billing_recipient_name,
                phone_number: values.billing_recipient_phone,
                line1: values.billing_line1,
                line2: values.billing_line2,
                state: values.billing_state,
                line3: values.billing_line3,
                postcode: values.billing_postcode,
                country: values.billing_country,
              };
            }
            data.billing_address.is_default_for_billing = true;
            data.billing_address.is_default_for_shipping = values.billingAddress;
            onSubmit({
              data,
              setSubmitting,
            });
          }
        }
      });
  },
});
