import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {formikEnhancer} from "./form-utils";
import {COLORS} from "../../../../style/theme";
import {customerHelpOption} from "../../../../store/dataHelper";
import {FileUpload} from "../../fileupload";

const SupportForm = ({
 isValid,
 isSubmitting,
 errors,
 handleBlur,
 handleChange,
 handleSubmit,
 values,
 setFieldValue,
 setSubmitting,
 }) => (
  <Fragment>

    <form id="email-form" name="email-form" data-name="Email Form" className="p24-form" onSubmit={handleSubmit}>
      <label htmlFor="name" className="p24-support-form-field-title">
        We aim to get back to you within 24 hours!
      </label>
      <select
        id="field"
        name="type"
        required=""
        className="w-select"
        onChange={(e)=>{ setFieldValue("type", e.target.value)}}
        onBlur={handleBlur}
        value={values.type}
      >
        {customerHelpOption.map((option) => (
          <option
            value={option.value}
            selected={option.label === ""}
          >
            {option.label}
          </option>
        ))}
      </select>
      <input
        type="text"
        className="p24-product-code-field w-input"
        maxLength="256"
        name="reference"
        data-name="Product Code"
        placeholder="Product Code or Order Reference (if you have one)"
        id="product_code"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.reference}
        style={{backgroundColor: errors.first_name ? COLORS.ERROR : 'transparent'}}
      />
      <textarea
        placeholder="Tell us about your Query / Issue"
        maxLength="5000"
        id="node" data-name=""
        className="p24-query-description-field w-input"
        name="message"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.message}
      />

      <FileUpload
        values={values}
        setFieldValue={setFieldValue}
        setSubmitting={setSubmitting}
        component='customer-support'
        allowMultiple
      />

      <input
        type="submit"
        className="p24-support-submit-button w-button"
        value={isSubmitting ? "Please wait..." : "Submit"}
        disabled={!isValid || isSubmitting}
      />
    </form>

  </Fragment>


);

SupportForm.propTypes = {
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func,
  setSubmitting: PropTypes.func,
};

export const CustomerSupportForm = formikEnhancer(SupportForm);

CustomerSupportForm.propTypes = {
  attemptSubmit: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};
