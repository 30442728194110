import React from "react";
import PropTypes from "prop-types";
import {formikEnhancer} from "./form-utils.js";

const Form = ({
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    isSubmitting,
    errors,
    setFieldValue,
    values,
    setSubmitting
    }) => (

            <div className="p22-date-filter-block w-form">
                <form
                    id="email-form"
                    name="email-form"
                    data-name="Email Form"
                    className="p22-filter-form"
                    onSubmit={handleSubmit}
                >
                    <label htmlFor="name" className="p22-from-label">
                        From
                    </label>
                    <input
                        type="date"
                        className="p22-from-date w-input"
                        maxLength="256"
                        name="start_date"
                        placeholder="dd/mm/yyyy"
                        id="name"
                        onChange={handleChange}
                    />
                    <label htmlFor="email-3" className="p22-to-label">
                        To
                    </label>
                    <input
                        type="date"
                        className="p22-to-label w-input"
                        maxLength="256"
                        name="end_date"
                        placeholder="dd/mm/yyyy"
                        id="email-3"
                        required=""
                        onChange={handleChange}
                    />
                    <input
                        type="submit"
                        value={isSubmitting ? 'Please wait...': 'Filter' }
                        className="p22-date-filter-button w-button"
                    />
                </form>
                {/*<div className="w-form-done">*/}
                {/*  <div>Your filtered invoices can be found below!</div>*/}
                {/*</div>*/}
                {/*<div className="w-form-fail">*/}
                {/*  <div>Oops! Something went wrong.</div>*/}
                {/*</div>*/}
            </div>
);

Form.propTypes = {
    isValid: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setSubmitting: PropTypes.func,
    values: PropTypes.object.isRequired,
    setIsError: PropTypes.func,
};

export const OrderHistoryFilter = formikEnhancer(Form);

OrderHistoryFilter.propTypes = {
    attemptSubmit: PropTypes.func.isRequired,
};
