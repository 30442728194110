import {
  createStore,
} from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from "redux-devtools-extension";

import { rootReducer } from '../reducers';

const persistConfig = {
  key: 'root',
  whitelist: ['auth'],
  blacklist: ['ui'],
  storage,
};

const pReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(pReducer, composeWithDevTools());

persistStore(store);
