import React from "react";
import { formikEnhancer } from "./form-utils";

const Form = ({
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  isSuccess,
  setFieldValue,
}) => (
  <>
    <form
      id="email-form"
      name="email-form"
      data-name="Email Form"
      onSubmit={handleSubmit}
    >
      <input
        type="text"
        className="p1---modal-first-name w-input"
        maxLength="256"
        name="first_name"
        placeholder="First Name"
        id="node"
        required=""
        onChange={handleChange}
      />
      {errors.first_name && (
        <label
          className="w-commerce-commercecheckoutlabel p5-field-label"
          style={{ color: "red" }}
        >
          {errors.first_name}
        </label>
      )}
      <input
        type="text"
        className="p1---modal-last-name w-input"
        maxLength="256"
        name="last_name"
        data-name="Last Name"
        placeholder="Last Name"
        id="Last-Name"
        required=""
        onChange={handleChange}
      />
      {errors.last_name && (
        <label
          className="w-commerce-commercecheckoutlabel p5-field-label"
          style={{ color: "red" }}
        >
          {errors.last_name}
        </label>
      )}
      <input
        type="email"
        className="p1---modal-email w-input"
        maxLength="256"
        name="email"
        data-name="Email"
        placeholder="Email Address"
        id="Email-3"
        required=""
        onChange={handleChange}
      />
      {errors.email && (
        <label
          className="w-commerce-commercecheckoutlabel p5-field-label"
          style={{ color: "red" }}
        >
          {errors.email}
        </label>
      )}
      <input
        type="tel"
        className="p1---modal-phone-no w-input"
        maxLength="256"
        name="contact_no"
        data-name="Phone"
        placeholder="Phone No"
        id="Phone"
        required=""
        onChange={handleChange}
      />
      {errors.contact_no && (
        <label
          className="w-commerce-commercecheckoutlabel p5-field-label"
          style={{ color: "red" }}
        >
          {errors.contact_no}
        </label>
      )}
      <textarea
        placeholder="Tell us how we can help you!"
        maxLength="5000"
        id="Requirements"
        name="message"
        required=""
        data-name="Requirements"
        className="p1---modalform-text-area w-input"
        onChange={handleChange}
      />
      {errors.message && (
        <label
          className="w-commerce-commercecheckoutlabel p5-field-label"
          style={{ color: "red" }}
        >
          {errors.message}
        </label>
      )}
      <label className="w-checkbox">
        <input
          type="checkbox"
          id="checkbox"
          name="checkbox"
          data-name="Checkbox"
          className="w-checkbox-input"
          onChange={(e) => setFieldValue("checkbox", e.target.checked)}
        />
        <span className="checkbox-label w-form-label">
          I consent to a monthly email with the latest Customer Stories,
          Industry News &amp; Offers
        </span>
      </label>
      <input
        type="submit"
        value={isSubmitting ? "Please wait..." : "Submit"}
        className="p1---modal-submit-button w-button"
        disabled={isSubmitting}
      />
    </form>
    {isSuccess && (
      <div className="succes-state w-form-done">
        <div>Thank you! Your submission has been received!</div>
      </div>
    )}
  </>
);

export const CustomQuoteForm = formikEnhancer(Form);
