import React from "react";
import { Formik, Field, Form } from "formik";
import {END_POINTS} from "../../api";
import {post} from "../../api/api-caller";
import {toast} from "react-toastify";
import { reportEvent } from "../../utils";
import PropTypes from "prop-types";

export const QuoteForm = ({pageTitle}) => {

  const handleSubmit = (values, actions) => {
    let data = {...values, message: `\n -Your Requirements? \n  ${values.requirement} \n -What are you packing? \n  ${values.packing} \n -How many do you need? \n  ${values.quantity} \n -When do you need these? \n  ${values.delivery_time} `, };
    post(END_POINTS.CONTACT_US, data )
        .then((resp) => {
          reportEvent('Enquiry', 'Submit', pageTitle)
          toast.success('Thank you! Your submission has been received!')
          actions.resetForm({
            values: {
              type: 'enquiry',
              packing: "",
              quantity: "",
              delivery_time: "",
              email: "",
              requirement: "",
              first_name: "",
              last_name: "",
              contact_no: "",
            },
          });
        })
        .catch();
  }
  return (
      <>
        <Formik
            initialValues={{type: 'enquiry'}}
            // validationSchema={validations}
            onSubmit={handleSubmit}
            enableReinitialize
        >
          <Form id="email-form" name="email-form" data-name="Email Form" className="p28-form-block">
            <div className="p28-form-block-2">
              <Field
                  type="text"
                  className="p28-form-field w-input"
                  maxLength="256"
                  name="packing"
                  data-name="Packing"
                  placeholder="What are you packing?"
                  id="Packing"
                  required
              />
              <Field
                  type="text"
                  className="p28-form-field w-input"
                  maxLength="256"
                  name="quantity"
                  data-name="Quantity"
                  placeholder="How many do you need?"
                  id="Quantity-4"
                  required/>
              <Field
                  type="tel"
                  className="p28-form-field w-input"
                  maxLength="256"
                  name="delivery_time"
                  data-name="delivery time"
                  placeholder="When do you need it?"
                  id="Phone-2"
                  required/>
              <Field
                  type="text-area"
                  data-name="Field"
                  maxLength="5000"
                  id="field"
                  name="requirement"
                  required
                  placeholder="Tell us about your Requirements"
                  className="p28-form-para w-input">
            </Field>
            </div>
            <div className="p28-form-block-2">
              <Field
                  type="text"
                  className="p28-form-field w-input"
                  maxLength="256"
                  name="first_name"
                  data-name="First Name 2"
                  placeholder="First Name"
                  id="First-Name-2"
                  required
              />
              <Field
                  type="text"
                  className="p28-form-field w-input"
                  maxLength="256"
                  name="last_name"
                  data-name="Last Name 2"
                  placeholder="Last Name"
                  id="Last-Name-2"
                  required
              />
              <Field
                  type="tel"
                  className="p28-form-field w-input"
                  maxLength="256"
                  name="contact_no"
                  data-name="Phone 2"
                  placeholder="Phone"
                  id="Phone-2"
                  required/>
              <Field
                  type="email"
                  className="p28-form-field w-input"
                  maxLength="256"
                  name="email"
                  data-name="Email 4"
                  placeholder="Email"
                  id="Email-4"
                  required/>
              <Field
                  type="submit"
                  value="Request Quote"
                  data-wait="Please wait..."
                  className="p28-submit-button w-button"
              />
            </div>
          </Form>
        </Formik>
        <div className="w-form-done">
          <div>Thank you! Your submission has been received!</div>
        </div>
        <div className="w-form-fail">
          <div>Oops! Something went wrong while submitting the form.</div>
        </div>
      </>
  )
}
QuoteForm.propTypes ={
  pageTitle: PropTypes.string.isRequired,
}
export default QuoteForm;
