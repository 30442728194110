import React, {useState} from "react";
import {SignupForm} from "./form";
import {post} from "../../../api/api-caller";
import {END_POINTS} from "../../../api";
import { ActivationModal } from "./index";

import {toast} from "react-toastify";

export const Signup = ({setAccountInfo, redirectUrl, setAccountToken}) => {

  const [isError, setIsError] = useState(false);
  const [isActivate, showIsActivate] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const closeModal = () => showIsActivate(false)

  return (
    <div className="p6-signup-block">
      <h1 className="p6-singup-title">Sign Up</h1>
      <div className="p6-signup-form w-form">
        <SignupForm
          attemptSignup={({data, setSubmitting}) => {
             post(END_POINTS.SIGN_UP, data)
               .then((resp) => {
                 setUserEmail(data.email);
                 setAccountToken(resp.token)
                 setAccountInfo(resp);
                 showIsActivate(true)
                toast.success(`Signed up successfully !`);
               })
               .catch(() => {
                 setIsError(true);
                 setSubmitting(false);
               })
          }}
        />
        {
          isError &&
          <div className="e_signup_errorblock w-form-fail" style={{display: 'block'}}>
            <div className="e_signup_errormessageblock">
              Oops! That&#x27;s not right, try again!
            </div>
          </div>
        }
        {/*{isActivate &&*/}
        {/*  <ActivationModal*/}
        {/*      closeModal={closeModal}*/}
        {/*      email={userEmail}*/}
        {/*      redirectUrl={redirectUrl}*/}
        {/*  />*/}
        {/*}*/}
      </div>
    </div>
  );
};
