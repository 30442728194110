import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import { Link } from "react-router-dom";
import { ROUTES } from "../../utils/values";
import bag from "../../assets/images/shopping_bag.svg";
import { Cart } from "../cart";
import logo from "../../assets/images/logo.svg";
import { get, post } from "../../api/api-caller";
import { END_POINTS } from "../../api";
import { CustomQuote } from "../product-detail/custom-quote";
import { CookieModal } from "../account/cookie-modal";
import { toast } from "react-toastify";
import Cookies from "js-cookie"
import { ActivationModal } from "../login-signup/signup";
import Autocomplete from 'react-autocomplete';
import { FreeSampleRequest } from "../product-detail/free-sample";
import { CustomPouchQuoteForm } from "../product-landing/custom-pouch-qoutation"

export const HeaderComponent = ({
  verifyAccount,
  account,
  cartVisibility,
  toggleCart,
  setBasket,
  basket,
  refreshCart,
  customQuoteModal,
  showCookieModal,
  showActivationModal,
  toggleCookieModal,
  toggleCustomQuoteModal,
  toggleActivationModal,
  resetAuth,
  shippingCountry,
  taxHandlingMethod,
  showFreeSampleModal,
  toggleFreeSampleModal,
  togglePouchQuotationModal,
  showPouchQuotationForm
}) => {

  const history = useHistory();

  useEffect(()=>{
   toggleActivationModal();
  });

  const logout = () => {
    post(END_POINTS.LOGOUT)
      .then((resp) => {
        if (!resp.success) {
          toast.error("Something went wrong !");
        } else {
          resetAuth();
        }
      })
      .catch((err) => console.error(err));
  };

  const userCookieAcceptance = () => {
    Cookies.set("cookies-accept", true, { expires: 365})
    toggleCookieModal(false);
  };
  
  useEffect(() => {
    get(`${END_POINTS.BASKET}?shipping_country=${shippingCountry}&tax_handling_method=${taxHandlingMethod ? taxHandlingMethod : null}`)
      .then((resp) => {
        get(
          `${END_POINTS.BASKETS}${resp.id}/lines/?page_size=30`,
          null,
          !account.token
        )
          .then((lines) => {
            setBasket({
              ...resp,
              lines: [...lines.results.sort((a, b) => a.product.title.localeCompare(b.product.title))],
            });
          })
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  }, [account.token, refreshCart]);

  const [categories, setCategories] = useState([]);
  const [selectedCat, setSelectedCat] = useState(null);

  useEffect(() => {
    get(END_POINTS.ROOT_CATEGORIES).then((resp) => {
      setCategories(resp);
    });
  }, []);

  const [subCategories, setSubCategories] = useState([]);

  useEffect(() => {
    const isSideBarVisible = $(".w-nav-overlay").css("display") === "block";
    selectedCat &&
      isSideBarVisible &&
      get(`${END_POINTS.ROOT_CATEGORIES}${selectedCat}/?page_size=30`)
        .then((resp) => {
          setSubCategories(resp);
          $(`.sidemenu-sub-category-accordian_${selectedCat}`).slideDown();
        })
        .catch((err) => console.error(err));
  }, [selectedCat]);

  const [query, setQuery] = useState("");

  const [searchResults, setSearchResults] = useState([]);

  return (
    <>
      {cartVisibility && (
        <Cart
          onClose={() => {
            toggleCart();
          }}
        />
      )}
      
      {customQuoteModal && (
        <CustomQuote
          onClose={() => toggleCustomQuoteModal(false)}
          token={account.token}
        />
      )}
      
      {showCookieModal && (
       <CookieModal onAccept={() => userCookieAcceptance()}/>
      )}
      
      {showActivationModal && (
        <ActivationModal redirectUrl={"/"} email={account.email} closeModal={()=> {toggleActivationModal(false); verifyAccount()}} logout={logout}/>
      )}
      
      {showFreeSampleModal && (
        <FreeSampleRequest
          onClose={() => toggleFreeSampleModal(false)}
        />
      )}
      
      {showPouchQuotationForm && (
        <CustomPouchQuoteForm
          onClose={() => togglePouchQuotationModal(false)}
        />
      )}
      <footer className="top-menu-wrapper">
        <div className="top-menu-container">
          <div className="top-menu-text-wrapper">
            <div className="top-menu-text-block">
              <a href="#" className="header-links">
                United Kingdom
              </a>
              |
              <Link
                to={(account.token && account.email_verified) ? ROUTES.CUSTOMER_SERVICE : ROUTES.LOGIN}
                className="header-links"
              >
                Customer Service
              </Link>
              |
              <Link
                to={(account.token && account.email_verified) ? ROUTES.ACCOUNT : ROUTES.LOGIN}
                className="header-links"
              >
                My Account
              </Link>
              |
              <Link to={ROUTES.LOGIN} className="header-links">
                Register
              </Link>
              |
              <Link
                to={ROUTES.LOGIN}
                onClick={() => {
                  (account.token && account.email_verified) && logout();
                }}
                className="header-links"
              >
                {(account.token && account.email_verified)  ? "Logout" : "Login"}
              </Link>
            </div>
          </div>
        </div>
      </footer>
      <footer className="nav-wrapper">
        <div className="nav-container">
          <div
            data-collapse="all"
            data-animation="over-left"
            data-duration="400"
            role="banner"
            className="navbar w-nav"
          >
            <div className="burger-wrap">
              <div
                data-w-id="da118f73-695b-2ab4-6d7d-47639018bfcf"
                className="menu-button w-nav-button"
              >
                <img
                  src="https://uploads-ssl.webflow.com/5ec7f38ac0ca9b25efc04d51/5ec7f38b1681b846e59b83ff_hamburger.svg"
                  alt=""
                  className="burger-icon"
                />
              </div>
              {/*<Link to={ROUTES.ROOT}>*/}
              {/*  <img src={logo} />*/}
              {/*</Link>*/}
              <Link to={ROUTES.ROOT} className="justbulklogoheader" />
            </div>
            <div className="search-wrapper">
              <div className="search-block w-form">
                <div
                  id="wf-form-Header-Search"
                  name="wf-form-Header-Search"
                  data-name="Header Search"
                  className="nav-search-container"
                >
                  <div className="nav-search-field w-input" style={{ zIndex: '9997' }}>
                    <Autocomplete
                      menuStyle={{
                        marginTop: '11px',
                        background: 'white',
                        overflow: 'auto',
                        maxHeight: '30vh',
                        border: '1px solid #a3a3a3',
                        borderTop: 0,
                      }}
                      getItemValue={(item) => item.label}
                      items={searchResults}
                      renderItem={(item, isHighlighted) =>
                        <div style={{ background: isHighlighted ? 'lightgray' : 'white', padding: '10px' }}>
                          {item.label}
                        </div>
                      }
                      value={query}
                      onChange={(e) => {
                        setQuery(e.target.value);
                        get(`${END_POINTS.SEARCH}?keyword=${e.target.value}`)
                          .then(({ data }) => {
                            setSearchResults(data.products.map((prod) => ({
                              value: prod.product_url,
                              label: prod.title,
                            })));
                          });
                      }}
                      onSelect={(value, option) => {
                        setQuery(value);
                        history.push(`${ROUTES.CATEGORIES}${option.value}`)
                      }}
                      placeholder='Search...'
                    />
                  </div>
                  {/*<input*/}
                  {/*  type="text"*/}
                  {/*  className="nav-search-field w-input"*/}
                  {/*  maxLength="256"*/}
                  {/*  name="SearchField-2"*/}
                  {/*  data-name="Search Field 2"*/}
                  {/*  placeholder="Search for anything.."*/}
                  {/*  id="SearchField-2"*/}
                  {/*  onChange={(e) => setQuery(e.target.value)}*/}
                  {/*/>*/}
                  <Link
                   // to={query ? `${ROUTES.SEARCH}/${query}` : "#"}
                    to="#"
                    type="submit"
                    value="Search"
                    data-wait="Please wait..."
                    className="nav-search-button w-button"
                  >
                    Search
                  </Link>
                </div>
              </div>
            </div>
            <div className="nav-cart-contact-wrapper">
              <div className="nav-contact-wrapper">
                <a href="tel:+4402080162108" className="nav-phone-no">
                  0208 016 2108
                </a>
                <a href="tel:+4402080162108" className="nav-timing-text">
                  9 am - 9 pm / 7 Days
                </a>
              </div>
              <div
                className="nav-cart-trigger"
                onClick={() => {
                  toggleCart();
                }}
              >
                <img src={bag} alt="" className="nav-cart-icon" />
              </div>
              <div
                className="nav-cart-count-wrapper"
                onClick={() => {
                  toggleCart();
                }}
                style={{ cursor: "pointer" }}
              >
                <div className="nav-cart-circle">
                  <div className="nav-cart-count">
                    {(basket.lines && basket.lines.length) || 0}
                  </div>
                </div>
              </div>
            </div>
            <nav role="navigation" className="nav-menu-2 w-nav-menu">
              <a href="#" className="close-menu-btn w-inline-block">
                <img
                  src="https://uploads-ssl.webflow.com/5ec7f38ac0ca9b25efc04d51/5ec7f38b1681b8d44f9b841b_arrow_left.svg"
                  width="16"
                  alt=""
                  className="back-icon"
                />
              </a>
              <Link
                to={ROUTES.ROOT}
                className="nav-link-wrapper w-inline-block"
              >
                <div className="nav-line" />
                <div className="nav-link-2">Home</div>
              </Link>
              <div className="nav-logo-wrap">
                <img src={logo} alt="" className="logo-2" />
              </div>
              <div className="side-menu-category-wrapper">
                {categories && categories.map((cat) => (
                  <div
                    key={`sidebar-cat-${cat.slug}`}
                    className="side-menu-accordian-block"
                  >
                    <div
                      data-w-id="bd83ba31-20a1-e485-0de7-9b9c3842a5f2"
                      className="sidemenu-category-title"
                      onClick={() => {
                        if (selectedCat !== cat.slug) {
                          $(
                            `.sidemenu-sub-category-accordian_${selectedCat}`
                          ).slideToggle();
                          setSelectedCat(cat.slug);
                          setSubCategories([]);
                        } else {
                          $(
                            `.sidemenu-sub-category-accordian_${cat.slug}`
                          ).slideToggle();
                        }
                      }}
                      style={{ textAlign: "start" }}
                    >
                      {`${cat.name} +`}
                    </div>
                    <div
                      className={`sidemenu-sub-category-accordian sidemenu-sub-category-accordian_${cat.slug}`}
                      style={{ display: "none", textAlign: "left" }}
                    >
                      {subCategories.map((sub) => (
                        <Link
                          key={`sidebar-subcat-${sub.slug}`}
                          to={`${ROUTES.CATEGORIES}/${cat.slug}/${sub.slug}`}
                          className="side-menu-sub-category-title"
                          onClick={() => {
                            $(".w-nav-overlay").css("display", "none");
                          }}
                        >
                          {sub.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </nav>
          </div>
        </div>
      </footer>
    </>
  );
};
