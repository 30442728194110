/*
page 29
*/
import React from "react";
import {QuoteForm} from "./qoute-form";
import {ROUTES} from "../../utils/values";
import MetaDecorator from "../../utils/meta-decorator";
import {actions} from "../../actions/ui";
import {connect} from "react-redux";

export const StandUpPouchesLandingPage = ({
  toggleFreeSampleModal,
}) => {
  return (
    <React.Fragment>
      <MetaDecorator
        description="High Quality, BRC Certified 140 Micron Stand Up Pouches. Available in Stock with 48 Hour Delivery. Custom Logos in as little as 7 Days! Printed in the UK."
        title="Stand Up Pouches"
        robots="“index, follow”"
      />
      <div className="p28-content-wrapper">
        <div className="p28-header-wrapper">
          <div className="p28-page-title-wrapper">
            <div className="p28-title-hash">#FlexiblePackaging</div>
            <h1 className="p28-h1">Stand Up Pouches</h1>
            <h1 className="p28-h2">
              Stand up pouches are a great more eco friendly alternative to traditional
              forms of rigid packaging. Their light weight and flexibility allows ease of transport and
              lower merchandise risk!
            </h1>
            <h3 className="p28-h3">Available in Stock 48 hour delivery! Or Custom Printed in 14 Days..</h3>
            <div className="p29-cta-wrapper">
              <a href="#" className="p29-cta-button w-button" onClick={() => toggleFreeSampleModal(true)}>Request Free Sample</a>
            </div>
          </div>
          <div className="p28-picture-container">
            <img
              src="https://justbulk.co.uk/media/uploaded-media/Group_Picture_of_Stand_Up_Pouches_Pouchshop_UK.png"
              loading="eager" width="800" height="386" alt="Pouchshop UK Stand Up Pouch Group Picture "
              srcSet="https://justbulk.co.uk/media/uploaded-media/Group_Picture_of_Stand_Up_Pouches_Pouchshop_UK.png"
              sizes="(max-width: 800px) 100vw, 800px" className="l1-p1-pouchshop-banner"/>
          </div>
        </div>
        <div className="p29-strip-wrapper">
          <div className="p29-snippet-block">
            <div className="p28-bullet-point"></div>
            <div className="p28-snippet-text">
              <strong className="bold-text-2">140 Micron Premium Quality</strong>
            </div>
          </div>
          <div className="p29-snippet-block">
            <div className="p28-bullet-point"></div>
            <div className="p28-snippet-text">
              <strong className="bold-text-2">Custom Print from 500 Units</strong>
            </div>
          </div>
          <div className="p29-snippet-block">
            <div className="p28-bullet-point"></div>
            <div className="p28-snippet-text">
              <strong className="bold-text-2">We Print Labels at the Best Rates!</strong>
            </div>
          </div>
          {/*<div className="p29-snippet-block">
            <div className="p28-bullet-point"></div>
            <div className="p28-snippet-text">
              <strong className="bold-text-2">48 Hour Delivery </strong>
            </div>
          </div>*/}
        </div>
        <div className="p29-mobile-link-wrapper">
          <div className="p29-mobile-snippet">Scroll Down &amp; Learn More about how our stand up pouches
            compare to others online or..
          </div>
          <a
            href={`${ROUTES.CATEGORIES}/flexible-packaging/stand-up-pouches`}
            className="p29-mobile-links w-button"
          >
            Browse Unprinted Pouches
          </a>
          <a
            href={ROUTES.CUSTOM_POUCHES}
            className="p29-mobile-links w-button"
          >
            Shop Custom Printed Pouches
          </a>
          <a
            href={`${ROUTES.CATEGORIES}/flexible-packaging/labels-stickers`}
            className="p29-mobile-links w-button"
          >
            Shop Printed Labels &amp; Stickers
          </a>
        </div>
        <div className="p29-content-wrapper-1">
          <div className="p29-c1-image-container">
            <img
              src="https://justbulk.co.uk/media/uploaded-media/440x440_Stock_1.png"
              loading="lazy" width="700"
              height="380" alt="" className="p29-c1-image"
            />
          </div>
          <div className="p29-c1-text-wrapper">
            <h4 className="p28-h4">Our Stock Stand Up Pouches</h4>
            <div className="p28-c1-text">Stand Up Pouches are a great flexible packaging solution for brands
              looking to reduce their carbon foot print and/or increase their product shelf life. Our Food
              Grade Stand Up Pouches are suitable to pack products across a multitude of industries
              ranging from snacks, bolts and even salts!Available in Matt White, Matt Black &amp; Kraft
              Paper in sizes ranging from 70g to 1kg all in stock.
            </div>
            <div className="p29-cta-wrapper">
              <a
                href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches"
                className="p29-cta-button-2 w-button">
                Browse Stock Pouches
              </a>
            </div>
          </div>
        </div>
        <div className="p29-content-wrapper-2">
          <div className="p29-c2-wrapper">
            <div className="p29-c2-block-1">
              <div className="p29-c2-block-1-image-container">
                <img
                  src="https://justbulk.co.uk/media/uploaded-media/V2_440x440_Stock_Comparison.png"
                  loading="lazy"
                  width="495" height="380" alt=""
                  className="p29-c2-block-1-image"
                />
              </div>
              <div className="p29-c2-block-1-text-wrapper">
                <h4 className="p28-h4">140 Mic Premium Stand Up Pouches</h4>
                <div className="p28-c1-text">
                  Have a look for yourself.. The difference is noticeable our
                  premium quality 140 Micron pouches offer a flawless finish in contrast to the creasy
                  95-120 Micron Pouches sold online.
                  <br/><br/>
                  Premium packaging portrays not only
                  quality but also prevents you from being open to packaging failure. One of the
                  main reasons people turned to flexibles apart from weight is the reduced
                  merchandising risk, where packaging often becomes unsellable if it becomes
                  scrunched opting for lower quality pouches will almost always cause these
                  issues. This is especially important where you are supplying products that are
                  to be used over a period of time.
                  <br/><br/>
                  All our stock and printed stand up pouches range from 140 Mic to 160 Mic depending on the size.
                </div>
                <div className="p29-cta-wrapper">
                  <a href="#" className="p29-cta-button-2 w-button" onClick={() => toggleFreeSampleModal(true)}>Request Free Sample</a>
                </div>
              </div>
            </div>
            <div className="p29-c2-block-2">
              <div className="p29-c2-block-2-image-container">
                <img
                  src="https://justbulk.co.uk/media/uploaded-media/440x440_Stickers.png" loading="lazy"
                  width="495" height="250" alt=""
                  className="p29-c2-block-2-image"/>
              </div>
              <div className="p29-c2-block-1-text-wrapper">
                <h4 className="p28-h4">Printed Labels for Stock</h4>
                <div className="p28-c1-text">Add your branding to the pouches with our printed labels
                  available in squares, circles and rectangles of various sizes. We offer the most
                  competitive prices online. Seen it better elsewhere? Let us know! We print and
                  deliver our labels within 3-4 working days!
                </div>
                <div className="p28-accent-text">
                  <strong>Alternatively we can digitally print your
                    pouches with an MOQ as low as 500 Units!
                  </strong>
                </div>
                <div className="p29-cta-wrapper">
                  <a href="https://justbulk.co.uk/categories/flexible-packaging/labels-stickers"
                     className="p29-cta-button-2 w-button">Browse Printed Stickers</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p29-strip-wrapper">
          <div className="p29-snippet-block">
            <div className="p28-bullet-point"></div>
            <div className="p28-snippet-text"><strong className="bold-text-2">140 Micron Premium
              Quality</strong></div>
          </div>
          <div className="p29-snippet-block">
            <div className="p28-bullet-point"></div>
            <div className="p28-snippet-text"><strong className="bold-text-4">BRC Accredited</strong></div>
          </div>
          <div className="p29-snippet-block">
            <div className="p28-bullet-point"></div>
            <div className="p28-snippet-text">
              <strong className="bold-text-2">We Print Labels at the Best Rates!</strong>
            </div>
          </div>
          {/*<div className="p29-snippet-block">
            <div className="p28-bullet-point"></div>
            <div className="p28-snippet-text"><strong className="bold-text-2">48 Hour Delivery </strong>
            </div>
          </div>*/}
        </div>
        <div className="p29-product-grid">
          <h4 className="p29-grid-title">Shop / Browse our Flexible Pouch Products below!</h4>
          <h4 className="p29-grid-accent-text">You can Request Free Samples on Product Pages!</h4>
          <div className="w-layout-grid lp-p1-pouchshop-grid">
            <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2b3f-7fc867f5" className="lp-p1-product-frame">
              <img
                src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-White-Stand-Up-Pouch-Picture-1.png"
                alt="Pouchshop uk matt white stand up pouch" loading="lazy" className="lp-p1-picutre"/>
              <div className="lp-p1-product-title">
                <a href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/Matt-White-Stand-Up-Pouch"
                   className="link">Matt White Stand Up Pouch</a>
              </div>
              <div className="lp-p1-product-price">from £0.08 P/Unit</div>
            </div>
    
            <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2b46-7fc867f5" className="lp-p1-product-frame">
              <img
                src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-Black-Stand-Up-Pouch-Picture-1.png"
                alt="Pouchshop UK matt black stand up pouch" loading="lazy" className="lp-p1-picutre"/>
              <div className="lp-p1-product-title">
                <a href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/Matt-Black-Stand-Up-Pouch"
                   className="link">Matt Black Stand Up Pouch</a>
              </div>
              <div className="lp-p1-product-price">from £0.08 P/Unit</div>
            </div>
            <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2b4d-7fc867f5" className="lp-p1-product-frame">
              <img
                src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-Kraft-Stand-Up-Pouch-Picture-1.png"
                alt="Pouchshop UK Brown Kraft Pouch" loading="lazy" className="lp-p1-picutre"/>
              <div className="lp-p1-product-title">
                <a href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/Brown-Kraft-Stand-Up-Pouch"
                   className="link">Brown Kraft Stand Up Pouch</a>
              </div>
              <div className="lp-p1-product-price">from £0.08 P/Unit</div>
            </div>
            <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2b54-7fc867f5" className="lp-p1-product-frame">
              <img
                src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-Holographic-Stand-Up-Pouch-Picture-1.png"
                alt="Pouchshop UK Holographic Stand Up Pouch" loading="lazy" className="lp-p1-picutre"/>
              <div className="lp-p1-product-title">
                <a href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/holographic-stand-up-pouch"
                   className="link">Holographic Stand Up Pouch</a>
              </div>
              <div className="lp-p1-product-price">from £0.10 P/Unit</div>
            </div>
            <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2b5b-7fc867f5" className="lp-p1-product-frame">
              <img
                src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-Shiny-White-Stand-Up-Pouch-Picture-1.png"
                alt="Pouchshop uk Gloss White Stand Up Pouch" loading="lazy" className="lp-p1-picutre"/>
              <div className="lp-p1-product-title">
                <a href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/gloss-white-stand-up-pouch"
                   className="link">Gloss White Stand Up Pouch</a>
              </div>
              <div className="lp-p1-product-price">from £0.10 P/Unit</div>
            </div>
            <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2b62-7fc867f5" className="lp-p1-product-frame">
              <img
                src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-Shiny-Black-Stand-Up-Pouch-Picture-1.png"
                alt="Pouchshop UK Gloss Black Stand Up Pouch" loading="lazy" className="lp-p1-picutre"/>
              <div className="lp-p1-product-title">
                <a href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/gloss-black-stand-up-pouch"
                   className="link">Gloss Black Stand Up Pouch </a>
              </div>
              <div className="lp-p1-product-price">from £0.10 P/Unit</div>
            </div>
            <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2b69-7fc867f5" className="lp-p1-product-frame">
              <img
                src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-Gold-Stand-Up-Pouch-Picture-1.png"
                alt="Pouchshop uk Shiny Gold Stand Up Pouch " loading="lazy" className="lp-p1-picutre"/>
              <div className="lp-p1-product-title">
                <a href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/Shiny-Gold-stand-up-pouch"
                   className="link">Shiny Gold Stand Up Pouch </a>
              </div>
              <div className="lp-p1-product-price">from £0.10 P/Unit</div>
            </div>
            <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2b70-7fc867f5" className="lp-p1-product-frame">
              <img
                src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-Chrome-Stand-Up-Pouch-Picture-1.png"
                alt="Pouchshop UK Chrome Silver Stand Up Pouch" loading="lazy" className="lp-p1-picutre"/>
              <div className="lp-p1-product-title">
                <a
                  href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/chrome-silver-stand-up-pouch"
                  className="link">Chrome Silver Stand Up Pouch </a>
              </div>
              <div className="lp-p1-product-price">from £0.10 P/Unit</div>
            </div>
            <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2b77-7fc867f5" className="lp-p1-product-frame">
              <img
                src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-White-Clear-Stand-Up-Pouch-Picture-1.png"
                alt="Pouchshop UK Gloss White Half Clear Stand Up Pouch" loading="lazy" className="lp-p1-picutre"/>
              <div className="lp-p1-product-title">
                <a
                  href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/Gloss-White-One-Side-Clear-Stand-Up-Pouch"
                  className="link">Gloss White / Clear Stand Up Pouch </a>
              </div>
              <div className="lp-p1-product-price">from £0.12 P/Unit</div>
            </div>
            <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2b7e-7fc867f5" className="lp-p1-product-frame">
              <img
                src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-Black-Clear-Stand-Up-Pouch-Picture-1.png"
                alt="Pouchshop UK Gloss Black Half Clear Stand Up Pouch" loading="lazy" className="lp-p1-picutre"/>
              <div className="lp-p1-product-title">
                <a
                  href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/gloss-black-one-side-clear-stand-up-pouch"
                  className="link">Gloss Black / Clear Stand Up Pouch </a>
              </div>
              <div className="lp-p1-product-price">from £0.12 P/Unit</div>
            </div>
            <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2b85-7fc867f5" className="lp-p1-product-frame">
              <img
                src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-Chrome-Clear-Stand-Up-Pouch-Picture-1.png"
                alt="Pouchshop UK Chrome Silver Half Clear Stand Up Pouch" loading="lazy" className="lp-p1-picutre"/>
              <div className="lp-p1-product-title">
                <a
                  href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/Chrome-Silver-one-side-clear-stand-up-pouch"
                  className="link">Chrome Silver / Clear Stand Up Pouch </a>
              </div>
              <div className="lp-p1-product-price">from £0.12 P/Unit</div>
            </div>
            <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2b8c-7fc867f5" className="lp-p1-product-frame">
              <img
                src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-Holographic-Clear-Stand-Up-Pouch-Picture-1.png"
                alt="Pouchshop UK Holographic Half Clear Stand Up Pouch" loading="lazy" className="lp-p1-picutre"/>
              <div className="lp-p1-product-title">
                <a
                  href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/holographic-clear-stand-up-pouch"
                  className="link">Holographic / Clear Stand Up Pouch </a>
              </div>
              <div className="lp-p1-product-price">from £0.12 P/Unit</div>
            </div>
            <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2b93-7fc867f5" className="lp-p1-product-frame">
              <img
                src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-Kraft-Window-Stand-Up-Pouch-Picture-1.png"
                alt="Pouchshop UK Brown Kraft with Window Stand Up Pouch" loading="lazy" className="lp-p1-picutre"/>
              <div className="lp-p1-product-title">
                <a
                  href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/Brown-Kraft-Rectangle-Window-Pouch"
                  className="link">Brown Kraft Rectangle Window Pouch </a>
              </div>
              <div className="lp-p1-product-price">from £0.12 P/Unit</div>
            </div>
            <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2b9a-7fc867f5" className="lp-p1-product-frame">
              <img
                src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-Kraft-Oval-Window-Stand-Up-Pouch-Picture-1.png"
                alt="Pouchshop UK Brown Kraft Round Window Pouch" loading="lazy" className="lp-p1-picutre"/>
              <div className="lp-p1-product-title">
                <a
                  href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/brown-kraft-round-window-pouch"
                  className="link">Brown Kraft Round Window Pouch </a>
              </div>
              <div className="lp-p1-product-price">from £0.12 P/Unit</div>
            </div>
            <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2ba1-7fc867f5" className="lp-p1-product-frame">
              <img
                src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-Transparent-Stand-Up-Pouch-Picture-1.png"
                alt="Pouchshop UK Transparent Clear Stand Up Pouch" loading="lazy" className="lp-p1-picutre"/>
              <div className="lp-p1-product-title">
                <a href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/Transparent-stand-up-pouch"
                   className="link">Transparent / Clear Stand Up Pouch </a>
              </div>
              <div className="lp-p1-product-price">from £0.12P/Unit</div>
            </div>
            <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2ba8-7fc867f5" className="lp-p1-product-frame">
              <img
                src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-Spout-Pouch-Stand-Up-Pouch-Picture-1.png"
                alt="Pouchshop UK Spout Pouch with Top Spout" loading="lazy" className="lp-p1-picutre"/>
              <div className="lp-p1-product-title">
                <a
                  href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/Top-spouted-Clear-Spout-Pouch"
                  className="link">Top Spouted Clear Spout Pouch</a>
              </div>
              <div className="lp-p1-product-price">from £0.20 P/Unit</div>
            </div>
            <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2baf-7fc867f5" className="lp-p1-product-frame">
              <img
                src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-Silver-Stand-Up-Pouch-Picture-1.png"
                alt="Pouchshop UK Gloss Silver Foil Pouch" loading="lazy" className="lp-p1-picutre"/>
              <div className="lp-p1-product-title">
                <a
                  href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/gloss-laminated-silver-pouch"
                  className="link">Gloss Laminated Silver Foil Pouch</a>
              </div>
              <div className="lp-p1-product-price">from £0.12 P/Unit</div>
            </div>
            <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2bb6-7fc867f5" className="lp-p1-product-frame">
              <img
                src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-White-Kraft-Window-Stand-Up-Pouch-Picture-1.png"
                alt="Pouchshop UK White Kraft Rectangle Window Pouch" loading="lazy" className="lp-p1-picutre"/>
              <div className="lp-p1-product-title">
                <a
                  href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/White-kraft-rectangle-window-pouch"
                  className="link">White Kraft Rectangle Window Pouch</a>
              </div>
              <div className="lp-p1-product-price">from £0.12 P/Unit</div>
            </div>
            <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2bbd-7fc867f5" className="lp-p1-product-frame">
              <img
                src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-White-Kraft-Stand-Up-Pouch-Picture-1.png"
                alt="Pouchshop UK White Kraft Stand Up Pouch" loading="lazy" className="lp-p1-picutre"/>
              <div className="lp-p1-product-title">
                <a href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/white-kraft-stand-up-pouch"
                   className="link">White Kraft Stand Up Pouch</a>
              </div>
              <div className="lp-p1-product-price">from £0.12 P/Unit</div>
            </div>
            <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2bc4-7fc867f5" className="lp-p1-product-frame">
              <img
                src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-Flat-Bottom-Bag.png"
                alt="Brown Kraft Flat Bottom Pouch with Pocket Zipper" loading="lazy" className="lp-p1-picutre"/>
              <div className="lp-p1-product-title">
                <a
                  href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/Brown-Kraft-Flat-Bottom-Pouch-with-pocket-zipper"
                  className="link">Brown Kraft Flat Bottom Pouch with Pocket Zipper</a>
              </div>
              <div className="lp-p1-product-price">from £0.30 P/Unit</div>
            </div>
          </div>
        </div>
        
        <div className="p29-content-wrapper-3">
          <div className="p29-c3-title">
            <strong className="bold-text-5">Custom Printed Stand Up Pouches</strong>
          </div>
          <div className="p28-picture-container">
            <img
              src="https://justbulk.co.uk/media/uploaded-media/Header_1.png" loading="lazy" width="800"
              height="386"
              srcSet="https://justbulk.co.uk/media/uploaded-media/Header_1.png 500w, https://justbulk.co.uk/media/uploaded-media/Header_1.png 800w"
              sizes="(max-width: 479px) 100vw, (max-width: 991px) 98vw, 799.98046875px"
              alt="" className="p28-banner-image"/>
          </div>
          <div className="p29-c3-subtitle">
            <strong className="bold-text-6">Digitally Printed, Multiple Designs &amp; Low MOQ&#x27;s!</strong>
          </div>
          <div className="p29-c3-content-text">
            As part of our efforts to support SME&#x27;s, we offer custom
            printed stand up pouches from only 500 Units aim to deliver them within 14 Days. Included in the
            service is a free artwork check that includes any small amendments you may require. To ensure we
            print your designs to the highest quality as well as maintain that standard we use a state of
            the art $2.5m HP Indigo 20000 Press for all our prints.
          </div>
          <a
            href={ROUTES.CUSTOM_POUCHES}
            className="p29-c3-custom-print-button w-button">
            View Custom Print Prices
          </a>
        </div>
        <div className="p28-content-wrapper-4">
          <div className="p29-c4-title">
            <strong className="bold-text-3">
              No Waiting for Brand Pricing &quot;Quotes&quot;, Everything&#x27;s Online
            </strong>
          </div>
          <div className="p28-c4-process-wrapper">
            <div className="p28-step-block">
              <div className="p28-c4-number">1.</div>
              <div className="p28-step-text">Select your options &amp; Checkout! Or Fill out our Custom
                Quote Form and we&#x27;ll quote you within 48hrs.
              </div>
            </div>
            <div className="p28-step-block">
              <div className="p28-c4-number">2.</div>
              <div className="p28-step-text">Head over to &#x27;My Account&#x27; upload your artwork,
                clearly labelling all uploads
              </div>
            </div>
            <div className="p28-step-block">
              <div className="p28-c4-number">3.</div>
              <div className="p28-step-text">We&#x27;ll send you a proof and print agreement, sign and
                approve the doc and we&#x27;re good to go!
              </div>
            </div>
          </div>
        </div>
        <div className="p29-content-wrapper">
          <div className="p28-c5-title">
            <strong className="bold-text-3">Benefits of Stand Up Pouches</strong>
          </div>
          <div className="p28-spec-text">
            <strong>1. Delivery Diversity </strong><br/>
            Stand up pouches have the
            flexibility to both be retailed in store and be put directly in the post, whether you&#x27;re
            packing powder or grains, why limit yourself to damage prone packaging?
            <br/>
            You can also pack nearly 10x more stand up pouches on a single pallet in contrast to other forms of rigid
            packaging reducing both the no of pallets needed for transport and thus the overall
            logistical cost and carbon footprint.<br/><br/>
            <strong>2. Product to Packaging Ratio</strong><br/>
            Stand up pouches have a far greater product to packaging ratio in
            contrast to other forms of packaging due to their sheer flexibility and format. Other
            items would first need to be foil wrapped, then retail packaged and lastly further
            packaged! Amounting to more packaging than product!
            <br/><br/>
            <strong>3. Samples</strong><br/>
            With the growing level of eCommerce in the UK, people want to
            try before they buy or at least buy a smaller volume before committing to a larger
            size, stand up pouches especially small ones are a great way to do this, available
            in small quantities and easily customisable they are a great way to get your product
            out there online, at exhibitions even in stores.<br/><br/>
            <strong>4. Product Value</strong><br/>
            Enhance your product value by providing a more sustainable
            product, let your customers conscience be clear! The sheer weight of stand up
            pouches gives it a drastically lower carbon footprint, at the same being
            available in paper &amp; recyclable formats allows for a completely
            environmentally friendly solution so long as this type of packaging is suitable
            for your product!<br/><br/>
            <strong>5. Multi Use</strong><br/>
            Sell larger volumes
            giving your consumer the ability to consume your product over a period of
            time in contrast to selling single use product in single use packaging. All
            our stand up pouches feature a resealable zip allowing for multi use that
            can be further heat sealed to provide product security.<br/><br/>
            <strong>6. Customer Convenience</strong><br/>
            Stand up pouches are a form of portable
            packaging, they give consumers the ability to put your product in their
            pocket or bag and move around without having to worry about spoilage and
            spillage thus providing convenience.<br/><br/>
            <strong>7. Perception </strong><br/>
            Nearly 90% of consumers believe stand up
            pouches provide greater product freshness and would opt for a
            product packed in one that they&#x27;d consume over a period of
            time. Ever tasted a moist biscuit? Yeah nobody likes those!<br/><br/>Stand
            up pouches in general are a great way to package perishable food
            due their high barrier properties allows them to protect your
            goods from environmental elements such as moisture all the while
            retaining your products aroma and crispness. <br/>‍<br/>‍<br/>
          </div>
        </div>
        <div className="p28-content-wrapper-8">
          <div className="p28-c8-title">
            <strong className="bold-text-3">Get a Custom Quote within 48 Hours!</strong>
          </div>
          <div className="p28-form w-form" id="custom_quote_form">
            <QuoteForm pageTitle="stand-up-pouches"/>
          </div>
        </div>
      </div>
      <div className="p28-recommended-wrapper">
        <div className="p1---recommended-prod-title-block">
          <h2 className="p28-recommended-title">Browse Ranges</h2>
        </div>
        <div className="p1---recommended-products-block">
          <div className="p28-product-frame">
            <img
              src="https://justbulk.co.uk/media/uploaded-media/Matt_White_Pic_350x400.png" alt=""
              className="p28-recommended-picture"
            />
            <a href="/categories/flexible-packaging/stand-up-pouches" className="p28-recommended-product-text">Shop Matt
              White Stand Up Pouches</a>
          </div>
          <div className="p28-product-frame">
            <img
              src="https://justbulk.co.uk/media/uploaded-media/Matt_Black__Pic_350x400.png" alt=""
              className="p28-recommended-picture"
            />
            <a href="/categories/flexible-packaging/stand-up-pouches" className="p28-recommended-product-text">Shop Matt
              Black Stand Up Pouches</a>
          </div>
          <div className="p28-product-frame">
            <img
              src="https://justbulk.co.uk/media/uploaded-media/Kraft_Group_Pic_350x400.png" alt=""
              className="p28-recommended-picture"
            />
            <a href="/categories/flexible-packaging/stand-up-pouches" className="p28-recommended-product-text">Shop
              Brown Kraft Stand Up Pouches</a>
          </div>
          <div className="p28-product-frame">
            <img
              src="https://justbulk.co.uk/media/uploaded-media/Custom_Print_Category_350x400_format.png" alt=""
              className="p28-recommended-picture"/>
            <a href="/categories/flexible-packaging/custom-printed-pouches-from-500-units"
               className="p28-recommended-product-text">Shop Digitally Printed Stand Up Pouches</a>
          </div>
          <div className="p28-product-frame">
            <img
              src="https://justbulk.co.uk/media/uploaded-media/Printed_Labels__Stickers.png" alt=""
              className="p28-recommended-picture"
            />
            <a href="/categories/flexible-packaging/labels-stickers" className="p28-recommended-product-text">Shop
              Custom Printed Labels</a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  toggleFreeSampleModal: actions.toggleFreeSampleModal,
};

export const StandUpPouchesLandingPageContainer = connect(null, mapDispatchToProps)(StandUpPouchesLandingPage);
