import { connect } from 'react-redux';
import { OrderConfirmation } from './order-confirmation';
import { actions } from "../../actions/ui";
import { actions as authActions } from "../../actions/auth";

const mapStateToProps = state => ({
  basket: state.auth.basket,
  shipping: state.ui.shipping,
  account: state.auth.account,
  userCountry : state.ui.country
});

const mapDispatchToProps = {
  setBasket: authActions.setBasket,
  setShipping: actions.setShipping,
};

export const OrderConfirmationContainer = connect(mapStateToProps, mapDispatchToProps)(OrderConfirmation);
