import React, {useEffect, useState} from "react";
import MetaDecorator from "../../utils/meta-decorator";
import img from "../../assets/images/Justbulk---Takeaway-Packaging-3.png";
import {get} from "../../api/api-caller";
import {END_POINTS} from "../../api";
import {Link} from "react-router-dom";
import {ROUTES} from "../../utils/values";

export const TakeAway = () => {
  
  const [packaging, setPackaging] = useState([])
  
  useEffect(() => {
    get(`${END_POINTS.ROOT_CATEGORIES}food-packaging/?page_size=100`)
      .then((resp) => {
        setPackaging(resp)
      })
      .catch((err) => console.error(err));
  }, []);
  
  return (
    <React.Fragment>
      <MetaDecorator
        description="Your One Stop Takeaway, we stock over 400 different types of Containers, Boxes, Pots, Pouches, Condiments & More"
        title="Take Away Packaging"
        robots="“index, follow”"
      />
      <div className="lp-p1-page-wrapper">
        <div className="lp-p1-head-wrapper">
          <div className="l1-p2-first-wrapper">
            <div className="l1-p2-page-title-wrapper-copy">
              <div className="lp-p--title-hash">#TakeawayPackaging</div>
              <h1 className="lp-p2h1-copy">UK&#x27;s Leading Takeaway Packaging Specialist</h1>
              <h1 className="l1-p1-h2">Your One Stop Takeaway, we stock over 400 different types of Containers, Boxes,
                Pots, Pouches, Condiments &amp; More. </h1>
              <h3 className="lp-p1-h3">We also Custom Print with a Free Design Service!</h3>
              <div className="p29-cta-wrapper">
                <a href="https://justbulk.co.uk/contact-us" className="lp-p1-cta w-button">Request Quote</a>
              </div>
            </div>
            <div className="l1-p1-picture-container">
              <img
                src={img}
                loading="eager" width="800" height="386"
                alt="Pouchshop UK Stand Up Pouch Group Picture "
                srcSet={`${img} 500w, ${img} 800w, ${img} 1080w, ${img} 1600w`}
                sizes="(max-width: 991px) 100vw, 43vw"
                className="l1-p1-pouchshop-banner"/></div>
          </div>
          <div className="l1-p1-strip-wrapper">
            <div className="l1-p1-snippet-block">
              <div className="l1-p1-bullet-point"></div>
              <div className="l1-p1-snippet-text"><strong className="bold-text-2">Premium Quality</strong></div>
            </div>
            <div className="l1-p1-snippet-block">
              <div className="l1-p1-bullet-point"></div>
              <div className="l1-p1-snippet-text"><strong className="bold-text-2">Custom Print Service</strong></div>
            </div>
            <div className="l1-p1-snippet-block">
              <div className="l1-p1-bullet-point"></div>
              <div className="l1-p1-snippet-text"><strong className="bold-text-2">UK Stockist</strong></div>
            </div>
            <div className="l1-p1-snippet-block">
              <div className="l1-p1-bullet-point"></div>
              <div className="l1-p1-snippet-text"><strong className="bold-text-2">BRC Food Grade</strong></div>
            </div>
          </div>
          <div className="lp-p2-text-copy">You can find all our stock takeaway categories available to shop below, as
            well as that we supply a wide range condinments as well as fruit veg the every day consumable for every
            takeaway. All our takeaway packaging is manufactured in the UK or Europe and in many instances our lines
            are becoming more and more environment friendly. All deliveries are made within a 2-3 Day Period via our
            various logistic partners for smaller order Next Day Delivery is used as you may appreciate larger orders
            require palletization and the use of independent courier networks.
          </div>
        </div>
        <div className="w-layout-grid lp-p1-pouchshop-grid">
          {packaging.map(item => (
              <div id="w-node-_7058eb91-5848-b7dd-cff4-079ea7fc2059-6627ec85" className="lp-p1-product-frame">
                <img
                  src={item.image} alt="Cold Takeaway Packaging - Justbulk"
                  loading="lazy"
                  className="lp-p1-picutre" />
                <div className="lp-p1-product-title">
                  <Link
                    key={`categories-side-subcat-food-packaging`}
                    to={`${ROUTES.CATEGORIES}/food-packaging/${item.slug}`}
                    className="link"
                  >
                    {item.name}
                  </Link>
                </div>
                <div className="lp-p1-product-price">from £{item.price_starting_from} P/Unit</div>
              </div>
            )
          )}
        </div>
        <div className="lp-p1-page-wrapper2">
          <div className="lp-p1-head-wrapper">
            <div className="lp-p1-title">Plastic Takeaway Packaging vs Compostable Takeaway packaging which is better?
            </div>
            <div className="lp-p1-text">Although compostable packaging is available, here’s a fun fact, THE UK HAS NO
              COMPOSTING facilities, so it all ends up in landfill, yeah it might degrade towards the end of our
              lifetimes but it will also release methane in the environment till it degrades which is much worse for the
              environment in contrast to plastic. As well as that, compostable packaging takes twice as much resources
              to produce in contrast to plastic packaging, thus plastic is not the enemy. <br/><br/>In fact, Recyclable
                Plastic Packaging is the most sustainable form of packaging available on the market despite the large
                amounts of misconceptions. Recyclable takeaway packaging is always made from a mono material which means
                its one material and therefore can be recycled in your normal recycling bins, compostable packaging on
                the other hand always has to be lined with a PLA lining to make it safe for food contact which again is
                very difficult to split. <br/><br/><strong>You may ask what about the circular economy and plastic
                  takeaway packaging?<br/></strong>Well once recyclable takeaway packaging is recycled it can actually be
                  used again to create more in general at Justbulk we now only sell Recyclable Plastic Packaging that
                  has been derived from recycled plastic therefore allowing for a full circular economy.<br/><br/><strong>If
                    Plastic is better then why are plastic straws banned in the UK?</strong><br/>Well this is due to the
                    type of plastic they are made from which is polypropylene, a plastic that is relatively difficult to
                    recycle. There are many forms of plastic and the most recyclables ones. Recyclable plastics hold a
                    recycling code of 1-4 everything else is relatively not recyclable in the UK.<br/><br/></div>
          </div>
          <div className="lp-p1-head-wrapper">
            <div className="lp-p1-title">Why Choose our Takeaway Packaging?</div>
            <div className="lp-p1-text">Due to the pandemic a large proportion of sales for restaurants, bars and
              takeaways have become delivery only. Saving a few pence on cheaper packaging may be beneficial in the
              short run but it also makes your food a commodity, like in a fine dining you pay for the experience your
              takeaway packaging should also present a similar experience, it should be aesthetically pleasing and of
              high quality which is what our packaging provides.<br/>‍<br/>‍<br/></div>
          </div>
          <div className="lp-p1-head-wrapper">
            <div className="lp-p1-title">Why to Not Buy Cheap Takeaway Packaging?</div>
            <div className="lp-p1-text">Beyond looking cheap, cheap takeaway packaging can result in packaging failure,
              imagine getting left a review for cold food or a leaking container. <br/><br/>Reviews are what takeaways and
                restaurants thrive on give your customers something to write about beyond the food, even if the food is
                mediocre at best they will always have something to write about such as “it was well presented” “still
                warm when we got it”, the quality was great! <br/><br/>The visual presentation of your food is the first
                  que upon which customers base their opinions overall, is saving a few pence really worth loosing the
                  chance for your food to get a full 5* review. <br/><br/>‍<br/></div>
          </div>
          <div className="lp-p1-head-wrapper">
            <div className="lp-p1-title">Custom Takeaway Packaging</div>
            <div className="lp-p1-text"><strong>Benefits of Customising your Takeaway Packaging</strong><br/>Custom
              takeaway packaging is one of many strategies that businesses should consider for differentiating
              themselves from competitors. By investing in customised takeaway packaging, restaurant owners are likely
              to experience increased profits and customer satisfaction.Custom printed takeaway containers help your
              business stand out from others in your area by presenting them with something eye-catching and unique.
              This ensures that customers know they have received quality service, high quality food and an enjoyable
              eating experience - leading to increased returns.<br/><strong><br/>What to consider</strong><br/>Choosing
                takeaway packaging can be difficult because there are so many options. But if you&#x27;re considering
                custom takeaway packaging for your restaurant, then there are some things to keep in mind when choosing.
                The first thing to consider is how customers will receive your takeaways. Will they be handed over in
                person or will they be ordered online? If it&#x27;s more convenient for your customers to order online,
                make sure you provide them with an image of what they&#x27;re getting and that it&#x27;s readable on any
                screen size. And if not, at least ensure they have enough information so they know what food it is once
                it&#x27;s handed over by someone else. <br/><br/>When choosing takeaway packaging, also think about
                  sustainability. Is recyclable takeaway packaging better than non-recyclable packaging? What about
                  compostable? It all depends on where you live and what can be composted or recycled in your local
                  waste streams. To find out, research your towns rules or ask an expert for advice. Remember these tips
                  when looking for custom takeaway packaging to make sure you get what works best for your business!<br/><br/><strong>Costs
                    and Minimum Order Quantities </strong><br/>Printed takeaway packaging can seem expensive at first but
                    when it is customised and design to reflect your business, it can have huge benefits, which you
                    often can&#x27;t put a price on. These are some of them: - Creating loyalty with customers. With
                    takeaway being such a popular option for food these days, more people than ever will be looking for
                    ways in which they can feel like they belong to something special as well as potentially get their
                    names on one of your printed takeaway containers, knowing that you care about them enough not just
                    to cater for their needs but also want them to feel like part of something bigger than
                    themselves.<br/><br/> That said, social media is another big factor in today&#x27;s marketing
                      strategies so if you&#x27;re not getting creative with your branding and maybe even thinking about
                      emojis or other apperance-related features then customers may think they&#x27;re being overlooked
                      and end up moving on elsewhere! - On the contrary Higher prices will attract more customers!<br/>
                        <br/>MOQ&#x27;s typically range around the 20-50,000 Mark depending on what you&#x27;re trying to
                          customise and can often be the same price as stock packaging which is literally pennies. <br/>Single
                            Wall Cups - 25,000<br/>Double Wall &amp; Ripple Cups - 20,000<br/>Cup Sleeves - 20,000<br/>Takeaway
                              Boxes/Trays/Containers - 40,000<br/>Soup Containers - 30,000<br/>Printed Napkins -
                                50,000<br/>Paper Bags - 20,000 <br/><br/>‍<br/></div>
          </div>
          <div className="lp-p1-head-wrapper">
            <div className="lp-p1-title">If you&#x27;re just starting up then our stock ranges are just as good!</div>
            <div className="lp-p1-text">We stock a wide variety of takeaway boxes, containers, pots and cups all made in
              a BRC Accredited facility which is the highest certification available for food contact packaging. Our
              stock ranges are derived from a wide variety of materials such as Recyclable Plastic, Compostable Kraft
              and even Natural Bamboo and other fibres.<br/></div>
          </div>
          <div className="lp-p2-head-wrapper-copy">
            <div className="lp-p1-title">Important Links</div>
            <div className="lp-p1-text"><strong>Find out what is recyclable in your local area in the UK by simply
              entering your postcode:<br/></strong>
              <a href="#">https://www.recyclenow.com/</a><strong><br/><br/>Online Verification for pouches, pouch shops
                and their manufacturers using certificate numbers. Below is a link to SGS and TUV&#x27;s certificate
                Database:</strong><br/>
                <a href="#">https://www.sgs-tuev-saar.com/en/certification-database.html </a><br/><br/><strong>Packaging
                Standards in a nutshell:</strong><br/>
                <a href="#">https://www.compliancegate.com/european-union-packaging-materials-regulations/</a><br/>
                <a href="#">https://www.food.gov.uk/business-guidance/packaging-and-labelling</a><br/><br/>‍<br/>‍<br/>‍<br/>
            </div>
          </div>
        </div>
      </div>
</React.Fragment>
)
;
};

export default TakeAway;
