import React, {useEffect, useState} from "react";
import {Field} from "formik";
import ErrorMessage from "../error_field";


export const DirectorFields = ({onDelete, index, currentField, errors, touched, setFieldValue}) => {
  console.log('errors', errors)
  const onChangeSelection = (isDirector) =>{
    setFieldValue(`${currentField}.is_director`, isDirector)
    setFieldValue(`${currentField}.filled_on_director_behalf`, !isDirector)
  }
  
  return (
    <>
      <div className="p34-form-split-wrapper">
        <div className="p34-form-c1-left">
          <div className="p34-form-field-block">
            <label htmlFor="name-4" className="p34-form-field-label">
              Directors First Name
            </label>
            <Field
              type="text"
              className="p34-form-field w-input" maxLength="256"
              name={`${currentField}.first_name`}
              id="first_name"
            />
            <ErrorMessage
              errors={errors}
              touched={touched}
              name='first_name'
            />
          </div>
          <div className="p34-form-field-block">
            <label htmlFor="name-4" className="p34-form-field-label">
              Directors Email Address
            </label>
            <Field
              type="text" className="p34-form-field w-input" maxLength="256"
              name={`${currentField}.email`}
              id="email"
            />
            <ErrorMessage
              errors={errors}
              touched={touched}
              name='email'
            />
          </div>
          <div className="p34-form-field-block">
            <label htmlFor="name-4" className="p34-form-field-label">
              Directors Address Line 1
            </label>
            <Field
              type="text" className="p34-form-field w-input" maxLength="256"
              name={`${currentField}.line1`}
              id="line1"
            />
            <ErrorMessage
              errors={errors}
              touched={touched}
              name='line1'
            />
          </div>
          <div className="p34-form-field-block">
            <label htmlFor="name-4" className="p34-form-field-label">
              Directors Postal Town
            </label>
            <Field
              type="text" className="p34-form-field w-input" maxLength="256"
              name={`${currentField}.postal_town`}
              id="postal_town"
            />
            <ErrorMessage
              errors={errors}
              touched={touched}
              name='postal_town'
            />
          </div>
          <div className="p34-form-field-block">
            <label className="p34-form-field-label">
              Directors Country of Residence
            </label>
            <Field
              type="text" className="p34-form-field w-input"
              maxLength="256"
              name={`${currentField}.country`}
              id="country_of_residence"
            />
            <ErrorMessage
              errors={errors}
              touched={touched}
              name='country'
            />
          </div>
          <label className="w-checkbox">
            <Field
              type="checkbox"
              id="checkbox-3"
              name={`${currentField}.is_director`}
              data-name="Checkbox 3"
              className="w-checkbox-input"
              onChange={() => onChangeSelection(true)}
            />
            <ErrorMessage
              errors={errors}
              touched={touched}
              name='is_director'
            />
            <span className="w-form-label">I am the Director of the company in question.</span>
          </label>
          <label className="w-checkbox">
            <Field
              type="checkbox"
              id="filled_on_director_behalf"
              name={`${currentField}.filled_on_director_behalf`}
              className="w-checkbox-input"
              onChange={() => onChangeSelection(false)}
            />
            <ErrorMessage
              errors={errors}
              touched={touched}
              name='filled_on_director_behalf'
            />
            <span className="w-form-label">I am filling this in behalf of a Director</span>
          </label>
        </div>
        <div className="p34-form-c1-right">
          <div className="p34-form-field-block">
            <label className="p34-form-field-label">Directors Last Name</label>
            <Field
              type="text" className="p34-form-field w-input" maxLength="256"
              name={`${currentField}.last_name`}
              placeholder="" id="last_name"
            />
            <ErrorMessage
              errors={errors}
              touched={touched}
              name='last_name'
            />
          </div>
          <div className="p34-form-field-block">
            <label className="p34-form-field-label">Directors Mobile No</label>
            <Field
              type="text" className="p34-form-field w-input" maxLength="256"
              name={`${currentField}.contact_no`}
              id="contact_no"
            />
            <ErrorMessage
              errors={errors}
              touched={touched}
              name='contact_no'
            />
          </div>
          <div className="p34-form-field-block">
            <label htmlFor="name-4" className="p34-form-field-label">
              Directors Address Line 2
            </label>
            <Field
              type="text" className="p34-form-field w-input" maxLength="256"
              name={`${currentField}.line2`}
              id="line2"
            />
            <ErrorMessage
              errors={errors}
              touched={touched}
              name='line2'
            />
          </div>
          <div className="p34-form-field-block">
            <label className="p34-form-field-label">
              Directors County
            </label>
            <Field
              type="text" className="p34-form-field w-input" maxLength="256"
              name={`${currentField}.county`}
              id="county"
            />
            <ErrorMessage
              errors={errors}
              touched={touched}
              name='county'
            />
          </div>
          <div className="p34-form-field-block">
            <label htmlFor="name-4" className="p34-form-field-label">Directors Postcode</label>
            <Field
              type="text" className="p34-form-field w-input" maxLength="256"
              name={`${currentField}.post_code`}
              id="post_code"
            />
            <ErrorMessage
              errors={errors}
              touched={touched}
              name='post_code'
            />
          </div>
          {index !== 0 &&
          <div className="p34-director-block">
            <Field type="button" value="Remove Director" className="p34-director-button-2 w-button" onClick={onDelete}/>
          </div>
          }
        
        </div>
      </div>
    </>
  )
}
