import React from "react";
import { Formik, Field, Form } from "formik";
import PropTypes from "prop-types";

const ShippingAddress = ({
    address,
    handleSubmit,
    countries,
    setFieldValue,
    values,
    handleChange,
    handleBlur

}) => {

  return(
      <Formik
          initialValues={address}
          onSubmit={handleSubmit}
          enableReinitialize
      >
        {props => (
            <div className="p23-shipping-address-wrapper w-form">
              <Form
                  id="email-form"
                  name="email-form"
                  data-name="Email Form"
                  className="p23-billing-form"
              >
                <div className="p23-title-wrapper">
                  <div className="p23-title-block">
                    <div className="p23-shipping-address-title">
                      Shipping Address
                    </div>
                  </div>
                </div>
                <div className="p23-row-wrapper">
                  <div className="p23-title-column-block">
                    <div className="p23-inner-grid-labels">Address Line 1</div>
                  </div>
                  <div className="p23-address-field-column-block">
                    <Field
                        type="text"
                        className="p23-ship---address-line-1-field-copy w-input"
                        maxLength="256"
                        name="line1"
                        placeholder="Example Text"
                        id="field-line1"
                        required=""
                    />
                  </div>
                </div>
                <div className="p23-row-wrapper">
                  <div className="p23-title-column-block">
                    <div className="p23-inner-grid-labels">Address Line 2</div>
                  </div>
                  <div className="p23-address-field-column-block">
                    <Field
                        type="text"
                        className="p23-ship---address-line-2-field w-input"
                        maxLength="256"
                        name="line2"
                        placeholder="Example Text"
                        id="field-line2"
                        required=""
                    />
                  </div>
                </div>
                <div className="p23-row-wrapper">
                  <div className="p23-title-column-block">
                    <div className="p23-inner-grid-labels">Town / City</div>
                  </div>
                  <div className="p23-address-field-column-block">
                    <Field
                        type="text"
                        className="p23-ship---town-field w-input"
                        maxLength="256"
                        name="line3"
                        placeholder="Example Text"
                        id="field-line3"
                        required=""
                    />
                  </div>
                </div>
                <div className="p23-row-wrapper">
                  <div className="p23-title-column-block">
                    <div className="p23-inner-grid-labels">
                      Province / County
                    </div>
                  </div>
                  <div className="p23-address-field-column-block">
                    <Field
                        type="text"
                        className="p23-ship---county-field w-input"
                        maxLength="256"
                        name="state"
                        placeholder="Example Text"
                        id="field-state"
                        required=""
                    />
                  </div>
                </div>
                <div className="p23-row-wrapper">
                  <div className="p23-title-column-block">
                    <div className="p23-inner-grid-labels">Postal Code</div>
                  </div>
                  <div className="p23-address-field-column-block">
                    <Field
                        type="text"
                        className="p23-ship---postal-code-field w-input"
                        maxLength="256"
                        name="postcode"
                        placeholder="Example Text"
                        id="field-postcode"
                        required=""
                    />
                  </div>
                </div>
                <div className="p23-row-wrapper">
                  <div className="p23-title-column-block">
                    <div className="p23-inner-grid-labels">Country</div>
                  </div>
                  <div className="p23-address-field-column-block">
                    <Field
                        name="country"
                        onChange={(e)=>{props.setFieldValue("country", e.target.value)}}
                        onBlur={props.handleBlur}
                        required
                        className="p23-ship---county-field w-input"
                        as="select"

                    >
                      { countries.map((country) => (
                          <option
                              value={country.url}
                              selected={country.url === address.url}
                          >
                            {country.printable_name}
                          </option>
                      ))}
                    </Field>
                  </div>
                </div>
                <input
                    type="submit"
                    value="Save"
                    data-wait="Please wait..."
                    className="p23-address-save-button w-button"
                />
              </Form>
              <div className="w-form-done">
                <div>Your New Shipping Address Has Been Saved!</div>
              </div>
              <div className="w-form-fail">
                <div>Oops! Something went wrong!</div>
              </div>
            </div>
        )}
      </Formik>
  )
}

ShippingAddress.propTypes ={
  address: PropTypes.object,
  handleSubmit: PropTypes.func,
  countries: PropTypes.array,
  values:PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur:PropTypes.func
}

export default ShippingAddress;