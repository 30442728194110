import { withFormik } from 'formik';


/*
* HOC to enhance our form with <Formik />
* Helps us pull out logic from the generator-form.jsx component
* */

export const formikEnhancer = withFormik({
    //enableReinitialize: true,
    handleSubmit: (values, { setSubmitting, props: { attemptSubmit } }) => {
        attemptSubmit({
            data: values,
            setSubmitting,
        });
    }
});
