import React from "react";
import PropTypes from "prop-types";
import { formikEnhancer } from "./form-utils";
import { COLORS } from "../../../../style/theme";
import { BASE } from "../../../../api";

const Form = ({
  handleChange,
  handleBlur,
  handleSubmit,
  isValid,
  isSubmitting,
  errors,
}) => (
  <form
    id="wf-form-Login_form"
    name="wf-form-Login_form"
    data-name="Login_form"
    data-redirect="versiony.com"
    className="p6-signup-form-block"
    onSubmit={handleSubmit}
  >
    <div className="p6-name-wrapper">
      <input
        type="text"
        className="p6-singup-first-name-field w-input"
        maxLength="256"
        name="first_name"
        data-name="First Name"
        placeholder="First Name"
        id="First-Name-3"
        onChange={handleChange}
        onBlur={handleBlur}
        style={{backgroundColor: errors.first_name ? COLORS.ERROR : 'transparent'}}
        required
      />
      <input
        type="text"
        className="p6-signup-last-name-field w-input"
        maxLength="256"
        name="last_name"
        data-name="Last Name"
        placeholder="Last Name"
        id="Last-Name"
        onChange={handleChange}
        onBlur={handleBlur}
        style={{backgroundColor: errors.last_name ? COLORS.ERROR : 'transparent'}}
        required
      />
    </div>
    <input
      type="text"
      className="p6-signup-email-field w-input"
      maxLength="256"
      name="email"
      data-name="Email Address"
      placeholder="Email Address"
      id="Email-Address-2"
      onChange={handleChange}
      onBlur={handleBlur}
      style={{backgroundColor: errors.email ? COLORS.ERROR : 'transparent'}}
      required
    />
    <input
      type="password"
      className="p6-signup-password-field w-input"
      maxLength="256"
      name="password"
      data-name="Password"
      placeholder="Password"
      id="Password-2"
      onChange={handleChange}
      onBlur={handleBlur}
      style={{backgroundColor: errors.password ? COLORS.ERROR : 'transparent'}}
      required
    />
    { errors.password && <label style={{ color: COLORS.TEXT_ERROR }}>{errors.password}</label> }
    <input
      type="submit"
      value={isSubmitting ? "Please wait..." : "Signup"}
      className="p6-signup-button w-button"
      disabled={!isValid || isSubmitting}
    />
    <div className="p6-signup-terms">
      By signing up to create an account you agree our{" "}
      <a href={`${BASE}/terms`}>
        <span className="text-span-2">Terms of Use</span>
      </a>{" "}
      &amp;
      <a href={`${BASE}/privacy-policy`}>
        <span className="text-span-2">Privacy Policy</span>
      </a>
      . <a href="#"/>
    </div>
  </form>
);

Form.propTypes = {
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export const SignupForm = formikEnhancer(Form);

SignupForm.propTypes = {
  attemptSignup: PropTypes.func.isRequired,
};
