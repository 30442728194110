import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

//const metaDecorator = require("../../data/metaDecorator");

export const MetaDecorator = ({ title, description, robots /*, imageUrl, imageAlt*/ }) => {
  return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        {robots &&  <meta property="robots" content={robots}/> }

        {/*<meta property="og:image" content={metaDecorator.hostname + imageUrl} />
        <meta
            property="og:url"
            content={metaDecorator.hostname + window.location.pathname + window.location.search}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image:alt" content={imageAlt} />
        <meta name="twitter:site" content={metaDecorator.twitterUsername} />*/}
      </Helmet>
  );
};

MetaDecorator.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  robots: PropTypes.string,
 // imageUrl: PropTypes.string.isRequired,
 // imageAlt: PropTypes.string.isRequired,
};

export default MetaDecorator;