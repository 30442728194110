import React from "react";
import { Login } from "./login";
import { Signup } from "./signup";
import { useParams } from "react-router-dom";

export const LoginSignup = () => {
  let { redirect } = useParams();
  return (
    <div className="p6-login-signup-wrapper">
      <div className="p6-title-block">
        <h1 className="p6-loginsignup-title">Log in or Sign Up to Checkout!</h1>
      </div>
      <div className="p6-form-containers">
        <Login redirectUrl={redirect} />
        <div className="p6-form-seperator" />
        <Signup />
      </div>
    </div>
  );
};
