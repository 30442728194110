import React, {useState} from "react";
import {CustomQuoteForm} from "./form";
import {post} from "../../../api/api-caller";
import {END_POINTS} from "../../../api";
import {CustomQuoteLoggedInForm} from "./loggedin";
import {toast} from "react-toastify";
import {reportEvent} from "../../../utils";

export const CustomQuote = ({ onClose, token }) => {
  const [isSuccess, setSuccess] = useState(false);

  const contactApi = ({data, setSubmitting}) => {
    let endPoint = token ? END_POINTS.PRODUCT_QUOTE : END_POINTS.CONTACT_US;
    let reportCategory = token ? 'CustomQuoteForm' : 'CustomRequest';
    post(endPoint, data )
      .then((resp) => {
        setSubmitting(false);
        setSuccess(true);
        onClose();
        reportEvent(reportCategory, 'Submit', data.product)
        toast.success('Thank you! Your submission has been received!')
      })
      .catch((err) => {
        setSubmitting(false);
      });
  };
  return(
    <div className="p1---quote-modal-wrapper">
      <div className="p1---modal-block">
        <a
          data-w-id="fa2970d5-1a8d-0eb5-a6d3-aa8a9178304b"
          href="#"
          className="p1---modal-close-link"
        >
          <span className="text-span" onClick={() => onClose()}>Close [ X ]</span>
        </a>
        <div className="p1---modal-close-text">
          Request a Custom Quote or More Info!
        </div>
        <div className="p1---modal-form w-form">
          {token && (<CustomQuoteLoggedInForm onSubmit={contactApi} isSuccess={isSuccess}/>)}
          {!token && <CustomQuoteForm onSubmit={contactApi} isSuccess={isSuccess}/>}
        </div>
      </div>
    </div>
  );
}
