import { Markup } from "interweave";
import React, {useState} from "react";
import $ from 'jquery';

const TABS = {
  description: "Description",
  specifications:"Specifications",
  delivery_and_returns: "Delivery & Returns",
  product_downloads: "Product Downloads",
  product_faqs:"Product FAQs"
};

export const InfoTabs = ({ product }) => {
  
  const [selectedTab, setSelectedTab] = useState(0);

  const tabs = $('.w-tab-link').click(function(e) {
    e.preventDefault();
    tabs.removeClass('w--current');
    $(this).addClass('w--current');
  });

  const onTabClick = (index) => {
    setSelectedTab(index);
  };

  return (
    <div className="p1---product-info-wrapper">
      <div className="p1---product-info-tabs-3">
        <div
          className="p1---tab-titles w-tab-menu"
        >
          {
            Object.keys(TABS).map((tab, index) => (
              product[tab] &&
              <a id={`tab_${index}`} className={`p1---tab-link-block w-inline-block w-tab-link ${selectedTab === index ? 'w--current' : ''}`}
                 onClick={() => onTabClick(index)}>
                <div className="p1---product-tab-text">{TABS[tab]}</div>
              </a>
            ))
          }
        </div>
        <div className="p1---tabs-content-product-info w-tab-content">
          {
            Object.keys(TABS).map((tab, index) => (
              product[tab] &&
              <div className={`w-tab-pane-product-info-section ${selectedTab === index ? 'w--tab-active' : ''}`}>
                <Markup content={product[tab]} />
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
}
