import React, { useEffect, useState } from "react";
import img from "../../assets/images/1Asset-29-100.jpg";
import { get, post } from "../../api/api-caller";
import { END_POINTS } from "../../api";
import { ROUTES } from "../../utils/values";
import { Link } from "react-router-dom";
import altImage from "../../assets/images/1Asset-15-100.jpg";
import { toast } from "react-toastify";

import whatsapp from '../../assets/images/whatsapp.png';
import problem from '../../assets/images/problem.png';
import order from '../../assets/images/order-sample.png';
import procurement from '../../assets/images/procurement.png';
import payment from '../../assets/images/secure-payment.png';

export const Home = ({ cart, setCart }) => {
  const [chat, setChat] = useState("");
  const [task, setTask] = useState("");
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    get(END_POINTS.ROOT_CATEGORIES)
      .then((resp) => {
        setCategories(resp);
      })
      .catch((err) => console.error(err));
  }, []);

  const [grid, setGrid] = useState({});
  const [recentProducts, setRecentProducts] = useState([]);

  useEffect(() => {
    get(END_POINTS.HOME)
      .then(({ data }) => {
        setGrid(data.engagement_grids);
        setRecentProducts(data.recently_viewed_products);
      })
      .catch((err) => console.error(err));
  }, []);
  return (
    <div className="p13-wrapper">
      <div className="p13-header-wrapper">
        <div className="p13-header-menu">
          <div className="p13-head-menu-title">Our Categories</div>
          <div className="p13-header-menu-category-block">
            {categories &&
              categories.map((cat) => (
                <Link
                  to={`${ROUTES.CATEGORIES}/${cat.slug}`}
                  className="p13-header-menu-category-title"
                  key={cat.slug}
                >
                  {cat.name}
                </Link>
              ))}
          </div>
        </div>
        <div className="p13-banner-block">
          <a
            href={Object.keys(grid).length && grid["HOME_MAIN_BANNER"].url}
            target="_blank"
          >
            <img
              src={Object.keys(grid).length && grid["HOME_MAIN_BANNER"].image}
              // srcSet="images/1Asset-29-100-p-500.jpeg 500w, images/1Asset-29-100-p-800.jpeg 800w, images/1Asset-29-100.jpg 801w"
              sizes="(max-width: 479px) 96vw, (max-width: 767px) 97vw, 74vw"
              alt=""
              className="image-19"
            />
          </a>
        </div>
      </div>
      <div className="p13-top-snippet-block">
        <div className="p13-top-snippet-text">
          {Object.keys(grid).length && grid.SNIPPET_1.description}
        </div>
      </div>
      <div className="w-layout-grid p13-grid">
        {Object.keys(grid).map((key, index) => {
          if (key.includes("HOME_GRID_1")) {
            return (
              <Link
                to={grid[key].url || ROUTES.ROOT}
                className="p13-product-block"
                style={{ textDecoration: "none" }}
                key={key}
              >
                <img
                  src={grid[key].image}
                  alt=""
                  className="p13-top-product-image"
                />
                <Link
                  to={grid[key].url || ROUTES.ROOT}
                  className="p13-top-product-title"
                >
                  {grid[key].admin_title}
                </Link>
              </Link>
            );
          }
        })}
      </div>
      <div className="p13-mid-snippet-block">
        <div className="p13-mid-snippet-text">
          {Object.keys(grid).length && grid.SNIPPET_2.description}
        </div>
        <Link to={Object.keys(grid).length && grid.SNIPPET_2.url} className="p13-mid-snippet-button w-button">
          {Object.keys(grid).length && grid.SNIPPET_2.title}
        </Link>
      </div>
      <div className="p13-split-wrapper">
        <div className="p13-left-wrapper">
          <div className="p13-left-wrapper-title">
            We could just place more products here, but we&#x27;re all about the
            service!
          </div>
          <div className="p13-chat-wrapper">
            <div className="p13-chat-title">
              Chat, discuss &amp; place an order with a dedicated account
              manager over Whatsapp!
            </div>
            <div className="p13-whatsapp-form-block w-form">
              <form
                id="wf-form-Whatsapp-Form"
                name="wf-form-Whatsapp-Form"
                data-name="Whatsapp Form"
                redirect="https://wa.me/00447957110110"
                data-redirect="https://wa.me/00447957110110"
                className="p13-whatsapp-form"
              >
                <input
                  type="text"
                  className="p13-whatsapp-message-field w-input"
                  maxLength="256"
                  name="Whatsapp-Message-Field"
                  data-name="Whatsapp Message Field"
                  placeholder="Type your message here... "
                  id="Whatsapp-Message-Field"
                  onChange={(e) => {
                    setChat(e.target.value);
                  }}
                  style={{ width: "82.5%" }}
                />
                <a
                  href={encodeURI(`https://wa.me/442080162108?text=${chat}`)}
                  className="p13-whatsapp-button w-button"
                  target="_blank"
                  {...(!chat
                    ? { style: { pointerEvents: "none", cursor: "default" } }
                    : {})}
                >
                  Start Chat
                </a>
              </form>
              {/*<div className="w-form-done">*/}
              {/*  <div>Thank you! Your submission has been received!</div>*/}
              {/*</div>*/}
              {/*<div className="w-form-fail">*/}
              {/*  <div>Oops! Something went wrong while submitting the form.</div>*/}
              {/*</div>*/}
            </div>
          </div>
          <div className="p13-underchat-wrapper">
            <div className="p13-statement-block">
              <div className="p13-statement-text">
                {Object.keys(grid).length &&
                  grid["HOME_TEXT_AREA_2"].description}
              </div>
            </div>
            <div className="p13-job-block">
              <div className="p13-job-title">
                Need something done with what you just bought? <br />
                Post your tasks here and we’ll connect you with someone in need
                of work for free! <strong>#SupportYourCommunity</strong>
              </div>
              <div className="p13-job-form-block w-form">
                <div
                  id="wf-form-Whatsapp-Form"
                  name="wf-form-Whatsapp-Form"
                  data-name="Whatsapp Form"
                  redirect="https://wa.me/00447957110110"
                  data-redirect="https://wa.me/00447957110110"
                  className="p13-job-form"
                >
                  <input
                    type="text"
                    className="p13-job-form-field w-input"
                    maxLength="256"
                    name="Describe-your-task"
                    data-name="Describe your task"
                    placeholder="Describe your task in once Sentance... "
                    id="Describe-your-task"
                    value={task}
                    onChange={(e) => setTask(e.target.value)}
                  />
                  <input
                    type="submit"
                    value="Post Task"
                    className="p13-job-form-button w-button"
                    disabled={!task}
                    onClick={() => {
                      post(END_POINTS.HOME_TASK, { task }).then(() => {
                        setTask("");
                        toast.success("Posted successfully !");
                      });
                    }}
                  />
                </div>
                {/*<div className="w-form-done">*/}
                {/*  <div>Thank you! Your submission has been received!</div>*/}
                {/*</div>*/}
                {/*<div className="w-form-fail">*/}
                {/*  <div>*/}
                {/*    Oops! Something went wrong while submitting the form.*/}
                {/*  </div>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
          <div className="p13-confidence-wrapper">
            <div className="p13-trust-section-title">
              Shop with Confidence...
            </div>
            <div className="w-layout-grid p13-circle-grid">
              <div className="p13-trust-block">
                <img
                  src={problem}
                  alt=""
                  className="p13-trust-image"
                />
                <a href="#" className="p13-trust-text">
                  Got a Problem?
                  <br />
                  We&#x27;re here 24/7
                </a>
              </div>
              <div className="p13-trust-block">
                <img
                  src={payment}
                  alt=""
                  className="p13-trust-image"
                />
                <a href="#" className="p13-trust-text">
                  Secure Payments
                  <br />
                  By Stripe
                </a>
              </div>
              <div className="p13-trust-block">
                <img
                  src={order}
                  alt=""
                  className="p13-trust-image"
                />
                <a href="#" className="p13-trust-text">
                  Order Samples
                  <br />
                  for most lines
                </a>
              </div>
              <div className="p13-trust-block">
                <img
                  src={procurement}
                  alt=""
                  className="p13-trust-image"
                />
                <a href="#" className="p13-trust-text">
                  World Class
                  <br />
                  Procurement
                </a>
              </div>
              <div className="p13-trust-block">
                <img
                  src={whatsapp}
                  alt=""
                  className="p13-trust-image"
                />
                <a href="#" className="p13-trust-text">
                  A Whatsapp Message Away!
                </a>
              </div>
            </div>
            <div className="p13-confidence-lower-wrapper">
              <div className="p13-conf-lower-picture">
                <div className="p13-conf-lower-text-line-1">
                  We serve thousands of new customers every week.
                </div>
                <div className="p13-conf-lower-text-line-2">
                  Join other shoppers in your area, and try Justbulk.com today.
                </div>
                <Link
                  to={ROUTES.LOGIN}
                  className="p13-conf-lower-button w-button"
                >
                  Sign Up Today
                </Link>
              </div>
            </div>
          </div>
          {recentProducts.length ? (
            <>
              <div className="p13-recently-viewed-title">
                Your Recently Viewed Products..
              </div>
              <div className="w-layout-grid p13-recently-viewed-grid">
                {recentProducts.map((product, index) => (
                  <div className="p13-recently-viewed-block" key={index}>
                    <img
                      src={
                        (product.images &&
                          product.images.length &&
                          product.images[0].original) ||
                        altImage
                      }
                      className="p13-recently-viewed-image"
                    />
                    <Link
                      to={`${ROUTES.CATEGORIES}${product.product_url}`}
                      className="p13-recently-viewed-product-title"
                    >
                      {product.title}
                    </Link>
                  </div>
                ))}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        <div className="p13-right-wrapper">
          <h3 className="p13-side-block-heading">
            <strong>
              Articles, News &amp; the latest from us and our customers!
            </strong>
          </h3>
          <div className="p13-side-blog-wrapper">
            <div className="w-layout-grid p13-side-blog-grid">
              {Object.keys(grid).map((key, index) => {
                if (key.includes("HOME_GRID_2")) {
                  return (
                    <div className="p13-side-blog-block" key={key}>
                      <Link
                        to={grid[key].url || ROUTES.ROOT}
                        rel="noopener noreferrer"
                      >
                        <img
                          src={grid[key].image}
                          alt=""
                          className="p13-side-blog-image"
                        />
                      </Link>
                      <Link
                        to={grid[key].url || ROUTES.ROOT}
                        className="p13-side-blog-title"
                        rel="noopener noreferrer"
                      >
                        {grid[key].admin_title}
                      </Link>
                      <div className="p13-side-blog-description">
                        {grid[key].description}
                      </div>
                      <Link
                        to={grid[key].url || ROUTES.ROOT}
                        className="p13-side-blog-link"
                        rel="noopener noreferrer"
                      >
                        Read Post
                      </Link>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
