import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import "./style/normalize.css";
import "./style/webflow.css";
import "./style/ebads-stupendous-project.webflow.css";
import 'react-toastify/dist/ReactToastify.css';
import "./App.css";
import {BrowserRouter} from "react-router-dom";
import { Header } from "./components/header";
import { FooterComponent } from "./components/footer";
import { Provider } from "react-redux";
import { store } from "./store";
import {Main} from "./components/main";
import {ToastContainer} from "react-toastify";

const stripe_key =  process.env.REACT_APP_STRIPE_KEY;
const promise = loadStripe(stripe_key);

class App extends React.Component {
  render() {
    return (
      <>
        <Provider store={store}>
          <BrowserRouter>
            <ToastContainer />
            <Header />
            <Elements stripe={promise}>
              <Main/>
            </Elements>
            <FooterComponent />
          </BrowserRouter>
        </Provider>
      </>
    );
  }
}

export default App;
