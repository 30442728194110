import * as types from './types';

export const resetAuth = () => ({
  type: types.AUTH_RESET,
});

export const setCart = data => ({
  type: types.UI_SET_CART,
  data,
});

export const setAccountToken = data => ({
  type: types.UI_SET_TOKEN,
  data,
});

export const setAccountInfo = data => ({
  type: types.UI_SET_ACCOUNT_INFO,
  data,
});

export const setBasket = data => ({
  type: types.UI_SET_BASKET,
  data,
});

export const verifyAccount = () => ({
  type: types.VERIFY_ACCOUNT,
});

export const toggleActivationModal = () => ({
  type: types.TOGGLE_ACTIVATION_MODAL,
})