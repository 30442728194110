import React from "react";
import { Redirect, Route } from "react-router";
import { ROUTES } from "../../../utils/values";

export const PrivateRoute = ({
  component: Component,
  token,
  path,
  ...rest
}) => (
  <Route
    {...rest}
    path={path}
    render={(props) =>
      token ? (
        <Component {...props} />
      ) : (
        <Redirect to={`${ROUTES.LOGIN}${path}`} />
      )
    }
  />
);
