import React, {useEffect, useState} from "react";

export const VendorHistory = () => {
  
  useEffect(() => {
  
  }, []);
  
  return (
    <>
      <div className="p35-right-wrapper">
        <h4 className="p21-right-wrapper-title">Your Customer Orders</h4>
        <div className="p35-filter-wrapper">
          <div className="p22-date-filter-block w-form">
            <form id="email-form" name="email-form" data-name="Email Form" className="p22-filter-form">
              <input
                type="text" className="p36-order-search w-input" maxLength="256" name="name" data-name="Name"
                placeholder="Search Order No&#x27;s or Customer Name" id="name"/>
              <input
                type="submit" value="Filter"
                data-wait="Please wait..."
                className="p22-date-filter-button-copy w-button"/>
            </form>
            <div className="w-form-done">
              <div>Your filtered invoices can be found below!</div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went wrong.</div>
            </div>
          </div>
          <div className="p22-date-filter-block w-form">
            <form id="email-form" name="email-form" data-name="Email Form" className="p22-filter-form"><label
              htmlFor="name-2" className="p22-from-label">From</label>
              <input
                type="text"
                className="p22-from-date w-input"
                maxLength="256" name="name-2"
                data-name="Name 2" placeholder="dd/mm/yyyy"
                id="name-2"/>
              <label htmlFor="email-3"
                     className="p22-to-label">To</label>
              <input
                type="text" className="p22-to-label w-input" maxLength="256" name="email-3" data-name="Email 3"
                placeholder="dd/mm/yyyy" id="email-3" required=""/>
              <input type="submit" value="Filter"
                     data-wait="Please wait..."
                     className="p22-date-filter-button w-button"/>
            </form>
            <div className="w-form-done">
              <div>Your filtered invoices can be found below!</div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went wrong.</div>
            </div>
          </div>
        </div>
        <div className="p35-listings-wrapper">
          <div className="p35-listing-title-row">
            <p className="p36-title-order-no"><strong>Order No</strong></p>
            <p className="p36-title-customer"><strong>Customer Name</strong></p>
            <p className="p36-title-order-total"><strong>Order Total</strong></p>
            <p className="p36-title-order-date"><strong>Order Date</strong></p>
            <p className="p36-title-order-status"><strong>Order Status</strong></p>
            <p className="p36-action-title"><strong>Actions</strong></p>
          </div>
          <div className="p35-listing-row">
            <p className="p36-order-no">19DIGITSLIMITED</p>
            <p className="p36-customer-name">Jack Floyd Mayweather </p>
            <p className="p36-order-total">£100,000.00</p>
            <p className="p36-order-date">10/10/2001</p>
            <div data-hover="" data-delay="0" className="p36-dropdown w-dropdown">
              <div className="p36-order-status-drop-down w-dropdown-toggle">
                <div className="w-icon-dropdown-toggle"></div>
                <div>Dropdown</div>
              </div>
              <nav className="w-dropdown-list">
                <a href="#" className="w-dropdown-link">Link 1</a>
                <a href="#" className="w-dropdown-link">Link 2</a>
                <a href="#" className="w-dropdown-link">Link 3</a>
              </nav>
            </div>
            <div className="p36-action-block">
              <p className="p35-action">
                <a href="#" className="p36-invoice-link">Customer Invoice</a>
              </p>
              <p className="p35-action">
                <a href="#" className="p36-invoice-link-copy">Your Invoice</a>
              </p>
            </div>
          </div>
          <div className="p35-listing-row">
            <p className="p36-order-no">19DIGITSLIMITED</p>
            <p className="p36-customer-name">Jack Floyd Mayweather </p>
            <p className="p36-order-total">£100,000.00</p>
            <p className="p36-order-date">10/10/2001</p>
            <div data-hover="" data-delay="0" className="p36-dropdown w-dropdown">
              <div className="p36-order-status-drop-down w-dropdown-toggle">
                <div className="w-icon-dropdown-toggle"></div>
                <div>Dropdown</div>
              </div>
              <nav className="w-dropdown-list">
                <a href="#" className="w-dropdown-link">Link 1</a>
                <a href="#" className="w-dropdown-link">Link 2</a>
                <a href="#" className="w-dropdown-link">Link 3</a>
              </nav>
            </div>
            <div className="p36-action-block">
              <p className="p35-action">
                <a href="#" className="p36-invoice-link">Customer Invoice</a>
              </p>
              <p className="p35-action">
                <a href="#" className="p36-invoice-link-copy">Your Invoice</a>
              </p>
            </div>
          </div>
          <div className="p35-listing-row">
            <p className="p36-order-no">19DIGITSLIMITED</p>
            <p className="p36-customer-name">Jack Floyd Mayweather </p>
            <p className="p36-order-total">£100,000.00</p>
            <p className="p36-order-date">10/10/2001</p>
            <div data-hover="" data-delay="0" className="p36-dropdown w-dropdown">
              <div className="p36-order-status-drop-down w-dropdown-toggle">
                <div className="w-icon-dropdown-toggle"></div>
                <div>Dropdown</div>
              </div>
              <nav className="w-dropdown-list">
                <a href="#" className="w-dropdown-link">Link 1</a>
                <a href="#" className="w-dropdown-link">Link 2</a>
                <a href="#" className="w-dropdown-link">Link 3</a>
              </nav>
            </div>
            <div className="p36-action-block">
              <p className="p35-action">
                <a href="#" className="p36-invoice-link">Customer Invoice</a>
              </p>
              <p className="p35-action">
                <a href="#" className="p36-invoice-link-copy">Your Invoice</a>
              </p>
            </div>
          </div>
          <div className="p35-listing-row">
            <p className="p36-order-no">19DIGITSLIMITED</p>
            <p className="p36-customer-name">Jack Floyd Mayweather </p>
            <p className="p36-order-total">£100,000.00</p>
            <p className="p36-order-date">10/10/2001</p>
            <div data-hover="" data-delay="0" className="p36-dropdown w-dropdown">
              <div className="p36-order-status-drop-down w-dropdown-toggle">
                <div className="w-icon-dropdown-toggle"></div>
                <div>Dropdown</div>
              </div>
              <nav className="w-dropdown-list">
                <a href="#" className="w-dropdown-link">Link 1</a>
                <a href="#" className="w-dropdown-link">Link 2</a>
                <a href="#" className="w-dropdown-link">Link 3</a>
              </nav>
            </div>
            <div className="p36-action-block">
              <p className="p35-action">
                <a href="#" className="p36-invoice-link">Customer Invoice</a>
              </p>
              <p className="p35-action">
                <a href="#" className="p36-invoice-link-copy">Your Invoice</a>
              </p>
            </div>
          </div>
          <div className="p35-listing-row">
            <p className="p36-order-no">19DIGITSLIMITED</p>
            <p className="p36-customer-name">Jack Floyd Mayweather </p>
            <p className="p36-order-total">£100,000.00</p>
            <p className="p36-order-date">10/10/2001</p>
            <div data-hover="" data-delay="0" className="p36-dropdown w-dropdown">
              <div className="p36-order-status-drop-down w-dropdown-toggle">
                <div className="w-icon-dropdown-toggle"></div>
                <div>Dropdown</div>
              </div>
              <nav className="w-dropdown-list">
                <a href="#" className="w-dropdown-link">Link 1</a>
                <a href="#" className="w-dropdown-link">Link 2</a>
                <a href="#" className="w-dropdown-link">Link 3</a>
              </nav>
            </div>
            <div className="p36-action-block">
              <p className="p35-action">
                <a href="#" className="p36-invoice-link">Customer Invoice</a>
              </p>
              <p className="p35-action">
                <a href="#" className="p36-invoice-link-copy">Your Invoice</a>
              </p>
            </div>
          </div>
          <div className="p35-listing-row">
            <p className="p36-order-no">19DIGITSLIMITED</p>
            <p className="p36-customer-name">Jack Floyd Mayweather </p>
            <p className="p36-order-total">£100,000.00</p>
            <p className="p36-order-date">10/10/2001</p>
            <div data-hover="" data-delay="0" className="p36-dropdown w-dropdown">
              <div className="p36-order-status-drop-down w-dropdown-toggle">
                <div className="w-icon-dropdown-toggle"></div>
                <div>Dropdown</div>
              </div>
              <nav className="w-dropdown-list">
                <a href="#" className="w-dropdown-link">Link 1</a>
                <a href="#" className="w-dropdown-link">Link 2</a>
                <a href="#" className="w-dropdown-link">Link 3</a>
              </nav>
            </div>
            <div className="p36-action-block">
              <p className="p35-action">
                <a href="#" className="p36-invoice-link">Customer Invoice</a>
              </p>
              <p className="p35-action">
                <a href="#" className="p36-invoice-link-copy">Your Invoice</a>
              </p>
            </div>
          </div>
        </div>
        <div data-hover="" data-delay="0" className="p21-account-nav-mobile w-dropdown">
          <div className="dropdown-toggle-4 w-dropdown-toggle">
            <div className="w-icon-dropdown-toggle"></div>
            <div>Account Navigation</div>
          </div>
          <nav className="w-dropdown-list">
            <a href="#" className="w-dropdown-link">Link</a>
            <a href="#" className="w-dropdown-link">Order History</a>
            <a href="#" className="w-dropdown-link">Address Book</a>
            <a href="#" className="w-dropdown-link">Saved Products</a>
            <a href="#" className="w-dropdown-link">Stock Alerts</a>
            <a href="#" className="w-dropdown-link">Stock Forecasts</a>
            <a href="#" className="w-dropdown-link">Your Artwork</a>
            <a href="#" className="w-dropdown-link">Customer Service</a>
          </nav>
        </div>
      </div>
    </>
  )
  
};
