import React, {useEffect, useState} from "react";
import {FieldArray, useFormikContext, Formik, Field, Form} from "formik";
import {post} from "../../../api/api-caller";
import {END_POINTS} from "../../../api";
import {toast} from "react-toastify";

export const Step6 = ({sellerId}) => {
  
  const step6 = {
    supplier_lead_id: sellerId,
    read_term_and_conditions: false,
    quality_of_goods_responsibility: false,
    sale_owned_products_only: false,
  }
  
  const handleSubmit = (values) => {
    if(!values.quality_of_goods_responsibility || !values.read_term_and_conditions || !values.sale_owned_products_only){
      toast.error('you must accept all the terms and conditions');
      return
    }
    post(`${END_POINTS.SELLER_SIGNUP}step6`, values)
      .then((resp) => {
        toast.success('Your seller account is registered successfully, you will be notified through email')
      })
      .catch()
  }
  return (
    <Formik initialValues={step6} onSubmit={handleSubmit}>
      {
        ({
           values,
           setFieldValue,
         },
        ) => {
          return (
            <Form>
              <div class="p34-form-c1">
                <div class="p34-form-title-wrapper">
                  <h2 class="p34-c1-h3">Terms &amp; Stuff<strong class="bold-text-12"><br/></strong></h2>
                  <div class="p34-progress-wrapper">
                    <div class="p34-progress-bar" style={{width: '100%'}}></div>
                  </div>
                  <label htmlFor="name-4" className="p34-form-field-label">
                    We&#x27;ll review your application and come back to
                    you with a decision within 2 - 5 Working Days, If successful you will be assigned a dedicated account
                    manager who will arrange the on boarding process with you for your products and listings.
                    <br/>
                  </label>
                  <label className="w-checkbox">
                    <input
                      type="checkbox"
                      id="terms"
                      className="w-checkbox-input"
                      name="read_term_and_conditions"
                      checked={values.read_term_and_conditions}
                      onChange={(e) =>
                        setFieldValue("read_term_and_conditions", e.target.checked)
                      }
                    />
                    <span className="w-form-label">I have read the Terms &amp; Conditions of Use and give consent for my details and company details
                      <br/> to be verified.
                    </span>
                  </label>
                  <label className="w-checkbox">
                    <input
                      type="checkbox"
                      id="quality_of_goods_responsibility"
                      name="quality_of_goods_responsibility"
                      className="w-checkbox-input"
                      checked={values.quality_of_goods_responsibility}
                      onChange={(e) => setFieldValue("quality_of_goods_responsibility", e.target.checked)}
                    
                    />
                    <span className="w-form-label">
                      I understand I the marketplace supplier are solely responsible for the quality and safety of the Goods <br/>
                      Sold through the Justbulk Marketplace.
                    </span>
                  </label>
                  <label className="w-checkbox">
                    <input
                      type="checkbox"
                      id="sale_owned_products_only"
                      name="sale_owned_products_only"
                      className="w-checkbox-input"
                      checked={values.sale_owned_products_only}
                      onChange={(e) => setFieldValue("sale_owned_products_only", e.target.checked)}
                    />
                    <span className="w-form-label">
                      I understand I am only allowed to sell goods I physically own and hold and ensure stock counts
                      <br/>
                      will remain as accurate as possible.
                      <br/>
                      <br/>
                    </span>
                  </label>
                  <button type="submit" className="p29-cta-button w-button">Sign up as a Seller</button>
                </div>
              </div>
            </Form>
          )
        }}
    
    </Formik>
  
  )
}
