import React, {useEffect, useState} from "react";
import { ItemCardContainer } from "./container.js";
import {get} from "../../../api/api-caller";
import {END_POINTS} from "../../../api";

export const SavedProducts = () => {

    const [items, setItems] = useState([]);
    const [pages, setPages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [pageSize , setPageSize] = useState(10);

    useEffect(() => {
        get(`${END_POINTS.GET_SAVED_PRODUCTS}?page_size=${pageSize}`)
            .then((res) => {
                setItems(res.results);
                setIsLoading(false);
                let pageCount = Math.ceil(res.count/pageSize);
                let pageArray = [];
                let lastIndex = pageCount > 12 ? 12 : pageCount;
                for(let i=currentPage; i<=lastIndex; i++){
                  pageArray.push(i)
                }
                setPages(pageArray);
            })
            .catch((err) => alert(err));
    }, []);

    const getCurrentPageItem = (page_number= currentPage) => {
      get(`${END_POINTS.GET_SAVED_PRODUCTS}?page_size=${pageSize}&page=${page_number}`)
          .then((res) => {
            setItems(res.results);
            setCurrentPage(page_number)
          })
          .catch((err) => alert(err));
    }

    const updatePagination = (page_number, flow) => {
      //let start;
      //let end;
      // if(flow === 1){
      //   start=pages[0]+1;
      //   end= pages[pages.length-1]+1;
      // }else if( flow === -1){
      //   start=pages[0]-1;
      //   end= pages[pages.length-1]-1;
      // }
      // let pageArray = []
      // for(let i=start; i<=end; i++){
      //   pageArray.push(i)
      //
      // }
      // setPages(pageArray);
    //  if(flow === 1)
        getCurrentPageItem(page_number+flow)
     // else
     //   getCurrentPageItem(start)
    }

    return (
        <>
            <div className="p25-your-artwork-wrapper">
                <h4 className="p21-right-wrapper-title">Saved Products</h4>
            </div>
            <div className="p25-historical-uploads-wrapper">
                {items.map(item => (
                    <ItemCardContainer item={item} getCurrentPageItem={getCurrentPageItem}/>
                ))}
            </div>

            <div class="p26-pagination-wrapper">
              {currentPage > 1 && <a href="#" className="p26-pagination-last-page" onClick={(e) => updatePagination(currentPage, -1)}>&lt;</a>}
              {pages.length > 0  &&
              pages.map((page_item, index)=> (
                    <div className={currentPage === index+1 ? 'p26-pagination-current-page' : 'p26-pagination-pages-available'} onClick={(e)=> getCurrentPageItem(page_item)}>{page_item}</div>
                ))
              }
              {currentPage < pages.length && < a href="#" className="p26-pagination-next-page" onClick={(e) => updatePagination(currentPage, 1)}>Next &gt;</a>}
            </div>

        </>
    )

};
