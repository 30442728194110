import React from "react";
import renderHTML from "react-render-html";

export default class Invoice extends React.Component {
  render() {
    return (
      <div style={{ margin: '20px 0 0 20px' }}>{renderHTML(this.props.content)}</div>
    );
  }
}
