/*
page Pouch Shop
*/
import React from "react";
import MetaDecorator from "../../utils/meta-decorator";

export const PouchShop = ({
 toggleFreeSampleModal,
 }) => {
  return (
    <React.Fragment>
      <MetaDecorator
        description="Your One Stop Pouch Shop! Shop over 250 different stock pouches sizes and styles available online with 48 Hour Delivery or Custom Print in 14 Days."
        title="Justbulk UK's leading Pouchshop"
        robots="“index, follow”"
      />
      <div className="lp-p1-page-wrapper">
        <div className="lp-p1-head-wrapper">
          <div className="l1-p1-first-wrapper">
            <div className="l1-p1-page-title-wrapper">
              <div className="lp-p--title-hash">#Pouchshop</div>
              <h1 className="lp-p-h1">Justbulk UK&#x27;s Leading PouchShop</h1>
              <h1 className="l1-p1-h2">Your One Stop Pouch Shop! Shop over 250 different sizes and styles online now
                with
                48 Hour Delivery. </h1>
              <h3 className="lp-p1-h3">Custom Printed Pouches in 10 Days. Get a Free Quotation Now!</h3>
              <div className="p29-cta-wrapper">
                <a href="https://justbulk.co.uk/contact-us" className="lp-p1-cta w-button">Request Quote</a>
              </div>
            </div>
            <div className="l1-p1-picture-container">
              <img
                src="https://justbulk.co.uk/media/uploaded-media/Group_Picture_of_Stand_Up_Pouches_Pouchshop_UK.png"
                loading="eager" width="800" height="386" alt="Pouchshop UK Stand Up Pouch Group Picture "
                srcSet="https://justbulk.co.uk/media/uploaded-media/Group_Picture_of_Stand_Up_Pouches_Pouchshop_UK.png"
                sizes="(max-width: 800px) 100vw, 800px" className="l1-p1-pouchshop-banner"/>
            </div>
          </div>
          <div className="l1-p1-strip-wrapper">
            <div className="l1-p1-snippet-block">
              <div className="l1-p1-bullet-point"></div>
              <div className="l1-p1-snippet-text"><strong className="bold-text-2">Premium Quality Pouches</strong></div>
            </div>
            <div className="l1-p1-snippet-block">
              <div className="l1-p1-bullet-point"></div>
              <div className="l1-p1-snippet-text"><strong className="bold-text-2">Printed from 500 Units</strong></div>
            </div>
            <div className="l1-p1-snippet-block">
              <div className="l1-p1-bullet-point"></div>
              <div className="l1-p1-snippet-text"><strong className="bold-text-2">UK Stockist</strong></div>
            </div>
            <div className="l1-p1-snippet-block">
              <div className="l1-p1-bullet-point"></div>
              <div className="l1-p1-snippet-text"><strong className="bold-text-2">BRC Food Grade</strong></div>
            </div>
          </div>
          <div className="lp-p1-text">You can find all our stock pouches available to shop below, each come in a wide
            range of
            sizes if your sizes! If you size is not listed just start a live chat chances are we may have it at our
            warehouse. All our UK Pouch orders are delivered from our UK Warehouse. If you are unsure of your sizing
            feel free to request a sample pouch, all our pouch samples are free so long as you place an order with us!
            We will charge you an initial £1.99 Fee for these which will be discounted when you place an order.
          </div>
        </div>
        <div className="w-layout-grid lp-p1-pouchshop-grid">
          <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2b3f-7fc867f5" className="lp-p1-product-frame">
            <img
              src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-White-Stand-Up-Pouch-Picture-1.png"
              alt="Pouchshop uk matt white stand up pouch" loading="lazy" className="lp-p1-picutre"/>
            <div className="lp-p1-product-title">
              <a href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/Matt-White-Stand-Up-Pouch"
                 className="link">Matt White Stand Up Pouch</a>
            </div>
            <div className="lp-p1-product-price">from £0.08 P/Unit</div>
          </div>
          
          <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2b46-7fc867f5" className="lp-p1-product-frame">
            <img
              src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-Black-Stand-Up-Pouch-Picture-1.png"
              alt="Pouchshop UK matt black stand up pouch" loading="lazy" className="lp-p1-picutre"/>
            <div className="lp-p1-product-title">
              <a href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/Matt-Black-Stand-Up-Pouch"
                 className="link">Matt Black Stand Up Pouch</a>
            </div>
            <div className="lp-p1-product-price">from £0.08 P/Unit</div>
          </div>
          <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2b4d-7fc867f5" className="lp-p1-product-frame">
            <img
              src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-Kraft-Stand-Up-Pouch-Picture-1.png"
              alt="Pouchshop UK Brown Kraft Pouch" loading="lazy" className="lp-p1-picutre"/>
            <div className="lp-p1-product-title">
              <a href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/Brown-Kraft-Stand-Up-Pouch"
                 className="link">Brown Kraft Stand Up Pouch</a>
            </div>
            <div className="lp-p1-product-price">from £0.08 P/Unit</div>
          </div>
          <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2b54-7fc867f5" className="lp-p1-product-frame">
            <img
              src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-Holographic-Stand-Up-Pouch-Picture-1.png"
              alt="Pouchshop UK Holographic Stand Up Pouch" loading="lazy" className="lp-p1-picutre"/>
            <div className="lp-p1-product-title">
              <a href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/holographic-stand-up-pouch"
                 className="link">Holographic Stand Up Pouch</a>
            </div>
            <div className="lp-p1-product-price">from £0.10 P/Unit</div>
          </div>
          <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2b5b-7fc867f5" className="lp-p1-product-frame">
            <img
              src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-Shiny-White-Stand-Up-Pouch-Picture-1.png"
              alt="Pouchshop uk Gloss White Stand Up Pouch" loading="lazy" className="lp-p1-picutre"/>
            <div className="lp-p1-product-title">
              <a href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/gloss-white-stand-up-pouch"
                 className="link">Gloss White Stand Up Pouch</a>
            </div>
            <div className="lp-p1-product-price">from £0.10 P/Unit</div>
          </div>
          <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2b62-7fc867f5" className="lp-p1-product-frame">
            <img
              src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-Shiny-Black-Stand-Up-Pouch-Picture-1.png"
              alt="Pouchshop UK Gloss Black Stand Up Pouch" loading="lazy" className="lp-p1-picutre"/>
            <div className="lp-p1-product-title">
              <a href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/gloss-black-stand-up-pouch"
                 className="link">Gloss Black Stand Up Pouch </a>
            </div>
            <div className="lp-p1-product-price">from £0.10 P/Unit</div>
          </div>
          <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2b69-7fc867f5" className="lp-p1-product-frame">
            <img
              src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-Gold-Stand-Up-Pouch-Picture-1.png"
              alt="Pouchshop uk Shiny Gold Stand Up Pouch " loading="lazy" className="lp-p1-picutre"/>
            <div className="lp-p1-product-title">
              <a href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/Shiny-Gold-stand-up-pouch"
                 className="link">Shiny Gold Stand Up Pouch </a>
            </div>
            <div className="lp-p1-product-price">from £0.10 P/Unit</div>
          </div>
          <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2b70-7fc867f5" className="lp-p1-product-frame">
            <img
              src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-Chrome-Stand-Up-Pouch-Picture-1.png"
              alt="Pouchshop UK Chrome Silver Stand Up Pouch" loading="lazy" className="lp-p1-picutre"/>
            <div className="lp-p1-product-title">
              <a
                href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/chrome-silver-stand-up-pouch"
                className="link">Chrome Silver Stand Up Pouch </a>
            </div>
            <div className="lp-p1-product-price">from £0.10 P/Unit</div>
          </div>
          <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2b77-7fc867f5" className="lp-p1-product-frame">
            <img
              src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-White-Clear-Stand-Up-Pouch-Picture-1.png"
              alt="Pouchshop UK Gloss White Half Clear Stand Up Pouch" loading="lazy" className="lp-p1-picutre"/>
            <div className="lp-p1-product-title">
              <a
                href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/Gloss-White-One-Side-Clear-Stand-Up-Pouch"
                className="link">Gloss White / Clear Stand Up Pouch </a>
            </div>
            <div className="lp-p1-product-price">from £0.12 P/Unit</div>
          </div>
          <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2b7e-7fc867f5" className="lp-p1-product-frame">
            <img
              src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-Black-Clear-Stand-Up-Pouch-Picture-1.png"
              alt="Pouchshop UK Gloss Black Half Clear Stand Up Pouch" loading="lazy" className="lp-p1-picutre"/>
            <div className="lp-p1-product-title">
              <a
                href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/gloss-black-one-side-clear-stand-up-pouch"
                className="link">Gloss Black / Clear Stand Up Pouch </a>
            </div>
            <div className="lp-p1-product-price">from £0.12 P/Unit</div>
          </div>
          <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2b85-7fc867f5" className="lp-p1-product-frame">
            <img
              src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-Chrome-Clear-Stand-Up-Pouch-Picture-1.png"
              alt="Pouchshop UK Chrome Silver Half Clear Stand Up Pouch" loading="lazy" className="lp-p1-picutre"/>
            <div className="lp-p1-product-title">
              <a
                href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/Chrome-Silver-one-side-clear-stand-up-pouch"
                className="link">Chrome Silver / Clear Stand Up Pouch </a>
            </div>
            <div className="lp-p1-product-price">from £0.12 P/Unit</div>
          </div>
          <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2b8c-7fc867f5" className="lp-p1-product-frame">
            <img
              src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-Holographic-Clear-Stand-Up-Pouch-Picture-1.png"
              alt="Pouchshop UK Holographic Half Clear Stand Up Pouch" loading="lazy" className="lp-p1-picutre"/>
            <div className="lp-p1-product-title">
              <a
                href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/holographic-clear-stand-up-pouch"
                className="link">Holographic / Clear Stand Up Pouch </a>
            </div>
            <div className="lp-p1-product-price">from £0.12 P/Unit</div>
          </div>
          <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2b93-7fc867f5" className="lp-p1-product-frame">
            <img
              src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-Kraft-Window-Stand-Up-Pouch-Picture-1.png"
              alt="Pouchshop UK Brown Kraft with Window Stand Up Pouch" loading="lazy" className="lp-p1-picutre"/>
            <div className="lp-p1-product-title">
              <a
                href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/Brown-Kraft-Rectangle-Window-Pouch"
                className="link">Brown Kraft Rectangle Window Pouch </a>
            </div>
            <div className="lp-p1-product-price">from £0.12 P/Unit</div>
          </div>
          <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2b9a-7fc867f5" className="lp-p1-product-frame">
            <img
              src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-Kraft-Oval-Window-Stand-Up-Pouch-Picture-1.png"
              alt="Pouchshop UK Brown Kraft Round Window Pouch" loading="lazy" className="lp-p1-picutre"/>
            <div className="lp-p1-product-title">
              <a
                href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/brown-kraft-round-window-pouch"
                className="link">Brown Kraft Round Window Pouch </a>
            </div>
            <div className="lp-p1-product-price">from £0.12 P/Unit</div>
          </div>
          <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2ba1-7fc867f5" className="lp-p1-product-frame">
            <img
              src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-Transparent-Stand-Up-Pouch-Picture-1.png"
              alt="Pouchshop UK Transparent Clear Stand Up Pouch" loading="lazy" className="lp-p1-picutre"/>
            <div className="lp-p1-product-title">
              <a href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/Transparent-stand-up-pouch"
                 className="link">Transparent / Clear Stand Up Pouch </a>
            </div>
            <div className="lp-p1-product-price">from £0.12P/Unit</div>
          </div>
          <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2ba8-7fc867f5" className="lp-p1-product-frame">
            <img
              src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-Spout-Pouch-Stand-Up-Pouch-Picture-1.png"
              alt="Pouchshop UK Spout Pouch with Top Spout" loading="lazy" className="lp-p1-picutre"/>
            <div className="lp-p1-product-title">
              <a
                href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/Top-spouted-Clear-Spout-Pouch"
                className="link">Top Spouted Clear Spout Pouch</a>
            </div>
            <div className="lp-p1-product-price">from £0.20 P/Unit</div>
          </div>
          <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2baf-7fc867f5" className="lp-p1-product-frame">
            <img
              src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-Silver-Stand-Up-Pouch-Picture-1.png"
              alt="Pouchshop UK Gloss Silver Foil Pouch" loading="lazy" className="lp-p1-picutre"/>
            <div className="lp-p1-product-title">
              <a
                href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/gloss-laminated-silver-pouch"
                className="link">Gloss Laminated Silver Foil Pouch</a>
            </div>
            <div className="lp-p1-product-price">from £0.12 P/Unit</div>
          </div>
          <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2bb6-7fc867f5" className="lp-p1-product-frame">
            <img
              src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-White-Kraft-Window-Stand-Up-Pouch-Picture-1.png"
              alt="Pouchshop UK White Kraft Rectangle Window Pouch" loading="lazy" className="lp-p1-picutre"/>
            <div className="lp-p1-product-title">
              <a
                href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/White-kraft-rectangle-window-pouch"
                className="link">White Kraft Rectangle Window Pouch</a>
            </div>
            <div className="lp-p1-product-price">from £0.12 P/Unit</div>
          </div>
          <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2bbd-7fc867f5" className="lp-p1-product-frame">
            <img
              src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-White-Kraft-Stand-Up-Pouch-Picture-1.png"
              alt="Pouchshop UK White Kraft Stand Up Pouch" loading="lazy" className="lp-p1-picutre"/>
            <div className="lp-p1-product-title">
              <a href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/white-kraft-stand-up-pouch"
                 className="link">White Kraft Stand Up Pouch</a>
            </div>
            <div className="lp-p1-product-price">from £0.12 P/Unit</div>
          </div>
          <div id="w-node-_87b1839d-3bfc-a7f3-4f65-985d612b2bc4-7fc867f5" className="lp-p1-product-frame">
            <img
              src="https://justbulk.co.uk/media/uploaded-media/Pouchshop-UK-Flat-Bottom-Bag.png"
              alt="Brown Kraft Flat Bottom Pouch with Pocket Zipper" loading="lazy" className="lp-p1-picutre"/>
            <div className="lp-p1-product-title">
              <a
                href="https://justbulk.co.uk/categories/flexible-packaging/stand-up-pouches/Brown-Kraft-Flat-Bottom-Pouch-with-pocket-zipper"
                className="link">Brown Kraft Flat Bottom Pouch with Pocket Zipper</a>
            </div>
            <div className="lp-p1-product-price">from £0.30 P/Unit</div>
          </div>
        </div>
        <div className="lp-p1-page-wrapper2">
          <div className="lp-p1-head-wrapper">
            <div className="lp-p1-title">About Our Pouch Shop</div>
            <div className="lp-p1-text">Justbulk is the largest online pouch shop, stocking over 250 different sizes and
              styles, unlike many pouch sellers online all our listed pouches on our online shop are BRC &amp; FDA
              Food Grade Accredited. In contrast to many of our competitors all our pouches are a premium thickness
              giving them that edge in a customers hard, we further have ultra premium pouches such as our Matt White,
              Matt Black Brown Kraft Pouches which range between 140 and 152 Mic in contrast to cheap 100-110 Mic ones
              found on other pouch shops!<br/><br/>When shopping for pouches online, you will find may well find cheaper
              goods a few pence available online that don&#x27;t hold food grade certifications or meet the standards
              leaving you vulnerable to large fines or even criminal chargers for putting consumers at risk if ever a
              complaint was made. All our listed pouches are made from virgin plastic unused and untouched and go well
              beyond meeting the bare minimum safety standard. <br/>‍<br/>‍<br/></div>
          </div>
        </div>
        <div className="lp-p1-page-wrapper2">
          <div className="lp-p1-head-wrapper">
            <div className="lp-p1-title">How to Shop Pouches Online?</div>
            <div className="lp-p1-text">Below are a number of checkpoints to tick off when shopping for pouches with us
              or
              at any other shop. <br/>1. Is the Material Stated <br/>This is a easy tell tale sign whether they are a
              specialist pouch supplier or just an online reseller that has no idea about food grade packaging.
              <br/><br/>2. Can they provide you a testing report to test food contact safety of material inside the
              pouch. <br/>Most pouches have a PE Inside however cheap suppliers often cut corners such as by using
              recyled PE which is not food grade safe instead of Virgin PE. <br/><br/>3. Can they provide a site
              certification that can be checked online?<br/>Its as simple as it is, if it doesn&#x27;t check out
              its a
              fake! <br/><br/>4. What sizes are listed and what do they refer to?<br/>An example is: 70g of
              coffee
              equates to 100g of powder or 100ml of water, if you&#x27;re unsure purchase a sample before
              hand!
              <br/>‍<br/>‍<br/></div>
          </div>
          <div className="lp-p1-head-wrapper">
            <div className="lp-p1-title">Custom Printed Pouches &amp; Labels</div>
            <div className="lp-p1-text">Beyond being a UK Pouch Shop, we also custom print our pouches in house as
              little as
              10 Working Days. We operate 3 printing methods for our pouches. The first is a surface print, where we
              take one of our stock lines from our pouch shop and print over it using a digital silk screen. The
              second method is we print the film for the pouch digitally and send this to our partner pouch converters
              to form the pouch. The last and final method is Gravure Printing where this is the most cost effective
              method when printing large volume, it also provides the most vibrant colours.<br/><br/>For many early
              stage companies printed pouches are not financially viable or are going through a discovery phase where
              their packaging and branding is constantly changing. For these types of customers we provide fully
              printed labels for your pouches delivered to your door within 2-3 working days from Artwork
              Confirmation! We also provide free artwork support for those looking to transition from labels to custom
              print or for customers starting out. Drop us an email at artwork@justbulk.co.uk to see how we can help
              you put together your pouches!<br/>‍<br/></div>
          </div>
          <div className="lp-p1-head-wrapper">
            <div className="lp-p1-title">Important Links</div>
            <div className="lp-p1-text"><strong>Online Verification for pouches, pouch shops and their manufacturers
              using
              certificate numbers. Below is a link to SGS and TUV&#x27;s certificate Database:</strong><br/>https://www.sgs-tuev-saar.com/en/certification-database.html
              <br/><br/><strong>Packaging Standards in a nutshell:</strong><br/>https://www.compliancegate.com/european-union-packaging-materials-regulations/<br/>https://www.food.gov.uk/business-guidance/packaging-and-labelling<br/><br/>‍<br/>‍<br/>‍<br/>
            </div>
          </div>
        </div>
      </div>
    
    </React.Fragment>
  )}