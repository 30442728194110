import React from "react";
import { Route, Switch } from "react-router-dom";
import { ROUTES } from "../../../utils/values";
import { YourDetails } from "../your-details/your-details";
import { AddressBook } from "../address-book";
import { OrderHistory } from "../order-history";
import { CustomerSupport } from "../customer-support";
import { Artwork } from "../artwork";
import { SavedProducts } from "../saved-products";
import { VendorListing } from "../vendor-management/vendor-profile";
import { VendorHistory } from "../vendor-management/vendor-selling-history";

export const AccountRouter = () => (
  <Switch>
    <Route path={ROUTES.ACCOUNT} exact>
      <YourDetails />
    </Route>
    <Route path={ROUTES.ACCOUNT_DETAILS} exact>
      <YourDetails />
    </Route>
    <Route path={ROUTES.ACCOUNT_ADDRESS_BOOK} exact>
      <AddressBook />
    </Route>
    <Route path={ROUTES.ACCOUNT_ORDER_HISTORY} exact>
      <OrderHistory />
    </Route>
    <Route path={ROUTES.SAVED_PRODUCTS} exact>
      <SavedProducts />
    </Route>
    <Route path={ROUTES.ARTWORK_UPLOAD} exact>
      <Artwork />
    </Route>
    <Route path={ROUTES.CUSTOMER_SERVICE} exact>
      <CustomerSupport />
    </Route>
    <Route path={ROUTES.CUSTOMER_SERVICE} exact>
      <CustomerSupport />
    </Route>
    <Route path={ROUTES.LISTINGS} exact>
      <VendorListing />
    </Route>
    <Route path={ROUTES.HISTORY} exact>
      <VendorHistory />
    </Route>
  </Switch>
);
