import React, { useState } from "react";
import altImage from "../../../assets/images/1Asset-15-100.jpg";
import { addToBasket, removeFromBasket } from "../../../utils/basket";

export const getSelectedAddOn = (
  additionalOptions,
  selected_additional_option
) => {
  if (!selected_additional_option) {
    return "";
  }
  const { text, additional_per_unit_price } = additionalOptions.find(
    (option) => option.id === selected_additional_option
  );
  return `${text} - £${additional_per_unit_price}`;
};

export const CartItem = ({ basketId, item, setRefresh, screenId }) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div className="p10-product-item-block-new">
      <div className="p10-order-item-copy">
        <div className="p10-product-info-block">
          <img
            src={
              (item.product.images &&
                item.product.images.length &&
                item.product.images[0].original) ||
              altImage
            }
            className="image-12"
          />
          <div className="p10-product-text-block">
            {screenId === "checkout" ? (
              <div className="p5-product-price-span">
                £{item.price_excl_tax}
              </div>
            ) : (
              ""
            )}
            <div className="p10-product-text">{item.product.title}</div>
            <div className="p10-product-text">
              {getSelectedAddOn(
                item.additional_options,
                item.selected_additional_option
              )}
            </div>
            {item.volumetric_pricing && (
              <select
                className="p10-product-quantity-dropdown w-dropdown"
                onChange={({ target: { value } }) => {
                  const values = value.split(",");
                  const options = {
                    url: item.stockrecord.split("stockrecord")[0],
                    package_id: values[2],
                  };
                  if (item.selected_additional_option) {
                    options.additional_option_id = item.selected_additional_option;
                  }
                  addToBasket({ ...options }, setIsLoading, setRefresh, item);
                }}
                style={{ minWidth: "200px" }}
              >
                {item.volumetric_pricing.map((option) => (
                  <option
                    className="w-dropdown-link"
                    value={`${option.package_quantity},${option.price_excl_tax},${option.id},${option.name}`}
                    selected={item.selected_volumetric_pricing === option.id}
                  >
                    {`${option.name} - ${option.package_quantity} Units - £${option.price_excl_tax}`}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>
      </div>
      <div className="p10-item-price-block">
        {screenId !== "checkout" ? (
          <>
            <div className="p10-product-item-price">£{item.price_excl_tax}</div>
            <a
              href="#"
              onClick={() =>
                !isLoading &&
                removeFromBasket(basketId, item.id, setIsLoading, setRefresh, item)
              }
              className="p10-remove-item"
            >
              {isLoading ? "Removing" : "Remove"}
            </a>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
