import React, { useEffect, useState } from "react";
import {Redirect, useHistory} from "react-router-dom";
import { get, post } from "../../api/api-caller";
import { END_POINTS } from "../../api";
import { CheckoutForm } from "./form";
import { ROUTES } from "../../utils/values";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { checkoutEvents } from "../../utils";

export const Checkout = ({
  basket,
  setBasket,
  setShipping,
  setRefresh,
  account,
  setAccountToken,
  toggleCart,
  setCountry,
  setTaxMethod,
  userCountry,
}) => {
  let history = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  const [countries, setCountries] = useState([]);
  const [shippingMethods, setShippingMethods] = useState([]);
  const [addresses, setAddresses] = useState({});

  const confirmAndPay = (data, setSubmitting) => {
    post(END_POINTS.CHECKOUT, data)
      .then((resp) => {
        if(resp){
          setSubmitting(false);
          setShipping({
            basket: { ...basket },
            shipping: { ...resp },
            values: { ...data.shipping_address },
          });
          setRefresh();
        //  window.dataLayer.push({ ...basket, ...data,  event:'checkout'});
          window.dataLayer.push({
            event: 'transaction',
            ecommerce: {
              checkout: {
                purchase: {
                  actionField: {
                    id: resp.number,        // Transaction ID. Required
                    affiliation: 'Online Store',
                    revenue: resp.total_incl_tax,   // Total transaction value (incl. tax and shipping)
                    tax:'0.00',
                    shipping: resp.shipping_incl_tax,
                    coupon: resp.voucher_discounts ?resp.voucher_discounts[0] : 'NONE',
                    shippingCode: resp.shipping_code,
                    currency: resp.currency
                  },
                  products: [/*{          // List of productFieldObjects.
                    name: 'Packaging',  // Name or ID is required.
                    id: '12345',
                    // 'price': '15.25',
                    // 'brand': 'Measureschool',
                    // 'category': 'Shirts',
                    // 'variant': 'Gray',
                    // 'quantity': 1,
                    // 'coupon': ''  // Optional fields may be omitted or set to empty string.
                  },
                    {
                      name: 'Packaging',  // Name or ID is required.
                      id: '123456',
                      // 'price': '33.75',
                      // 'brand': 'Measureschool',
                      // 'category': 'Shirts',
                      // 'variant': 'Black',
                      // 'quantity': 1
                    }*/]
                },
              }
            },
          });
          history.push(ROUTES.ORDER_CONFIRMATION);
        }else{
          setSubmitting(false);
          toast.error("Error encountered while checkout")
        }
      })
      .catch((err) => {
        console.error(err);
        setSubmitting(false);
      });
  };


  const getUserAddresses = () => {
      get(END_POINTS.ADDRESSES)
        .then((resp) => {
          setAddresses(resp?.data);
        }).catch()
  };

  const getShippingMethods = () => {
    get(END_POINTS.SHIPPING_METHODS)
      .then((methods) => {
        setShippingMethods(methods);
      })
      .catch((err) => console.error(err));
  };
  
  const getCountries = () => {
    get(END_POINTS.COUNTRIES)
      .then((results) => {
        setCountries(results);
      })
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    getCountries();
    getShippingMethods();
    getUserAddresses();
  }, []);
  
  useEffect(() => {
    const user_country = countries.find(cont => cont.url === addresses?.shipping_address?.country)?.iso_3166_1_a3;
    if (user_country)
    setCountry(user_country);
    
    if(addresses?.shipping_address?.country && addresses?.shipping_address?.country !== 'https://justbulk.co.uk/api/countries/GB/')
      setTaxMethod("all_inclusive")
    
    setRefresh();
  }, [countries, addresses]);
  
  return (
    <div className="p5-checkout-wrapper">
      <div className="p5-form-block w-form">
        {
          (shippingMethods.length && countries.length) ? (
            <CheckoutForm
              stripe={stripe}
              elements={elements}
              setRefresh={setRefresh}
              shippingMethods={shippingMethods}
              countries={countries}
              basket={basket}
              onSubmit={({ data, setSubmitting }) => {
                confirmAndPay(data, setSubmitting);
              }}
              addresses={addresses}
              account={account}
              setAccountToken={setAccountToken}
              getUserAddresses={getUserAddresses}
              getShippingMethods={getShippingMethods}
              getCountries={getCountries}
              toggleCart={toggleCart}
              setCountry={setCountry}
              setTaxMethod={setTaxMethod}
              userCountry={userCountry}
            />
          ) : null
        }

      </div>
    </div>
  );
};
