import { object, string } from 'yup';
import { withFormik } from 'formik';

/*
* formSchema serves both as the initial state to form as well
* as set of validation rules to apply on form fields
* */
const formSchema = object().shape({
  username: string()
    .email('Must be a valid email')
    .required('Required field'),
  password: string()
    .required('Required field'),
});

/*
* HOC to enhance our form with <Formik />
* Helps us pull out logic from the generator-form.jsx component
* */
export const formikEnhancer = withFormik({
  enableReinitialize: true,
  validationSchema: formSchema,
  // eslint-disable-next-line no-unused-vars
  mapPropsToValues: ({ isLogging, attemptLogin, ...rest }) => ({ ...rest }),
  handleSubmit: (values, { setSubmitting, props: { attemptLogin } }) => {
    attemptLogin({
      data: values,
      setSubmitting,
    });
  },
  displayName: 'LoginForm',
});
