import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {formikEnhancer} from "./form-utils";
import {FileUpload} from "../../fileupload";

const Form = ({
 handleChange,
 handleBlur,
 handleSubmit,
 isValid,
 isSubmitting,
 errors,
 setFieldValue,
 values,
 }) => (
  <Fragment>

    <form id="email-form" name="email-form" data-name="Email Form" className="p25-form" onSubmit={handleSubmit}>

      <label htmlFor="name" className="p25-artwork-form-field-title">
        Send us any artwork for your custom print orders here! Please Upload one at a time and clearly mark what
        each
        upload is related to e.g. a product or order, please also add any additional notes describing the
        content.
        (We only accept Ai, PSD, PDF, JPEG and PNG formats. It will not allow you to attach Zipped Folders)

      </label>
      <input
        type="text"
        className="p25-product-code-field w-input"
        maxLength="256"
        name="reference"
        placeholder="Product Code or Order Reference (if you have one)"
        id="name"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.name}
      />
      <textarea
        placeholder="Any Notes you want to add.."
        maxLength="5000"
        id="node"
        name="message"
        className="p25-artwork-notes w-input"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.message}
      />
      <FileUpload setFieldValue={setFieldValue} values={values} component='artwork'/>
      <input
        type="submit"
        value={isSubmitting ? "Please wait..." : "Submit"}
        className="p25-upload-artwork-files w-button"
        // disabled={!isValid || isSubmitting}
      />
    </form>

  </Fragment>


);

Form.propTypes = {
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setSubmitting: PropTypes.func,
  values: PropTypes.object.isRequired,
};

export const ArtUploadForm = formikEnhancer(Form);

ArtUploadForm.propTypes = {
  attemptSubmit: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};
