import React from "react";
import {formikEnhancer} from "./form-utils";

const Form = ({
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  isSuccess,
  setFieldValue,
 }) => (
    <>
      <form
          id="email-form"
          name="email-form"
          data-name="Email Form"
          onSubmit={handleSubmit}
      >

        <input
            type="text"
            className="p26-modal-product-name w-input"
            maxLength="256"
            name="product"
            data-name="Product"
            placeholder="Product"
            id="Product"
            required=""
            onChange={handleChange}
        />
        {errors.product && (
            <label
                className="w-commerce-commercecheckoutlabel p5-field-label"
                style={{color: "red"}}
            >
              {errors.product}
            </label>
        )}
        <input
            type="text"
            className="p26-quantity-field w-input"
            maxLength="256"
            name="quantity"
            data-name="Quantity 3"
            placeholder="Quantity"
            id="Quantity-3"
            required=""
            onChange={handleChange}
        />
        {errors.quantity && (
            <label
                className="w-commerce-commercecheckoutlabel p5-field-label"
                style={{color: "red"}}
            >
              {errors.quantity}
            </label>
        )}
        <input
            type="text"
            className="p26-estimated-date w-input"
            maxLength="256"
            name="delivery_time"
            data-name="Quantity 2"
            placeholder="When do you need it by?"
            id="Quantity-2"
            required=""
            onChange={handleChange}
        />
        {errors.delivery_time && (
            <label
                className="w-commerce-commercecheckoutlabel p5-field-label"
                style={{color: "red"}}
            >
              {errors.delivery_time}
            </label>
        )}
        <input
            type="text"
            className="p26-contact-time w-input"
            maxLength="256"
            name="contact_time"
            data-name="Time to Contact"
            placeholder="Best time to contact you?"
            id="Time-to-Contact"
            required=""
            onChange={handleChange}
        />
        {errors.contact_time && (
            <label
                className="w-commerce-commercecheckoutlabel p5-field-label"
                style={{color: "red"}}
            >
              {errors.contact_time}
            </label>
        )}
        <textarea
            placeholder="Tell us about what you&#x27;re looking for!"
            maxLength="5000"
            id="Requirements"
            name="requirements"
            required=""
            data-name="Requirements"
            className="p26-modal-notes w-input"
            onChange={handleChange}
        />
        {errors.requirements && (
            <label
                className="w-commerce-commercecheckoutlabel p5-field-label"
                style={{color: "red"}}
            >
              {errors.requirements}
            </label>
        )}
        <input
            type="submit"
            value={isSubmitting ? "Please wait..." : "Submit"}
            className="p1---modal-submit-button w-button"
            disabled={isSubmitting}
        />
      </form>
      {isSuccess && (
          <div className="succes-state w-form-done">
            <div>Thank you! Your submission has been received!</div>
          </div>
      )}
    </>
);

export const CustomQuoteLoggedInForm = formikEnhancer(Form);
