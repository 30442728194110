import React from "react";
import PropTypes from "prop-types";

export const CookieModal = ({
  onAccept,
}) => {
  return (
      <div className="p28-cookie-block">
        <div className="p28-cookie-modal-title">
          Before you Continue
        </div>
        <div className="p28-cookie-description">
          To ensure we deliver a better experience online we use cookies. We will
          assume you are happy with this if you continue to browse, otherwise you may decline our cookies via your
          browser settings. Read more about our use of cookies and terms of use
          <a href="https://www.justbulk.co.uk/terms" target="_blank">here</a>.
        </div>
        <a href="#" className="p28-cookie-accept-button w-button" onClick={() => onAccept()}>Accept Cookies</a>
      </div>
  )
}
CookieModal.propTypes = {
  onAccept: PropTypes.func.isRequired,
}
export default CookieModal;