import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { get } from "../../../../api/api-caller";
import { END_POINTS } from "../../../../api";
import ReactToPrint from "react-to-print";
import Invoice from "../../../ui/invoice";

const InvoiceTemplate = () => {
  let { invoiceId } = useParams();
  const invoiceRef = useRef();
  const [content, setContent] = useState("<div></div>");
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    get(`${END_POINTS.INVOICE}${invoiceId}/`)
      .then((resp) => {
        setContent(resp);
        setIsLoading(false);
      })
      .catch(() => {
        setIsError(true);
        setIsLoading(false);
      });
  });

  return (
    <div className="p13-wrapper" style={{ width: "-webkit-fill-available" }}>
      {isLoading ? (
        "Loading..."
      ) : (
        <>
          <div
            style={{
              paddingTop: "10px",
              marginLeft: "20px",
            }}
          >
            <ReactToPrint
              trigger={() => <button>Print</button>}
              content={() => invoiceRef.current}
            />
          </div>

          <Invoice ref={invoiceRef} content={content} />
        </>
      )}
      {!isLoading && isError && "Invoice not available"}
    </div>
  );
};

InvoiceTemplate.propTypes = {
  content: PropTypes.any,
};
export default InvoiceTemplate;
