import { connect } from 'react-redux';
import { Breadcrumbs } from './breadcrumbs';

const mapStateToProps = state => ({
  breadcrumbs: state.ui.breadcrumbs,
});

const mapDispatchToProps = {
};

export const BreadCrumbsContainer = connect(mapStateToProps, mapDispatchToProps)(Breadcrumbs);
