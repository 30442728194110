import React from "react";
import {DetailsForm} from "./form/form";
import {post} from "../../../api/api-caller";
import {END_POINTS} from "../../../api";
import {toast} from "react-toastify";

export const YourDetails = () => {
  
  const setUserDetails = (values) => {
    post(`${END_POINTS.USER_DETAILS}`, values)
      .then((resp) => {
        toast.success('Thank you! Your details have been updated!')
      })
      .catch(() => {
      })
  }
  
  return (
    <>
      <div className="p21-title-block">
        <h4 className="p21-right-wrapper-title">Your Details </h4>
      </div>
      <div className="p21-field-wrapper">
        <DetailsForm handleSubmit={setUserDetails}/>
      </div>
    </>
  )
}


