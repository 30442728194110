import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { uploadFile } from "../../api/api-caller";
import { END_POINTS } from "../../api";
import { toast } from "react-toastify";
import Progress from 'react-progressbar';


export const FileUpload = ({
  values,
  setFieldValue,
  allowMultiple,
  component,
}) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [fileSize, setFileSize] = useState(0);

  const uploadToBackend = (e) => {
    if(e.currentTarget.files.length){
      setIsUploading(true);
      setFieldValue(
          "files", Array.from(e.currentTarget.files)
      );
      setFileSize(e.currentTarget.files[0].size);

      uploadFile(`${END_POINTS.ACCOUNT}/upload/`, e.currentTarget, uploading)
          .then((resp) => {
            setUploadProgress(100);
            let ids = [];
            ids.push(resp.id);
            setFieldValue("attachments", ids);
            setIsUploading(false);
          })
          .catch((e) => {
            setIsUploading(false);
            if (values.files) removeFile(values.files.length - 1, true);
            else setFieldValue("files", null);
          });
    }
  };

  const uploading = (e) => {
    const {loaded, total} = e;
    let percent = Math.floor( (loaded * 100) / total )
    if( percent < 100 ){
      setUploadProgress(percent)
      setDataLoaded(loaded)
    }
  }

  const removeFile = (index, isError) => {
    if (values.files.length < 2) {
      setFieldValue("files", null);
      setFieldValue("attachments", []);
    } else {
      let fileArray = Array.from(values.files);
      let temp = fileArray.splice(index, 1);
      setFieldValue("files", temp);
      setFieldValue("attachments", values.attachments.splice(index, 1));
    }
    if(!isError)
      toast.success('File removed')
    else
      toast.error('File upload failed. Make sure the file format is supported and try again')
  };

  return (
    <Fragment>
      {component === "artwork" && (
        <Fragment>
          <div className="p25-attachment-wrapper">
            <input
              type="file"
              id="file-upload"
              className="custom-file-input"
              onChange={(e) => uploadToBackend(e)}
           //   multiple={allowMultiple}
            />
          </div>
          {values.files &&
          values.files.map((item, index) => (
              <div className="p24-attached-wrapper">
                <a href="#nowhere" className="re-p25-attached-file-link">
                  {item.name}
                </a>
                <input
                  type="button"
                  value="X"
                  className="p25-delete-selected-file w-button"
                  onClick={(e) => {
                    removeFile(index);
                  }}
                />
              </div>
            ))}
        </Fragment>
      )}
      {component === "customer-support" && (
        <Fragment>
          <div className="p24-attachment-wrapper">
            <input
              type="file"
              id="file-upload"
              className="custom-file-input"
              onChange={(e) => uploadToBackend(e)}
              // multiple={allowMultiple}
            />
          </div>
          {values.files &&
          values.files.map((item, index) => (
              <div className="p24-attached-wrapper">
                <a href="#nowhere" className="re-p24-attached-file-link">
                  {item.name}
                </a>
                <input
                  type="button"
                  value="X"
                  className="p24-delete-attachment-button w-button"
                  onClick={(e) => {
                    removeFile(index);
                  }}
                />
              </div>
            ))}
        </Fragment>
      )}
      
      {isUploading && (
        <div className="p24-upload-modal-wrapper">
          <div className="p24-modal-block">
            <div className="p24-modal-title">Uploading...</div>
            <div className="p24-progress-bar-wrapper">
              <div className="p24-progress-bar-tracker">
                <Progress completed={uploadProgress} color={'#000000'} height="20px"/>
              </div>
            </div>
            <div className="p24-progress-details-block">
              <div className="p24-progress-percentage">{uploadProgress}%</div>
              <div className="p24-progress-size-uploaded">
                {dataLoaded / 1000}
              </div>
              <div className="p24-total-progress-size">
                {" "}
                / {Math.ceil(fileSize / 1000)} Kb
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};
FileUpload.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  allowMultiple: PropTypes.bool,
  component: PropTypes.string.isRequired,
};
