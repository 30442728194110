import React, {useEffect, useState} from "react";
import {get, post} from "../../../api/api-caller";
import {END_POINTS} from "../../../api";
import {BillingAddress, ShippingAddress} from "./forms";
import {toast} from "react-toastify";

export const AddressBook = () => {

  const [shippingAddress , setShippingAddress] = useState({})
  const [billingAddress , setBillingAddress] = useState({})
  const [countries , setCountries] = useState([])

  const setNewAddress = (values) => {
    post(`${END_POINTS.ADDRESSES}`, values)
        .then((resp) => {
          toast.success(`Your New ${values.is_default_for_billing ? 'billing' : 'shipping'} Address Has Been Saved!`)
        })
        .catch()
  }
  const getAddress = () => {
    get(`${END_POINTS.ADDRESSES}`)
        .then((resp ) => {
          resp.data.billing_address.is_default_for_billing = true;
          resp.data.billing_address.is_default_for_shipping = false
          resp.data.shipping_address.is_default_for_shipping = true;
          resp.data.shipping_address.is_default_for_billing = false;
          setBillingAddress(resp.data.billing_address);
          setShippingAddress(resp.data.shipping_address);
        })
        .catch((err) => console.error(err));
  }

  const getCountries =() =>{
    get(`${END_POINTS.COUNTRIES}`)
        .then((resp ) => {
          setCountries(resp);
        })
        .catch((err) => console.error(err));
  }
  useEffect(() => {
    getAddress();
    getCountries();
  }, [])

  return(
  <>
    <div className="p21-title-block">
      <h4 className="p21-right-wrapper-title">Address Book</h4>
    </div>
    <div className="p23-address-grid">
      {billingAddress ? (
        <>
          <BillingAddress handleSubmit={setNewAddress} address={billingAddress} countries={countries}/>
          <ShippingAddress handleSubmit={setNewAddress} address={shippingAddress} countries={countries}/>
        </>
      ): <>Loading...</>}

    </div>
  </>
)
}
