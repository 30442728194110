import React from "react";
import { formikEnhancer } from "./form-utils";

const Form = ({
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  isSuccess,
  values,
}) => (
  <div id="w-node-54eee580db91-225965cf" className="p3---form-block-2 w-form">
    <form
      id="email-form"
      name="email-form"
      data-name="Email Form"
      onSubmit={handleSubmit}
    >
      <div className="p3---text-field-wrapper">
        <div className="text-field-wrap">
          <label htmlFor="First-Name" className="field-label">
            First Name
          </label>
          <input
            type="text"
            className="text-field w-input"
            maxLength="256"
            name="first_name"
            data-name="First Name"
            placeholder="Enter Your First Name"
            id="First-Name"
            value={values.first_name}
            onChange={handleChange}
          />
          {errors.first_name && (
            <label
              className="w-commerce-commercecheckoutlabel p5-field-label"
              style={{ color: "red" }}
            >
              {errors.first_name}
            </label>
          )}
        </div>
        <div className="text-field-spacer" />
        <div className="text-field-wrap">
          <label htmlFor="Last-Name" className="field-label">
            Last Name
          </label>
          <input
            type="text"
            className="text-field w-input"
            maxLength="256"
            name="last_name"
            data-name="Last Name"
            placeholder="Enter Your Last Name"
            id="Last-Name"
            value={values.last_name}
            onChange={handleChange}
          />
          {errors.last_name && (
            <label
              className="w-commerce-commercecheckoutlabel p5-field-label"
              style={{ color: "red" }}
            >
              {errors.last_name}
            </label>
          )}
        </div>
      </div>
      <div className="p3---text-field-wrapper">
        <div className="text-field-wrap">
          <label htmlFor="Email-4" className="field-label">
            Email
          </label>
          <input
            type="email"
            className="text-field w-input"
            maxLength="256"
            name="email"
            data-name="Email"
            placeholder="Enter Your Email Address "
            id="Email-4"
            value={values.email}
            onChange={handleChange}
          />
          {errors.email && (
            <label
              className="w-commerce-commercecheckoutlabel p5-field-label"
              style={{ color: "red" }}
            >
              {errors.email}
            </label>
          )}
        </div>
        <div className="text-field-spacer" />
        <div className="text-field-wrap">
          <label htmlFor="Phone" className="field-label">
            Contact No
          </label>
          <input
            type="tel"
            className="text-field w-input"
            maxLength="256"
            name="contact_no"
            data-name="Phone"
            placeholder="Enter Your Contact No"
            id="Phone"
            value={values.contact_no}
            onChange={handleChange}
          />
          {errors.contact_no && (
            <label
              className="w-commerce-commercecheckoutlabel p5-field-label"
              style={{ color: "red" }}
            >
              {errors.contact_no}
            </label>
          )}
        </div>
      </div>
      <div className="text-field-spacer" />
      <label className="field-label">Message</label>
      <textarea
        placeholder="How Can We Help You?"
        maxLength="5000"
        id="Message"
        name="message"
        value={values.message}
        className="p3---form-text-field-copy min-height w-input"
        onChange={handleChange}
      />
      {errors.message && (
        <label
          className="w-commerce-commercecheckoutlabel p5-field-label"
          style={{ color: "red" }}
        >
          {errors.message}
        </label>
      )}
      <input
        type="submit"
        disabled={isSubmitting}
        value={isSubmitting ? "Please Wait..." : "Submit"}
        className="p3---submit-button w-button"
      />
      {isSuccess && (
        <div className="succes-state w-form-done">
          <div>Thank you! Your submission has been received!</div>
        </div>
      )}
    </form>
  </div>
);

export const ContactForm = formikEnhancer(Form);
