export const BASE = process.env.REACT_APP_SERVER_URL
const BASE_URL = `${BASE}/api`;

export const END_POINTS = {
  BLOG_CATEGORIES: `${BASE_URL}/blog/categories`,
  BLOG: `${BASE_URL}/blog`,
  ROOT_CATEGORIES: `${BASE_URL}/categories/`,
  PRODUCTS: `${BASE_URL}/category/products`,
  PRODUCT_DETAIL: `${BASE_URL}/products`,
  FAQS: `${BASE_URL}/faq`,
  LOGIN: `${BASE_URL}/login/`,
  SIGN_UP: `${BASE_URL}/signup/`,
  BASKET: `${BASE_URL}/basket/`,
  BASKETS: `${BASE_URL}/baskets/`,
  LOGOUT: `${BASE_URL}/logout/`,
  SEARCH: `${BASE_URL}/search`,
  HOME: `${BASE_URL}/home`,
  ADD_TO_BASKET: `${BASE_URL}/basket/add-product/`,
  CHECKOUT: `${BASE_URL}/checkout/`,
  COUNTRIES: `${BASE_URL}/countries/`,
  SHIPPING_METHODS: `${BASE_URL}/basket/shipping-methods/`,
  PAYMENT_INTENT: `${BASE_URL}/create-payment-intent/`,
  VAT: `${BASE_URL}/user/vat/`,
  CONTACT_US: `${BASE_URL}/contact-us/`,
  PRODUCT_QUOTE: `${BASE_URL}/product-quote/`,
  NEWS_LETTER: `${BASE_URL}/newsletter/`,
  ORDERS: `${BASE_URL}/orders`,
  ACCOUNT:`${BASE_URL}/accounts`,
  SAVE_PRODUCT:`${BASE_URL}/product/favourites/`,
  GET_SAVED_PRODUCTS:`${BASE_URL}/accounts/saved_products`,
  ADD_VOUCHER:`${BASE_URL}/basket/add-voucher/`,
  VERIFY_OTP:`${BASE_URL}/email/verify/`,
  RESET_PASSWORD:`${BASE_URL}/password/reset/`,
  RESET_CONFIRM:`${BASE_URL}/password/reset/confirm/`,
  CHANGE_PASSWORD:`${BASE_URL}/password/change/`,
  PAGES: `${BASE_URL}/page`,
  HOME_TASK: `${BASE_URL}/home/post-task`,
  INVOICE:`${BASE_URL}/customer/invoice/`,
  ADDRESSES:`${BASE_URL}/accounts/addresses/`,
  USER_DETAILS:`${BASE_URL}/accounts/details/`,
  DOWNLOAD_FILE:`${BASE_URL}/accounts/download-file/`,
  SELLER_SIGNUP: `${BASE_URL}/partner/signup/`,
  GET_COMPANY_DATA: `${BASE_URL}/partner/signup/find_company/`,
  PARTNER_PAGE: `${BASE_URL}/partner/products/`,
};
