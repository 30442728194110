import axios from "axios";
import { store } from "../store";
import { resetAuth } from "../actions/auth/actions";
import FormData from "form-data";
import { toast } from "react-toastify";
import { getErrorsString, handleErrorResponse } from "../utils";
import Cookies from "js-cookie"
import fileDownload from 'js-file-download';


export const post = (endPoint, data, method = "POST") => {
  const user = store.getState().auth.account;
  const headers = {
    "Content-Type": "application/json",
    "Cookie": {
      "csrftoken": Cookies.get('csrftoken') ? Cookies.get('csrftoken') :"",
      "sessionid": Cookies.get('sessionid') ? Cookies.get('sessionid') :""
    }
  };
  if (user.token) {
    headers["Authorization"] = `Token ${user.token}`;
  }
  return axios({
    url: endPoint,
    method: method,
    headers,
    validateStatus: (status) => status >= 200 && status < 300,
    data: data && JSON.stringify(data),
  })
    .then(({ data: resp }) => resp)
    .catch((error) => handleErrorResponse(error));
};

export const get = (endPoint, setLoader, isIncludeCredentials) => {
  const user = store.getState().auth.account;
  setLoader && setLoader(true);
  const headers = {
    "Content-Type": "application/json",
    "Cookie": {
      "csrftoken": Cookies.get('csrftoken') ? Cookies.get('csrftoken') :"",
      "sessionid": Cookies.get('sessionid') ? Cookies.get('sessionid') :""
    }
  };
  if (user.token && !isIncludeCredentials) {
    headers["Authorization"] = `Token ${user.token}`;
  }

  return axios({
    url: endPoint,
    method: "GET",
    headers,
    //params: { page: '1', page_size: 20 },
    validateStatus: (status) => status >= 200 && status < 300,
    withCredentials: isIncludeCredentials,
  })
    .then(({ data: resp }) => resp)
    .catch((error) => {
      if (error?.response?.status === 401) {
        store.dispatch(resetAuth());
      } else if (error?.response?.status === 500) {
        toast.error("An unexpected error occurred at our end. While we’re fixing it, you can reach out to support for more details.");
        throw error;
      } else {
        const { response: { data = {} } = {} } = error;
        const errors = getErrorsString(data);
       // toast.error(errors ? error : 'An unexpected error occurred' );
        throw error;
      }
    })
    .finally(() => setLoader && setLoader(false));
};

/**
 * WIP: axios generic wrapper
 */
export const call = (
  endPoint,
  options = {},
  setLoader,
  isIncludeCredentials
) => {
  const { method, headers } = options;
  return axios({
    url: endPoint,
    method: method || "GET",
    headers,
    validateStatus: (status) => status >= 200 && status < 300,
    withCredentials: isIncludeCredentials,
  })
    .then(({ data: resp }) => resp)
    .catch((error) => handleErrorResponse(error))
    .finally(() => setLoader && setLoader(false));
};

export const uploadFile = (endPoint, data, uploading) => {
  const user = store.getState().auth.account;
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  if (user.token) {
    headers["Authorization"] = `Token ${user.token}`;
  }
  let image_data = null;
  if(data.files){
    image_data = new FormData()
    image_data.append("file", data.files[0]);
  }
  else
    image_data= data

  return axios({
    url: endPoint,
    method: "POST",
    headers,
    data: image_data,
    onUploadProgress: (progressEvent) => uploading(progressEvent)
  })
    .then(({ data: resp }) => resp)
    .catch((error) => {
      if (error?.response?.status === 401) {
        store.dispatch(resetAuth());
      } else {
        throw error;
      }
    });
};


export const download = (endPoint, file_name, isIncludeCredentials) => {
  const user = store.getState().auth.account;
  const headers = {
    "Content-Type": "application/json",
    "Cookie": {
      "csrftoken": Cookies.get('csrftoken') ? Cookies.get('csrftoken') :"",
      "sessionid": Cookies.get('sessionid') ? Cookies.get('sessionid') :""
    }
  };
  if (user.token && !isIncludeCredentials) {
    headers["Authorization"] = `Token ${user.token}`;
  }
  return axios({
    url: endPoint,
    method: "GET",
    headers,
    validateStatus: (status) => status >= 200 && status < 300,
    withCredentials: isIncludeCredentials,
    responseType: 'blob',
  })
    .then(({ data: resp }) => {
      fileDownload(resp, file_name)
    })
    .catch((e) => {
      if (e?.response?.status === 401) {
        store.dispatch(resetAuth());
      } else if (e.response?.status === 500) {
        toast.error("An unexpected error occurred at our end. While we’re fixing it, you can reach out to support for more details.");
        throw e;
      } else {
        const { response: { data = {} } = {} } = e;
        const errors = getErrorsString(data);
        toast.error(errors);
        throw e;
      }
    })
};
