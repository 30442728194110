import React from "react";
import {AccountRouter} from "./account-router";
import {ROUTES} from "../../utils/values";
import {NavLink} from "react-router-dom";


const accountLink = [
  {title: 'Your Details', link: ROUTES.ACCOUNT_DETAILS },
  {title: ' Order History', link: ROUTES.ACCOUNT_ORDER_HISTORY },
  {title: 'Address Book', link: ROUTES.ACCOUNT_ADDRESS_BOOK },
  {title: 'Saved Products', link: ROUTES.SAVED_PRODUCTS },
 /* {title: ' Stock Alerts', link: ''},
  {title: ' Stock Forecasts', link: ''},*/
  {title: ' Your Artwork', link: ROUTES.ARTWORK_UPLOAD },
  {title: ' Customer Service', link: ROUTES.CUSTOMER_SERVICE },
]

const vendorLinks = [
  {title: 'Listing', link: ROUTES.LISTINGS },
  {title: 'Orders', link: ROUTES.HISTORY }
]
export const Account = () => {
  return (
    <div className="p21-main-body">
      <div className="p21-category-wrapper">
        <div className="p21-left-wrapper">
          {/*SIDE NAV WEB*/}
          <aside className="p21---side-bar seperator">
            <div className="p21---sidebar-block">
              <h4 className="p21-cat-title">Your Account</h4>
              {accountLink.map((item) => (
                <h4 className="p21-menu-item">
                  <NavLink to={item.link} className="p21-link" activeClassName="p21-link-selected">
                    {item.title}
                  </NavLink>
                </h4>
              ))}
              <h4 className="p21-cat-title">Seller Tools</h4>
              {vendorLinks.map((item) => (
                <h4 className="p21-menu-item">
                  <NavLink to={item.link} className="p21-link" activeClassName="p21-link-selected">
                    {item.title}
                  </NavLink>
                </h4>
              ))}
              <div className="p21-left-menu"></div>
            </div>
          </aside>
          {/*SIDE NAV WEB END*/}
        </div>
        <div className="p23-right-wrapper">
          {/*SIDE NAV MOBILE*/}
          <div
            data-hover=""
            data-delay="0"
            className="p22-account-nav-mobile w-dropdown"
          >
            <div className="p22-dropdown-toggle w-dropdown-toggle">
              <div className="w-icon-dropdown-toggle"></div>
              <div className="p22-account-nav-text-block">Account Navigation</div>
            </div>
            <nav className="w-dropdown-list">
              {accountLink.map((item) => (
                <NavLink to={item.link} className="w-dropdown-link">
                  {item.title}
                </NavLink>
              ))}
            </nav>
          </div>
          {/*SIDE NAV MOBILE END*/}
          <div className="p25-inner-content-block">
            <AccountRouter/>
          </div>
        </div>
      </div>
    </div>
  )
};
