import React, {useEffect, useRef, useState} from "react";
import {Step1} from "./forms/step1";
import {Step2} from "./forms/step2";
import {Step3} from "./forms/step3";
import {Step4} from "./forms/step4";
import {Step5} from "./forms/step5";
import {Step6} from "./forms/step6";
import {get} from "../../api/api-caller";
import {END_POINTS} from "../../api";

export const SellerSignup = () => {
  
  const [activeStep, setActiveStep] = useState(1);
  const [sellerId, setSellerID] = useState(null);
  
  const messagesEndRef = useRef(null)
  
  const [step2Data, setStep2Data] = useState({});
  
  const getSellerData = (companyId) =>{
    get(`${END_POINTS.GET_COMPANY_DATA}${companyId}`)
      .then((resp) => {
        setStep2Data(resp)
      })
      .catch((err) => {});
  }
  const scrollToBottom = () => {
   return  messagesEndRef.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest"})
  }
  
  useEffect(() => {
    scrollToBottom()
  }, [activeStep]);
  
  return (
    <>
      <div className="p33-main-body">
        <div className="p33-wrapper">
          <div className="p34-header-wrapper">
            <div className="p33-title-wrapper">
              <div className="p28-title-hash">#B2B-MarketPlace</div>
              <h1 className="p28-h1"><strong>1 Step Away from Growth.<br/></strong></h1>
              <h3 className="p28-h3">Need help? Call us on 0208 016 2108 or Email us at accounts@justbulk.co.uk</h3>
            </div>
          </div>
          <div className="p34-c1">
            
            <div className="p34-form w-form">
              <div id="seller-signup" name="seller-signup" data-name="seller-signup" className="form">
                {activeStep >= 1 &&
                  <div ref={messagesEndRef}>
                    <Step1
                      setActiveStep={setActiveStep}
                      setSellerId={setSellerID}
                      getSellerData={getSellerData}
                    />
                  </div>
                }
                {activeStep > 1 &&
                  <div ref={messagesEndRef}>
                    <Step2
                      setActiveStep={setActiveStep}
                      sellerId={sellerId}
                      step2Data={step2Data}
                    />
                  </div>
                }
                {activeStep > 2 &&
                  <div ref={messagesEndRef}>
                    <Step3
                      setActiveStep={setActiveStep}
                      sellerId={sellerId}
                    />
                  </div>
                }
                {activeStep > 3 &&
                  <div ref={messagesEndRef}>
                    <Step4
                      setActiveStep={setActiveStep}
                      sellerId={sellerId}
                    />
                  </div>
                }
              {/*  {activeStep > 4 &&
                  <div ref={messagesEndRef}>
                    <Step5
                      setActiveStep={setActiveStep}
                      sellerId={sellerId}
                    />
                  </div}>*/
                }
                {activeStep > 4 &&
                  <div ref={messagesEndRef}>
                    <Step6
                      sellerId={sellerId}
                    />
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
  
};
