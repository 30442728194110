import React from "react";
import { Link, useLocation } from "react-router-dom";
import { capitalizeFirstLetter } from "../../utils";
import { ROUTES, CHECKOUT_FLOW_ROUTES } from "../../utils/values";

export const Breadcrumbs = () => {
  const location = useLocation();
  window.scrollTo(0, 0);
  const getCrumbs = () => {
    const paths = location.pathname.split("/");
    const links = [];
    let sum = ROUTES.ROOT;
    for (let i = 0; i < paths.length; i++) {
      if (paths[i]) {
        sum += `${paths[i]}/`;
        links.push({
          to: sum,
          label: capitalizeFirstLetter(paths[i]).split("-").join(" "),
        });
      }
    }
    return links;
  };

  return (
    <footer className="bread-crumb-wrapper">
      {CHECKOUT_FLOW_ROUTES.findIndex(
        (route) => route === location.pathname
      ) === -1 ? (
        <div className="bread-crumb-container">
          <div className="bread-crumb-text-wrapper">
            <div className="bread-crumb-text-block" style={{ display: "flex" }}>
              <Link className="p13-header-menu-category-title" to={ROUTES.ROOT}>
                Home
              </Link>
              <span>/</span>
              {location.pathname.length > 1 &&
                getCrumbs().map(({ to, label }, index) => (
                  <div
                    key={`breadcrumbs-${index}`}
                    style={{ display: "inline-flex" }}
                  >
                    <Link className="p13-header-menu-category-title" to={to}>
                      {label}
                    </Link>
                    <span style={{ paddingTop: "5px" }}>/</span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </footer>
  );
};
