export const ROUTES = {
  ROOT: '/',
  CONTACT_US: '/contact-us',
  PRODUCT: '/product',
  CATEGORIES: '/categories',
  BLOG_FEED: '/blog',
  BLOG_POST: '/blog',
  FAQS: '/faqs',
  LOGIN: '/login',
  CHECKOUT: '/checkout',
  LANDING: '/landing',
  ORDER_CONFIRMATION: '/order-confirmation',
  SEARCH: '/search',
  ACCOUNT: '/account',
  ACCOUNT_ADDRESS_BOOK: '/account/address-book',
  ACCOUNT_DETAILS: '/account/your-details',
  ACCOUNT_ORDER_HISTORY: '/account/order-history',
  SAVED_PRODUCTS:'/account/saved-products',
  CUSTOMER_SERVICE: '/account/customer-support',
  ARTWORK_UPLOAD:'/account/artwork',
  CHECKOUT_SUMMARY:'/checkout-summary',
  FORGOT_PASSWORD:'/reset-password',
  PAGES: '/static',
  NOT_FOUND: '/404',
  CHANGE_PASSWORD:'/password',
  CUSTOM_POUCHES:'/custom-printed-pouches',
  POUCH_PACKAGING:'/pouch-packaging',
  STAND_UP_POUCHES:'/stand-up-pouches',
  PRINTED_POUCHES: '/printed-pouches',
  CUSTOM_MAILER_BAGS: '/custom-printed-mailer-bags',
  MAILER_BAGS: '/printed/mailer-bags',
  POLY_BAGS: '/printed/poly-bags',
  SHIPPING_BAGS: '/printed/shipping-bags',
  POUCH_SHOP: '/pouchshop',
  TAKE_AWAY: '/takeaway-packaging',
 /* seller routes*/
  LISTINGS: '/account/:sellerId/listings',
  HISTORY: '/account/:sellerId/history',
  SELLER_SIGNUP: '/seller/signup',
  SELLER_MARKETPLACE: '/seller/marketplace',
  PARTNER_PRODUCTS: '/partner-products',
};

export const CHECKOUT_FLOW_ROUTES = [
  ROUTES.CHECKOUT,
  ROUTES.ORDER_CONFIRMATION,
  ROUTES.CHECKOUT_SUMMARY,
];
