export const customerHelpOption = [
  {label: "Select Issue Type", value: ""},
  {label: "Damaged Delivery", value: "damaged_goods"},
  {label: "Haven't Received My Goods", value: "not_received"},
  {label: "Received the wrong items", value: "wrong_item_received"},
  {label: "Faulty/Defective Goods", value: "faulty_goods"},
  {label: "Product Query", value: "product_query"},
  {label: "Credit Query", value: "credit_query"},
  {label: "Order Tracking/Update", value: "order_tracking"},
  {label: "Reporting: Site Error/Bug", value: "error_report"},
  {label: "Reporting: Phishing/Fraud", value: "fraud_report"},
  {label: "Returns", value: "returns"},
  {label: "Custom Product Issue", value: "custom_product_issue"},
  {label: "Social Collaboration", value: "socia_collaboration"},
];

const mailer_bags_colors_variants = [

];

export const categoriesToSlugMapping = (category) => {
  let values = category.split('>')
  let slug = ''
  for (let j = 0; j < values.length; j++) {
    let splitValue = values[j].split(' ');
    splitValue = splitValue.filter(function (e) {
      return e !== '';
    });
    for (let i = 0; i < splitValue.length; i++) {
      if (splitValue[i] !== '') {
        slug = `${slug}${splitValue[i].toLowerCase()}`
        if (i !== splitValue.length - 1)
          slug = `${slug}-`
      }
    }
    if (j !== values.length - 1)
      slug = `${slug}/`
  }
  return slug;

}