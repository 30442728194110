import { connect } from 'react-redux';
import { Partners } from './partners';
import { actions } from '../../actions/ui';

const mapStateToProps = state => ({
  breadcrumbs: state.ui.breadcrumbs,
});

const mapDispatchToProps = {
  setBreadCrumbs: actions.setBreadCrumbs,
};

export const PartnersContainer = connect(mapStateToProps, mapDispatchToProps)(Partners);
