import React from "react";
import {FieldArray, Formik, Form} from "formik";
import {DirectorFields} from "./DirectorFields";
import {post} from "../../../api/api-caller";
import {END_POINTS} from "../../../api";
import {object, string, array, bool} from "yup";

export const Step3 = ({setActiveStep, progress, sellerId}) => {
  
  const step3Schema = object().shape({
    directors: array()
      .of(
        object().shape({
            first_name: string()
              .required('First name is a required field'),
            last_name: string()
              .required('Last name is a required field'),
            email: string()
              .email('Invalid Email')
              .required('Email id is a required field'),
            contact_no: string()
              .required('Contact number is a required field'),
            line1: string()
              .required('Address line1 is a required field'),
            line2: string()
              .required('Address line2 is a required field'),
            county: string()
              .required('County is a required field'),
            country: string()
              .required('Country is a required field'),
            post_code: string()
              .required('Post code is a required field'),
            postal_town: string()
              .required('Postal town is a required field'),
            is_director: bool()
              .when("filled_on_director_behalf", {
                is: false,
                then: bool().required("only one of the option can be selected")
              }),
          }
        )
      ).min(1, "You need to add at least 1 director")
  });
  
  const director_schema = {
    first_name: "",
    last_name: "",
    email: "",
    contact_no: "",
    line1: "",
    line2: "",
    county: "",
    country: "",
    post_code: "",
    postal_town:"",
    is_director: true,
    filled_on_director_behalf: false,
  };
  
  const deleteDirector = async (remove, index) => {
    await remove(index);
  }
  const addNewDirector = (push) => {
    push(director_schema);
  }
  const handleSubmit = (values) => {
    post(`${END_POINTS.SELLER_SIGNUP}step3`, values)
      .then((resp) => {
        setActiveStep(4);
      })
      .catch()
  }
  const step3 = {
    supplier_lead_id: sellerId,
    directors: [{
      ...director_schema
    }],
  }
  
  return (
    <Formik
      initialValues={step3}
      onSubmit={handleSubmit}
      validationSchema={step3Schema}
    >
      {
        ({
           values,
           errors,
           touched,
           setFieldValue
         },
        ) => {
          return (
            <Form>
              <div className="p34-form-c1">
                <div className="p34-form-title-wrapper">
                  <h2 className="p34-c1-h3">Director Details - Director (1)<strong
                    className="bold-text-12"><br/></strong>
                  </h2>
                  <div className="p34-progress-wrapper">
                    <div className="p34-progress-bar" style={{width: '50%'}}></div>
                  </div>
                  <FieldArray
                    name="directors"
                    render={({push, remove}) => (
                      <>
                        {values.directors.map((director, index) => {
                          const currentField = `directors[${index}]`
                          return (
                            <DirectorFields
                              key={`${director.name}`}
                              index={index}
                              onDelete={() => deleteDirector(remove, index)}
                              currentField={currentField}
                              touched={touched?.directors && touched?.directors[index]}
                              errors={errors?.directors && errors?.directors[index]}
                              setFieldValue={setFieldValue}
                            />)
                        })}
                        <input type="button" value="Add Director" className="p34-director-button-1 w-button"
                               onClick={() => addNewDirector(push)}/>
                      </>
                    )}
                  />
                  <button type="submit" className="p34-next-form w-button" style={{float:"right"}}>Next</button>
                </div>
              </div>
            </Form>
          )
        }}
    </Formik>
  )
}
