import React, {useState, useEffect} from "react";
import {Link, useHistory} from "react-router-dom";
import {ROUTES} from "../../utils/values";
import {SummaryItem} from "./summary-item";
import a2 from "../../assets/images/2.png";
import a22 from "../../assets/images/22.png";
import a1 from "../../assets/images/1.png";
import a10 from "../../assets/images/10.png";
import {DiscountField} from "../ui/discount";
import $ from "jquery";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import {get} from "../../api/api-caller";
import {END_POINTS} from "../../api";
import _ from 'lodash';

export const CheckoutSummary = ({
 basket,
 setRefresh,
 shippingCountry,
 taxHandlingMethod,
 }) => {
  
  const history = useHistory();
  const [referenceNumber, setReferenceNumber] = useState("");
  const [estimatedShipping, setEstimatedShipping] = useState("0.00");
  const [shippingTax, setShippingTax] = useState("0.00");
  const [query, setQuery] = useState("");
  const [hasMinError, setHasMinError] = useState([]);
  const [canRedirect, setCanRedirect] = useState(true);
  const groupedLines = _.groupBy(basket.lines, (line) => line.partner_id)
  
  $(document).keyup(function (e) {
    if ($(".search_input:focus") && (e.key === 'Enter')) {
      if (query) {
        history.push(`${ROUTES.SEARCH}/${query}`)
      }
    }
  });
  
  function addAmount (accumulator, item) {
    return accumulator + Number(item.price_excl_tax);
  }
  
  useEffect(()=> {
    const minErrors =  basket?.lines?.map((line)=>{
      const minValue = basket?.min_order_value?.filter(item => item.partner_id === line.partner_id)
      const totalPricing = groupedLines[line.partner_id].reduce(addAmount, 0);
      return totalPricing < minValue?.[0].min_order_value
    })
    setHasMinError(minErrors)
    const redirectAllowed = !hasMinError?.length || ((hasMinError?.some((error) => error === true)) && basket?.lines?.length);
    setCanRedirect(redirectAllowed);
  },[basket])
  
   useEffect  (()=>{
    get(END_POINTS.SHIPPING_METHODS)
      .then((methods) => {
        setEstimatedShipping(methods.find(method => method.code === 'standard-shipping')?.price?.excl_tax);
        setShippingTax(methods.find(method => method.code === 'standard-shipping')?.price?.tax);
      })
      .catch((err) => console.error(err));
  }, [])
  
  const onCheckout =() =>{
    if(hasMinError.some((error) => error === true)){
      toast.error('The minimum purchase value constraint is not satisfied for the seller(s).')
    }
    else if(!basket?.lines?.length) {
      toast.warning('There are no items in cart')
    }
  }
  return (
    <div className="p27-checkout-wrapper">
      <div className="p27-form-block w-form">
        <form
          id="email-form"
          name="email-form"
          data-name="Email Form"
          className="p27-form"
        >
          <div className="p27-left-checkout-block">
            <div className="p27-title-wrapper">
              <div className="p27-current-step">
                <strong>1. Order Summary</strong>
              </div>
              <div className="p27-next-step">2. Details &amp; Payment</div>
              
              <div className="p27-load-list-block">
                <input
                  type="text"
                  className="p27-load-list-field w-input"
                  maxLength="256"
                  name="field-2"
                  data-name="Field 2"
                  placeholder="Cart Ref"
                  id="field-cart-ref"
                  value={referenceNumber}
                  onChange={(e) => setReferenceNumber(e.target.value)}
                />
                <a
                  href="#"
                  className="p27-load-quote-button w-button"
                  onClick={(e) => {
                  }}
                >
                  Load Cart
                </a>
              </div>
            </div>
            <div className="p27-title-row">
              <div className="p26-watched-item-block">
                <div className="p27-product-info-block">
                  <div className="p27-product-title">Your Items</div>
                </div>
              </div>
              <div className="p27-option-block">
                <div className="p27-selection-title">Selection</div>
              </div>
              <div className="p27-unit-price-block">
                <div className="p26-in-stock-wrapper"/>
                <div className="p27-unit-price-title">Unit Price</div>
              </div>
              <div className="p27-line-total-title">
                <div className="p27-total-price-title">Total Price</div>
              </div>
            </div>
            {basket && basket.lines && basket.lines.length
              ? basket.lines.map((line, index) => (
                <SummaryItem
                  basketId={basket.id}
                  item={line}
                  setRefresh={setRefresh}
                  minError={hasMinError?.[index]}
                  minValue={basket?.min_order_value?.filter(item => item.partner_id === line.partner_id)}
                />
              ))
              : "No Item in basket"}
            <div className="p27-total-row-1"/>
            <input
              onChange={(e) => setQuery(e.target.value)}
              type="text"
              className="p27-add-product w-input"
              maxLength="256"
              data-name=""
              placeholder="Search &amp; Add a Product by Name or Code"
              id="search_input"
              required=""
            />
            <div className="p27-cart-notes">
              We aim to deliver most items within 3-4 Days across Mainland UK,
              unless otherwise stated.
              <div className="p27-cart-snippet">
                <strong>
                  Share your cart with colleagues and friends using your unique
                  Cart Ref!
                </strong>
              </div>
              <br/>
              UK VAT is Zero Rated for all orders delivered outside the UK. For EU customers this means no UK VAT is charged,
              <br/>
              we do however offer an “All Inclusive Service” (DTP) which allows you to pay your Local Country taxes and VAT at checkout.
            </div>
            
            {/* $todo implement search here after finalizing with arsalan
            <div>
              <Search />
            </div>
            */}
          </div>
          <div className="p27-right-checkout-block">
            <h4 className="p27-checkout-title">Checkout Summary</h4>
            <div className="p27-cart-ref">Cart Ref: GB 102 345 678</div>
            <div className="p5-cart-summary-block"/>
            {basket.voucher_discounts && basket.voucher_discounts.length > 0 ? (
              <div className="p5-cart-summary-block">Voucher Applied</div>
            ) : (
              <div className="p5-discount-block">
                <DiscountField className="p27-discount-field" country={shippingCountry} taxHandlingMethod={taxHandlingMethod}/>
              </div>
            )}
            
            <div className="p5-subtotal-block">
              <div className="p5-order-summary-text">Subtotal (ex VAT)</div>
              <div className="p5-subtotal-price">
                £{basket.total_excl_tax_excl_discounts}
              </div>
            </div>
            <div className="p5-discount-total-block">
              <div className="p5-order-summary-text">Discount</div>
              <div className="p5-discount-price">
                £
                {basket.voucher_discounts && basket.voucher_discounts.length
                  ? `${basket.voucher_discounts[0].amount}`
                  : 0.0}
              </div>
            </div>
            <div className="p5-delivery-total-block">
              <div className="p5-order-summary-text">Delivery (Estimated)</div>
              <div className="p5-delivery-price">£{estimatedShipping}</div>
            </div>
            <div className="p5-vat-total-block">
              <div className="p5-order-summary-text">VAT</div>
              <div className="p5-vat-price">£{(parseFloat(basket.total_tax) + parseFloat(shippingTax)).toFixed(2)}</div>
            </div>
            <div className="p5-grand-total-block">
              <div className="p27-grand-total">Total</div>
              <div className="p27-grand-total-price">
                £{(parseFloat(basket.total_incl_tax) +  parseFloat(estimatedShipping) + parseFloat(shippingTax)).toFixed(2) }
              </div>
            </div>
            <Link
              to={canRedirect && ROUTES.CHECKOUT}
              className="p27-checkout-button w-button"
              onClick={() => onCheckout()}
            >
              Proceed to Checkout
            </Link>
            <div className="p27-help-box">
              Need help? Call us on{" "}
              <a href="tel:+442080162108">0208 016 2108</a> or start a live
              chat. We accept the following Payment Methods:
            </div>
            <div className="p27-card-box">
              <img
                src={a1}
                loading="lazy"
                alt=""
                className="p27-payment-icon"
              />
              <img
                src={a2}
                loading="lazy"
                alt=""
                className="p27-payment-icon"
              />
              <img
                src={a10}
                loading="lazy"
                alt=""
                className="p27-payment-icon"
              />
              <img
                src={a22}
                loading="lazy"
                alt=""
                className="p27-payment-icon"
              />
            </div>
          </div>
        </form>
        <div className="w-form-done">
          <div>Thank you! Your submission has been received!</div>
        </div>
        <div className="w-form-fail">
          <div>Oops! Something went wrong while submitting the form.</div>
        </div>
      </div>
    </div>
  );
};
