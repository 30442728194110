import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../utils/values";

const NotFoundComp = () => {
  return (
    <div
      style={{
        textAlign: "center",
      }}
    >
      <h1>
        404 - Not Found
        <br />
        <br />
        <Link to={ROUTES.ROOT} className="header-links">
          {"< Go to home"}
        </Link>
      </h1>
    </div>
  );
};

export const NotFound = NotFoundComp;
