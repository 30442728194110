import React from "react";
import PropTypes from "prop-types";
import {formikEnhancer} from "./form-utils.js";

const Form = ({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isValid,
                  isSubmitting,
                  errors,
                  setFieldValue,
                  values,
                  setSubmitting
              }) => (

    <div className="p22-filter-wrapper">
        <div className="p22-order-filter-block w-form">
            <form
                id="email-form"
                name="email-form"
                className="p22-order-filter-sub-block"
                onSubmit={handleSubmit}
            >
                <input
                    type="text"
                    className="p22-order-no-field w-input"
                    maxLength="256"
                    name="order_number"
                    placeholder="Order #"
                    id="name-2"
                    onChange={handleChange}
                />
                <input
                    type="submit"
                    value={isSubmitting ? 'Please wait...': 'Search' }
                    className="p22-order-search-button w-button"
                />
            </form>
            {/*<div className="w-form-done">*/}
            {/*  <div>You can find the order below!</div>*/}
            {/*</div>*/}
            {/*<div className="w-form-fail">*/}
            {/*  <div>Oops! Something went.</div>*/}
            {/*</div>*/}
        </div>
    </div>
);

Form.propTypes = {
    isValid: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setSubmitting: PropTypes.func,
    values: PropTypes.object.isRequired,
    setIsError: PropTypes.func,
};

export const OrderFilter = formikEnhancer(Form);

OrderFilter.propTypes = {
    attemptSubmit: PropTypes.func.isRequired,
};
