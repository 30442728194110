import { flatten } from "flat";
import { store } from "../store";
import { resetAuth } from "../actions/auth/actions";
import { toast } from "react-toastify";

export const formatDate = (dateTimeStr) => {
  const date = new Date(dateTimeStr);
  return `${date.getMonth()}/${date.getDate()}/${date.getFullYear()}`;
}


export const capitalizeFirstLetter = (string) => {
  if (/^[0-9]$/.test(string.charAt(0))) {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const resetValues = (data) =>
  Object.keys(data).reduce((obj, item) => {
    obj[item] = "";
    return obj;
  }, {});

export const getErrorsString = (data) => {
  const flattendData = flatten(data);
  return Object.keys(flattendData).reduce((result, key) => {
    const keys = key.split(".");
    if (keys.length >= 2) {
      const keyName = keys[keys.length - 2];
      result =
        keyName === "non_field_errors"
          ? `${flattendData[key]}\n`
          : `${keyName}: ${flattendData[key]}\n`;
    } else {
      result =
        key === "non_field_errors"
          ? `${flattendData[key]}\n`
          : `${key}: ${flattendData[key]}\n`;
    }
    return result;
  }, "");
};

export const handleErrorResponse = (error) => {
  const { response: { data = {}, status = 0 } = {} } = error;
  if (status === 401) {
    store.dispatch(resetAuth());
    return;
  }
  if (status === 500) {
    toast.error("An unexpected error occurred at our end. While we’re fixing it, you can reach out to support for more details.");
  } else {
    const errors = getErrorsString(data);
    toast.error(errors);
  }
  throw error;
};

export const getShippingMethod = (shippingMethods, country) => {
  if(country)
  return country === 'https://justbulk.co.uk/api/countries/GB/'
    ? shippingMethods.find(({ code }) => code === 'standard-shipping')
    : shippingMethods.find(({code}) => code === 'international-shipping');
  else
    return shippingMethods.find(({ code }) => code === 'standard-shipping');
};

export const getSelectedVariant = (variants, selectedPricing) =>
  variants ? variants.find(({ id }) => String(id) === String(selectedPricing)) || {} : {};

export const getTruncatedString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return `${str.slice(0, num)}...`;
};

export const reportEvent = (category, action, label, value=1) => {
  window.dataLayer.push({
    'event': category,
    'event_category': category,
    'event_label': label,
    'event_value': value,
    'product_name':label,
    'eventCallback': function() {
    }
  });
  // window.gtag('event', action, {
 //    'event_category': category,
 //    'event_label': label,
 //    'event_value': value,
 //  });
 
}

export const checkoutEvents = (data) => {
  window.gtag('event', 'Checkout' , data)
}
