import React, {useEffect, useState} from "react";

export const VendorListing = () => {
  
  useEffect(() => {
  
  }, []);
  
  return (
    <>
      <div className="p35-right-wrapper">
        <h4 className="p21-right-wrapper-title">Your Listings</h4>
        <div className="p35-filter-wrapper">
          <div className="p22-date-filter-block w-form">
            <form id="email-form" name="email-form" data-name="Email Form" className="p22-filter-form">
              <input
                type="text" className="p35-sku-searcj w-input" maxLength="256" name="name" data-name="Name"
                placeholder="Search SKU&#x27;s" id="name"/>
              <input
                type="submit" value="Filter" data-wait="Please wait..."
                className="p22-date-filter-button-copy w-button"/>
            </form>
            <div className="w-form-done">
              <div>Your filtered invoices can be found below!</div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went wrong.</div>
            </div>
          </div>
          <div className="p22-order-filter-block w-form">
            <form id="email-form" name="email-form" data-name="Email Form" className="p22-order-filter-sub-block">
              <input
                type="text"
                className="p35-product-title-search w-input"
                maxLength="256"
                name="name-3"
                data-name="Name 3"
                placeholder="Search Product Title"
                id="name-3"/>
              <input
                type="submit"
                value="Filter"
                data-wait="Loading"
                className="p35-search-button w-button"
              />
              <input
                type="submit"
                value="Create Listing"
                data-wait="Loading"
                className="p35-add-product w-button"
              />
            </form>
            <div className="w-form-done">
              <div>You can find the order below!</div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went.</div>
            </div>
          </div>
        </div>
        <div className="p35-listings-wrapper">
          <div className="p35-listing-title-row">
            <p className="p35-title-sku-copy"><strong>Product SKU</strong></p>
            <p className="p35-product-title2"><strong>Product Title</strong></p>
            <p className="p35-title-stock"><strong>Stock</strong></p>
            <p className="p35-product-views"><strong>Views</strong></p>
            <p className="p35-status-title"><strong>Status</strong></p>
            <p className="p35-action-title"><strong>Actions</strong></p>
          </div>
          <div className="p35-listing-row">
            <p className="p35-sku">19DIGITSLIMITED</p>
            <p className="p35-product-title">PREMIUM STAND UP POUCH 140 MICRON THICK MATT WHITE KRAFT WHITE BLACK </p>
            <p className="p35-stock">50,000</p>
            <p className="p35-views">50,000</p>
            <p className="p35-status">Live</p>
            <p className="p35-action">
              <a href="#" className="p35-action-link">Edit </a>
            </p>
          </div>
          <div className="p35-listing-row">
            <p className="p35-sku">19DIGITSLIMITED</p>
            <p className="p35-product-title">Plain White Jumper with Black Stitching</p>
            <p className="p35-stock">50,000</p>
            <p className="p35-views">50,000</p>
            <p className="p35-status">Pending</p>
            <p className="p35-action">
              <a href="#" className="p35-action-link">Edit</a>
            </p>
          </div>
          <div className="p35-listing-row">
            <p className="p35-sku">19DIGITSLIMITED</p>
            <p className="p35-product-title">Plain White Jumper with Black Stitching</p>
            <p className="p35-stock">50,000</p>
            <p className="p35-views">50,000</p>
            <p className="p35-status">Paused</p>
            <p className="p35-action">
              <a href="#" className="p35-action-link">Edit</a>
            </p>
          </div>
          <div className="p35-listing-row">
            <p className="p35-sku">19DIGITSLIMITED</p>
            <p className="p35-product-title">Plain White Jumper with Black Stitching</p>
            <p className="p35-stock">50,000</p>
            <p className="p35-views">50,000</p>
            <p className="p35-status">Live</p>
            <p className="p35-action">
              <a href="#" className="p35-action-link">Edit</a>
            </p>
          </div>
          <div className="p35-listing-row">
            <p className="p35-sku">19DIGITSLIMITED</p>
            <p className="p35-product-title">Plain White Jumper with Black Stitching</p>
            <p className="p35-stock">50,000</p>
            <p className="p35-views">50,000</p>
            <p className="p35-status">Live</p>
            <p className="p35-action">
              <a href="#" className="p35-action-link">Edit</a>
            </p>
          </div>
          <div className="p35-listing-row">
            <p className="p35-sku">19DIGITSLIMITED</p>
            <p className="p35-product-title">Plain White Jumper with Black Stitching</p>
            <p className="p35-stock">50,000</p>
            <p className="p35-views">50,000</p>
            <p className="p35-status">Live</p>
            <p className="p35-action">
              <a href="#" className="p35-action-link">Edit</a>
            </p>
          </div>
          <div className="p35-listing-row">
            <p className="p35-sku">19DIGITSLIMITED</p>
            <p className="p35-product-title">Plain White Jumper with Black Stitching</p>
            <p className="p35-stock">50,000</p>
            <p className="p35-views">50,000</p>
            <p className="p35-status">Live</p>
            <p className="p35-action">
              <a href="#" className="p35-action-link">Edit</a>
            </p>
          </div>
        </div>
        <div data-hover="" data-delay="0" className="p21-account-nav-mobile w-dropdown">
          <div className="dropdown-toggle-4 w-dropdown-toggle">
            <div className="w-icon-dropdown-toggle"></div>
            <div>Account Navigation</div>
          </div>
          <nav className="w-dropdown-list">
            <a href="#" className="w-dropdown-link">Link</a>
            <a href="#" className="w-dropdown-link">Order History</a>
            <a href="#" className="w-dropdown-link">Address Book</a>
            <a href="#" className="w-dropdown-link">Saved Products</a>
            <a href="#" className="w-dropdown-link">Stock Alerts</a>
            <a href="#" className="w-dropdown-link">Stock Forecasts</a>
            <a href="#" className="w-dropdown-link">Your Artwork</a>
            <a href="#" className="w-dropdown-link">Customer Service</a>
          </nav>
        </div>
      </div>
    </>
  )
  
};
