import React from "react";
import altImage from '../../../assets/images/1Asset-15-100.jpg'

export const CartSummaryItem = ({ item }) => (
  <div className="p5-order-item">
    <img
      src={
        (item.product.images &&
        item.product.images.length &&
        item.product.images[0].original) ||
        altImage
      }
      className="image-12"
    />
    <div>
      <div className="p5-summary-product-title">
        <strong>{item.product.title}</strong>
      </div>
      <div className="p5-summary-quantity">
        {item.volumetric_pricing
          ? `
          ${
            item.volumetric_pricing.find(
              ({ id }) => id === item.selected_volumetric_pricing
            ).name
          } - ${
              item.volumetric_pricing.find(
                ({ id }) => id === item.selected_volumetric_pricing
              ).package_quantity
            } Units - £${
              item.volumetric_pricing.find(
                ({ id }) => id === item.selected_volumetric_pricing
              ).price_excl_tax
            }
          `
          : null}
      </div>
      <div className="p5-product-price-span">£{item.price_excl_tax_excl_discounts}</div>
    </div>
  </div>
);
