import { connect } from 'react-redux';
import { Link } from './link';
import { actions } from '../../../actions/ui';

const mapStateToProps = state => ({
  breadcrumbs: state.ui.breadcrumbs,
});

const mapDispatchToProps = {
  setBreadCrumbs: actions.setBreadCrumbs,
};

export const LinksContainer = connect(mapStateToProps, mapDispatchToProps)(Link);
