import React, { useState } from "react";
import PropTypes from "prop-types";
import { ROUTES } from "../../../../utils/values";
import { get, post } from "../../../../api/api-caller";
import { END_POINTS } from "../../../../api";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const ActivationModal = ({ closeModal, email, redirectUrl, logout }) => {
  const history = useHistory();
  const [code, setCode] = useState("");
  const [error , setError] = useState(null);

  const getOtP = () => {
    get(`${END_POINTS.VERIFY_OTP}?email${email}`)
      .then((resp) => {
        toast.success(`OTP is sent to ${email}.`);
      })
      .catch(() => {
        toast.error("Error Activating account try again");
      });
  };
  const activate = () => {
    let data = {
      email: email,
      otp: code,
    };
    post(END_POINTS.VERIFY_OTP, data)
      .then((resp) => {
        if (resp.success) {
          closeModal();
          toast.success("Your account is activated");
          //history.push(redirectUrl ? `/${redirectUrl}` : '/');
          history.push(redirectUrl || ROUTES.ROOT);
        }else{
          setError(resp.message)
        }
      })
      .catch();
  };

  return (
    <div className="p20-modal">
      <div className="p20-modal-block">
        <div className="p20-form-block w-form">
          <div
            id="email-form"
            name="email-form"
            data-name="Email Form"
            className="p20-form-body"
          >
            <div className="p20-form-content">
              <div className="p20-form-title-wrapper">
                <h4 className="p20-form-title">We've sent your verification code to {email}</h4>
                <a
                  data-w-id="9248706e-3a6a-5a5e-9c98-b0190ed3b7ba"
                  href="#"
                  className="p10---modal-close-link"
                >
                  {/*  <span className="p10-close-text-span-copy" onClick={()=> closeModal(false)}>Close [ X ]</span>*/}
                </a>
              </div>
              <div className="p20-text-message">
                Thanks for signing up to JustBulk, just before you continue we
                need you to confirm your email address.
                <br />
              </div>
              <input
                type="password"
                className="p20-verification-field w-input"
                maxLength="256"
                name="Verification-code"
                data-name="Verification code"
                placeholder="Enter your verification code"
                id="Verification-code"
                required=""
                value={code}
                onChange={(e) => {
                  setCode(e.target.value);
                }}
              />
              <input
                type="button"
                value="Verify &amp; Continue"
                data-wait="Please wait..."
                className="p20-verify-button w-button"
                disabled={!code || code ===""}
                onClick={() => {
                  activate();
                }}
              />
            </div>
            <div className="p20-help-text">
              Need Help?
              <a href={`${ROUTES.CONTACT_US}`} className="link-3" onClick={()=>logout()}>
                Contact Us
              </a>
              <br />
              <a href="#" className="link-3" onClick={() => getOtP()}>
                Resend Activation Code
              </a>
              <span> or </span>
              <a href="#" className="link-3" onClick={() => logout()}>
                Exit Sign Up Again
              </a>
              <br />
            </div>
          </div>
          <div className="w-form-done">
            <div>Thank you! You will be redirected to wherever you were!</div>
          </div>
          {error && (
              <div
                  className="e_login_errorblock w-form-fail"
                  style={{ display: "block" }}
              >
                <div className="e_login_errormessageblock">
                  <div>{error}</div>
                </div>
              </div>
          )}

        </div>
      </div>
    </div>
  );
};

ActivationModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string.isRequired,
};
export default ActivationModal;
