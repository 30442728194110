import React, {useEffect, useState} from "react";
import img from '../../assets/images/1Asset-10Warehouse2.png';
import volumetric from "../../assets/images/1Asset-7Volumetric.png";
import subscription_img from "../../assets/images/1Asset-5Subscription.png";
import custom_order_img from "../../assets/images/1Asset-6Custom-Orders.png";
import digital_img from "../../assets/images/1Asset-8Digital.png";
import human_img from "../../assets/images/1Asset-9Human.png";
import comm_img from "../../assets/images/1Asset-15Ads.png";
import sdrs_img from "../../assets/images/1Asset-14SDRs.png";
import intros_img from "../../assets/images/1Asset-13Intros.png";
import {ROUTES} from "../../utils/values";

export const SellerMarketPlace = () => {
  
  useEffect(() => {
  
  }, []);
  
  return (
    <div className="p33-main-body">
      <div className="p33-wrapper">
        <div className="p33-header-wrapper">
          <div className="p33-title-wrapper">
            <div className="p28-title-hash">#B2B-MarketPlace</div>
            <h1 className="p28-h1"><strong>Reach thousands of Businesses with our nationalised approach.<br/></strong>
            </h1>
            <h1 className="p28-h2">We&#x27;re not a directory, we&#x27;re not a marketplace that favours one country
              either, we&#x27;re on the ground and independent to each country we operate it.</h1>
            <h3 className="p28-h3">Partner with Justbulk to launch your new business growth</h3>
            <div className="p29-cta-wrapper">
              <a href={ROUTES.SELLER_SIGNUP} className="p29-cta-button w-button">Start Selling - It&#x27;s Free </a>
            </div>
          </div>
        </div>
        <div className="p33-c2">
          <div className="p33-c2-left-wrapper">
            <img
              src={img} loading="lazy" width="600"
              height="600"
              sizes="(max-width: 479px) 300px, (max-width: 767px) 88vw, (max-width: 991px) 78vw, 47vw"
              alt="" className="p33-c3-image"
            />
          </div>
          <div className="p33-c2-right-wrapper">
            <h2 className="p33-c2-h2">A B2B Marketplace <br/>that <span className="text-span-5">does more</span>, gives
              more and charges less!<strong className="bold-text-12"><br/></strong></h2>
            <div className="p33-c2-bulletpoint-wrapper">
              <div className="p33-c2-bulletpoint"></div>
              <h1 className="p33-c2-para">No Coding Requiring </h1>
            </div>
            <div className="p33-c2-bulletpoint-wrapper">
              <div className="p33-c2-bulletpoint"></div>
              <h1 className="p33-c2-para">No Designing Required</h1>
            </div>
            <div className="p33-c2-bulletpoint-wrapper">
              <div className="p33-c2-bulletpoint"></div>
              <h1 className="p33-c2-para">We handle Product Photography</h1>
            </div>
            <div className="p33-c2-bulletpoint-wrapper">
              <div className="p33-c2-bulletpoint"></div>
              <h1 className="p33-c2-para">We help you write your listings</h1>
            </div>
            <div className="p33-c2-bulletpoint-wrapper">
              <div className="p33-c2-bulletpoint"></div>
              <h1 className="p33-c2-para">We Advertise your Products</h1>
            </div>
            <div className="p33-c2-bulletpoint-wrapper">
              <div className="p33-c2-bulletpoint"></div>
              <h1 className="p33-c2-para">We handle import &amp; Duties</h1>
            </div>
            <div className="p33-c2-bulletpoint-wrapper">
              <div className="p33-c2-bulletpoint"></div>
              <h1 className="p33-c2-para">We Offer Competitive Fulfillment </h1>
            </div>
            <div className="p33-c2-bulletpoint-wrapper">
              <div className="p33-c2-bulletpoint"></div>
              <h1 className="p33-c2-para">We Manage your Customer Retention </h1>
            </div>
            <div className="p33-c2-bulletpoint-wrapper">
              <div className="p33-c2-bulletpoint"></div>
              <h1 className="p33-c2-para">We Follow up on missed oppertunities</h1>
            </div>
            <a href={ROUTES.SELLER_SIGNUP} className="p33-cta-1 w-button">Sign Up as a Seller</a>
          </div>
        </div>
        <div className="p33-c4">
          <div className="p33-c4-wrapper">
            <h2 className="p33-c4-h2">Sell <span className="text-span-4">Anything </span><br/>And Everything anyway you
              like.<strong className="bold-text-12"><br/></strong></h2>
            <h1 className="p33-c4-card-left-para">We understand every business sells differently, &amp; we have a
              customer success team dedicated to helping you setup the correct format!</h1>
            <a href="https://calendly.com/justbulk" className="p33-cta-1 w-button">Book a Call Back </a>
          </div>
          <div className="p33-c4-grid-wrapper">
            <div className="w-layout-grid grid">
              <div className="p33-c4-card">
                <img
                  src={volumetric} loading="lazy" height="250"
                  width="250" alt="" className="p33-c4-img"
                />
                <h1 className="p33-c4-card-text-main">Sell Volumetric Stock<br/></h1>
                <h1 className="p33-c4-card-text">No presents volumes, sell according to your inventory!</h1>
              </div>
              <div className="p33-c4-card">
                <img
                  src={subscription_img} loading="lazy" width="250"
                  height="250" alt="" className="p33-c4-img"
                />
                <h1 className="p33-c4-card-text-main">Sell Subscriptions<br/></h1>
                <h1 className="p33-c4-card-text">Offer Volume discounts for frequent buyers. </h1>
              </div>
              <div className="p33-c4-card">
                <img
                  src={custom_order_img} loading="lazy" width="250"
                  height="250" alt="" className="p33-c4-img"
                />
                <h1 className="p33-c4-card-text-main">Take Custom Prod Orders<br/></h1>
                <h1 className="p33-c4-card-text">We&#x27;ll custom build a pricing <br/>engine for you</h1>
              </div>
              <div className="p33-c4-card">
                <img
                  src={digital_img} loading="lazy" width="250"
                  height="250" alt="" className="p33-c4-img"
                />
                <h1 className="p33-c4-card-text-main">Sell Digital Goods<br/></h1>
                <h1 className="p33-c4-card-text">Digital makes up of 1/3rd of online purchases. Don&#x27;t miss
                  out!</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="p33-c3">
          <div className="p33-c3-left-wrapper">
            <img
              src={human_img} loading="lazy" width="600"
              height="450"
              sizes="(max-width: 479px) 300px, (max-width: 767px) 88vw, (max-width: 991px) 78vw, 49vw"
              alt="" className="p33-c3-image2"
            />
          </div>
          <div className="p33-c3-right-wrapper">
            <h2 className="p33-c3-title">We have brought <br/>humans back.<strong
              className="bold-text-12"><br/></strong>
            </h2>
            <h1 className="p28-h2">Because its always nice to speak to local people for advise about something before
              buying, all our sales staff are independent of our listed suppliers. They are dedicated to helping you
              sell your product.</h1>
            <h3 className="p28-h3">You can even get dedicated humans for your products!</h3>
          </div>
        </div>
        <div className="p33-c1-copy">
          <div className="p33-c1-left">
            <h1 className="p33-c1-side-text">QUESTION</h1>
          </div>
          <div className="p33-c1-rightcopy">
            <h2 className="p33-c1-h2">If you had the chance to join Alibaba, Amazon, Ebay in their <span>early </span>days
              would you give up the opportunity.....<strong className="bold-text-12"><br/></strong></h2>
            <h1 className="p33-c1-para">The difference is we only entertain Vetted Businesses, we&#x27;re not really
              fond of Deep Sea Stock. We&#x27;re all about local suppliers and manufacturers that can get goods out to
              you within 1-10 Days. Thanks to this stringent vetting process we&#x27;re trusted by some of the biggest
              brands across the UK &amp; Europe.<br/></h1>
            <a href="#" className="p33-c1-link">Not a Seller? Join our Investor Que </a>
          </div>
        </div>
        <div className="p33-c5">
          <div className="p33-c5-right">
            <div className="p33-c5-left">
              <h2 className="p33-c3-title">Growth Tools.<br/><span
                className="text-span-6">If you&#x27;re ready to grow</span><strong
                className="bold-text-12"><br/></strong></h2>
              <h1 className="p33-c5-h3">Once you&#x27;ve got your account setup, we&#x27;ve got an array of marketing
                tools to help you increase your awareness and sales. BUT the big but unlike other platforms we
                understand scaleability so everything is done on our side for you!</h1>
              <a href={ROUTES.CONTACT_US} className="p33-c5-cta w-button">Request Pricing</a>
            </div>
          </div>
          <div className="p33-c5-card-wrapper">
            <div className="p33-c5-card">
              <img
                src={comm_img} loading="lazy" height="225" width="225"
                alt="" className="p33-c5-img"
              />
              <h1 className="p33-c4-card-text-main">ADs<br/></h1>
              <h1 className="p33-c4-card-text">Cross Channel &amp; Native Ads to bespoke Product or Service Landing
                Pages. </h1>
            </div>
            <div className="p33-c5-card">
              <img
                src={sdrs_img} loading="lazy" height="225" width="225"
                alt="" className="p33-c5-img"
              />
              <h1 className="p33-c4-card-text-main">SDRs<br/></h1>
              <h1 className="p33-c4-card-text">Native Expert Sales Dev Reps, to win you business, split time &amp; full
                time.</h1>
            </div>
            <div className="p33-c5-card">
              <img
                src={intros_img} loading="lazy" height="225"
                width="225" alt="" className="p33-c5-img"
              />
              <h1 className="p33-c4-card-text-main">INTROs<br/></h1>
              <h1 className="p33-c4-card-text">
                Get warm introductions to others on our global network.
                <br/>
                Grow your Channel Marketing<br/>
              </h1>
            </div>
          </div>
        </div>
        <div className="p33-c6">
          <div className="p33-c6-left">
            <div className="p33-c6-left-sub-block">
              <div className="p33-c6-greenline"></div>
              <div className="p33-c6-bullet-block">
                <div className="p33-c2-bulletpoint-wrapper">
                  <div className="p33-c2-bulletpoint"></div>
                  <h1 className="p33-c2-para">Imports, Exports Storage &amp; Fullfillment </h1>
                </div>
                <h1 className="p33-c6-para">If you want to expand internationally, we&#x27;ll support you all the way.
                  We&#x27;ll import the goods for you, store them and ship them out when someone places an order online,
                  or even when you have an Offline Order!</h1>
              </div>
              <div className="p33-c6-bullet-block">
                <div className="p33-c2-bulletpoint-wrapper">
                  <div className="p33-c2-bulletpoint"></div>
                  <h1 className="p33-c2-para">Trade Assurance &amp; Mediation </h1>
                </div>
                <h1 className="p33-c6-para">Payments are only released to suppliers upon delivery. We also mediate
                  transactional disputes for all kinds of orders, be it custom print, digital we look at details on a
                  case by case basis. </h1>
              </div>
              <div className="p33-c6-bullet-block">
                <div className="p33-c2-bulletpoint-wrapper">
                  <div className="p33-c2-bulletpoint"></div>
                  <h1 className="p33-c2-para">Net 7, Net 30, Net 60 Credit </h1>
                </div>
                <h1 className="p33-c6-para">Business is built on Credit, We offer various Interest Free Credit Terms
                  when you transact with a supplier on our Network. Subject to Trading History &amp; Terms </h1>
              </div>
              <div className="p33-c6-greenline"></div>
            </div>
          </div>
          <div className="p33-c6-right">
            <h2 className="p33-c3-title"><span className="text-span-6">More Trade Services</span><strong
              className="bold-text-12"><br/></strong></h2>
            <h2 className="p33-c3-title">We want you to Grow</h2>
            <h2 className="p33-c3-title"><span className="text-span-6">thats our</span><span
              className="text-span-7"> Agenda.</span><strong className="bold-text-12"><br/></strong></h2>
            <a href={ROUTES.SELLER_SIGNUP} className="p33-c6-cta w-button">Sign Up as a Seller for Free </a>
          </div>
        </div>
        <div className="p33-c7">
          <div className="p33-c7-a">
            <div className="p33-c7-process">
              <div className="p33-c7-h3"><strong className="bold-text-3">Get a Call Back within 48 Hours!</strong></div>
              <h2 className="p33-c7-h4">Getting Started is simple, What&#x27;s Stopping you?<strong
                className="bold-text-12"><br/></strong></h2>
              <h1 className="p33-c6-para2">&quot; Success is tied with action, you don&#x27;t loose anything by getting
                in touch, but by not doing so you will not gain anything for sure &quot;</h1>
            </div>
          </div>
          <div style={{alignContent:"center"}}>
            <a
              href="https://calendly.com/justbulk"
              className="p33-c7-form-button w-button"
            >
              Book Time
            </a>
          </div>
        </div>
      </div>
    </div>
  )
};
