import { object, string } from 'yup';
import { withFormik } from 'formik';

/*
* formSchema serves both as the initial state to form as well
* as set of validation rules to apply on form fields
* */
const formSchema = object().shape({
  file: string()
    .required('Required field'),
});

/*
* HOC to enhance our form with <Formik />
* Helps us pull out logic from the generator-form.jsx component
* */
export const formikEnhancer = withFormik({
  enableReinitialize: true,
  initialValues:{name:"", message: "", file:null},
  //validationSchema: formSchema,
  handleSubmit: (values, { setSubmitting, resetForm, props: { attemptSubmit } }) => {
    attemptSubmit({
      data: values,
      setSubmitting,
      resetForm,
    });
  }
});
