import { connect } from 'react-redux';
import { CheckoutSummary } from './checkout-summary';
import {actions as uiActions} from "../../actions/ui";
import {actions as authActions} from "../../actions/auth";

const mapStateToProps = state => ({
  basket: state.auth.basket,
  shippingCountry: state.ui.country,
  taxHandlingMethod: state.ui.taxHandlingMethod,
});

const mapDispatchToProps = {
  setBasket: authActions.setBasket,
  toggleCart: uiActions.toggleCartModal,
  setRefresh: uiActions.setRefreshCart,
};

export const CheckoutSummaryContainer = connect(mapStateToProps, mapDispatchToProps)(CheckoutSummary);
