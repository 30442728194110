import { connect } from 'react-redux';
import { HeaderComponent } from './header';
import { actions } from '../../actions/ui';
import { actions as authActions } from '../../actions/auth';
import {resetAuth} from "../../actions/auth/actions";

const mapStateToProps = state => ({
  account: state.auth.account,
  basket: state.auth.basket,
  cart: state.auth.cart,
  showActivationModal: state.auth.showActivationModal,
  cartVisibility: state.ui.cartVisibility,
  refreshCart: state.ui.refreshCart,
  customQuoteModal: state.ui.customQuoteModal,
  showCookieModal: state.ui.showCookieModal,
  shippingCountry: state.ui.country,
  taxHandlingMethod: state.ui.taxHandlingMethod,
  showFreeSampleModal: state.ui.showFreeSampleModal,
  showPouchQuotationForm: state.ui.showPouchQuotationForm,
});

const mapDispatchToProps = {
  verifyAccount: authActions.verifyAccount,
  setCart: authActions.setCart,
  toggleCart: actions.toggleCartModal,
  setAccountToken: authActions.setAccountToken,
  setBasket: authActions.setBasket,
  toggleCustomQuoteModal: actions.toggleCustomQuoteModal,
  toggleCookieModal: actions.toggleCookieModal,
  toggleActivationModal: authActions.toggleActivationModal,
  toggleFreeSampleModal: actions.toggleFreeSampleModal,
  togglePouchQuotationModal: actions.togglePouchQuotationModal,
  resetAuth,
};

export const HeaderContainer = connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);
