import React, { useEffect, useState } from "react";
import { get } from "../../api/api-caller";
import { END_POINTS } from "../../api";
import $ from "jquery";
import { Link } from "react-router-dom";
import { ROUTES } from "../../utils/values";

export const FAQs = () => {
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    get(END_POINTS.FAQS)
      .then(({ results }) => {
        setFaqs(results);
      })
      .catch((err) => console.error(err));
  }, []);
  return (
    <div className="p2--faq-container">
      <div className="w-layout-grid p2---faq-grid">
        <aside id="w-node-0db45023e19e-0d67f4ba" className="p2---faq-sidebar">
          <div className="p2---faq-sidebar-block flex">
            <h4 className="p2---faq-sidebar-title">Categories</h4>
            {faqs.map(({ title, pk }) => (
              <a
                href="#"
                className="p2---faq-side-bar-link"
                onClick={() => {
                  $("html, body").animate(
                    {
                      scrollTop: $(`#${pk}`).offset().top,
                    },
                    500
                  );
                }}
              >
                {title}
              </a>
            ))}
          </div>
        </aside>
        <div id="w-node-0db45023e1a8-0d67f4ba" className="p2---faq-wrapper">
          {faqs.map((faq) => (
            <div id={faq.pk} className="p2---faq-block">
              <h2 className="p2---faq-title">{faq.title}</h2>
              <ul role="list" className="w-list-unstyled">
                {faq.faq_set.map((set) => (
                  <li className="p2---faq-list-item">
                    <h3 className="p2---faq-question">{set.question}</h3>
                    <p className="p2-faq-answer">{set.answer}</p>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          <div className="p2---bottom-help-text">
            Still got a question? Please, don&#x27;t hesitate to get in touch
            via our{" "}
            <Link to={ROUTES.CONTACT_US} className="p2---contact-link">
              contact form
            </Link>
            . We are happy to help you!
          </div>
        </div>
      </div>
    </div>
  );
};
