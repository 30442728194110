import React, {useState} from "react";
import {Field, Form, Formik} from "formik";
import {object, string} from "yup";
import {post} from "../../../api/api-caller";
import {END_POINTS} from "../../../api";
import {toast} from "react-toastify";
import {reportEvent} from "../../../utils";

export const FreeSampleRequest = ({
 onClose,
}) => {
  
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isError, setIsError] = useState(false)
  const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/
  
  const formSchema = object().shape({
    full_name: string()
      .required('Name is a required field'),
    email: string()
      .email('Invalid Email')
      .required('Email is a required field'),
    contact_no: string()
      .matches(phoneRegExp, 'Phone number is not valid')
      .required('Phone is a required field'),
    delivery_address: string()
      .required('Delivery address is required'),
    message: string()
      .required('Kindly fill answers. These are required'),
  });
  
  const submitFreeSampleRequest = (values, actions) => {
    let data = {
      ...values,
      first_name: values.full_name,
      message: `\n Delivery Address. \n ${values.delivery_address} \n Answers. \n ${values.message}`
    };
    post(END_POINTS.CONTACT_US, data)
      .then((resp) => {
        //  reportEvent('Enquiry', 'Submit')
        reportEvent('Enquiry', 'Submit', window.location.pathname)
        toast.success('Thank you for submitting your enquiry! Please look out for an email from us!')
        actions.resetForm({
          values: {
            type: 'enquiry',
            full_name: "",
            quantity: "",
            delivery_address: "",
            email: "",
            requirement: "",
            message:"",
            contact_no: "",
          },
        });
        setIsSubmitted(true);
        onClose();
      })
      .catch((e) => {
        setIsError(true);
      });
  }
  
  return (
    <>
      <div className="p1-request-sample-modal">
        <div className="p1-sample-modal-block">
          <a data-w-id="724bb8df-efde-4c4e-363b-3de82289a763" href="#" className="p1---modal-close-link">
            <span className="text-span" onClick={onClose}>Close [ X ]</span>
          </a>
          <div className="p1-sample-modal-title">Request your Free Samples</div>
          <div className="p1-sample-modal-text">Please ensure the contact details in your account are up to date, in
            case
            we need to reach out to you!
            <br/>
          </div>
          <Formik
            initialValues={{type: 'enquiry', accept: true}}
            onSubmit={submitFreeSampleRequest}
            enableReinitialize
            validationSchema={formSchema}
          >
            {({isSubmitting, errors, values, touched}) => (
              <div className="p1-sample-modal-form w-form">
                <Form
                  id="free-sample-request-form"
                  name="free-sample-request-form"
                  data-name="free-sample-request-form"
                >
                  <Field
                    type="text"
                    className="p1-sample-modal-name-field w-input"
                    maxLength="256"
                    name="full_name"
                    data-name="Name"
                    placeholder="Your Full Name"
                    id="name"
                    required
                  />
                  {errors.full_name && touched.full_name && (
                    <label
                      className="w-commerce-commercecheckoutlabel p5-field-label"
                      style={{color: "red"}}
                    >
                      {errors.full_name}
                    </label>
                  )}
                  
                  <Field
                    type="text"
                    className="p1-sample-modal-email-address w-input"
                    maxLength="256"
                    name="email"
                    data-name="Name 2"
                    placeholder="Email Address"
                    id="email_free_sample"
                    required
                  />
                  {errors.email && touched.email && (
                    <label
                      className="w-commerce-commercecheckoutlabel p5-field-label"
                      style={{color: "red"}}
                    >
                      {errors.email}
                    </label>
                  )}
                  <Field
                    type="text"
                    className="p1-sample-modal-phone w-input"
                    maxLength="256"
                    name="contact_no"
                    data-name="contact_no"
                    placeholder="Phone No"
                    id="contact_no_free_sample"
                    required
                  />
                  {errors.contact_no && touched.contact_no && (
                    <label
                      className="w-commerce-commercecheckoutlabel p5-field-label"
                      style={{color: "red"}}
                    >
                      {errors.contact_no}
                    </label>
                  )}
                  <Field
                    type="text"
                    as="textarea"
                    className="p1-address-field w-input"
                    maxLength="256"
                    name="delivery_address"
                    data-name="delivery_address"
                    placeholder="Delivery Address"
                    id="delivery_address"
                    required
                  />
                  {errors.delivery_address && touched.delivery_address && (
                    <label
                      className="w-commerce-commercecheckoutlabel p5-field-label"
                      style={{color: "red"}}
                    >
                      {errors.delivery_address}
                    </label>
                  )}
                  <div className="p1-sample-modal-text">
                    <strong>Please tell us the following below:</strong>
                    <br/>
                    1. What do you need a sample of (Size/Colour)
                    <br/>
                    2. What are you looking to pack? / or do with it?
                    <br/>
                    3. How many you&#x27;re looking to purchase?
                    <br/>
                    4. A bit about your brand / company / web link
                    <br/>
                  </div>
                  <Field
                    type="text"
                    as="textarea"
                    className="p1-sample-modal-requirements w-input"
                    name="message"
                    data-name="message"
                    placeholder="Your Answers"
                    id="message"
                    required
                  />
                  {errors.message && touched.message && (
                    <label
                      className="w-commerce-commercecheckoutlabel p5-field-label"
                      style={{color: "red"}}
                    >
                      {errors.message}
                    </label>
                  )}
                  <label
                    className="w-checkbox p1-sample-modal-check-box">
                    <Field
                      type="checkbox"
                      id="checkbox-2"
                      name="accept"
                      data-name="accept"
                      className="w-checkbox-input"
                    />
                    <span className="checkbox-label-5 w-form-label">
                I understand this is a promotional product &amp; agree to be contacted in the future in relation to it.
              </span>
                  </label>
                  <input
                    type="submit"
                    value={isSubmitting ? "Sending Request..." : "Submit"}
                    className="p1-sample-modal-button w-button"
                    disabled={isSubmitting || !values.accept}
                  />
                </Form>
  
                {isSubmitted &&
                  <div className="w-form-done-free-sample">
                    <div>Thank you for submitting your enquiry! Please look out for an email from us!</div>
                  </div>
                }
                
                {isError &&
                  <div className="w-form-fail">
                    <div>Oops! Something went wrong while submitting the form.</div>
                  </div>
                }
                
              </div>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
};

export default FreeSampleRequest;
