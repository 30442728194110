import React from "react";
import {Formik, Field, Form} from "formik";
import {post} from "../../../api/api-caller";
import {END_POINTS} from "../../../api";
import {object, string} from "yup";
import ErrorMessage from "../error_field";

export const Step2 = ({setActiveStep, sellerId, step2Data}) => {
  console.log(step2Data);
  debugger;
  const step2Schema = object().shape({
    address_line_1: string()
      .required('Address Line 1 is a required field')
      .max(30),
    address_line_2: string()
      .required('Address Line 2 is a required field')
      .max(30),
    postal_town: string()
      .required('Postal town is a required field'),
    county: string()
      .required('County is a required field'),
    country: string()
      .required('Country is a required field'),
    postal_code: string()
      .required('Postal code is a required field'),
    company_sic_code: string()
      .required('Company SIC is a required field'),
    company_category: string()
      .required('Company category is a required field'),
    incorporation_date: string()
      .required('Incorporation date is a required field'),
    last_confirmation_statement_date: string()
      .required('This is a required field'),
    trading_address_line_1: string()
      .when("is_same_trading_address", {
        is: false,
        then: string().required("Address Line 1 is a required field")
      }),
    trading_address_line_2: string()
      .when("is_same_trading_address", {
        is: false,
        then: string().required("Address Line 2 is a required field")
      }),
    trading_postal_town: string()
      .when("is_same_trading_address", {
        is: false,
        then: string().required("Postal town is a required field")
      }),
    trading_county: string()
      .when("is_same_trading_address", {
        is: false,
        then: string().required("County is a required fields")
      }),
    trading_country: string()
      .when("is_same_trading_address", {
        is: false,
        then: string().required("Country is a required field")
      }),
    trading_postal_code: string()
      .when("is_same_trading_address", {
        is: false,
        then: string().required("Postal code is a required field")
      }),
    trading_company_sic_code: string()
      .when("is_same_trading_address", {
        is: false,
        then: string().required("Company SIC is a required field")
      }),
    trading_company_category: string()
      .when("is_same_trading_address", {
        is: false,
        then: string().required("Company category is a required fields")
      }),
    trading_incorporation_date: string()
      .when("is_same_trading_address", {
        is: false,
        then: string().required("Incorporation date is a required field")
      }),
    trading_last_confirmation_statement_date: string()
      .when("is_same_trading_address", {
        is: false,
        then: string().required("This is a required Field")
      }),
  });
  
  const step2 = {
    supplier_lead_id: sellerId,   // "id passed from step 1",
    address_line_1: step2Data.address_line_1 || '',
    address_line_2: step2Data.address_line_2 || '',
    postal_town: step2Data.locality || '',
    county: "",
    country: step2Data.country || '',
    postal_code: step2Data.postal_code || '',
    company_sic_code: "",
    company_category: "",
    incorporation_date: "",
    last_confirmation_statement_date: "",
    is_same_trading_address: true,
    trading_address_line_1: "",
    trading_address_line_2: "",
    trading_postal_town: "",
    trading_county: "",
    trading_country: "",
    trading_postal_code: "",
    trading_company_sic_code: "",
    trading_company_category: "",
    trading_incorporation_date: "",
    trading_last_confirmation_statement_date: ""
  }
  
  const handleSubmit = (values) => {
    post(`${END_POINTS.SELLER_SIGNUP}step2`, values)
      .then((resp) => {
        console.log("step2 submitted")
        console.log(values)
        setActiveStep(3);
      })
      .catch()
  }
  
  return (
    <Formik initialValues={step2} onSubmit={handleSubmit} validationSchema={step2Schema}>
      {
        ({
           values,
           setFieldValue,
           errors,
           touched
         },
        ) => {
          return (
            <Form>
              <div className="p34-form-c1">
                <div className="p34-form-title-wrapper">
                  <h2 className="p34-c1-h3">Your Registered Company Details
                    <strong className="bold-text-12"><br/></strong>
                  </h2>
                  <div className="p34-progress-wrapper">
                    <div className="p34-progress-bar" style={{width: '25%'}}></div>
                  </div>
                  <div className="p34-form-split-wrapper">
                    <div className="p34-form-c1-left">
                      <div className="p34-form-field-block">
                        <label htmlFor="name-4" className="p34-form-field-label">
                          Address Line 1
                        </label>
                        <Field
                          type="text" className="p34-form-field w-input" maxLength="256"
                          name="address_line_1" id="address_line_1"
                          value={step2.address_line_1}
                        />
                        <ErrorMessage
                          errors={errors}
                          touched={touched}
                          name="address_line_1"
                        />
                      </div>
                      <div className="p34-form-field-block">
                        <label htmlFor="name-4" className="p34-form-field-label">
                          Postal Town
                        </label>
                        <Field
                          type="text" className="p34-form-field w-input" maxLength="256"
                          name="postal_town" id="postal_town"
                          value={step2.postal_town}
                        />
                        <ErrorMessage
                          errors={errors}
                          touched={touched}
                          name="postal_town"
                        />
                      </div>
                      <div className="p34-form-field-block">
                        <label htmlFor="name-4" className="p34-form-field-label">Country</label>
                        <Field
                          type="text" className="p34-form-field w-input" maxLength="256"
                          name="country"
                          id="country"
                          value={step2.country}
                        />
                        <ErrorMessage
                          errors={errors}
                          touched={touched}
                          name="country"
                        />
                      </div>
                      <div className="p34-form-field-block">
                        <label htmlFor="name-4" className="p34-form-field-label">Company SIC Code/s</label>
                        <Field
                          type="text" className="p34-form-field w-input" maxLength="256"
                          name="company_sic_code" id="company_sic_code"
                        />
                        <ErrorMessage
                          errors={errors}
                          touched={touched}
                          name="company_sic_code"
                        />
                      </div>
                      <div className="p34-form-field-block">
                        <label htmlFor="name-4" className="p34-form-field-label">Company Category</label>
                        <Field
                          type="text" className="p34-form-field w-input" maxLength="256"
                          name="company_category" data-name="Name 3" placeholder="" id="company_category"
                        />
                        <ErrorMessage
                          errors={errors}
                          touched={touched}
                          name="company_category"
                        />
                      </div>
                      <label className="w-checkbox">
                        <input
                          type="checkbox"
                          id="checkbox-3"
                          name="is_same_trading_address"
                          data-name="Checkbox 3"
                          className="w-checkbox-input"
                          checked={values.is_same_trading_address}
                          onChange={(e) => setFieldValue("is_same_trading_address", e.target.checked)}
                        />
                        <span className="w-form-label">My Trading Address is the same as above.</span></label>
                    </div>
                    <div className="p34-form-c1-right">
                      <div className="p34-form-field-block">
                        <label htmlFor="name-4" className="p34-form-field-label">Address Line 2</label>
                        <Field
                          type="text"
                          className="p34-form-field w-input"
                          maxLength="256"
                          name="address_line_2"
                          id="address_line_2"
                          value={step2.address_line_2}
                        />
                        <ErrorMessage
                          errors={errors}
                          touched={touched}
                          name="address_line_2"
                        />
                      </div>
                      <div className="p34-form-field-block">
                        <label className="p34-form-field-label">County</label>
                        <Field
                          type="text"
                          className="p34-form-field w-input"
                          maxLength="256"
                          name="county"
                          id="county"
                          value={step2.county}
                        />
                        <ErrorMessage
                          errors={errors}
                          touched={touched}
                          name="county"
                        />
                      </div>
                      <div className="p34-form-field-block">
                        <label htmlFor="name-4" className="p34-form-field-label">Postal Code</label>
                        <Field
                          type="text" className="p34-form-field w-input" maxLength="256"
                          name="postal_code"
                          id="postal_code"
                          value={step2.postal_code}
                        />
                        <ErrorMessage
                          errors={errors}
                          touched={touched}
                          name="postal_code"
                        />
                      </div>
                      <div className="p34-form-field-block">
                        <label htmlFor="name-4" className="p34-form-field-label">Date of Incorporation </label>
                        <Field
                          type="text" className="p34-form-field w-input" maxLength="256"
                          name="incorporation_date"
                          id="incorporation_date"
                        />
                        <ErrorMessage
                          errors={errors}
                          touched={touched}
                          name="incorporation_date"
                        />
                      </div>
                      <div className="p34-form-field-block">
                        <label htmlFor="name-4" className="p34-form-field-label">
                          Last Confirmation Statement Date
                        </label>
                        <Field
                          type="text"
                          className="p34-form-field w-input"
                          maxLength="256"
                          name="last_confirmation_statement_date"
                          id="last_confirmation_statement_date"
                        />
                        <ErrorMessage
                          errors={errors}
                          touched={touched}
                          name="last_confirmation_statement_date"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {values.is_same_trading_address && (
                  <button disabled={errors.length} onClick={() => setActiveStep(3)}
                          className="p34-next-form w-button">Next</button>
                )}
              </div>
              {!values.is_same_trading_address && (
                <div className="p34-form-c1">
                  <div className="p34-form-title-wrapper">
                    <h2 className="p34-c1-h3">Trading Address Details
                      <strong className="bold-text-12"><br/></strong>
                    </h2>
                    <div className="p34-progress-wrapper">
                      <div className="p34-progress-bar" style={{width: '25%'}}></div>
                    </div>
                    <div className="p34-form-split-wrapper">
                      <div className="p34-form-c1-left">
                        <div className="p34-form-field-block">
                          <label htmlFor="name-4" className="p34-form-field-label">
                            Address Line 1
                          </label>
                          <Field
                            type="text" className="p34-form-field w-input" maxLength="256"
                            name="trading_address_line_1" id="trading_address_line_1"
                          />
                          <ErrorMessage
                            errors={errors}
                            touched={touched}
                            name="trading_address_line_1"
                          />
                        </div>
                        <div className="p34-form-field-block">
                          <label htmlFor="name-4" className="p34-form-field-label">
                            Postal Town
                          </label>
                          <Field
                            type="text" className="p34-form-field w-input" maxLength="256"
                            name="trading_postal_town" id="trading_postal_town"
                          />
                          <ErrorMessage
                            errors={errors}
                            touched={touched}
                            name="trading_postal_town"
                          />
                        </div>
                        <div className="p34-form-field-block">
                          <label htmlFor="name-4" className="p34-form-field-label">Country</label>
                          <Field
                            type="text" className="p34-form-field w-input" maxLength="256" name="trading_country"
                            id="trading_country"
                          />
                          <ErrorMessage
                            errors={errors}
                            touched={touched}
                            name="trading_country"
                          />
                        </div>
                        
                        <div className="p34-form-field-block">
                          <label htmlFor="name-4" className="p34-form-field-label">Company SIC Code/s</label>
                          <Field
                            type="text" className="p34-form-field w-input" maxLength="256"
                            name="trading_company_sic_code" id="trading_company_sic_code"
                          />
                          <ErrorMessage
                            errors={errors}
                            touched={touched}
                            name="trading_company_sic_code"
                          />
                        </div>
                        <div className="p34-form-field-block">
                          <label htmlFor="name-4" className="p34-form-field-label">Company Category</label>
                          <Field
                            type="text" className="p34-form-field w-input" maxLength="256"
                            name="trading_company_category" data-name="Name 3" placeholder=""
                            id="trading_company_category"
                          />
                          <ErrorMessage
                            errors={errors}
                            touched={touched}
                            name="trading_company_category"
                          />
                        </div>
                      </div>
                      <div className="p34-form-c1-right">
                        <div className="p34-form-field-block">
                          <label htmlFor="name-4" className="p34-form-field-label">Address Line 2</label>
                          <Field
                            type="text" className="p34-form-field w-input" maxLength="256"
                                 name="trading_address_line_2" placeholder="" id="name-trading_address_line_2"
                          />
                          <ErrorMessage
                            errors={errors}
                            touched={touched}
                            name="trading_address_line_2"
                          />
                        </div>
                        <div className="p34-form-field-block">
                          <label htmlFor="name-4" className="p34-form-field-label">County</label>
                          <Field
                            type="text" className="p34-form-field w-input" maxLength="256"
                            name="trading_county"
                            id="trading_county"
                          />
                          <ErrorMessage
                            errors={errors}
                            touched={touched}
                            name="trading_county"
                          />
                        </div>
                        <div className="p34-form-field-block">
                          <label htmlFor="name-4" className="p34-form-field-label">
                            Postal Code
                          </label>
                          <Field
                            type="text" className="p34-form-field w-input" maxLength="256"
                            name="postal_code"
                            id="trading_postal_code"
                          />
                          <ErrorMessage
                            errors={errors}
                            touched={touched}
                            name="trading_postal_code"
                          />
                        </div>
                        <div className="p34-form-field-block">
                          <label htmlFor="name-4" className="p34-form-field-label">Date of Incorporation </label>
                          <Field
                            type="text" className="p34-form-field w-input" maxLength="256"
                            name="incorporation_date"
                            id="trading_incorporation_date"
                          />
                          <ErrorMessage
                            errors={errors}
                            touched={touched}
                            name="trading_incorporation_date"
                          />
                        </div>
                        <div className="p34-form-field-block">
                          <label htmlFor="name-4" className="p34-form-field-label">
                            Last Confirmation Statement Date
                          </label>
                          <Field
                            type="text"
                            className="p34-form-field w-input"
                            maxLength="256"
                            name="trading_last_confirmation_statement_date"
                            id="trading_last_confirmation_statement_date"
                          />
                          <ErrorMessage
                            errors={errors}
                            touched={touched}
                            name="trading_last_confirmation_statement_date"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <button type="submit" disabled={errors.length} className="p34-next-form w-button">Next</button>
                </div>
              )}
            </Form>
          )
        }}
    </Formik>
  
  )
}
