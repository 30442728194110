import { connect } from 'react-redux';
import { Checkout } from './checkout';
import { actions as uiActions } from "../../actions/ui";
import { actions as authActions} from "../../actions/auth";

const mapStateToProps = state => ({
  basket: state.auth.basket,
  shipping: state.ui.shipping,
  account: state.auth.account,
  userCountry: state.ui.country,
});

const mapDispatchToProps = {
  setBasket: authActions.setBasket,
  toggleCart: uiActions.toggleCartModal,
  setShipping: uiActions.setShipping,
  setRefresh: uiActions.setRefreshCart,
  setAccountToken: authActions.setAccountToken,
  setCountry: uiActions.setCountry,
  setTaxMethod: uiActions.setTaxMethod,
};

export const CheckoutContainer = connect(mapStateToProps, mapDispatchToProps)(Checkout);
