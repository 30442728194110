import React from "react";
import {post} from "../../api/api-caller";
import {END_POINTS} from "../../api";
import {toast} from "react-toastify";
import { Formik, Field, Form } from "formik";


export const ChangePassword = () => {

  const submitChangePassword = (values) => {
    post(END_POINTS.CHANGE_PASSWORD, values)
        .then((resp) => {
          if(resp){
            toast.success('Thank you! Your Password has been Updated.')
          }
        })
        .catch()
  }
  return (
      <div className="p19-body-wrapper">
        <div className="p19-reset-wrapper">
          <div className="p19-new-password-form w-form">
            <Formik onSubmit={submitChangePassword} initialValues={{old_password:"", new_password1:"", new_password2:""}}>
              <Form
                  id="wf-form-Reset-Form"
                  name="wf-form-Reset-Form"
                  data-name="Reset Form"
                  className="p19-form"
              >
                <div className="p19-form-title">Select a New Password</div>
                <Field
                    type="password"
                    className="p19-new-password-field-1 w-input"
                    maxLength="256"
                    name="old_password"
                    data-name="Old Password"
                    placeholder="Old Password"
                    id="old-password-2"
                    required=""
                />
                <Field
                    type="password"
                    className="p19-new-password-field-1 w-input"
                    maxLength="256"
                    name="new_password1"
                    data-name="New Password"
                    placeholder="New Password"
                    id="New-Password-2"
                    required=""
                />
                <Field
                    type="password"
                    className="p19-new-password-field-2 w-input"
                    maxLength="256"
                    name="new_password2"
                    data-name="New Password 2"
                    placeholder="Retype Password"
                    id="New-Password"
                    required=""
                />
                <input
                    type="submit"
                    value="Save Password"
                    data-wait="Please wait..."
                    className="p19-save-password-button w-button"
                />
              </Form>

            </Formik>

          </div>
        </div>
      </div>

  );
}



