import {useHistory, useLocation} from "react-router-dom";
import {CartSummaryItem} from "../../checkout/basket-item";
import {DiscountField} from "../discount";
import React, {useState} from "react";
import {ROUTES} from "../../../utils/values";

export const SummaryCard = ({
  basket,
  values: fromData,
  isLoading,
  shipping,
  setFieldValue,
  isLocal,
  account,
  toggleCart,
  showPassword,
}) => {
  
  const history = useHistory();
  const location = useLocation();
  const isCheckout = location.pathname === ROUTES.CHECKOUT;
  const totalDiscount = basket?.voucher_discounts?.length
    ? basket?.voucher_discounts.reduce((total, { amount }) => total + parseFloat(amount), 0)
    : 0;
  const {
    shipping_price_tax,
    shipping_price,
  } = fromData;
  
  const openCartModal = () => {
    toggleCart()
  }
  const calculateTotal  = () => {
    if(isLocal){
      return shipping_price
        ? (
          parseFloat(basket?.total_incl_tax) +
          parseFloat(shipping_price_tax) +
          parseFloat(shipping_price)
        ).toFixed(2)
        : parseFloat(basket?.total_incl_tax).toFixed(2);
    }
    else{
      const add_import =  fromData.tax_handling_method === 'all_inclusive' ? parseFloat(basket?.import_charges) : 0.00
      return shipping_price
        ? (
          parseFloat(basket?.total_incl_tax) +
          parseFloat(shipping_price) +
          add_import
        ).toFixed(2)
        : (parseFloat(basket?.total_incl_tax) + add_import).toFixed(2);
    }
  }
  
  return (
    <div className="p5-right-checkout-block">
      <h4 className="p5-right-bar-title">Order Summary
        {showPassword &&
        <>
          {" "}
          <a href="#" className="link-8" onClick={() => openCartModal()}>Edit Cart</a>
        </>
        }
      </h4>
      <div className="p5-cart-summary-block">
        {basket?.lines?.map((line) => (
          <CartSummaryItem
            item={line}
          />
        ))}
      </div>
      {
        isCheckout ? (
          <div className="p5-discount-block">
            {
              !basket?.voucher_discounts?.length ? (
                <DiscountField className='p5-discount-field'/>
              ) : 'Discount Applied'
            }
          </div>
        ) : null
      }
  
      <div className="p5-subtotal-block">
        <div className="p5-order-summary-text">Subtotal</div>
        <div className="p5-subtotal-price">£{basket?.total_excl_tax_excl_discounts}</div>
      </div>
      
      {parseFloat(totalDiscount) > 0 &&
        <div className="p5-delivery-total-block">
          <div className="p5-order-summary-text">Discount</div>
          <div className="p5-delivery-price">£{totalDiscount}</div>
        </div>
      }
      <div className="p5-delivery-total-block">
        <div className="p5-order-summary-text">Delivery</div>
        <div className="p5-delivery-price">
          £{shipping_price || 0}
        </div>
      </div>
      
      {!isLocal ? (
        <div className="p5-vat-total-block">
          <div className="p5-order-summary-text">Import</div>
          <div className="p5-vat-price">
            £{fromData.tax_handling_method === 'all_inclusive' ? basket?.import_charges : 0.00}
          </div>
        </div>
      ): null}
      {isLocal ? (
        <div className="p5-vat-total-block">
        <div className="p5-order-summary-text">VAT</div>
        <div className="p5-vat-price">
          £
          {shipping_price_tax && parseFloat(basket.total_tax) > 0
            ? (
              parseFloat(basket.total_tax) + parseFloat(shipping_price_tax)
            ).toFixed(2)
            : parseFloat(basket.total_tax).toFixed(2)}
        </div>
      </div>) : null }
      
      <div className="p5-grand-total-block">
        <div className="p5-grand-total-title">Total</div>
        <div className="p5-grandtotal-price">
          £
          {calculateTotal()}
        </div>
      </div>
      {(!(account?.token) && showPassword )&&
        <>
          <p className="p5-account-creation-text">
            An account will be automatically created for you, so you can view Invoices & upload artwork.
          </p>
          <div className="p5-password-block">
            <input
              type="password"
              className="p5-account-password w-input"
              maxLength="256"
              name="password"
              data-name="password"
              placeholder="Set Password"
              id="checkout-password"
              onChange={e => setFieldValue('password', e.target.value)}
            />
          </div>
          <p className="p5-terms-text">
            By continuing you agree to our
            {" "}
            <a href="https://www.justbulk.co.uk/terms" target="_blank" className="link-7">
               Terms &amp;  Conditions
            </a>
          </p>
        </>
      }
     
      {
        isCheckout ? (
          <input
            type="submit"
            disabled={isLoading}
            value={isLoading ? "Please Wait..." : "Confirm & Pay "}
            className="p5-pay-button w-button"
          />
        ) : (
          <button
            className="p7-download-pdf-button w-button"
            onClick={() => {
              let array = shipping.invoice.split("/");
              const id = array[shipping.invoice.split("/").length - 2];
              history.push(`${ROUTES.ACCOUNT_ORDER_HISTORY}/${id}`);
            }}
          >
            View Invoice
          </button>
        )
      }
    </div>
  );
}
