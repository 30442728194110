import { connect } from 'react-redux';

import { Login } from './login';
import { actions } from '../../../actions/auth';

const mapStateToProps = state => ({
  token: state.auth.account.token,
});

const mapDispatchToProps = {
  setAccountToken: actions.setAccountToken,
};

export const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(Login);
