import React, { useEffect, useState } from "react";
import { ProductTile } from "../ui/product-tile";
import { Link, useParams } from "react-router-dom";
import { get } from "../../api/api-caller";
import { END_POINTS } from "../../api";
import { ROUTES } from "../../utils/values";
import $ from "jquery";
import MetaDecorator from "../../utils/meta-decorator";
import Markdown from "markdown-to-jsx";

export const Categories = ({ breadcrumbs, setBreadCrumbs }) => {
  let { slug, sub } = useParams();

  const [selectedCat, setSelectedCat] = useState(slug || null);
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [meta , setMeta] = useState(null);

  useEffect(() => {
    get(END_POINTS.ROOT_CATEGORIES, setIsLoading)
      .then((resp) => {
        setCategories(resp);
        !selectedCat && setSelectedCat(resp[0].slug);
        setMeta(resp[0].meta_description);
      })
      .catch((err) => console.error(err));
  }, []);

  const [subCategories, setSubCategories] = useState([]);

  useEffect(() => {
    selectedCat &&
      get(
        `${END_POINTS.ROOT_CATEGORIES}${selectedCat}/?page_size=30`,
        setIsLoading
      )
        .then((resp) => {
          setSubCategories(resp);
          $(`.sub-category-accordian_${selectedCat}`).slideDown();
        })
        .catch((err) => console.error(err));
  }, [selectedCat]);

  useEffect(() => {
    if (!slug) {
      get(END_POINTS.ROOT_CATEGORIES, setIsLoading)
        .then((resp) => {
          setCategories(resp);
          !selectedCat && setSelectedCat(resp[0].slug);
        })
        .catch((err) => console.error(err));
    } else {
      slug !== selectedCat && setSubCategories([]);
      setSelectedCat(slug);
    }
  }, [slug]);

  const [products, setProducts] = useState([]);

  useEffect(() => {
    sub &&
      get(`${END_POINTS.PRODUCTS}/${sub}?page_size=30`, setIsLoading)
        .then((resp) => {
          if(resp.meta_description)
            setMeta(resp.meta_description.trimLeft().trimRight())
          else setMeta(null);
          setProducts(resp.results);
        })
        .catch((err) => console.error(err));
  }, [sub]);

  return (
    <>
      {meta ?
        <Markdown
          options={{
            overrides: {
              MetaDecorator: {
                component: MetaDecorator,
              },
            },
          }}
        >
          {meta}
        </Markdown> :
        <MetaDecorator description="Just Bulk is the home of consumer wholesale. A retail revolution." title="Just Bulk" />
      }
      <div className="p8-category-wrapper">
        <div className="p8-left-wrapper">
          <aside className="p8---side-bar-copy seperator">
            <div className="p8---sidebar-block">
              <h4 className="p8-cat-title">Categories</h4>
              <div className="p8-left-menu">
                <div className="p8-side-menu-accordian-block">
                  {categories.map((cat) => (
                      <div key={`categories-side-cat-${cat.slug}`}>
                        <div>
                          <Link
                              to={`${ROUTES.CATEGORIES}/${cat.slug}`}
                              className="p8-sidemenu-category-title"
                              style={{
                                textAlign: "end",
                                textDecoration: "none",
                                width: "100%",
                              }}
                              onClick={() => {
                                $(`.sub-category-accordian_${cat.slug}`).slideToggle();
                                setMeta(cat.meta_description)
                              }}
                          >
                            {cat.name + " +"}
                          </Link>
                        </div>
                        <div
                            className={`sub-category-accordian_${cat.slug}`}
                        >
                          <div className={`subcat_${cat.slug}`}>
                            {cat.slug === selectedCat &&
                            subCategories.map((subCat) => (
                                <Link
                                    key={`categories-side-subcat-${subCat.slug}`}
                                    to={`${ROUTES.CATEGORIES}/${selectedCat}/${subCat.slug}`}
                                    className="p8-side-menu-sub-category-title"
                                >
                                  {subCat.name}
                                </Link>
                            ))}
                          </div>
                        </div>
                      </div>
                  ))}
                </div>
              </div>
            </div>
          </aside>
        </div>
        <div className="p8-right-wrapper">
          <div className="w-layout-grid p8-product-grid">
            {!isLoading ? (
                !sub ? (
                    subCategories &&
                    subCategories.map((subCat) => (
                        <ProductTile
                            key={`categories-subcat-${subCat.slug}`}
                            title={subCat.name}
                            to={`${ROUTES.CATEGORIES}/${slug}/${subCat.slug}`}
                            desc={subCat.description}
                            img={subCat.image}
                            price={subCat.starting_from}
                        />
                    ))
                ) : products.length ? (
                    products.map((prod) => (
                        <ProductTile
                            key={`categories-product-${prod.slug}`}
                            isProduct={true}
                            title={prod.title}
                            to={`${ROUTES.CATEGORIES}/${selectedCat}/${sub}/${prod.slug}`}
                            img={prod.images.length ? prod.images[0].original : null}
                            price={prod.price_starting_from}
                        />
                    ))
                ) : (
                    <>
                      <div
                          id="w-node-87729ebcdc70-07ca1e79"
                          className="p8---product-frame"
                      >
                        No data available
                      </div>
                    </>
                )
            ) : (
                <>
                  <div
                      id="w-node-87729ebcdc70-07ca1e79"
                      className="p8---product-frame"
                  >
                    Loading...
                  </div>
                </>
            )}
          </div>
        </div>
      </div>

    </>
  );
};
