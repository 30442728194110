import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { get } from "../../api/api-caller";
import { END_POINTS } from "../../api";
import { Link } from "../ui/Link";
import { ROUTES } from "../../utils/values";

export const Search = () => {
  let { query } = useParams();
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    get(`${END_POINTS.SEARCH}?keyword=${query}`, setIsLoading)
      .then(({ data }) => {
        const mappedData = [];
        data.products.length && mappedData.push(
          data.products.map((prod) => (
            <div className="p4--search-results-block">
              <div className="p4-search-result">
                <Link to={`${ROUTES.CATEGORIES}${prod.product_url}`} className="p4-search-result-title">
                  {prod.title}
                </Link>
                <div className="p4-search-result-url">{prod.slug}</div>
                <p className="p4-search-result-snippet">{prod.description}</p>
              </div>
            </div>
          ))
        );
        data.categories.length && mappedData.push(
          data.categories.map((cat) => (
            <div className="p4--search-results-block">
              <div className="p4-search-result">
                <Link to={`${ROUTES.CATEGORIES}${cat.category_url}`} className="p4-search-result-title">
                  {cat.name}
                </Link>
                <div className="p4-search-result-url">{cat.slug}</div>
                <p className="p4-search-result-snippet">{cat.description}</p>
              </div>
            </div>
          ))
        );
        data.blogs.length && mappedData.push(
          data.blogs.map((blog) => (
            <div className="p4--search-results-block">
              <div className="p4-search-result">
                <Link to={`#`} className="p4-search-result-title">
                  {blog.title}
                </Link>
                <div className="p4-search-result-url">{blog.slug}</div>
                <p className="p4-search-result-snippet">
                  {blog.short_description}
                </p>
              </div>
            </div>
          ))
        );
        data.faqs.length && mappedData.push(
          data.faqs.map((faq) => (
            <div className="p4--search-results-block">
              <div className="p4-search-result">
                <Link to={`#`} className="p4-search-result-title">
                  {faq.question}
                </Link>
                <p className="p4-search-result-snippet">{faq.answer}</p>
              </div>
            </div>
          ))
        );
        setSearchResults(mappedData);
      })
      .catch((err) => console.error(err));
  }, [query]);
  return (
    <div className="p4-search-wrapper">
      <h1 className="p4-page-title">Search Results</h1>
      {isLoading ? 'Please wait...' : searchResults.length ? [...searchResults] : 'No Results Found'}
    </div>
  );
};
