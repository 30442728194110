import React, { useState } from "react";
import { ContactForm } from "./form";
import { post } from "../../api/api-caller";
import { END_POINTS } from "../../api";
import { ROUTES } from "../../utils/values";
import { Link } from "react-router-dom";
import { resetValues } from "../../utils";
import { toast } from "react-toastify";
import { reportEvent } from "../../utils";

export const Contact = () => {
  const [isSuccess, setSuccess] = useState(false);

  const contactApi = ({ data, setSubmitting, resetForm }) => {
    post(END_POINTS.CONTACT_US, data)
      .then((resp) => {
        if(resp){
          resetForm(resetValues(data));
          setSubmitting(false);
          setSuccess(true);
          reportEvent('Contact', 'Submit', 'Contact Page Form')
          toast.success("Thank you for contacting us.");
        }
      })
      .catch((err) => {
        alert("Oops! Something went wrong while submitting the form.");
        setSubmitting(false);
      });
  };

  return (
    <div className="p3---contact-container">
      <div className="w-layout-grid p3---contact-grid">
        <aside
          id="w-node-54eee580db75-225965cf"
          className="p3---contact-side-bar seperator"
        >
          <div className="p3---sidebar-block">
            <h4 className="contact-sidebar-title">Address</h4>
            <div>
              Garden Studios
              <br />71-75 Shelton Street,
              <br />
              Covent Garden,
              <br />
              London, WC2H 9JQ
            </div>
          </div>
          <div className="p3---sidebar-block">
            <h4 className="contact-sidebar-title">Email Us</h4>
            <a
              href="mailto:company@domain.com?subject=You&#x27;ve%20got%20a%20new%20email"
              className="sidebar-link"
            >
              info@justbulk.co.uk
            </a>
          </div>
          <div className="p3---sidebar-block">
            <h4 className="contact-sidebar-title">Give Us A Call</h4>
            <a href="tel:815-515-2603" className="sidebar-link">
              0208 016 2108
            </a>
          </div>
          <div className="p3---sidebar-block">
            <h4 className="contact-sidebar-title">FAQ</h4>
            <div>
              Did you already take a look at our{" "}
              <Link to={ROUTES.FAQS}>FAQ</Link>? Chances are you&#x27;ll find
              your answer there.
            </div>
          </div>
        </aside>
        <ContactForm onSubmit={contactApi} isSuccess={isSuccess} />
      </div>
    </div>
  );
};
