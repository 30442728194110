import { types } from '../../actions/ui';
import Cookies from "js-cookie"

const INITIAL_STATE = {
  cartVisibility: false,
  breadcrumbs: [],
  shipping: {},
  refreshCart: false,
  customQuoteModal: false,
  showCookieModal: !Cookies.get('cookies-accept'),
  country: "GBR",
  taxHandlingMethod:"",
  showFreeSampleModal: false,
  showPouchQuotationForm: false,
};

export const uiReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.UI_TOGGLE_CART_MODAL:
      return {
        ...state,
        cartVisibility: !state.cartVisibility
      };
    case types.UI_SET_BREADCRUMBS:
      return {
        ...state,
        breadcrumbs: [ ...action.data ],
      };
    case types.UI_SET_SHIPPING:
      return {
        ...state,
        shipping: action.data,
      };
    case types.UI_SET_REFRESH_CART:
      return {
        ...state,
        refreshCart: !state.refreshCart,
      };
    case types.UI_TOGGlE_CUSTOM_QUOTE_MODAL:
      return {
        ...state,
        customQuoteModal: action.data,
      };
    case types.UI_TOGGlE_COOKIE_MODAL:
      return {
        ...state,
        showCookieModal: action.data,
      };
    case types.UI_SET_COUNTRY:
      return {
        ...state,
        country: action.data,
      };
    case types.UI_SET_TAX_METHOD:
      return {
        ...state,
        taxHandlingMethod: action.data,
      };
    case types.UI_TOGGLE_FREE_SAMPLE_MODAL:
      return {
        ...state,
        showFreeSampleModal: action.data,
      };
    case types.UI_TOGGLE_POUCH_QUOTATION_MODAL:
      return {
        ...state,
        showPouchQuotationForm: action.data,
      };
    default:
      return state;
  }
};
