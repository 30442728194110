import React, {useState} from "react";
import {get, post} from "../../../api/api-caller";
import {END_POINTS} from "../../../api";
import { toast } from "react-toastify";
import {useDispatch} from "react-redux";
import {setRefreshCart} from "../../../actions/ui/actions";

export const DiscountField = ({ className }) => {
  const dispatch = useDispatch();

  const [value, setValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const applyDiscount = () => {
    setIsLoading(true);
    post(END_POINTS.ADD_VOUCHER, { vouchercode: value })
      .then((resp) => {
        get(END_POINTS.BASKET)
          .then((resp) => {
            dispatch(setRefreshCart());
            toast.success('Discount applied successfully');
          })
          .catch(() => {});
      })
      .finally(() => setIsLoading(false));
  };
  return (
    <>
      <input
        type="text"
        className={`${className} w-input`}
        maxLength="256"
        name="field-2"
        data-name="Field 2"
        placeholder="Enter Discount Code"
        id="field-discount-code"
        value={value}
        onChange={e => setValue(e.target.value) }
      />
      <a
        href="#"
        className="p5-discount-button w-button"
        onClick={() => applyDiscount()}
      >
        { isLoading ? '...' : 'Apply' }
      </a>
    </>
  );
}
