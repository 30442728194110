import React from "react";
import PropTypes from "prop-types";
import { formikEnhancer } from "./form-utils";

const Form = ({
  handleChange,
  handleBlur,
  handleSubmit,
  isValid,
  isSubmitting,
  errors,
  values,
  resetPassword,
  forgotClicked
}) => (
  <form
    id="wf-form-Login_form"
    name="wf-form-Login_form"
    data-name="Login_form"
    data-redirect="versiony.com"
    className="p6-login-form-sub-block"
    onSubmit={handleSubmit}
  >
    <input
      className="p6-login-email-field w-input"
      maxLength="256"
      placeholder="Email Address"
      autoComplete="off"
      id="username"
      name="username"
      type="text"
      onChange={handleChange}
      onBlur={handleBlur}
      style={{backgroundColor: errors.email || forgotClicked ? '#ffdede' : 'transparent'}}
      required
    />
    <input
      autoComplete="off"
      className="p6-login-password-field w-input"
      data-name="Password"
      id="password"
      maxLength="256"
      name="password"
      onBlur={handleBlur}
      onChange={handleChange}
      placeholder="Password"
      required=""
      type="password"
      style={{backgroundColor: errors.password ? '#ffdede' : 'transparent'}}
    />
    <input
      type="submit"
      value={isSubmitting ? "Please wait..." : "Login"}
      className="p6-login-button w-button"
      disabled={isSubmitting}
    />
    <div className="p6-forgot-password-block">
      <a href="#">
        <span className="text-span-2" onClick={() => resetPassword(values)}>Forgot Password</span>
      </a>
    </div>
  </form>
);

Form.propTypes = {
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isLogging: PropTypes.bool.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
};

export const LoginForm = formikEnhancer(Form);

LoginForm.propTypes = {
  isLogging: PropTypes.bool.isRequired,
  forgotClicked: PropTypes.bool.isRequired,
  attemptLogin: PropTypes.func.isRequired,
};
