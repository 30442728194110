import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { get } from "../../api/api-caller";
import { END_POINTS } from "../../api";
import { ROUTES } from "../../utils/values";

export const Pages = (props) => {
  const paths = props.location.pathname.split("/");
  const slug = paths[paths.length - 1];

  const [pageData, setPageData] = useState({});
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    get(`${END_POINTS.PAGES}/${slug}/`)
      .then((resp) => {
        setPageData(resp);
      })
      .catch(() => {
        setIsError(true);
      });
  }, []);

  return !isError ? (
    <div className="p14-wrapper" style={{ width: "100%" }}>
      <h1>{pageData.title}</h1>
      <div dangerouslySetInnerHTML={{__html: pageData.content}} />
    </div>
  ) : (
    <Redirect to={ROUTES.NOT_FOUND} />
  );
};
