import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { END_POINTS } from "../../../api";
import { get } from "../../../api/api-caller";
import * as moment from "moment";
import { OrderHistoryFilter } from "./form/form";
import { OrderFilter } from "./form/orderFilter";
import { ROUTES } from "../../../utils/values";

export const OrderHistory = () => {
  const history = useHistory();
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    get(END_POINTS.ORDERS, setIsLoading)
      .then(({ results }) => {
        setOrders(results);
        setIsLoading(false);
      })
      .catch((err) => alert(err));
  }, []);

  const downloadInvoice = (invoiceUrl) => {
    let array = invoiceUrl.split("/");
    const id = array[invoiceUrl.split("/").length - 1];
    history.push(`${ROUTES.ACCOUNT_ORDER_HISTORY}/${id}`);
  };

  return (
    <>
      <div className="p21-title-block">
        <h4 className="p21-right-wrapper-title">Order History</h4>
      </div>
      <div className="p22-filter-wrapper">
        <OrderHistoryFilter
          attemptSubmit={({ data, setSubmitting }) => {
            if (!data.start_date) alert("start filter value not selected");
            const end = data.end_date ? data.end_date : new Date().getDate();
            const endpoint = `${END_POINTS.ORDERS}?start_date=${data.start_date}&end_date=${end}`;
            get(endpoint)
              .then(({ results }) => {
                setOrders(results);
                setSubmitting(false);
              })
              .catch(() => {
                setSubmitting(false);
              });
          }}
        />
        <OrderFilter
          attemptSubmit={({ data, setSubmitting }) => {
            const endpoint = `${END_POINTS.ORDERS}?order_number=${data.order_number}`;
            get(endpoint)
              .then(({ results }) => {
                setOrders(results);
                setSubmitting(false);
              })
              .catch(() => {
                setSubmitting(false);
              });
          }}
        />
      </div>

      <div className="p22-grid-wrapper">
        <div className="p22-table-title-wrapper">
          <div className="p22-order-column-block">
            <div className="p22-column-title-block">
              <div className="p22-column-title">Order Reference</div>
            </div>
          </div>
          <div className="p22-order-total-column">
            <div className="p22-order-title-block">
              <div className="p22-order-title">Order Total (Inc Tax)</div>
            </div>
          </div>
          <div className="p22-order-date-column">
            <div className="p22-order-date-block">
              <div className="p22-order-date-title">Order Date (dd/mm/yy)</div>
            </div>
          </div>
          <div className="p22-link-column">
            <div className="p22-link-block">
              <div className="p22-link-title">Invoice</div>
            </div>
          </div>
        </div>
        {isLoading
          ? "Loading..."
          : !orders.length
          ? "No data found"
          : orders.map((order) => (
              <div className="p22-row-wrapper">
                <div className="p22-order-column-block">
                  <div className="p22-column-title-block">
                    <div className="p22-order-ref">{order.number}</div>
                  </div>
                </div>
                <div className="p22-order-total-column">
                  <div className="p22-order-title-block">
                    <div className="p22-order-total">
                      £{order.total_incl_tax}
                    </div>
                  </div>
                </div>
                <div className="p22-order-date-column">
                  <div className="p22-order-date-block">
                    <div className="p22-order-date">
                      {moment(order.date_placed).format("DD/MM/YYYY")}
                    </div>
                  </div>
                </div>
                <div className="p22-link-column">
                  <div className="p22-link-block">
                    <a
                      href="#"
                      className="p22-invoice-button w-button"
                      onClick={() => downloadInvoice(order.invoice)}
                    >
                      View
                    </a>
                  </div>
                </div>
              </div>
            ))}
      </div>
    </>
  );
};
