import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {post} from "../../../api/api-caller";
import {END_POINTS} from "../../../api";
import {toast} from "react-toastify";
import {categoriesToSlugMapping} from "../../../store/dataHelper";
import {Link} from "react-router-dom";
import {ROUTES} from "../../../utils/values";

export const ItemCard = ({
                           item,
                           setRefresh,
                           toggleCustomQuoteModal,
                           getCurrentPageItem
                         }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [volumetricPrice, setVolumetricPrice] = useState({});
  const [addOn, setAddOn] = useState({});
  const addToBasket = (options, item) => {
    post(END_POINTS.ADD_TO_BASKET, options)
        .then(() => {
          setRefresh();
          window.dataLayer.push({...options, ...item, ...volumetricPrice, event:'add_to_cart'});
        })
        .catch((err) => {
          alert(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
  };
  const removeFromSaved = (id) => {
    let data = {
      product_id: id
    }
    post(END_POINTS.GET_SAVED_PRODUCTS, data, 'DELETE')
        .then(() => {
          toast.success('product removed from saved list')
          getCurrentPageItem();
        })
        .catch((err) => {
          alert(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
  }
  const viewData = (item) => {
    return `${ROUTES.CATEGORIES}/${categoriesToSlugMapping(item.categories[0])}/${item.slug}`
  }
  useEffect(() => {
    (item.is_volumetric_pricing && item.volumetric_prices[0]) &&
    setVolumetricPrice({
      total:
          item.volumetric_prices[0].package_quantity *
          item.volumetric_prices[0].price_excl_tax,
      perUnit: item.volumetric_prices[0].price_excl_tax,
      qty: item.volumetric_prices[0].package_quantity,
      subtotal:
          item.volumetric_prices[0].package_quantity *
          item.volumetric_prices[0].price_excl_tax,
      id: item.volumetric_prices[0].id,
    });

  }, {});
  return (
      <div className="p26-watched-item-wrapper">
        <div className="p26-watched-item-block">
          <img src={item.images.length > 0 ? item.images[0].original : ''} alt="" className="p26-product-image"/>
          <div className="p26-product-info-block">
            <div className="p26-product-title">{item.title}</div>
            <div className="p26-product-sku">SKU: {item.sku}</div>
            {item.is_volumetric_pricing && (
                <>
                  {item.additional_options && item.additional_options.length ? (
                      <select
                          className="p26-price-quantity-dropdown w-dropdown p26-dropdown-text"
                          onChange={({target: {value}}) => {
                            if (value === "0") {
                              setAddOn({});
                              setVolumetricPrice({
                                ...volumetricPrice,
                                subtotal: volumetricPrice.total,
                              });
                              return;
                            }
                            const [price, id] = value.split(",");
                            setAddOn({
                              id,
                              price: parseFloat(price),
                            });
                            setVolumetricPrice({
                              ...volumetricPrice,
                              subtotal:
                                  (volumetricPrice.perUnit + parseFloat(price)) *
                                  volumetricPrice.qty,
                            });
                          }}
                      >
                        <option className="w-dropdown-link p26-dropdown-list w-dropdown-list" value="0">
                          None
                        </option>
                        {item.additional_options &&
                        item.additional_options.map((option) => (
                            <option
                                className="w-dropdown-link p26-dropdown-list w-dropdown-list"
                                value={`${option.additional_per_unit_price},${option.id}`}
                            >
                              {`${option.text} - £${option.additional_per_unit_price}`}
                            </option>
                        ))}
                      </select>
                  ) : (
                      ""
                  )}
                  {item.is_volumetric_pricing && (
                      <select
                          className="p26-price-quantity-dropdown w-dropdown p26-dropdown-text"
                          onChange={({target: {value}}) => {
                            const parts = value.split(",");
                            setVolumetricPrice({
                              ...volumetricPrice,
                              total: parts[0] * parts[1],
                              perUnit: parseFloat(parts[1]),
                              qty: parseFloat(parts[0]),
                              subtotal:
                                  parts[0] * (parseFloat(parts[1]) + (addOn.price || 0)),
                              id: parts[2],
                            });
                          }}
                      >
                        {item.volumetric_prices.length > 0 ?
                            item.volumetric_prices.map((option) => (
                                <option
                                    className="w-dropdown-link p26-dropdown-list w-dropdown-list"
                                    value={`${option.package_quantity},${option.price_excl_tax},${option.id}`}
                                >
                                  {`${option.name} - ${option.package_quantity} Units - £${option.price_excl_tax}`}
                                </option>
                            )) :
                            <option className="w-dropdown-link p26-dropdown-list w-dropdown-list" value="0">
                              No Options Available
                            </option>
                        }
                      </select>
                  )}

                </>
            )}

          </div>
        </div>
        <div className="p26-price-block">
          <div className="p26-in-stock-wrapper">
            <div className="p26-in-stock-dot"/>
            <div className="p26-in-stock-text">In Stock - {item.stock_status}</div>
          </div>
          <div className="p26-item-price">Item Price:
            £{item.is_volumetric_pricing
                ? parseFloat(volumetricPrice.subtotal).toFixed(2)
                : item.price && parseFloat(item.price.incl_tax).toFixed(2)}
          </div>
          <div className="p26-unit-price">
            {item.is_volumetric_pricing && `Unit Price: £ ${volumetricPrice.perUnit ? volumetricPrice.perUnit : 'N/A'} P/Unit`}
          </div>
          <Link
              to={viewData(item)}
              className="p26-view-product-button w-button"
          >
            View Product
          </Link>
        </div>
        <div className="p26-button-block">
          <a href="#" className="p26-add-to-cart-button w-button">Buy it Now</a>
          <a
              href="#"
              onClick={() => {
                const options = {url: item.url};
                if (Object.keys(volumetricPrice).length) {
                  options.package_id = volumetricPrice.id;
                }
                if (Object.keys(addOn).length) {
                  options.additional_option_id = addOn.id;
                }
                setIsLoading(true);
                addToBasket({...options}, item);
              }}
              className="p26-passive-button w-button"
          >
            {isLoading ? "Please wait..." : "Add to Cart"}
          </a>
          <a href="#" className="p26-passive-button w-button" onClick={() => {
            toggleCustomQuoteModal(true);
          }}>Custom Quote</a>
          <a href="#" className="p26-remove-item-button" onClick={() => removeFromSaved(item.id)}>Remove Item</a>
        </div>
      </div>
  )

};

ItemCard.propTypes = {
  item: PropTypes.object.isRequired,
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isLogging: PropTypes.bool.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  getCurrentPageItem: PropTypes.func.isRequired,
};