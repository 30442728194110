import React, {useEffect, useRef, useState} from "react";
import $ from "jquery";
import {Markup} from "interweave";
import {Link, useHistory, useLocation, useParams} from "react-router-dom";
import {get, post} from "../../api/api-caller";
import {END_POINTS} from "../../api";
import {toast} from "react-toastify";
import {ROUTES} from "../../utils/values";
import MetaDecorator from "../../utils/meta-decorator";
import {InfoTabs} from "./info-tabs";

const Diamond = require("../../assets/images/1Asset-15-100.jpg");

export const Product = ({setRefresh, toggleCustomQuoteModal, account, toggleFreeSampleModal}) => {
  const {productSlug} = useParams();
  const selectRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption] = useState(0);
  const [product, setProduct] = useState({});
  const [volumetricPrice, setVolumetricPrice] = useState({});
  const [addOn, setAddOn] = useState({});
  const [isSaved, setSaved] = useState(false);
  const [hidePricing, setHidePricing] = useState(!!account.token);
  
  const addToBasket = (options, product) => {
    post(END_POINTS.ADD_TO_BASKET, options)
      .then(() => {
        toast.success("Product added successfully");
        setRefresh();
        window.dataLayer.push({...options, ...product, ...volumetricPrice, event:'add_to_cart'});
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  
  const toggleImage = (id) => {
    for (let i = 0; i < product.images.length; i++) {
      $(`#w-tabs-0-data-w-pane-${i}`).removeClass("w--tab-active");
    }
    $(id).addClass("w--tab-active");
  };
  
  useEffect(() => {
    get(`${END_POINTS.PRODUCT_DETAIL}/${productSlug}/`)
      .then((result) => {
        setProduct(result);
        setSaved(result.is_saved);
        setHidePricing(!result.price && !account.token);
        result.is_volumetric_pricing &&
        setVolumetricPrice({
          total:
            result.volumetric_prices[0].package_quantity *
            result.volumetric_prices[0].price_excl_tax,
          perUnit: result.volumetric_prices[0].price_excl_tax,
          qty: result.volumetric_prices[0].package_quantity,
          subtotal:
            result.volumetric_prices[0].package_quantity *
            result.volumetric_prices[0].price_excl_tax,
          id: result.volumetric_prices[0].id,
        });
      })
      .then(() => {
        selectRef.selectedIndex = 0;
      })
      .catch((err) => {
        console.error(err);
      });
  }, [productSlug]);
  
  const saveProduct = (id) => {
    let data = {
      product_id: id,
    };
    post(END_POINTS.SAVE_PRODUCT, data)
      .then((resp) => {
        toast.success("Product saved to watch list");
        setSaved(true);
        setRefresh();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  
  let getStockStatus = (status) =>{
    console.log(status)
    if(status){
      if(status === "Out of Stock")
        return status;
      else
        return `In Stock - ${status}`
    }
  }
  
  return (
    <>
      <MetaDecorator title={product.title} description={product.description}/>
      <div className="p1-product-section-wrapper">
        <div className="p1-productpicwrapper">
          <div
            data-duration-in="300"
            data-duration-out="100"
            className="p1---tabbed-picture-block w-tabs"
          >
            <div className="p1---tabs-menu w-tab-menu">
              {product.images &&
              product.images.map((img, index) => (
                <a
                  data-w-tab={`Tab ${index}`}
                  className="p1---tablink w-inline-block w-tab-link"
                  id={`w-tabs-0-data-w-tab-${index}`}
                  onClick={() => {
                    toggleImage(`#w-tabs-0-data-w-pane-${index}`);
                  }}
                  role="tab"
                  aria-controls={`w-tabs-0-data-w-pane-${index}`}
                  aria-selected="false"
                  data-ol-has-click-handler=""
                  tabIndex="-1"
                >
                  <img src={img.original} alt="" className="image-6"/>
                </a>
              ))}
            </div>
            <div className="p1---tabs-content w-tab-content">
              {product.images &&
              product.images.map((img, index) => (
                <div
                  data-w-tab={`Tab ${index}`}
                  className={`p1---tabpane w-tab-pane ${
                    !index && "w--tab-active"
                  }`}
                  id={`w-tabs-0-data-w-pane-${index}`}
                  role="tabpanel"
                  aria-labelledby={`w-tabs-0-data-w-tab-${index}`}
                >
                  <img
                    src={img.original}
                    height="100"
                    alt=""
                    className="image"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="p1---product-details-block">
          <div className="p1---sku inline small">SKU</div>
          <div className="p1---sku-no">{product.sku}</div>
          <div className="p1---in-stock-wrapper">
            <div className={`${product.stock_status && product.stock_status === "Out of Stock" ? "p1---out-stock-dot" : "p1---in-stock-dot" }`}/>
            <div className={`${product.stock_status && product.stock_status === "Out of Stock" ? "p1---out-stock-text" : "p1---in-stock-text" }`}>
              {product.stock_status}
            </div>
          </div>
          <h1 className="p1---product-title">{product.title}</h1>
          {!hidePricing &&
            <div className="p1---product-price product-page sale">
              £
              {product.is_volumetric_pricing
                ? isNaN(parseFloat(volumetricPrice.subtotal).toFixed(2)) ? '0.00' : parseFloat(volumetricPrice.subtotal).toFixed(2)
                : product.price && (isNaN(parseFloat(product.price.incl_tax).toFixed(2)) ? '0.00' : parseFloat(product.price.incl_tax).toFixed(2))
              }
            </div>
          }
          {!hidePricing &&
            <div className="p1---unit-price product-page">
              {product.is_volumetric_pricing &&
              `£${isNaN(parseFloat(volumetricPrice.perUnit) +
                parseFloat(addOn.price || 0)) ? '0.00' :
                (
                  parseFloat(volumetricPrice.perUnit) +
                  parseFloat(addOn.price || 0)
                ).toFixed(2)} P/Unit`}
            </div>
          }
          <p className="p1---brief-product-details">
            <Markup content={product.short_description}/>
          </p>
          <a href="#" className="p1-sold-by-field">Sold by: <span className="text-span-8">{product.sold_by}</span></a>
          <div className="p1---dropdown-wrapper">
            {/*Variants*/}
            {product.product_variants && product.product_variants.length ? (
              <div className="additional-options-wrapper">
                <select
                  value={selectedOption}
                  className="additional-options"
                  onChange={({target: {value}}) => {
                    history.push(
                      `${location.pathname.split(productSlug)[0]}${value}`
                    );
                  }}
                >
                  <option
                    className="w-dropdown-link"
                    value="0"
                    disabled
                    selected
                  >
                    {product.title}
                  </option>
                  {product.product_variants &&
                  product.product_variants.map((option) => (
                    <option className="w-dropdown-link" value={option.slug}>
                      {option.title}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              ""
            )}
            {/*Additional options*/}
            {product.additional_options && product.additional_options.length ? (
              <div className="additional-options-wrapper">
                <select
                  className="additional-options"
                  onChange={({target: {value}}) => {
                    if (value === "0") {
                      setAddOn({});
                      setVolumetricPrice({
                        ...volumetricPrice,
                        subtotal: volumetricPrice.total,
                      });
                      return;
                    }
                    const [price, id] = value.split(",");
                    setAddOn({
                      id,
                      price: parseFloat(price),
                    });
                    setVolumetricPrice({
                      ...volumetricPrice,
                      subtotal:
                        (volumetricPrice.perUnit + parseFloat(price)) *
                        volumetricPrice.qty,
                    });
                  }}
                >
                  <option className="w-dropdown-link" value="0">
                    None
                  </option>
                  {product.additional_options &&
                  product.additional_options.map((option) => (
                    <option
                      className="w-dropdown-link"
                      value={`${option.additional_per_unit_price},${option.id}`}
                    >
                      {`${option.text} - £${option.additional_per_unit_price}`}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              ""
            )}
            
            {/*Prices*/}
            <div>
              {!hidePricing && product.is_volumetric_pricing && (
                <>
                  <div className="additional-options-wrapper">
                    <select
                      className="additional-options"
                      onChange={({target: {value}}) => {
                        const parts = value.split(",");
                        setVolumetricPrice({
                          ...volumetricPrice,
                          total: parts[0] * parts[1],
                          perUnit: parseFloat(parts[1]),
                          qty: parseFloat(parts[0]),
                          subtotal:
                            parts[0] *
                            (parseFloat(parts[1]) + (addOn.price || 0)),
                          id: parts[2],
                        });
                      }}
                    >
                      {product.volumetric_prices &&
                      product.volumetric_prices.map((option) => (
                        <option
                          className="w-dropdown-link"
                          value={`${option.package_quantity},${option.price_excl_tax},${option.id}`}
                        >
                          {`${option.name} - ${option.package_quantity} Units - £${option.price_excl_tax}`}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="p1---cart-sample-wrapper">
                    <a
                      href="#"
                      onClick={() => {
                        const options = {url: product.url};
                        if (Object.keys(volumetricPrice).length) {
                          options.package_id = volumetricPrice.id;
                        }
                        if (Object.keys(addOn).length) {
                          options.additional_option_id = addOn.id;
                        }
                        setIsLoading(true);
                        addToBasket({...options}, product);
                      }}
                      className="p1---add-to-cart w-button"
                    >
                      {isLoading ? "Please wait..." : "Add to Cart"}
                    </a>
                    {product.can_request_sample &&
                    <a
                      href="#"
                      className="p1---free-sample-button w-button"
                      onClick={() => toggleFreeSampleModal(true)}>
                      Request Free Sample
                    </a>
                    }
                  </div>
                </>
              )}
              {hidePricing &&
                <div style={{marginTop:'10px', marginBottom:'10px'}}>
                  <Link to={ROUTES.LOGIN} className="p1-hide-price-message">Sign Up for Free to View Wholesale Pricing</Link>
                </div>
              }
            </div>
          </div>
          <div className="p1---product-tick-block">
            <div className="p1---product-tick-rows">
              <img
                src="https://uploads-ssl.webflow.com/5ec7f38ac0ca9b25efc04d51/5ec7f38b1681b8c0989b8412_check.svg"
                alt=""
                className="p1---product-tick-icon"
              />
              <div className="p1---product-tick-text">
                {product.product_tick_1}
              </div>
            </div>
            <div className="p1---product-tick-rows">
              <img
                src="https://uploads-ssl.webflow.com/5ec7f38ac0ca9b25efc04d51/5ec7f38b1681b8c0989b8412_check.svg"
                alt=""
                className="p1---product-tick-icon"
              />
              <div className="p1---product-tick-text">
                {product.product_tick_2}
              </div>
            </div>
            <div className="p1---product-tick-rows">
              <img
                src="https://uploads-ssl.webflow.com/5ec7f38ac0ca9b25efc04d51/5ec7f38b1681b8c0989b8412_check.svg"
                alt=""
                className="p1---product-tick-icon"
              />
              <div className="p1---product-tick-text">
                {product.product_tick_3}
              </div>
            </div>
          </div>
          <span
            data-w-id="d2dddd30-95b4-578c-ab8a-4806237b7d52"
            onClick={() => {
              toggleCustomQuoteModal(true);
            }}
            className="p1---quote-button w-button"
          >
            Custom Quote
          </span>
          {account.token && isSaved && product.is_volumetric_pricing && (
            <a
              className="p1---save-to-watch-list-button w-button"
              onClick={() => toast.info("already saved in watchlist")}
            >
              Saved
            </a>
          )}
          {account.token && !isSaved && product.is_volumetric_pricing && (
            <a
              className="p1---save-to-watch-list-button w-button"
              onClick={() => saveProduct(product.id)}
            >
              Watch Product
            </a>
          )}
          {!account.token && (
            <Link
              to={ROUTES.LOGIN}
              className="p1---save-to-watch-list-button w-button"
            >
              Login to Save
            </Link>
          )}
        </div>
      </div>
      <InfoTabs product={product}/>
      {product.recommended_products && product.recommended_products.length ? (
        <div className="p1---recommended-products-wrapper">
          <div className="p1---recommended-prod-title-block">
            <h2 className="p1---recommended-prod-title">
              Recommended Products
            </h2>
          </div>
          <div className="p1---recommended-products-block">
            {product.recommended_products.map((rec_prod) => (
              <Link to={"#"} className="p1---recommended-product-frame">
                <img
                  src={
                    rec_prod.images.length
                      ? rec_prod.images[0].original
                      : Diamond
                  }
                  alt=""
                  className="image-8"
                />
                <div className="p1---recommend-prod-description">
                  {rec_prod.title}
                </div>
              </Link>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
