import { connect } from 'react-redux';

import { Signup } from './signup';
import { actions } from '../../../actions/auth';

const mapDispatchToProps = {
  setAccountInfo: actions.setAccountInfo,
  setAccountToken: actions.setAccountToken,
};

export const SignupContainer = connect(null, mapDispatchToProps)(Signup);
