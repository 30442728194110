import { connect } from 'react-redux';
import { Home } from './home';
import { actions } from '../../actions/auth';

const mapStateToProps = state => ({
  cart: (() => state.auth.cart)(),
});

const mapDispatchToProps = {
  setCart: actions.setCart,
};

export const HomeContainer = connect(mapStateToProps, mapDispatchToProps)(Home);
