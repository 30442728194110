import { connect } from 'react-redux';
import { Cart } from './cart';
import {actions as uiActions} from "../../actions/ui";
import {actions as authActions} from "../../actions/auth";

const mapStateToProps = state => ({
  basket: state.auth.basket,
});

const mapDispatchToProps = {
  setBasket: authActions.setBasket,
  toggleCart: uiActions.toggleCartModal,
  setRefresh: uiActions.setRefreshCart,
};

export const CartContainer = connect(mapStateToProps, mapDispatchToProps)(Cart);
