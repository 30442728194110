import {boolean, string} from "yup";

export const requiredNullableString = () => string().nullable().required("Field is required");

export const conditionallyRequiredString = (conditionKey) => string().nullable().when(conditionKey, {
  is: false,
  then: string().required("Field is required"),
});

export const getBillingFormValidations = () => ({
  billingAddress: boolean(),
  billing_country: conditionallyRequiredString("billingAddress"),
  billing_recipient_name: conditionallyRequiredString("billingAddress"),
  billing_recipient_phone: string().nullable()
  .when("billingAddress", {
    is: false,
    then: string()
      .test('internationalNumber', 'Phone must be in international format', (val = ' ') => val.charAt(0) === '+')
      .required("Field is required"),
  }),
  billing_line1: conditionallyRequiredString("billingAddress"),
  billing_line2: string().nullable(),
  billing_state: conditionallyRequiredString("billingAddress"),
  billing_line3: conditionallyRequiredString("billingAddress"),
  billing_postcode: conditionallyRequiredString("billingAddress"),
});

export const getShippingFormValidations = () => ({
  recipient_name: requiredNullableString(),
  recipient_phone: string()
    .nullable()
    .test('internationalNumber', 'Phone must be in international format', (val = ' ') => val.charAt(0) === '+')
    .required("Field is required"),
  line1: requiredNullableString(),
  line2: string().nullable(),
  state: requiredNullableString(),
  line3: requiredNullableString(),
  postcode: requiredNullableString(),
});
