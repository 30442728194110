import { types } from '../../actions/auth';

const INITIAL_STATE = {
  account: {
    token: null,
  },
  cart: [],
  basket: {},
  showActivationModal: false,
};

export const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.UI_SET_CART:
      return {
        ...state,
        cart: [ ...state.cart, action.data ]
      };
    case types.UI_SET_TOKEN:
      return {
        ...state,
        account: {
          ...state.account,
          ...action.data,
        }
      };
    case types.UI_SET_ACCOUNT_INFO:
      return {
        ...state,
        account: {
          ...state.account,
          ...action.data,
        }
      };
    case types.UI_SET_BASKET:
      return {
        ...state,
        basket: action.data,
      };
    case types.AUTH_RESET:
      return {
        ...state,
        account: { ...INITIAL_STATE.account },
      };
    case types.VERIFY_ACCOUNT:
      return {
        ...state,
        account: {
          ...state.account,
          email_verified: true,
          ...action.data,
        }
      };
    case types.TOGGLE_ACTIVATION_MODAL:
      return {
        ...state,
        showActivationModal: (state.account.token &&!state.account.email_verified ),
      };
    default:
      return state;
  }
};
