import React from "react";
import { Formik, Field, Form } from "formik";
import PropTypes from "prop-types";

const BillingAddress = ({
  handleSubmit,
  handleChange,
  handleBlur,
  address,
  countries,
  values,
  setFieldValue,
}) => {
  return (
    <Formik initialValues={address} onSubmit={handleSubmit} enableReinitialize>
      {(props) => (
        <div className="p23-billing-address-wrapper w-form">
          <Form
            id="email-form"
            name="email-form"
            data-name="Email Form"
            className="p23-billing-form"
          >
            <div className="p23-title-wrapper">
              <div className="p23-title-block">
                <div className="p23-address-block-title">Billing Address</div>
              </div>
            </div>
            <div className="p23-row-wrapper">
              <div className="p23-title-column-block">
                <div className="p23-inner-grid-labels">Address Line 1</div>
              </div>
              <div className="p23-address-field-column-block">
                <Field
                  type="text"
                  className="p23-bill---address-line-1-field w-input"
                  maxLength="256"
                  name="line1"
                  placeholder="Example Text"
                  id="field"
                  required=""
                />
              </div>
            </div>
            <div className="p23-row-wrapper">
              <div className="p23-title-column-block">
                <div className="p23-inner-grid-labels">Address Line 2</div>
              </div>
              <div className="p23-address-field-column-block">
                <Field
                  type="text"
                  className="p23-bill---address-line-2-field w-input"
                  maxLength="256"
                  name="line2"
                  placeholder="Example Text"
                  id="field-billing-2"
                  required=""
                />
              </div>
            </div>
            <div className="p23-row-wrapper">
              <div className="p23-title-column-block">
                <div className="p23-inner-grid-labels">Town / City</div>
              </div>
              <div className="p23-address-field-column-block">
                <Field
                  type="text"
                  className="p23-bill---town-field w-input"
                  maxLength="256"
                  name="line3"
                  placeholder="Example Text"
                  id="field-billing-3"
                  required=""
                />
              </div>
            </div>
            <div className="p23-row-wrapper">
              <div className="p23-title-column-block">
                <div className="p23-inner-grid-labels">Province / County</div>
              </div>
              <div className="p23-address-field-column-block">
                <Field
                  type="text"
                  className="p23-bill---county-field w-input"
                  maxLength="256"
                  name="state"
                  placeholder="Example Text"
                  id="field-billing-4"
                  required=""
                />
              </div>
            </div>
            <div className="p23-row-wrapper">
              <div className="p23-title-column-block">
                <div className="p23-inner-grid-labels">Postal Code</div>
              </div>
              <div className="p23-address-field-column-block">
                <Field
                  type="text"
                  className="p23-bill---postal-code-field w-input"
                  maxLength="256"
                  name="postcode"
                  placeholder="Example Text"
                  id="field-billing-5"
                  required=""
                />
              </div>
            </div>
            <div className="p23-row-wrapper">
              <div className="p23-title-column-block">
                <div className="p23-inner-grid-labels">Country</div>
              </div>
              <div className="p23-address-field-column-block">
                <Field
                  name="country"
                  as="select"
                  onChange={(e) => {
                    props.setFieldValue("country", e.target.value);
                  }}
                  onBlur={handleBlur}
                  required
                  className="p23-ship---county-field w-input"
                >
                  {countries.map((country) => (
                    <option
                      value={country.url}
                      selected={country.url === props.values.url}
                    >
                      {country.printable_name}
                    </option>
                  ))}
                </Field>
              </div>
            </div>
            <input
              type="submit"
              value="Save"
              data-wait="Please wait..."
              className="p23-address-save-button w-button"
            />
          </Form>
          <div className="w-form-done">
            <div>Your New Billing Address Has Been Saved!</div>
          </div>
          <div className="w-form-fail">
            <div>Oops! Something went wrong!</div>
          </div>
        </div>
      )}
    </Formik>
  );
};
BillingAddress.propTypes = {
  address: PropTypes.object,
  handleSubmit: PropTypes.func,
  countries: PropTypes.array,
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
};

export default BillingAddress;
