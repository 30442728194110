import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { ROUTES } from "../../utils/values";
import { SummaryCard } from "../ui/summary-card";

export const OrderConfirmation = ({ shipping: orderDetails, setShipping, userCountry }) => {
  let history = useHistory();
  const { basket = {}, shipping = {}, values = {} } = orderDetails;
  
  const localCountries = ['GBR', "WL", "NRD", "SCT" ]
  const checkIsLocal = () => {
    return userCountry ? localCountries.includes(userCountry) : false;
  }
  const [isLocal, setIsLocal] = useState(checkIsLocal());

  useEffect(() => {
    if (!Object.keys(orderDetails).length) {
      history.push(ROUTES.ROOT);
    }
  }, []);

  useEffect(() => {
    return () => {
      setShipping({});
    };
  }, []);

  if (!Object.keys(orderDetails).length) {
    return <Redirect to={ROUTES.ROOT} />;
  }
  
  return (
    <>
      <div className="p7-order-confirmation-alert">
        <div className="p7-order-confirmation-alert-text">
          Thanks for shopping! Your order is confirmed and will be processed as
          soon as possible. You&#x27;ll receive a confirmation email shortly.
          Don&#x27;t forget to check your spam folder.
        </div>
      </div>
      <div className="p7-checkout-wrapper">
        <div className="w-form">
          <form
            id="email-form"
            name="email-form"
            data-name="Email Form"
            className="p7-grid-wrapper"
          >
            <div className="w-layout-grid p7-order-grid">
              <div className="p7-left-checkout-block">
                <div className="w-commerce-commercecheckoutblockheader p7-left-block-header">
                  <h4 className="p7-confirmation-title">Customer Info</h4>
                </div>
                <fieldset className="w-commerce-commercecheckoutblockcontent p7-customer-info-wrapper">
                  <p className="p7-paragraph">
                    <span className="p7---company-name-span">
                      {shipping?.shipping_address?.title}
                    </span>
                    <br />
                    <span className="p7-last-name-span">
                      {`${shipping?.shipping_address?.first_name} ${shipping?.shipping_address?.last_name}`} <br />
                    </span>
                    <span className="p7-email-address">
                      {shipping?.shipping_address?.email}
                    </span>
                    <span className="p7-phone-number-span">
                      <br />
                      {shipping?.shipping_address?.phone_number}
                      <br />
                    </span>
                    <span className="p7-vat-span">
                      {shipping?.shipping_address?.vat_number}
                    </span>
                    <span className="p7-phone-number-span">
                      <br />‍
                    </span>
                  </p>
                </fieldset>
                <div className="w-commerce-commercecheckoutblockheader p5-left-block-header">
                  <h4 className="p7-confirmation-title">Address Details</h4>
                </div>
                <fieldset className="w-commerce-commercecheckoutblockcontent p5-address-block">
                  <p className="p7-paragraph">
                    <span className="p7---recipient-name-span">
                      {shipping?.shipping_address?.recipient_name}
                    </span>
                    <span className="p7-recipient-no-span">
                      <br />
                      {shipping?.shipping_address?.recipient_phone}
                      <br />
                    </span>
                    <span className="p7-address-line-1-span">
                      {shipping?.shipping_address?.line1}
                    </span>
                    <br />
                    {shipping?.shipping_address?.line2 ? (
                      <>
                        <span className="p7-address-line-2-span">
                          {shipping?.shipping_address?.line2}
                        </span>
                        <br />
                      </>
                    ) : null}
                    <span className="p7-city-span">
                      {shipping?.shipping_address?.line3}
                    </span>
                    <br />
                    <span className="p7-state-span">
                      {shipping?.shipping_address?.state}
                    </span>
                    <br />
                    <span className="p7-zip-span">
                      {shipping?.shipping_address?.postcode}
                    </span>
                    <br />
                    <span className="p7-country-span">
                      {shipping &&
                        shipping.shipping_address &&
                        shipping.shipping_address.country}
                    </span>
                    <span className="p7-address-line-2-span">
                      <br />‍
                    </span>
                  </p>
                </fieldset>
                <div className="w-commerce-commercecheckoutblockheader p5-left-block-header">
                  <h4 className="p7-confirmation-title">Billing Details</h4>
                </div>
                <fieldset className="w-commerce-commercecheckoutblockcontent p5-address-block">
                  <p className="p7-paragraph">
                    <span className="p7---company-name-span">
                      {shipping?.billing_address?.title}
                    </span>
                    <br />
                    <span className="p7-last-name-span">
                      {`${shipping?.shipping_address?.first_name} ${shipping?.shipping_address?.last_name}`} <br />
                    </span>
                    <span className="p7-address-line-1-span">
                      {shipping?.billing_address?.line1}
                    </span>
                    <br />
                    {shipping?.billing_address?.line2 ? (
                      <>
                        <span className="p7-address-line-2-span">
                          {shipping?.billing_address?.line2}
                        </span>
                        <br />
                      </>
                    ) : null}
                    <span className="p7-city-span">
                      {shipping?.billing_address?.line3}
                    </span>
                    <br />
                    <span className="p7-state-span">
                      {shipping?.billing_address?.state}
                    </span>
                    <br />
                    <span className="p7-zip-span">
                      {shipping?.billing_address?.postcode}
                    </span>
                    <br />
                    <span className="p7-country-span">
                      {shipping &&
                        shipping.billing_address &&
                        shipping.billing_address.country}
                    </span>
                    <span className="p7-address-line-2-span">
                      <br />‍
                    </span>
                  </p>
                </fieldset>
                <div className="w-commerce-commercecheckoutblockheader p5-left-block-header">
                  <h4 className="p5-checkout-title">Shipping Method</h4>
                </div>
                <div className="p7-shipping-method-wrapper">
                  <p className="p7-paragraph">
                    <span className="p7-shipping-method-span">
                      {shipping && shipping.shipping_method}
                      <br />‍
                    </span>
                  </p>
                </div>
                {/*<div className="w-commerce-commercecheckoutblockheader p5-left-block-header">*/}
                {/*  <h4 className="p5-checkout-title">Payment Details</h4>*/}
                {/*</div>*/}
                {/*<div className="p7-payment-wrapper">*/}
                {/*  <p className="p7-paragraph">*/}
                {/*    <span className="p7-last-3-digits">Card Ending: 427</span>*/}
                {/*    <span className="p7-address-line-2-span">*/}
                {/*      <br />‍*/}
                {/*    </span>*/}
                {/*  </p>*/}
                {/*</div>*/}
              </div>
              <SummaryCard
                values={values}
                basket={basket}
                shipping={shipping}
                isLocal={isLocal}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
