import React, {useEffect, useState} from "react";
import {ROUTES} from "../../../utils/values";
import {post} from "../../../api/api-caller";
import {END_POINTS} from "../../../api";
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";

const ForgotPassword = () => {
  let { uid, token } = useParams();
  let [password , setPassword] =useState("");
  let [confirmPassword , setConfirmPassword] =useState("");

  const submitResetPassword = () => {
    let data = {
      new_password1: password,
      new_password2: confirmPassword,
      uid,
      token
    }
    post(END_POINTS.RESET_CONFIRM, data)
        .then((resp) => {
          if(resp){
            toast.success(resp.message)
          }
        })
        .catch(()=>{

        })
  }
  return (
      <div className="p19-body-wrapper">
        <div className="p19-reset-wrapper">
          <div className="p19-new-password-form w-form">
            <form
                id="wf-form-Reset-Form"
                name="wf-form-Reset-Form"
                data-name="Reset Form"
                className="p19-form"
            >
              <div className="p19-form-title">Select a New Password</div>
              <input
                  type="password"
                  className="p19-new-password-field-1 w-input"
                  maxLength="256"
                  minLength="10"
                  name="new_password1"
                  data-name="New Password"
                  placeholder="New Password"
                  id="New-Password-2"
                  onChange={(e)=>setPassword(e.target.value)}
                  style={{backgroundColor: (password !== confirmPassword && confirmPassword !== "") ? '#ffdede' : 'transparent'}}
                  required
              />
              <input
                  type="password"
                  className="p19-new-password-field-2 w-input"
                  maxLength="256"
                  minLength="10"
                  name="new_password2"
                  data-name="New Password 2"
                  placeholder="Retype Password"
                  id="New-Password"
                  onChange={(e)=>setConfirmPassword(e.target.value)}
                  style={{backgroundColor: (password !== confirmPassword && confirmPassword !== "") ? '#ffdede' : 'transparent'}}
                  required
              />
              <input
                  type="submit"
                  value="Save Password"
                  data-wait="Please wait..."
                  className="p19-save-password-button w-button"
                  disabled={password !== confirmPassword}
                  onClick={() => submitResetPassword()}
              />
              <div className="p19-help-text">Still having issues? <a href={`${ROUTES.CONTACT_US}`} className="link-2">Contact Us</a></div>
            </form>
            <div className="w-form-done">
              <div>Thank you! Your Password has been Reset.</div>
            </div>
          </div>
        </div>
      </div>

  );
}

export default ForgotPassword;



