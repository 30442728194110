import React, {useEffect, useState} from "react";
import { post } from "../../../api/api-caller";
import { END_POINTS } from "../../../api";
import { getSelectedVariant } from "../../../utils";

export const SummaryItem = ({ basketId, item, setRefresh, minError, minValue }) => {
  const [selectedAddOn, setSelectedAddOn] = useState(
    getSelectedVariant(item.additional_options, item.selected_additional_option)
  );
  
  const selectedPricing = getSelectedVariant(
    item.volumetric_pricing,
    item.selected_volumetric_pricing
  );
  const [selectedVariant, setSelectedVariant] = useState(
    Object.keys(item.volumetric_pricing).length
      ? `${selectedPricing.name} - ${selectedPricing.package_quantity} Units - £${selectedPricing.price_excl_tax}`
      : ""
  );
  const [isLoading, setIsLoading] = useState(false);

  const addToBasket = (options) => {
    setIsLoading(true);
    post(END_POINTS.ADD_TO_BASKET, options)
      .then(() => {
        setRefresh();
        window.dataLayer.push({...options, ...item, ...selectedVariant, event:'add_to_cart'});
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const removeFromBasket = () => {
    setIsLoading(true);
    post(`${END_POINTS.BASKETS}${basketId}/lines/${item.id}/`, null, "DELETE")
      .then((resp) => {
        window.dataLayer.push({...item, event:'remove_from_cart'});
        setRefresh();
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onAddOnChange = (e) => {
    const options = {
      url: item.stockrecord.split("stockrecord")[0],
      package_id: item.selected_volumetric_pricing,
    };
    if (e.target.value) {
      options.additional_option_id = Number(e.target.value);
    }
    setSelectedAddOn(
      getSelectedVariant(item.additional_options, e.target.value)
    );
    addToBasket(options);
  };

  const onQuantityChange = ({ target: { value } }) => {
    const [qty, price, id, name] = value.split(",");
    const options = {
      url: item.stockrecord.split("stockrecord")[0],
      package_id: id,
    };
    if (item.selected_additional_option) {
      options.additional_option_id = item.selected_additional_option;
    }
    setSelectedVariant(`${name} - ${qty} Units - £${price}`);
    addToBasket(options);
  };

  return (
    <>
      <div className="p27-product-row">
        <div className="p26-watched-item-block">
          <div className="p27-product-info-block">
            <div className="p27-product-title">{item.product.title}</div>
            <div className="p27-product-sku">SKU: {item.product.sku}</div>
            <a href="#" className="p27-sold-by-field">Sold by: <span className="text-span-8">Justbulk</span></a>
          </div>
        </div>
        {/*<div className="p27-option-block">
        {(item.product_variants && item.product_variants.length) ? (
            <div
              data-hover=""
              data-delay="0"
              className="p27-additional-option-dropdown w-dropdown"
            >
              <div className="p27-dropdown-toggle w-dropdown-toggle">
                <select
                  value={selectedOption}
                  className="p27-dropdown-text summary-dropdowns"
                  // onChange={({ target: { value } }) => {
                  //   history.push(
                  //     `${location.pathname.split(productSlug)[0]}${value}`
                  //   );
                  // }}
                >
                  <option
                    className="w-dropdown-link"
                    value="0"
                    disabled
                    selected
                  >
                    {item.title}
                  </option>
                  {item.product_variants.map((option) => (
                    <option
                      className="p26-dropdown-list w-dropdown-list w-dropdown-link"
                      value={option.slug}
                    >
                      {option.title}
                    </option>
                  ))}
                </select>
              </div>
          </div>
        ) : (
          ""
        )}
      </div>*/}
        <div className="p27-option-block">
          {item.additional_options.length ? (
            <div
              data-hover=""
              data-delay="0"
              className="p27-additional-option-dropdown w-dropdown"
            >
              <div className="p27-dropdown-toggle w-dropdown-toggle">
                <select
                  className="p27-dropdown-text summary-dropdowns"
                  onChange={onAddOnChange}
                >
                  <option
                    value=""
                    className="p26-dropdown-list w-dropdown-list w-dropdown-link"
                  >
                    Additional Options
                  </option>
                  {item.additional_options.map((option) => (
                    <option
                      className="p26-dropdown-list w-dropdown-list w-dropdown-link"
                      value={option.id}
                      selected={item.selected_additional_option === option.id}
                    >
                      {`${option.text} - £${option.additional_per_unit_price}`}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          ) : null}
      
          {item.volumetric_pricing.length ? (
            <div
              data-hover=""
              data-delay="0"
              className="p27-price-dropdown w-dropdown"
            >
              <div className="p27-dropdown-toggle w-dropdown-toggle">
                <select
                  className="p27-dropdown-text summary-dropdowns"
                  onChange={onQuantityChange}
                >
                  {item.volumetric_pricing.map((option) => (
                    <option
                      className="p26-dropdown-list w-dropdown-list w-dropdown-link"
                      value={`${option.package_quantity},${option.price_excl_tax},${option.id},${option.name}`}
                      selected={item.selected_volumetric_pricing === option.id}
                    >
                      {`${option.name} - ${option.package_quantity} Units - £${option.price_excl_tax}`}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          ) : null}
        </div>
        <div className="p27-unit-price-block">
          {selectedVariant
            ?
            <div className="p27-unit-price" style={{ marginRight: "10px"}}>
              £{
              Object.keys(selectedAddOn).length
                ? (
                  parseFloat(
                    getSelectedVariant(
                      item.additional_options,
                      selectedAddOn.id
                    ).additional_per_unit_price || 0.0
                  ) + parseFloat(selectedVariant.split("£")[1])
                ).toFixed(2)
                : parseFloat(selectedVariant.split("£")[1])
            }
            </div>
            :  <div className="p27-unit-price">£{item.price_excl_tax_excl_discounts}</div>
          }
        </div>
        <div className="p27-line-total-block">
          <div className="p27-line-total">
            £{item.price_excl_tax_excl_discounts}
          </div>
        </div>
        <div className="p27-remove-block">
          <a
            href="#"
            className="p26-remove-item-button"
            onClick={() => !isLoading && removeFromBasket()}
            {...(isLoading
              ? { style: { pointerEvents: "none", cursor: "default" } }
              : {})}
          >
            {isLoading ? "..." : "Remove"}
          </a>
        </div>
      </div>
      {minError &&
        <div className="p27-moq-error">
          <div className="p27-moq-error-message">This Seller Requires a Minimum Order of <span
            className="p27-moq-amount">£{minValue[0].min_order_value}</span></div>
        </div>
      }
    </>
  );
};
