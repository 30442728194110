import { connect } from 'react-redux';
import { Product } from './product';
import {actions} from "../../actions/ui";

const mapStateToProps = state => ({
  customQuoteModal: state.ui.customQuoteModal,
  account: state.auth.account,
});

const mapDispatchToProps = {
  setRefresh: actions.setRefreshCart,
  toggleCustomQuoteModal: actions.toggleCustomQuoteModal,
  toggleFreeSampleModal:actions.toggleFreeSampleModal,
  
};

export const ProductContainer = connect(mapStateToProps, mapDispatchToProps)(Product);
