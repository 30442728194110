import { object, string } from 'yup';
import { withFormik } from 'formik';
/*
* formSchema serves both as the initial state to form as well
* as set of validation rules to apply on form fields
* */

const formSchema = object().shape({
  first_name: string()
    .required('First name is a required field'),
  last_name: string()
    .required('Last name is a required field'),
  email: string()
    .email('Invalid Email')
    .required('Email is a required field'),
  contact_no: string()
    .required('Phone is a required field'),
  message: string()
    .required('Requirements is a required field'),
});

/*
* HOC to enhance our form with <Formik />
* Helps us pull out logic from the generator-form.jsx component
* */
export const formikEnhancer = withFormik({
  enableReinitialize: true,
  validationSchema: formSchema,
  validateOnChange: false,
  validateOnBlur: false,
  mapPropsToValues: props => ({ checkbox: false, }),
  handleSubmit: (values, {setSubmitting, props: {onSubmit}}) => {
    onSubmit({
      data: { ...values, type: 'enquiry' },
      setSubmitting,
    });
  }
});
