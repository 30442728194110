import React from "react";
import {Formik, Field, Form} from "formik";
import {get, post} from "../../../api/api-caller";
import {END_POINTS} from "../../../api";
import {object, string} from "yup";
import ErrorMessage from "../error_field";

export const Step1 = ({setActiveStep, setSellerId, getSellerData}) => {
  
  const step1 = {
    first_name: "",
    last_name: "",
    email_address: "",
    business_name: "",
    registration_no: "",
    phone_no: "",
    trading_name: "",
  }
  
  const step1Schema = object().shape({
    first_name: string()
      .required('First name is a required field'),
    last_name: string()
      .required('Last name is a required field'),
    email_address: string()
      .email('Invalid Email')
      .required('Email id is a required field'),
    phone_no: string()
      .required('Phone number is a required field'),
    registration_no: string()
      .required('Registration is a required field'),
    trading_name: string()
      .required('Trading name is a required field'),
    business_name: string()
      .required('Business name is a required field'),
  });
  
  const handleSubmit = (values) => {
    post(`${END_POINTS.SELLER_SIGNUP}step1`, values)
      .then((resp) => {
        setSellerId(resp.supplier_lead_id)
        setActiveStep(2);
      })
      .catch()
  }
  return (
    <Formik
      initialValues={step1}
      onSubmit={handleSubmit}
      validationSchema={step1Schema}
    >{
      ({
         errors,
         touched,
         values
       },
      ) => {
        return (
          <Form>
            <div className="p34-form-c1">
              <div className="p34-form-title-wrapper">
                <h2 className="p34-c1-h3">About you.. <strong className="bold-text-12"><br/></strong></h2>
                <div className="p34-progress-wrapper">
                  <div className="p34-progress-bar" style={{width: '0%'}}></div>
                </div>
                <div className="p34-form-split-wrapper">
                  <div className="p34-form-c1-left">
                    <div className="p34-form-field-block">
                      <label htmlFor="name" className="p34-form-field-label">
                        First Name
                      </label>
                      <Field
                        type="text"
                        className="p34-form-field w-input"
                        maxLength="256"
                        name="first_name"
                        placeholder=""
                        id="first_name"
                      />
                      <ErrorMessage
                        errors={errors}
                        touched={touched}
                        name="first_name"
                      />
                    </div>
                    <div className="p34-form-field-block">
                      <label htmlFor="name-4" className="p34-form-field-label">
                        Email Address
                      </label>
                      <Field
                        type="text"
                        className="p34-form-field w-input"
                        maxLength="256"
                        name="email_address"
                        placeholder=""
                        id="email_address"
                      />
                      <ErrorMessage
                        errors={errors}
                        touched={touched}
                        name="email_address"
                      />
                    </div>
                  </div>
                  <div className="p34-form-c1-right">
                    <div className="p34-form-field-block">
                      <label htmlFor="name-4" className="p34-form-field-label">
                        Last Name
                      </label>
                      <Field
                        type="text"
                        className="p34-form-field w-input"
                        maxLength="256"
                        name="last_name"
                        placeholder=""
                        id="last_name"
                      />
                      <ErrorMessage
                        errors={errors}
                        touched={touched}
                        name="last_name"
                      />
                    </div>
                    <div className="p34-form-field-block">
                      <label htmlFor="name-4" className="p34-form-field-label">
                        Phone No
                      </label>
                      <Field
                        type="text"
                        className="p34-form-field w-input"
                        maxLength="256"
                        name="phone_no"
                        data-name="phone_no"
                        placeholder=""
                        id="phone_no"
                      />
                      <ErrorMessage
                        errors={errors}
                        touched={touched}
                        name="phone_no"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="p34-form-split-wrapper">
                <div className="p34-form-c1-left">
                  <div className="p34-form-field-block">
                    <label htmlFor="name-4" className="p34-form-field-label">
                      Registered Business Name
                    </label>
                    <Field
                      type="text"
                      className="p34-form-field w-input"
                      maxLength="256"
                      name="business_name"
                      placeholder=""
                      id="business_name"
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="business_name"
                    />
                  </div>
                  <div className="p34-form-field-block">
                    <label htmlFor="name-4" className="p34-form-field-label">Trading Name (If Different)</label>
                    <Field
                      type="text"
                      className="p34-form-field w-input" maxLength="256"
                      name="trading_name" placeholder="" id="trading_name"
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="trading_name"
                    />
                  </div>
                </div>
                <div className="p34-form-c1-right">
                  <div className="p34-form-field-block">
                    <label htmlFor="name-4" className="p34-form-field-label">Registration No</label>
                    <Field
                      type="text"
                      className="p34-form-field w-input"
                      maxLength="256"
                      name="registration_no"
                      placeholder=""
                      id="registration_no"
                    />
                    <ErrorMessage
                      errors={errors}
                      touched={touched}
                      name="registration_no"
                    />
                  </div>
                  <div className="p34-form-field-block">
                    <label
                      htmlFor="name-4"
                      className="p34-form-field-label-white">
                      Pull Companies House Data
                    </label>
                    <button onClick={() => getSellerData(values.registration_no)} className="p34-form-button w-button">Find my company (UK
                      Only)
                    </button>
                  </div>
                </div>
              </div>
              <button type="submit" className="p34-next-form w-button">Next</button>
            </div>
          </Form>
        )
      }}
    </Formik>
  
  )
}
