/**
 * page 28
 * */
import React from "react";
import {QuoteForm} from "./qoute-form";
import MetaDecorator from "../../utils/meta-decorator";
import {actions} from "../../actions/ui";
import {connect} from "react-redux";
import img from "../../assets/images/1Flag.svg";
import img2 from "../../assets/images/1Price-Match.jpeg";
import  _1_phone_img from "../../assets/images/1Phone-SVG.svg";

export const CustomPouchesLandingPage = ({
                                           toggleFreeSampleModal,
                                           togglePouchQuotationModal,
                                         }) => {
  
  return (
    <React.Fragment>
      <MetaDecorator
        description="Custom Printed Pouches delivered to your door in as little as 14 Days! Digitally Printed, No Plate Fees, Instant Pricing & Free Artwork Support! From only 500 Units."
        title="Custom Printed Pouches"
        robots="“index, follow”"
      />
      <div className="p28-content-wrapper">
        <div className="p28-header-wrapper">
          <div className="p28-page-title-wrapper">
            <div className="p28-title-hash">#DigitallyPrinted</div>
            <h1 className="p28-h1">The next generation of Custom Printed Pouches
            </h1>
            <h1 className="p28-h2">
              Now more than ever, brands are recognising the importance of hybrid packaging solutions
              suitable both for retail and mail order sales. All the while remaining eco-friendly &amp; unique as
              possible!
            </h1>
            <h3 className="p28-h3">Custom Packaging to your door in as little as 10 Days.</h3>
            <div className="p29-cta-wrapper">
              <a href="#" className="p29-cta-button w-button" onClick={() => toggleFreeSampleModal(true)}>Request Free
                Sample</a>
            </div>
          </div>
          <div className="p28-picture-container">
            <img
              src="https://justbulk.co.uk/media/uploaded-media/Header_1.png"
              loading="lazy" width="800" height="386"
              srcSet="https://justbulk.co.uk/media/uploaded-media/Header_1.png 500w, https://justbulk.co.uk/media/uploaded-media/Header_1.png 800w"
              sizes="(max-width: 479px) 100vw, (max-width: 991px) 98vw, 57vw"
              alt="" className="p28-banner-image"/>
          </div>
        </div>
        <div className="p28-strip-wrapper">
          <div className="p28-snippet-block">
            <div className="p28-bullet-point"></div>
            <div className="p28-snippet-text"><strong className="bold-text-2">Print Multiple Designs</strong></div>
          </div>
          <div className="p28-snippet-block">
            <div className="p28-bullet-point"></div>
            <div className="p28-snippet-text"><strong className="bold-text-2">Custom Print from 500 Units</strong>
            </div>
          </div>
          <div className="p28-snippet-block">
            <div className="p28-bullet-point"></div>
            <div className="p28-snippet-text"><strong className="bold-text-2">Free Design Service</strong></div>
          </div>
          <div className="p28-snippet-block">
            <div className="p28-bullet-point"></div>
            <div className="p28-snippet-text"><strong className="bold-text-2">No Plate/Colour Fees</strong></div>
          </div>
          {/*<div className="p28-snippet-block">
                        <div className="p28-bullet-point"></div>
                        <div className="p28-snippet-text"><strong className="bold-text-2">Instant Pricing Available</strong></div>
                    </div>*/}
        </div>
        <div className="p28-content-wrapper-1">
          <div className="p28-c1-image-container">
            <img src="https://justbulk.co.uk/media/uploaded-media/Header_2.png" loading="lazy" width="700"
                 height="380"
                 srcSet="https://justbulk.co.uk/media/uploaded-media/Header_2.png 500w, https://justbulk.co.uk/media/uploaded-media/Header_2.png 700w"
                 sizes="(max-width: 479px) 100vw, (max-width: 767px) 94vw, 56vw"
                 alt="" className="p28-c1-image"/>
          </div>
          <div className="p28-c1-text-wrapper">
            <h4 className="p28-h4">Our Service</h4>
            <div className="p28-c1-text">Get custom printed pouches in weeks not months, We&#x27;ll help you the whole
              way
              through, no hidden charges such as artwork help, dieline fees or proof fees! Hell we&#x27;ll even
              photograph
              your product for free once its done! We print digitally in house!
            </div>
            <a href="#" className="p28-quote-button w-button" onClick={() => togglePouchQuotationModal(true)}>Get Free
              Quote </a>
          </div>
        </div>
        <div className="p28-content-wrapper-2">
          <div className="p28-c2-stock-wrapper">
            <h4 className="p28-h4-style-2">Stock <br/>Ranges</h4>
            <div className="p28-stock-block">
              <div className="p28-stock-image-container">
                <img
                  src="https://justbulk.co.uk/media/uploaded-media/Matt_White__Thumb_2.png"
                  loading="lazy" width="155" height="163" alt="" className="p28-stock-image"/>
              </div>
              <h4 className="p28-h4-style-3">
                <a
                  href="categories/flexible-packaging/stand-up-pouches"
                  target="_blank"
                  className="link-10"
                >
                  Matt White Range
                </a>
              </h4>
            </div>
            <div className="p28-stock-block">
              <div className="p28-stock-image-container">
                <img src="https://justbulk.co.uk/media/uploaded-media/Matt_Black_Thumb.png"
                     loading="lazy" width="155" height="163" alt="" className="p28-stock-image"/>
              </div>
              <h4 className="p28-h4-style-3">
                <a
                  href="categories/flexible-packaging/stand-up-pouches"
                  target="_blank"
                  className="link-11"
                >
                  Matt Black Range
                </a>
              </h4>
            </div>
            <div className="p28-stock-block">
              <div className="p28-stock-image-container">
                <img
                  src="https://justbulk.co.uk/media/uploaded-media/Kraft_Thumb.png"
                  loading="lazy" width="155" height="163" alt="" className="p28-stock-image"/>
              </div>
              <h4 className="p28-h4-style-3">
                <a
                  href="categories/flexible-packaging/stand-up-pouches"
                  target="_blank"
                  className="link-11"
                >
                  Kraft Paper Range
                </a>
              
              </h4>
            </div>
          </div>
          <div className="p28-c2-wrapper">
            <div className="p28-c2-block-1">
              <div className="p28-c2-block-1-image-container">
                <img src="https://justbulk.co.uk/media/uploaded-media/Header_3.png" loading="lazy" width="495"
                     eight="380" alt="" className="p28-c2-block-1-image"/>
              </div>
              <div className="p28-c2-block-1-text-wrapper">
                <h4 className="p28-h4">Our Product Quality</h4>
                <div className="p28-c1-text">
                  Have a look for yourself.. See the difference! We don&#x27;t compromise on
                  material quality to offer you a cheaper price. This leaves you open to packaging failure or flimsy
                  pouches that aren&#x27;t perceived well. <br/><br/>Both our stock and custom printed pouches are
                  produced with a minimum thickness of 140mic ranging up to 160mic. On the contrary every seller
                  we&#x27;ve
                  found online typically offers between 95 &amp; 127mic.<br/>‍<br/>
                </div>
                <a href="#" className="p28-free-sample-button-2 w-button" onClick={() => toggleFreeSampleModal(true)}>Request
                  Free Sample</a>
              </div>
            </div>
            <div className="p28-c2-block-2">
              <div className="p28-c2-block-2-image-container">
                <img src="https://justbulk.co.uk/media/uploaded-media/Header_4.png" loading="lazy" width="495"
                     height="250" alt="" className="p28-c2-block-2-image"/>
              </div>
              <div className="p28-c2-block-1-text-wrapper">
                <h4 className="p28-h4">Printed Labels for Stock</h4>
                <div className="p28-c1-text">
                  For smaller companies where digital is out of budget, or unfeasible for
                  multiple design quantities we offer digitally printed labels within 2-3 working days that go
                  beautifully with our premium stock pouches.
                </div>
                <div className="p28-accent-text">
                  <strong>
                    With digital printed pouches you can choose to print a new design with every 1000 units!
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p28-call-out-wrapper">
          <img
            src={img}
            loading="lazy"
            width="40"
            height="30"
            alt=""
            className="image-23"/>
          <div className="p28-call-out-text">
            <strong className="bold-text-8">
              If you&#x27;re happy with our Quality, We&#x27;ll Price Match Your Quotes.
            </strong>
          </div>
          <img
            src={img}
            loading="lazy"
            width="40"
            height="30"
            alt=""
            className="image-23"/>
        </div>
        <div className="p28-accent-text-2">
          We Print: Stand Up Pouches | Spout Pouches | 3 Side Seal Pouches | Flat Bottom Bags | Valved Pouches
        </div>
        <div className="p28-content-wrapper-3-2">
          <div className="p28-c3-text-wrapper">
            <div className="p28-c3-block-1-text-wrapper">
              <h4 className="p28-h4-c3">Instant Pricing</h4>
              <div className="p28-c3-text">We don&#x27;t look at your brand &amp; Price you. No Material Jargon our
                pouches are suitable for all products except liquids &amp; are food grade safe. <br/>No Stupid Delivery
                Fees, No Duty
              </div>
              <div className="p28-accent-text-c3"><strong>From a 10 Day Turnaround, We&#x27;re Fastest in the
                Market!</strong></div>
              <div className="p28-c3-text-2">If you require higher quantity pricing, specific materials, a custom size,
                need help or simply want it cheaper and can wait a little longer fill out our custom quote form.
              </div>
              
              <div className="p28-price-match-div">
                <a href="#" className="p28-custom-quote-button w-button"
                   onClick={() => togglePouchQuotationModal(true)}>Get Price Match Quote</a>
                <img
                  src={img2}
                  loading="lazy"
                  width="120"
                  alt=""
                  className="image-22"
                />
                <div className="p28-accent-text-c4">Tell us your target price.</div>
              </div>
            </div>
          </div>
          <div className="p28-c3-product-selection-wrapper">
            <div className="p28-price-wrapper">
              <div className="p28-product-price"><strong>Custom Quote </strong></div>
              <div className="p28-unit-symbol">within 24 hours or :</div>
              <div className="p28-call-now-div">
                <img
                  src={_1_phone_img} loading="lazy" width="20" height="20"
                  alt="" className="image-21"/>
                <div className="p28-call-to-confirm-price">
                  <a href="tel:02080162108" className="link-9">0208 016 2108</a>
                </div>
              </div>
            </div>
            <div className="p28-dropdown-wrapper-2">
              <div className="p28-price-wrapper-2">
                <div className="p28-column-1">
                  <div className="p28-column-text"><strong>Stand Up Pouch Sizes: </strong></div>
                  <div className="p28-column-text"><em>Width x Height x Depth</em></div>
                  <div className="p28-column-text">70g - 110 x 170 x 70mm</div>
                  <div className="p28-column-text">150g - 130 x 210 x 80mm</div>
                  <div className="p28-column-text">250g - 160 x 230 x 80mm</div>
                  <div className="p28-column-text">500g - 190 x 260 x 110mm</div>
                  <div className="p28-column-text">750g - 200 x 310 x 110mm</div>
                  <div className="p28-column-text"><em>MOQ</em></div>
                </div>
                <div className="p28-column-2">
                  <div className="p28-column-text"><strong>Digitally Printed</strong></div>
                  <div className="p28-column-text"><em>From</em></div>
                  <div className="p28-column-text">0.27</div>
                  <div className="p28-column-text">0.29</div>
                  <div className="p28-column-text">0.31</div>
                  <div className="p28-column-text">0.33</div>
                  <div className="p28-column-text">0.35</div>
                  <div className="p28-column-text"><em>1000</em></div>
                </div>
                <div className="p28-column-3">
                  <div className="p28-column-text"><strong>Rotogravure Print </strong></div>
                  <div className="p28-column-text"><em>From </em></div>
                  <div className="p28-column-text">0.14</div>
                  <div className="p28-column-text">0.18</div>
                  <div className="p28-column-text">0.21</div>
                  <div className="p28-column-text">0.23</div>
                  <div className="p28-column-text">0.25</div>
                  <div className="p28-column-text"><em>5000</em></div>
                </div>
              </div>
              <div className="p28---cart-sample-wrapper-copy">
                <a href="https://www.calendly.com/justbulk" target="_blank" className="p28-add-to-cart-button w-button">Book
                  a Call Back</a>
                <a href="#" className="p28-free-sample-button w-button" onClick={() => toggleFreeSampleModal(true)}>Request Size Sample</a>
              </div>
              <div className="p28-accent-text-c5">Email us at <strong>sales@justbulk.co.uk</strong> for a free Artwork
                Review
              </div>
            </div>
          </div>
          {/*dynamically loaded product selection component*/}
          {/*<ProductSelectionContainer/>*/}
        </div>
        
        <div className="p28-accent-text-3">
          Unsure? Test Prints Available on your desired Material.
          <a href="https://www.calendly.com/justbulk" target="_blank" className="p28-booking-link">Book Call Back</a>
        </div>
        <div className="p28-content-wrapper-4">
          <div className="p28-c4-title">
            <strong className="bold-text-3">
              The Process Ahead
            </strong>
          </div>
          <div className="p28-c4-process-wrapper">
            <div className="p28-step-block">
              <div className="p28-c4-number">1.</div>
              <div className="p28-step-text">
                Select your options &amp; Checkout! Or Fill out our Custom Quote Form and we&#x27;ll quote you within
                48hrs.
              </div>
            </div>
            <div className="p28-step-block">
              <div className="p28-c4-number">2.</div>
              <div className="p28-step-text">
                Head over to &#x27;My Account&#x27; upload your artwork, clearly labelling all uploads
              </div>
            </div>
            <div className="p28-step-block">
              <div className="p28-c4-number">3.</div>
              <div className="p28-step-text">
                We&#x27;ll send you a proof and print agreement, sign and approve the doc and we&#x27;re good to go!
              </div>
            </div>
          </div>
        </div>
        <div className="p28-content-wrapper-5">
          <div className="p28-c5-title">
            <strong className="bold-text-3">Specifications</strong>
          </div>
          <div className="p28-spec-text">
            <strong>1. Classic Custom Printed Pouches</strong>
            <br/>
            Digitally printed using the
            highest quality digital press on the market, a HP Indigo 20,000, on a 3 layer material composition;
            MOPP/VMPET/PE which combined provides a 140 Micron thickness, far superior to all online sellers. Our
            bespoke
            material layer welding and bag making process insures the cleanest crease free film to be produced which
            is
            unrivaled in this particular composition. This high barrier composition is suitable for most food goods
            and
            even some more corrosive substances such as salts. If you are unsure how your product will react feel free
            to
            get in touch before hand.
            <br/>
            <br/>
            <strong>2. Recyclable Custom Printed Pouches</strong>
            <br/>
            We are one of the
            very few digital printers that have managed to pull of a print using a 100% Recyclable material
            composition
            made of PE/PE. Common problems with digitally printed recyclable pouches is blurry print, with a lot of
            noise, washed out colours is the most common issue with 90% of manufacturers where small text and light
            colours become invisible, we have overcome this about 95% as due to the nature of the material slight
            wash
            will always remain however our print always remain intact. The last biggest issue that occurs in
            suppliers
            that use thin or cheaper materials is creasing and slight transparency. We use a 140-160 Micron
            thickness to
            avoid this issue however figurative shapes may remain visible but will have a crease free
            finish.
            <br/>
            <br/>
            <strong>3. Kraft Custom Printed Pouches</strong>
            <br/>
            Our Custom Kraft Pouches are
            constructed
            using Natural Kraft, PET and PE. In contrast to other suppliers we have replaced the middle metal
            layer
            with a thick PET Layer that offers similar barrier properties as having metal. We did this in order
            for
            the pouch to be more Eco friendly as PET itself holds a Recycling Code 1, in contrast to metalised
            pouches
            in which the metal layer cannot be separated from the adjoining paper and plastic layers which defeats
            the
            purpose of going for Kraft. This Non - Metalised kraft pouch has been tested to remain food grade safe
            for
            up to 2 Years in ambient conditions. If you would like us to use a metalised layer instead where you
            require greater barrier properties just let us know after placing your order, there&#x27;s no extra
            charge. The material for this would be Naturual Kraft/VMPET/PE.
            <br/>
            ‍
            <br/>
            In the additional options you
            may
            select a further 4th lamination layer which is constructed of MOPP to give it a smooth transparent
            matt
            finish as opposed to leaving a paper feel. The MOPP layer will also provide better colour results
            when
            printed on as opposed to printing directly onto kraft, this is generally the go to option for most
            retailed products as kraft paper on its own can stain while being retailed, stored or
            transported.
            <br/>
            <br/>
            All our Kraft Pouches bare a minimum 152 Micron thickness as standard and can go
            up
            to 180 Microns if required.
            <br/>
            <br/>
            <strong>4. Compostable Custom Printed Pouches</strong>
            <br/>
            As
            the
            name suggests, our compostable packaging decomposes! Digitally we&#x27;re unaware of anyone else
            offering this service. If you want something eco this is as eco as it gets! Constructed from a
            Natural Kraft Paper and lined with an organically derived PE Layer. This material decomposes in
            ambient conditions in around 6 months depending on the thickness. We select the thickness based
            on
            the item to be packed this generally ranges from 120 Micron - 180 Microns to ensure adequate
            packaging protection.
            <br/>
            <br/>
            <strong>5. Other Compositions<br/></strong>
            If you need a bespoke
            composition for your product please fill out custom form quote and we&#x27;ll get back to you
            within a day or two! However, please respect creating bespoke multi layer films for pouch
            production will require a much higher MOQ than available online and will start from 10,000
            Units,
            while also requiring a longer lead time. That said, at larger volumes prices are also
            significantly cheaper so don&#x27;t be put off!
            <br/>‍<br/>‍<br/>
          </div>
        </div>
        <div className="p28-content-wrapper-6">
          <div className="p28-c5-title"><strong className="bold-text-3">Custom Print FAQ&#x27;s</strong></div>
          <div data-duration-in="300" data-duration-out="100" className="p28-faq w-tabs">
            <div className="p28-tabs-menu w-tab-menu">
              <a data-w-tab="Tab 2" className="p28-tab-link-tab-2 w-inline-block w-tab-link">
                <div className="p28-tab-menu-title">Production</div>
              </a>
              <a data-w-tab="Tab 3" className="p28-tab-link-tab3 w-inline-block w-tab-link">
                <div className="p28-tab-menu-title">Delivery</div>
              </a>
              <a data-w-tab="Tab 4" className="p28-tab-link-tab4 w-inline-block w-tab-link">
                <div className="p28-tab-menu-title">After Sales</div>
              </a>
              <a data-w-tab="Tab 5" className="p28-tab-link-tab-5 w-inline-block w-tab-link w--current">
                <div className="p28-tab-menu-title">Free Design Service / Artwork Help</div>
              </a>
            </div>
            <div className="p28-tab-content w-tab-content">
              <div data-w-tab="Tab 2" className="w-tab-pane">
                <div className="p28-spec-text"><strong>1. What are your Production Variances?</strong><br/>500 - 999 -
                  20%<br/>1000 - 4999 = 10%<br/>5000 or more = 5%<br/><br/><strong>2. Why do Production Variances
                    Occur? I
                    thought they don&#x27;t occur in digital!</strong><br/>Production Variances occurred traditionally
                  in
                  Rotogravure and Flexographic methods where by it was impossible to stop the machine run accurately
                  as
                  it doesn&#x27;t measure impressions it measures in reel length printed. As well as that you have
                  to
                  account for the first 20m and last 20m as wasted print while also leaving room for any issues that
                  occur during the forming process, thus you were generally always left with a 20% variance
                  regardless
                  of quantity. When printing digitally we don&#x27;t have these issues except due to the fact we may
                  overprint by a certain quantity to insure we meet your quantity is met after taking out any
                  defected
                  pouches that occur during the bag making process, however as soon as you surpass the 5k mark this
                  percentage indefinitely becomes 5%, which is the lowest production variance on the
                  market. <br/>‍<br/><strong>3. What Quality Control Procedures do you have in place? </strong><br/>We
                  have
                  several spot checkers across throughout our production process, as well as conduct random
                  sampling
                  once all goods are packed. For Digitally printed pouches, a machine also scans for major
                  irregularities. <br/><br/></div>
              </div>
              <div data-w-tab="Tab 3" className="tab-pane-tab-3 w-tab-pane">
                <div className="p28-spec-text"><strong>1. What is your standard Delivery Time?</strong><br/>We
                  typically
                  offer a 3-4 Day standard delivery service directly to your door, this is in addition to our 14 Day
                  production turnaround, however in most cases we deliver within this period. <br/>(Please Note Due to
                  Covid-19 our distribution partners are under immense strain and it is out of our control if delays
                  occur on their end, you will be able to track their service once dispatched)<br/><br/><strong>2.
                    Will I
                    have to Pay Import Fees &amp; Duty?</strong><br/>No, nothing no additional charges, dutys, taxes
                  or
                  fees to pay upon delivery or later.<br/><br/><strong>3. How are good delivered?</strong><br/>We
                  typically use DPD and their partners for most orders, unless we&#x27;re sending bulky or heavy
                  loads where a pallet service will be used. In this case we will contact you before arranging a
                  pallet service to ensure your items can be delivered and offloaded safely. There is no
                  additional
                  charge for pallet delivery. <br/><br/></div>
              </div>
              <div data-w-tab="Tab 4" className="tab-pane-tab-4 w-tab-pane">
                <div className="p28-spec-text">
                  <strong>
                    1. We are a new business could you assist us with product photography?
                  </strong>
                  <br/>Yes and No it really depends on how busy we are, if we absolutely love your brand then we
                  probably will!<br/>‍<br/>
                  <strong>2. I am unhappy with the finished Product?</strong>
                  <br/>
                  Please note registration issues do occur but are hardly noticeable upon general observation, we place
                  a 5%
                  tolerance on sizes (mm), print positioning (mm) and colour shades as these cannot be further fine
                  tuned. Beyond that if there is an issue on our end we will investigate the cause and offer a
                  reprint.
                  However, you will not be able to change the design, If an error has arisen due to design
                  negligence
                  such as not following our artwork guides we will not take any liability for this and you will have to
                  pay for the entire quantity supplied. Please note a proof is only to confirm the content of the design
                  that will be sent to print and isn&#x27;t in any way an example of the finished product as issues such
                  as differences in monitors can cause users to see different shades and highlights depending on
                  screen settings that can distort how your artwork is viewed.
                  <br/><br/>
                  <strong>3 . Repeat Rewards </strong>
                  <br/>
                  If you&#x27;re looking to print with us again, be sure to stay up to date with our emails! We will
                  send
                  you personalised discount codes depending on how frequently or what you order with us!
                  <br/><br/>
                </div>
              </div>
              <div data-w-tab="Tab 5" className="tab-pane-tab-5 w-tab-pane w--tab-active">
                <div className="p28-spec-text"><strong>1. Do you really provide a Free Design Service?</strong><br/>Our
                  free design service is subject to a minimum order quantity of 4000 Units. Upon taking a design
                  service
                  order we will charge a £300 design fee which we will reimburse when and if you place your custom
                  print
                  order. This is not reimbursable against any other type of purchase and is valid for 2 months after
                  completion of the initial design. The service includes 3 revisions of your choice that do not
                  fundamentally change the entire design, we recommend you provide examples of designs you would like
                  in
                  order to best manage expectations. Additional revisions will be charged at £25 P/revision. Upon
                  handover
                  the intellectual property will become yours and we will provide you the actual design files, and you
                  will be free to use this with other suppliers. You can also use this service alone if you
                  require. <br/><br/><strong>2. Could you help me make a small change?</strong><br/>If it is a small
                  adjustment such as moving an object, changing a font, or placing a vector object/logo we will do
                  this
                  for no cost however, you will have to wait in a que till our in house designer gets around to it,
                  this
                  may happen the same day or may take a few days. <br/><br/><strong>3. What format should my artwork
                    be
                    in?</strong><br/>All artwork should be provided in vector format, in an illustrator or pdf file,
                  we
                  will generally not accept any other file format and if we do we will not take liability of any
                  print
                  issues arising due to it.<br/>All text/fonts should be objectified/outlines as well as attached,
                  all
                  media should be embedded within the file. All artwork should be created in CMYK, if you have
                  designed your artwork in RGB or Photoshop using standard preset settings you will end up with
                  a
                  faded washed our print. For further information please see our Artwork Guide. <br/>‍<br/><br/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p28-content-wrapper-7">
          <div className="p28-c5-title"><strong className="bold-text-3">Printing Method Comparison for Custom
            Pouches</strong></div>
          <div className="p28-c7-comparison-wrapper">
            <div className="p28-c7-left-wrapper">
              <div className="p28-comparison-text">
                <strong>Gravure / Flexographic</strong>‍
                <br/><br/>
                MOQ: 10,000 - 40,000
                Depending on Size, P/Design
                <br/>
                10k Print Variance: Up to 20-45% (Charged Extra)
                <br/><br/>
                Lead Times:
                <br/>
                3-4 Weeks for Manufacturers Using Air Cargo
                <br/>
                6-7 Weeks for Manufacturers by Sea
                <br/>
                4-6 Weeks for Resellers Using Air Cargo
                <br/>
                8-12 Weeks for Resellers Using Sea Cargo <
                br/><br/>
                Cost Example 10k
                Units:
                <br/>
                Unit Cost: 0.15*10,000 = £1500
                <br/>
                4 Colour Print: £150*4 = £600
                <br/>
                Air Cargo: £800
                <br/>
                Duty: £420
                <br/>
                Quoted Cost: £3,320 - 0.332 P/Unit
                <br/>
                Potential Variance: £664 - £1494 (Extra
                Charge)
                <br/>
                Realistic Minimal Lead time 5-6 weeks
                <br/>Total Payable Min: £3984 - £4814<br/>Actual Cost: 0.398
                - 0.48
                P/Unit
                <br/><br/>
                Summary:
                <br/>
                Suitable for large volumes 50k+, cylinders typically warm
                up or set
                in after 2k Units, therefore short runs often cause issues. Short runs for these
                machines
                are typically 10-40k of ONE design. Typically limited to 4-8 colours. Printing Non
                Vector
                Graphics almost always an issue. You are told you can use the plates again. But
                often these
                plates corrode or get damaged in storage and transport, they&#x27;re made of a
                thin rubber,
                only useful if you&#x27;re ordering again within a year.
                <br/><br/>Quality Control: Human Inspection
              </div>
            </div>
            <div className="p28-c7-right-wrapper">
              <div className="p28-comparison-text-right">
                <strong>Digitally Printed Pouches (Our Method)</strong>
                <br/><br/>
                MOQ: 500 - Multiple Designs
                <br/>
                10k Print Variance up to 0-5% (Charged
                Extra)
                <br/><br/><br/>
                Lead Times:
                <br/>
                7-14 Days for Manufacturers
                <br/>
                6 Weeks for Manufacturers by Land/Sea
                <br/>
                4-6 Weeks for Resellers Using Air Cargo<
                br/>
                8-10 Weeks for Resellers Using Sea Cargo
                <br/><br/>
                Cost Example 10k Units:
                <br/>
                Unit Cost: 0.40*10,000 = £4000
                <br/>
                Colour Cost: None
                <br/>
                Cargo: None
                <br/>
                Duty: None
                <br/>
                Quoted Cost: £4000 - 0.40P/Unit
                <br/>
                Potential Variance: £0.00 - £200
                <br/>
                Realistic Minimal Lead time 10-14 Days
                <br/>
                Total Payable Min: £4000 - £4200
                <br/>
                Actual Cost: 0.40 - 0.42 P/Unit
                <br/><br/><br/>
                Summary:
                <br/>
                Suitable for both short and large volumes, of multiple designs.
                Multiple designs can be split into 1k Splits. Suitable for both vector and non vector artwork.
                Prints full CMYK spectrum + White. No Hidden Charges that can arise, if faults arise they can
                be quickly rectified. Minimal setup time required.
                <br/><br/>
                Quality Control: Machine Inspection + Human Inspection
              </div>
            </div>
          </div>
        </div>
        <div className="p28-content-wrapper-8" id="custom_quote_form">
          <div className="p28-c8-title">
            <strong className="bold-text-3">Get a Custom Quote within 48 Hours!</strong>
          </div>
          <div className="p28-form w-form">
            <QuoteForm pageTitle="custom-printed-pouches"/>
          </div>
        </div>
      </div>
      <div className="p28-recommended-wrapper">
        <div className="p1---recommended-prod-title-block">
          <h2 className="p28-recommended-title">Recommended Products</h2>
        </div>
        <div className="p1---recommended-products-block">
          <div className="p28-product-frame">
            <img src="https://justbulk.co.uk/media/images/products/2021/01/Brown_Mailer_Bag_Just_Bulk.png" alt=""
                 className="p28-recommended-picture"/>
            <a href="/categories/e-commerce-packaging/mailer-bags" className="p28-recommended-product-text">Custom
              Printed Mailers Bags<br/>7 Day Turnaround</a>
          </div>
          <div className="p28-product-frame">
            <img
              src="https://justbulk.co.uk/media/uploaded-media/Matt_White_Pic_350x400.png" alt=""
              className="p28-recommended-picture"
            />
            <a href="/categories/flexible-packaging/stand-up-pouches" className="p28-recommended-product-text">Shop Matt
              White Stand Up Pouches</a>
          </div>
          <div className="p28-product-frame">
            <img
              src="https://justbulk.co.uk/media/uploaded-media/Matt_Black__Pic_350x400.png" alt=""
              className="p28-recommended-picture"
            />
            <a href="/categories/flexible-packaging/stand-up-pouches" className="p28-recommended-product-text">Shop Matt
              Black Stand Up Pouches</a>
          </div>
          <div className="p28-product-frame">
            <img src="https://justbulk.co.uk/media/uploaded-media/Printed_Labels__Stickers.png" alt=""
                 className="p28-recommended-picture"/>
            <a href="/categories/flexible-packaging/labels-stickers" className="p28-recommended-product-text">Custom
              Printed Labels 2 Days Turnaround</a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};


const mapDispatchToProps = {
  toggleFreeSampleModal: actions.toggleFreeSampleModal,
  togglePouchQuotationModal: actions.togglePouchQuotationModal,
};

export const CustomPouchesLandingPageContainer = connect(null, mapDispatchToProps)(CustomPouchesLandingPage);
