import React from "react";
import { Switch, Route } from "react-router-dom";
import { Home } from "../home";
import { Contact } from "../contact";
import { Product } from "../product-detail";
import { Categories } from "../categories";
import { BlogFeed } from "../blog-feed";
import { BlogPost } from "../blog-post";
import { FAQs } from "../faqs";
import { ROUTES } from "../../utils/values";
import { LoginSignup } from "../login-signup";
import { Checkout } from "../checkout";
import { Landing } from "../landing";
import { OrderConfirmation } from "../order-confirmation";
import { Search } from "../search";
import { Account } from "../account";
import { PrivateRoute } from "./private-route";
import { CheckoutSummary } from "../checkout-summary";
import { ForgotPassword } from "../login-signup/forgot";
import { Pages } from "../pages";
import { NotFound } from "../not-found";
import { Invoice } from "../account/order-history/invoices";
import { ChangePassword } from "../password";
import { PouchShop } from "../product-landing/PouchShop";
import { TakeAway } from "../product-landing/take-away"
import { SellerSignup } from "../seller-signup";
import { SellerMarketPlace } from "../marketplace";
import {
  CustomPouchesLandingPage,
  MailerBags,
  StandUpPouchesLandingPage,
  PouchPackagingLandingPage
} from "../product-landing";
import { Partners } from "../partners";


export const Router = () => (
  <Switch>
    <Route exact path="/">
      <Home />
    </Route>
    <Route path={ROUTES.CONTACT_US}>
      <Contact />
    </Route>
    <Route path={`${ROUTES.PARTNER_PRODUCTS}/:partnerId`} exact>
      <Categories />
    </Route>
    <Route path={`${ROUTES.CATEGORIES}/:slug/:sub/:productSlug`} exact>
      <Product />
    </Route>
    <Route path={`${ROUTES.CATEGORIES}/:slug/:sub`} exact>
      <Categories />
    </Route>
    <Route path={`${ROUTES.CATEGORIES}/:slug`} exact>
      <Categories />
    </Route>
    <Route path={`${ROUTES.CATEGORIES}`}>
      <Categories />
    </Route>
    <Route path={ROUTES.BLOG_FEED} exact>
      <BlogFeed />
    </Route>
    <Route path={`${ROUTES.BLOG_FEED}/:slug`} exact>
      <BlogPost />
    </Route>
    <Route path={ROUTES.FAQS}>
      <FAQs />
    </Route>
    {/*<Route path={ROUTES.LOGIN}>*/}
    <Route path={ROUTES.LOGIN} exact>
      <LoginSignup />
    </Route>
    <Route path={`${ROUTES.LOGIN}/:redirect`}>
      <LoginSignup />
    </Route>
    <Route path={ROUTES.CHECKOUT} >
      <Checkout />
    </Route>
    <Route path={ROUTES.LANDING}>
      <Landing />
    </Route>
    <Route
      path={ROUTES.ORDER_CONFIRMATION}
      component={OrderConfirmation}
    />
    <Route path={`${ROUTES.SEARCH}/:query`} exact>
      <Search />
    </Route>
    <Route
      path={`${ROUTES.ACCOUNT_ORDER_HISTORY}/:invoiceId`}
      component={Invoice}
      exact
    />

    <PrivateRoute path={`${ROUTES.ACCOUNT}`} component={Account} />

    <PrivateRoute
      path={`${ROUTES.CHANGE_PASSWORD}`}
      component={ChangePassword}
    />

    <Route path={ROUTES.CHECKOUT_SUMMARY} exact>
      <CheckoutSummary />
    </Route>
    {/*<PrivateRoute path={ROUTES.CHECKOUT_SUMMARY} component={CheckoutSummary} exact />*/}

    <Route path={`${ROUTES.FORGOT_PASSWORD}/:uid/:token`} exact>
      <ForgotPassword />
    </Route>
    <Route path={`${ROUTES.CUSTOM_POUCHES}`} exact>
      <CustomPouchesLandingPage />
    </Route>
    <Route path={`${ROUTES.PRINTED_POUCHES}`} exact>
      <CustomPouchesLandingPage />
    </Route>
    <Route path={`${ROUTES.POUCH_PACKAGING}`} exact>
      <PouchPackagingLandingPage />
    </Route>
    <Route path={`${ROUTES.STAND_UP_POUCHES}`} exact>
      <StandUpPouchesLandingPage />
    </Route>
    <Route path={`${ROUTES.CUSTOM_MAILER_BAGS}`} exact>
      <MailerBags />
    </Route>
    <Route path={`${ROUTES.POLY_BAGS}`} exact>
      <MailerBags />
    </Route>
    <Route path={`${ROUTES.MAILER_BAGS}`} exact>
      <MailerBags />
    </Route>
    <Route path={`${ROUTES.SHIPPING_BAGS}`} exact>
      <MailerBags />
    </Route>
    <Route path={`${ROUTES.POUCH_SHOP}`} exact>
      <PouchShop />
    </Route>
    <Route path={`${ROUTES.TAKE_AWAY}`} exact>
      <TakeAway />
    </Route>
    <Route path={`${ROUTES.SELLER_SIGNUP}`} exact>
      <SellerSignup />
    </Route>
    <Route path={`${ROUTES.SELLER_MARKETPLACE}`} exact>
      <SellerMarketPlace />
    </Route>
    <Route path={ROUTES.NOT_FOUND} component={NotFound} />
    <Route component={Pages} />
  </Switch>
);
