import * as types from './types';

export const toggleCartModal = () => ({
  type: types.UI_TOGGLE_CART_MODAL,
});

export const setBreadCrumbs = data => ({
  type: types.UI_SET_BREADCRUMBS,
  data,
});

export const setShipping = data => ({
  type: types.UI_SET_SHIPPING,
  data,
});

export const setRefreshCart = () => ({
  type: types.UI_SET_REFRESH_CART,
});

export const toggleCustomQuoteModal = data => ({
  type: types.UI_TOGGlE_CUSTOM_QUOTE_MODAL,
  data,
});

export const toggleCookieModal = data => ({
  type: types.UI_TOGGlE_COOKIE_MODAL,
  data
})

export const setCountry = data => ({
  type: types.UI_SET_COUNTRY,
  data
})

export const setTaxMethod = data => ({
  type: types.UI_SET_TAX_METHOD,
  data
})

export const toggleFreeSampleModal = data => ({
  type: types.UI_TOGGLE_FREE_SAMPLE_MODAL,
  data
})

export const togglePouchQuotationModal = data => ({
  type: types.UI_TOGGLE_POUCH_QUOTATION_MODAL,
  data
})