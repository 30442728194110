import React, {useEffect, useState} from "react";
import {ArtUploadForm} from "./form/artUploadForm";
import {get, post, download} from "../../../api/api-caller";
import {END_POINTS} from "../../../api";
import {ROUTES} from "../../../utils/values";
import {toast} from "react-toastify";

export const Artwork = () => {
  const [isError, setIsError] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [submitted, setSubmitted] = useState(true)
  //********* artwork fetch **********//
  const [artworks, setArtworks] = useState([]);

  const getArtwork = () => {
    get(`${END_POINTS.ACCOUNT}/artwork/`)
      .then(({ results }) => {
        setArtworks(results);
      })
      .catch((err) => console.error(err));
  }

  const getFileFromBackend = (file) => {
    download(`${END_POINTS.DOWNLOAD_FILE}${file.file_ref}/`, file.file_name)
      .then((resp) => {
      })
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    getArtwork();
  }, []);

  const datetimeformat = (value) => {
    let date = new Date(value);
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = date.getFullYear();
    return  mm + '/' + dd + '/' + yyyy + " - " + date.getHours() +":" +date.getMinutes();
  }
  return (
    <>
      <div className="p25-your-artwork-wrapper">
        <div className="p25-your-artwork-form w-form">
          <div className="p21-title-block">
            <h4 className="p21-right-wrapper-title">Your Artwork</h4>
          </div>
          <ArtUploadForm attemptSubmit={({data, setSubmitting, resetForm}) => {
            post(`${END_POINTS.ACCOUNT}/artwork/`, data)
              .then((resp) => {
                setSubmitting(false);
                setSubmitted(true);
                resetForm({values:{name:"", message: "", file:null}});
                toast.success('Thank you! Your submission has been received!')
                getArtwork();
              })
              .catch(() => {
                setIsError(true);
                setSubmitting(false);
              })
          }}/>
          {submitted &&
          <div className="w-form-done">
            <div>Thank you! Your submission has been received!</div>
          </div>
          }

          {isError &&
          <div className="w-form-fail">
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
          }
        </div>
      </div>
      <div className="p25-historical-uploads-wrapper">
        <div className="p25-historical-upload-title">Historical Artwork</div>
        <div className="p25-grid-wrapper">
          <div className="p25-table-title-wrapper">
            <div className="p25-ref-block">
              <div className="p25-column-title">Order / Prod Ref</div>
            </div>
            <div className="p25-file-name-title-wrapper">
              <div className="p25-file-name-title">File Name</div>
            </div>
            <div className="p25-upload-title-wrapper">
              <div className="p25-upload-title"> Date - Time</div>
            </div>
            <div className="p25-file-view-block">
              <div className="p25-file-view-title">View File</div>
            </div>
          </div>
          <div className="p25-row-wrapper">
            {artworks.map((item, index)=>(
              <div>
                { item.files[0] &&
                  <>
                    <div className="p25-upper-row-wrapper">
                      <div className="p25-ref-field-block">
                        <div className="p25-ref">{item.reference}</div>
                      </div>
                      <div className="p25-file-name-field-block">
                        <div className="p25-file-name">{item.files[0].file_name}</div>
                      </div>
                      <div className="p25-upload-date-block">
                        <div className="p25-upload-date">{datetimeformat(item.updated_at)}</div>
                      </div>
                      <div className="p25-button-block">
                        <button onClick={() => getFileFromBackend(item.files[0])} className="p25-view-file-button w-button">Download</button>
                      </div>
                    </div>
                    <div className="p25-lower-notes-row">
                      <div className="p25-upload-notes">
                        {item.message}
                      </div>
                    </div>
                  </>
                }
              </div>
            ))}
          </div>
        </div>


      </div>
      {isUploading && (
        <div className="p24-upload-modal-wrapper">
          <div className="p24-modal-block">
            <div className="p24-modal-title">Uploading...</div>
            <div className="p24-progress-bar-wrapper">
              <div className="p24-progress-bar-tracker"></div>
            </div>
            <div className="p24-progress-details-block">
              <div className="p24-progress-percentage">35%</div>
              <div className="p24-progress-size-uploaded">100</div>
              <div className="p24-total-progress-size"> / 2000 Mb</div>
            </div>
          </div>
        </div>
      )}
    </>
  )

};
