import React from "react";
import img1 from "../../assets/images/1Asset-29-100.jpg"
import img2 from "../../assets/images/1Asset-15-100.jpg";

export const Landing = () => (
  <div className="p12-content-wrapper">
    <div className="p12-left-wrapper">
      <div className="p12-header-picture-block">
        <img
          src={img1}
          // srcSet={"../../assets/images/1Asset-29-100-p-500.jpeg 500w, images/1Asset-29-100-p-800.jpeg 800w, images/1Asset-29-100.jpg 801w"
          // sizes="(max-width: 479px) 96vw, (max-width: 991px) 95vw, 77vw"
          alt=""
          className="image-18"
        />
      </div>
      <div className="p12-title-block">
        <h1 className="p12-h1">H1 Stand Up Pouches Filling Machine</h1>
        <h2 className="p12-h2">
          This is a blog description it will span two lines what do you think
          will happen, hmm okay maybe we need to add a third line yeah... that
          looks better okay.
        </h2>
        <a href="#" className="p12-button-1 w-button">
          View Products
        </a>
        <a href="#" className="p12-button-1 w-button">
          Custom Quote
        </a>
      </div>
      <div className="p12-product-summary-section-title">
        <h3 className="p12-section-title">An Overview of Stand Up Pouches..</h3>
      </div>
      <div className="p12-product-summary-wrapper">
        <img
          src={img2}
          height=""
          alt=""
          className="image-16"
        />
        <div className="p12-product-summary-text-block">
          <h3 className="p12-h3">Filling Machine Type 1 </h3>
          <div className="p12-bullet-point-block">
            <div className="p12-bullet-point"></div>
            <div className="p12-product-summary-text">
              This is a blog description it will span two lines what do you
              think will happen, hmm okay maybe we need to add a third line
              yeah... that looks better okay.
            </div>
          </div>
          <div className="p12-bullet-point-block">
            <div className="p12-bullet-point"></div>
            <div className="p12-product-summary-text">
              This is a blog description it will span two lines what do you
              think will happen, hmm okay maybe we need to add a third line
              yeah... that looks better okay.
            </div>
          </div>
          <div className="p12-bullet-point-block">
            <div className="p12-bullet-point"></div>
            <div className="p12-product-summary-text">
              This is a blog description it will span two lines what do you
              think will happen, hmm okay maybe we need to add a third line
              yeah... that looks better okay.
            </div>
          </div>
        </div>
      </div>
      <div className="p12-product-summary-wrapper">
        <img
          src={img2}
          height=""
          alt=""
          className="image-16"
        />
        <div className="p12-product-summary-text-block">
          <h3 className="p12-h3">Filling Machine Type 1 </h3>
          <div className="p12-bullet-point-block">
            <div className="p12-bullet-point"></div>
            <div className="p12-product-summary-text">
              This is a blog description it will span two lines what do you
              think will happen, hmm okay maybe we need to add a third line
              yeah... that looks better okay.
            </div>
          </div>
          <div className="p12-bullet-point-block">
            <div className="p12-bullet-point"></div>
            <div className="p12-product-summary-text">
              This is a blog description it will span two lines what do you
              think will happen, hmm okay maybe we need to add a third line
              yeah... that looks better okay.
            </div>
          </div>
          <div className="p12-bullet-point-block">
            <div className="p12-bullet-point"></div>
            <div className="p12-product-summary-text">
              This is a blog description it will span two lines what do you
              think will happen, hmm okay maybe we need to add a third line
              yeah... that looks better okay.
            </div>
          </div>
        </div>
      </div>
      <div className="p12-snippet-block">
        <div className="p12-snippet-text">
          We often clear our stock at the end of the month want to be know what
          we have?
        </div>
        <a href="#" className="p12-snippet-button w-button">
          Free Design Service
        </a>
      </div>
      <div className="p12-paragraph-block">
        <div className="text-block-9">
          This is a Paragraph in Rich Text Editor <br />
          This is a blog description it will span two lines what do you think
          will happen, hmm okay maybe we need to add a third line yeah.. that
          looks better okay This is a blog description it will span two lines
          what do you think will happen, hmm okay maybe we need to add a third
          line yeah.. that looks better okay This is a blog description it will
          span two lines what do you think will happen, hmm okay maybe we need
          to add a third line yeah.. that looks better okay
          <br />
          <br />
          This is a Paragraph in Rich Text Editor <br />
          This is a blog description it will span two lines what do you think
          will happen, hmm okay maybe we need to add a third line yeah.. that
          looks better okay This is a blog description it will span two lines
          what do you think will happen, hmm okay maybe we need to add a third
          line yeah.. that looks better okay This is a blog description it will
          span two lines what do you think will happen, hmm okay maybe we need
          to add a third line yeah.. that looks better okay
          <br />
          <br />
          This is a Paragraph in Rich Text Editor <br />
          This is a blog description it will span two lines what do you think
          will happen, hmm okay maybe we need to add a third line yeah.. that
          looks better okay This is a blog description it will span two lines
          what do you think will happen, hmm okay maybe we need to add a third
          line yeah.. that looks better okay This is a blog description it will
          span two lines what do you think will happen, hmm okay maybe we need
          to add a third line yeah.. that looks better okay
        </div>
      </div>
      <h3 className="p12-section-title">Recommended Products ..</h3>
      <div className="p12-recommended-product-text">
        To order a sample, simply navigate to the product you want &amp; select
        sample in the dropdown!
      </div>
      <div className="w-layout-grid p12-grid-2-copy">
        <div className="p12-product-block">
          <img
            src={img2}
            alt=""
            className="p12-side-product-image"
          />
          <a href="#" className="p12-side-product-title">
            Flexible Stand Up Pouch Black dfjskjsklfjsdklfjksdlf ghfdg{" "}
          </a>
        </div>
        <div className="p12-product-block">
          <img
            src={img2}
            alt=""
            className="p12-side-product-image"
          />
          <a href="#" className="p12-side-product-title">
            Flexible Stand Up Pouch Black dfjskjsklfjsdklfjksdlf ghfdg{" "}
          </a>
        </div>
        <div className="p12-product-block">
          <img
            src={img2}
            alt=""
            className="p12-side-product-image"
          />
          <a href="#" className="p12-side-product-title">
            Flexible Stand Up Pouch Black
          </a>
        </div>
        <div className="p12-product-block">
          <img
            src={img2}
            alt=""
            className="p12-side-product-image"
          />
          <a href="#" className="p12-side-product-title">
            Flexible Stand Up Pouch Black
          </a>
        </div>
      </div>
      <h3 className="p12-section-title">FAQ&#x27;s...</h3>
      <div className="p12-faq-text-block">
        <div className="p12-faq-text">
          This is a Paragraph in Rich Text Editor <br />
          This is a blog description it will span two lines what do you think
          will happen, hmm okay maybe we need to add a third line yeah.. that
          looks better okay This is a blog description it will span two lines
          what do you think will happen, hmm okay maybe we need to add a third
          line yeah.. that looks better okay This is a blog description it will
          span two lines what do you think will happen, hmm okay maybe we need
          to add a third line yeah.. that looks better okay
        </div>
      </div>
      <div className="p12-form w-form">
        <form
          id="email-form"
          name="email-form"
          data-name="Email Form"
          className="p12-form-block"
        >
          <textarea
            placeholder="Ask a Question &amp; We&#x27;ll get back to you within 24 Hrs."
            maxLength="5000"
            id="field"
            name="field"
            required=""
            className="p12-form-para w-input"
          ></textarea>
          <div className="p12-form-block-2">
            <input
              type="text"
              className="p12-form-field w-input"
              maxLength="256"
              name="First-Name"
              data-name="First Name"
              placeholder="First Name"
              id="First-Name"
              required=""
            />
            <input
              type="text"
              className="p12-form-field w-input"
              maxLength="256"
              name="Last-Name"
              data-name="Last Name"
              placeholder="Last Name"
              id="Last-Name"
              required=""
            />
            <input
              type="tel"
              className="p12-form-field w-input"
              maxLength="256"
              name="Phone"
              data-name="Phone"
              placeholder="Phone"
              id="Phone"
              required=""
            />
            <input
              type="email"
              className="p12-form-field w-input"
              maxLength="256"
              name="Email"
              data-name="Email"
              placeholder="Email"
              id="Email-4"
              required=""
            />
            <input
              type="submit"
              value="Send Question"
              data-wait="Please wait..."
              className="p12-form-submit-button w-button"
            />
          </div>
        </form>
        <div className="w-form-done">
          <div>Thank you! Your submission has been received!</div>
        </div>
        <div className="w-form-fail">
          <div>Oops! Something went wrong while submitting the form.</div>
        </div>
      </div>
    </div>
    <div className="p12-right-wrapper">
      <h3 className="p12-side-block-heading">Our Stock Product Ranges</h3>
      <div className="p12-side-product-wrapper">
        <div className="p12-side-product-block">
          <img
            src={"../../assets/images/1Asset-15-100.jpg"}
            alt=""
            className="p12-side-product-image"
          />
          <a href="#" className="p12-side-product-title">
            Flexible Stand Up Pouch Black dfjskjsklfjsdklfjksdlf ghfdg{" "}
          </a>
        </div>
        <div className="p12-side-product-block">
          <img
            src={img2}
            alt=""
            className="p12-side-product-image"
          />
          <a href="#" className="p12-side-product-title">
            Flexible Stand Up Pouch Black dfjskjsklfjsdklfjksdlf ghfdg{" "}
          </a>
        </div>
        <div className="p12-side-product-block">
          <img
            src={img2}
            alt=""
            className="p12-side-product-image"
          />
          <a href="#" className="p12-side-product-title">
            Flexible Stand Up Pouch Black
          </a>
        </div>
      </div>
    </div>
  </div>
);
