import React, {useState} from "react";
import {Field, Form, Formik} from "formik";
import {END_POINTS} from "../../api";
import {post} from "../../api/api-caller";
import {toast} from "react-toastify";
import PropTypes from "prop-types";
import {object, string} from "yup";

export const CustomPouchQuoteForm = ({onClose}) => {
  
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isError, setIsError] = useState(false)
  const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/
  
  const formSchema = object().shape({
    first_name: string()
      .required('Full Name is required'),
    email: string()
      .email('Invalid Email')
      .required('Email is required'),
    contact_no: string()
      .matches(phoneRegExp, 'Phone number is not valid')
      .required('Contact Number is required'),
    sizes: string()
      .required('Sizes are required'),
    quantity: string()
      .min(0)
      .required('Quantity is required'),
    no_of_designs: string()
      .min(0)
      .required('No of Designs are required'),
    material: string()
      .required('Material is required'),
  });
  
  const handleSubmit = (values, actions) => {
    let data = {
      ...values,
      message: `\n -Your Requirements? \n  ${values.requirement} \n -Required material? \n  ${values.material} \n -How many do you need? \n  ${values.quantity} \n -How many designs are there? \n  ${values.no_of_designs} \n -What is the size? \n ${values.sizes} \n -Is artwork ready? ${values.is_artwork_ready ? 'yes' : 'no'}`,
    };
    post(END_POINTS.CONTACT_US, data)
      .then((resp) => {
       // reportEvent('Enquiry', 'Submit', )
        toast.success('Thank you for submitting your enquiry! Please look out for an email from us!')
        actions.resetForm({
          values: {
            type: 'enquiry',
            sizes: "",
            quantity: "",
            material: "",
            email: "",
            requirement: "",
            no_of_designs: "",
            contact_no: "",
            first_name: "",
          },
        });
        setIsSubmitted(true);
        onClose();
      })
      .catch(() =>{
        setIsError(true);
      });
  }
  return (
      <div className="p28-pouch-modal">
        <div className="p28-pouch-modal-block">
          <a href="#" className="p1---modal-close-link">
            <span className="text-span" onClick={onClose}>Close [ X ]</span>
          </a>
          <div className="p26-modal-quote-title">
            Custom Pouch Quotation
          </div>
          <div className="p26-quote-description">
            Please fill in to the best of your knowledge so we can provide you the best possible quote!
          </div>
          <div className="p1---modal-form w-form">
            <Formik
              initialValues={{type: 'enquiry'}}
              validationSchema={formSchema}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({isSubmitting, errors, values, touched}) => (
                <Form id="email-form" name="email-form" data-name="email-form">
                  <Field
                    type="text"
                    className="p26-modal-product-name w-input"
                    maxLength="256"
                    name="first_name"
                    data-name="Full Name"
                    placeholder="Full Name" id="Full-Name"
                    required
                  />
                  {errors.first_name && touched.first_name &&(
                    <label
                      className="w-commerce-commercecheckoutlabel p5-field-label"
                      style={{color: "red"}}
                    >
                      {errors.first_name}
                    </label>
                  )}
                  <Field
                    type="text"
                    className="p26-modal-product-name w-input"
                    maxLength="256"
                    name="email"
                    data-name="Email"
                    placeholder="Email "
                    id="Email-5"
                    required
                  />
                  {errors.email && touched.email && (
                    <label
                      className="w-commerce-commercecheckoutlabel p5-field-label"
                      style={{color: "red"}}
                    >
                      {errors.email}
                    </label>
                  )}
                  <Field
                    type="text"
                    className="p26-modal-product-name w-input"
                    maxLength="256"
                    name="contact_no"
                    data-name="Phone"
                    placeholder="Phone"
                    id="Phone-3"
                    required
                  />
                  {errors.contact_no && touched.contact_no && (
                    <label
                      className="w-commerce-commercecheckoutlabel p5-field-label"
                      style={{color: "red"}}
                    >
                      {errors.contact_no}
                    </label>
                  )}
                  <div className="p28-text">
                    <strong>Your Requirements: </strong>
                  </div>
                  <Field
                    type="number"
                    className="p26-quantity-field w-input"
                    maxLength="256"
                    name="quantity"
                    data-name="Quantity 3"
                    min="0"
                    placeholder="Quantity"
                    id="Quantity-3"
                    required
                  />
                  {errors.quantity && touched.quantity && (
                    <label
                      className="w-commerce-commercecheckoutlabel p5-field-label"
                      style={{color: "red"}}
                    >
                      {errors.quantity}
                    </label>
                  )}
                  <Field
                    type="text"
                    className="p26-quantity-field w-input"
                    maxLength="256"
                    name="sizes"
                    data-name="Sizes"
                    placeholder="Sizes"
                    id="Sizes-2"
                    required
                  />
                  {errors.sizes && touched.sizes && (
                    <label
                      className="w-commerce-commercecheckoutlabel p5-field-label"
                      style={{color: "red"}}
                    >
                      {errors.sizes}
                    </label>
                  )}
                  <Field
                    type="text"
                    className="p26-quantity-field w-input"
                    maxLength="256"
                    name="material"
                    data-name="Material"
                    placeholder="Material " id="Material"
                    required
                  />
                  {errors.material && touched.material && (
                    <label
                      className="w-commerce-commercecheckoutlabel p5-field-label"
                      style={{color: "red"}}
                    >
                      {errors.material}
                    </label>
                  )}
                  <Field
                    type="number"
                    className="p26-quantity-field w-input"
                    maxLength="256"
                    name="no_of_designs"
                    data-name="No of Designs"
                    placeholder="No of Designs " id="No-of-Designs"
                    min="0"
                    required
                  />
                  {errors.no_of_designs && touched.no_of_designs && (
                    <label
                      className="w-commerce-commercecheckoutlabel p5-field-label"
                      style={{color: "red"}}
                    >
                      {errors.no_of_designs}
                    </label>
                  )}
                  <label className="w-checkbox checkbox-field">
                    <Field
                      type="checkbox"
                      id="checkbox-2"
                      name="is_artwork_ready"
                      data-name="Checkbox 2"
                      className="w-checkbox-input"
                    />
                    <span className="w-form-label">I have my Artwork Ready </span>
                  </label>
                  <div className="p28-text">Additional Notes: <br/></div>
                  <Field
                    type="text"
                    placeholder="Any other options you require, your deadline etc"
                    maxLength="5000"
                    id="Requirements-2"
                    name="requirement"
                    data-name="Requirements 2"
                    className="p26-modal-notes w-input">
                  </Field>
                  
                  <Field
                    type="submit"
                    value="Request Quote"
                    data-wait="Please wait..."
                    className="p1---modal-submit-button w-button"
                  />
                </Form>

              )}
            </Formik>
            {isSubmitted &&
            <div className="w-form-done-free-sample">
              <div>Thank you for submitting your enquiry! Please look out for an email from us!</div>
            </div>
            }
  
            {isError &&
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
            }
          </div>
        </div>
      </div>
  )
}

CustomPouchQuoteForm.propTypes ={
  onClose: PropTypes.func.isRequired,
}
export default CustomPouchQuoteForm;
