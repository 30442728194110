import React, {useEffect, useState} from "react";
import {Redirect, useHistory} from "react-router-dom";
import { LoginForm } from "./form";
import { END_POINTS } from "../../../api";
import { post, get } from "../../../api/api-caller";
import { ROUTES } from "../../../utils/values";
import { toast } from "react-toastify";
import { ActivationModal } from "../signup";

export const Login = ({ token, setAccountToken, redirectUrl }) => {
  const history = useHistory();
  const [isError, setIsError] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [isInactive, setIsInactive] = useState(false);
  const [isActivate, showIsActivate] = useState(false);
  const [forgotClicked, setForgotClicked] = useState(false);

  const resetPassword = (values) => {
    if (!values.username) {
      toast.error(
        "Please enter your email address and we'll send you a reset link."
      );
      setForgotClicked(true);
    } else {
      let data = {
        email: values.username,
      };
      post(END_POINTS.RESET_PASSWORD, data)
        .then((resp) => {
          if (resp) {
            toast.success("We've emailed you a reset link.");
            // history.push(redirectUrl || ROUTES.ROOT);
          }
        })
        .catch(() => {});
    }
  };

  const closeModal = () => showIsActivate(false);

  if (token && redirectUrl) {
    return <Redirect to={`/${redirectUrl}`}/>;
  }

  return (
    <div className="p6-login-block">
      <h1 className="p6-login-heading">Log in</h1>
      <div className="p6-login-form-block w-form">
        <LoginForm
          attemptLogin={({ data, setSubmitting }) => {
            setUserEmail(data.username);
            post(END_POINTS.LOGIN, data)
              .then((resp) => {
                if (!resp.token) {
                  setIsError(true);
                } else {
                  setAccountToken(resp);
                  setIsInactive(!resp.email_verified);
                  if (resp.email_verified)
                    // debugger;
                    // history.push(redirectUrl ? `/${redirectUrl}` : ROUTES.ROOT);
                    history.goBack();
                }
              })
              .catch(() => {
                setIsError(true);
                setSubmitting(false);
              });
          }}
          resetPassword={resetPassword}
          forgotClicked={forgotClicked}
        />
        
        {isError && (
          <div
            className="e_login_errorblock w-form-fail"
            style={{ display: "block" }}
          >
            <div className="e_login_errormessageblock">
              Oops! Try again or reset your password below.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
