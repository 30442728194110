import React, {useEffect, useState} from "react";
import $ from "jquery";
import PropTypes from "prop-types";
import {formikEnhancer} from "./form-utils";
import {CardElement, PaymentRequestButtonElement, useStripe} from "@stripe/react-stripe-js";
import {SummaryCard} from "../../ui/summary-card";
import BrexitCheckout from "../BrexitCheckout";
import {get} from "../../../api/api-caller";
import {END_POINTS} from "../../../api";

const Form = ({
 handleChange,
 handleBlur,
 handleSubmit,
 isSubmitting,
 errors,
 basket,
 countries,
 shippingMethods,
 setRefresh,
 values,
 setFieldValue,
 account,
 setAccountToken,
 toggleCart,
 setCountry,
 setTaxMethod,
 userCountry,
 }) => {
 // const stripe = useStripe();
  const localCountries = ['https://justbulk.co.uk/api/countries/GB/', 'https://justbulk.co.uk/api/countries/ND/', 'https://justbulk.co.uk/api/countries/WL/', 'https://justbulk.co.uk/api/countries/CT/' ]
  
  const checkIsLocal = (country) => {
    return country ? localCountries.includes(country) : false;
  }
  const defaultCountry =  values.country ? values.country : localCountries[0];
  
  const [isLocal, setIsLocal] = useState(checkIsLocal(defaultCountry));
  const [selfHandled , setSelfHandled] = useState(false);
  // const [paymentRequest, setPaymentRequest] = useState(null);
  
  const errorsCount = Object.keys(errors).length;
  
  const refreshBasketValues = (country) => {
    const selected_country = countries?.find(cont => cont.url === country) || {}
    if(selected_country)
    get(`${END_POINTS.BASKET}?shipping_country=${selected_country.iso_3166_1_a3}&tax_handling_method=${values.tax_handling_method}`)
      .then((resp) => {
        setRefresh();
      })
      .catch(() => {});
  }
  
  const onCountryChange = (country) => {
    setIsLocal(checkIsLocal(country));
    setCountry(countries?.find(cont => cont.url === country)?.iso_3166_1_a3);
    if (country === "https://justbulk.co.uk/api/countries/GB/") {
      setFieldValue('shipping_method_code', 'standard-shipping')
    } else {
      setFieldValue('shipping_method_code', 'international-shipping')
    }
    setFieldValue('country', country);
    refreshBasketValues(country);
  }
  
  const onTaxMethodChange = (value) => {
    setSelfHandled(value);
    let taxType = value ? 'self_handling' : 'all_inclusive';
    setFieldValue('tax_handling_method', taxType);
    setTaxMethod(taxType);
    refreshBasketValues(values.country);
  }
  
  useEffect(() => {
    try {
      const errorKeys = Object.keys(errors).sort();
      if (errorKeys[0] === "payment") {
        $("html, body").animate({
          scrollTop: $("#card-element").offset().top,
        });
        return;
      }
      if (errorKeys.length) {
        if ($(`input[name=${errorKeys[0]}]`).offset()) {
          $("html, body").animate({
            scrollTop: $(`input[name=${errorKeys[0]}]`).offset().top,
          });
        } else {
          $("html, body").animate({
            scrollTop: $(`select[name=${errorKeys[0]}]`).offset().top,
          });
        }
      }
    } catch (e) {
    }
  }, [errorsCount]);
  
  useEffect(() => {
    if (values.shipping_method_code) {
      const method = shippingMethods.find(({code}) => code === values.shipping_method_code);
      setFieldValue("shipping_price", parseFloat(method?.price?.excl_tax));
      setFieldValue("shipping_price_tax", parseFloat(method?.price?.tax));
    }
  }, [values.shipping_method_code]);
  
  useEffect(() =>{
    onCountryChange(defaultCountry);
  }, [])
  
  /**useEffect(() => {
   *
   * google pay use effect api request code
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'GB',
        currency: 'eur',
        total: {
          label: 'Demo total',
          amount: 1099,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });
      
      // Check the availability of the Payment Request API.
      pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe]);*/
  
  return (
    <form
      id="email-form"
      name="email-form"
      data-name="Email Form"
      className="p5-form"
      onSubmit={handleSubmit}
    >
      <div className="w-layout-grid p5-checkout-grid">
        <div className="p5-left-checkout-block">
          <p className="p5-notice-text">
            Brexit Notice: As of Jan 1st 2021, VAT will be Zero rated (not charged) for all EU
            Customers after you select your country.
          </p>
          {!account.token &&
            <BrexitCheckout
              setAccountToken={setAccountToken}
            />
          }
          <div className="w-commerce-commercecheckoutblockheader p5-left-block-header">
            <h4 className="p5-checkout-title">Customer Info</h4>
            <div className="muted-text small">* Required</div>
          </div>
          <div/>
          <fieldset className="w-commerce-commercecheckoutblockcontent p5-customer-info-block">
            <label className="w-commerce-commercecheckoutlabel p5-field-label">
              Company Name - ( Leave blank if you&#x27;re an Individual )
            </label>
            <input
              type="text"
              name="title"
              required=""
              value={values.title}
              className="w-commerce-commercecheckoutshippingstreetaddress p5-company-field version-2"
              onChange={handleChange}
            />
            <div className="p5-name-wrapper">
              <div className="p5-first-name-block">
                <label className="w-commerce-commercecheckoutlabel p5-field-label">
                  First Name*
                </label>
                <input
                  type="text"
                  name="first_name"
                  required=""
                  value={values.first_name}
                  className="w-commerce-commercecheckoutshippingcity p5-first-name-field version-2"
                  onChange={handleChange}
                />
                {errors.first_name && (
                  <label
                    className="w-commerce-commercecheckoutlabel p5-field-label"
                    style={{color: "red"}}
                  >
                    {errors.first_name}
                  </label>
                )}
              </div>
              <div className="p5-last-name-block">
                <label className="w-commerce-commercecheckoutlabel p5-field-label">
                  Last Name*
                </label>
                <input
                  type="text"
                  name="last_name"
                  required=""
                  value={values.last_name}
                  className="w-commerce-commercecheckoutshippingcity p5-last-name-field version-2"
                  onChange={handleChange}
                />
                {errors.last_name && (
                  <label
                    className="w-commerce-commercecheckoutlabel p5-field-label"
                    style={{color: "red"}}
                  >
                    {errors.last_name}
                  </label>
                )}
              </div>
            </div>
            <div className="p5-name-wrapper">
              <div className="p5-first-name-block">
                <label className="w-commerce-commercecheckoutlabel p5-field-label">
                  Phone No*
                </label>
                <input
                  type="text"
                  name="phone_number"
                  required=""
                  value={values.phone_number}
                  className="w-commerce-commercecheckoutshippingcity p5-customer-info-phone version-2"
                  onChange={handleChange}
                />
                {errors.phone_number && (
                  <label
                    className="w-commerce-commercecheckoutlabel p5-field-label"
                    style={{color: "red"}}
                  >
                    {errors.phone_number}
                  </label>
                )}
              </div>
              <div className="p5-last-name-block">
                <label className="w-commerce-commercecheckoutlabel p5-field-label">
                  Email*
                </label>
                <input
                  type="email"
                  name="email"
                  required=""
                  value={values.email}
                  className="w-commerce-commercecheckoutshippingcity p5-customer-info-email version-2"
                  onChange={handleChange}
                />
                {errors.email && (
                  <label
                    className="w-commerce-commercecheckoutlabel p5-field-label"
                    style={{color: "red"}}
                  >
                    {errors.email}
                  </label>
                )}
              </div>
            </div>
          </fieldset>
          <div className="w-commerce-commercecheckoutblockheader p5-left-block-header">
            <h4 className="p5-checkout-title">Address Details</h4>
            <div className="muted-text small">* Required</div>
          </div>
          <fieldset className="w-commerce-commercecheckoutblockcontent p5-address-block">
            <div className="p5-name-wrapper">
              <div className="p5-first-name-block">
                <label className="w-commerce-commercecheckoutlabel p5-field-label">
                  Recipient Name
                </label>
                <input
                  type="text"
                  name="recipient_name"
                  required=""
                  value={values.recipient_name}
                  className="w-commerce-commercecheckoutshippingcity p5-recipient-name version-2"
                  onChange={handleChange}
                />
                {errors.recipient_name && (
                  <label
                    className="w-commerce-commercecheckoutlabel p5-field-label"
                    style={{color: "red"}}
                  >
                    {errors.recipient_name}
                  </label>
                )}
              </div>
              <div className="p5-last-name-block">
                <label className="w-commerce-commercecheckoutlabel p5-field-label">
                  Recipient Mobile No*
                </label>
                <input
                  type="text"
                  name="recipient_phone"
                  required=""
                  className="w-commerce-commercecheckoutshippingcity p5-recipient-mobile-no version-2"
                  onChange={handleChange}
                />
                {errors.recipient_phone && (
                  <label
                    className="w-commerce-commercecheckoutlabel p5-field-label"
                    style={{color: "red"}}
                  >
                    {errors.recipient_phone}
                  </label>
                )}
              </div>
            </div>
            <label className="w-commerce-commercecheckoutlabel p5-field-label">
              Street Address *
            </label>
            <input
              type="text"
              name="line1"
              required=""
              value={values.line1}
              className="w-commerce-commercecheckoutshippingstreetaddress p5-address-line-1-field version-2"
              onChange={handleChange}
            />
            <input
              type="text"
              name="line2"
              value={values.line2}
              className="w-commerce-commercecheckoutshippingstreetaddressoptional p5-address-line-2-field version-2"
              onChange={handleChange}
            />
            {errors.line1 && (
              <label
                className="w-commerce-commercecheckoutlabel p5-field-label"
                style={{color: "red"}}
              >
                {errors.line1}
              </label>
            )}
            <div className="w-commerce-commercecheckoutrow checkout-content-row">
              <div className="w-commerce-commercecheckoutcolumn">
                <label className="w-commerce-commercecheckoutlabel p5-field-label">
                  City *
                </label>
                <input
                  type="text"
                  name="line3"
                  required
                  className="w-commerce-commercecheckoutshippingcity p5-city-field version-2"
                  value={values.line3}
                  onChange={handleChange}
                />
                {errors.line3 && (
                  <label
                    className="w-commerce-commercecheckoutlabel p5-field-label"
                    style={{color: "red"}}
                  >
                    {errors.line3}
                  </label>
                )}
              </div>
              <div className="w-commerce-commercecheckoutcolumn">
                <label className="w-commerce-commercecheckoutlabel p5-field-label">
                  State/Province*
                </label>
                <input
                  type="text"
                  name="state"
                  value={values.state}
                  className="w-commerce-commercecheckoutshippingstateprovince p5-state-field version-2"
                  onChange={handleChange}
                />
                {errors.state && (
                  <label
                    className="w-commerce-commercecheckoutlabel p5-field-label"
                    style={{color: "red"}}
                  >
                    {errors.state}
                  </label>
                )}
              </div>
              <div className="w-commerce-commercecheckoutcolumn">
                <label className="w-commerce-commercecheckoutlabel p5-field-label">
                  Zip/Postal Code *
                </label>
                <input
                  type="text"
                  name="postcode"
                  required
                  value={values.postcode}
                  className="w-commerce-commercecheckoutshippingzippostalcode p5-zip-field version-2"
                  onChange={handleChange}
                />
                {errors.postcode && (
                  <label
                    className="w-commerce-commercecheckoutlabel p5-field-label"
                    style={{color: "red"}}
                  >
                    {errors.postcode}
                  </label>
                )}
              </div>
            </div>
            <label className="w-commerce-commercecheckoutlabel p5-field-label">
              Country *
            </label>
            <select
              name="country"
              className="w-commerce-commercecheckoutshippingcountryselector select-field"
              onChange={e => onCountryChange(e.target.value)}
            >
              <option disabled>
                Choose country
              </option>
              {countries.map(({url, printable_name}) => (
                <option
                  value={url}
                  selected={url === defaultCountry}
                >
                  {printable_name}
                </option>
              ))}
            </select>
            {errors.country && (
              <label
                className="w-commerce-commercecheckoutlabel p5-field-label"
                style={{color: "red"}}
              >
                {errors.country}
              </label>
            )}
          </fieldset>
          <label className="w-checkbox p5-billing-address-checkbox">
            <input
              type="checkbox"
              id="checkbox-2"
              name="billingAddress"
              data-name="Checkbox 2"
              checked={values.billingAddress}
              className="w-checkbox-input"
              onChange={(e) =>
                setFieldValue("billingAddress", e.target.checked)
              }
            />
            <span className="checkbox-label-2 w-form-label">
              Billing Address is the same as the Shipping Address above
            </span>
          </label>
          {!values.billingAddress ? (
            <>
              <div className="w-commerce-commercecheckoutblockheader p5-left-block-header">
                <h4 className="p5-checkout-title">Billing Address Details</h4>
                <div className="muted-text small">* Required</div>
              </div>
              <fieldset className="w-commerce-commercecheckoutblockcontent p5-address-block">
                <div className="p5-name-wrapper">
                  <div className="p5-first-name-block">
                    <label className="w-commerce-commercecheckoutlabel p5-field-label">
                      Recipient Name
                    </label>
                    <input
                      type="text"
                      name="billing_recipient_name"
                      required=""
                      className="w-commerce-commercecheckoutshippingcity p5-recipient-name version-2"
                      onChange={handleChange}
                    />
                    {errors.billing_recipient_name && (
                      <label
                        className="w-commerce-commercecheckoutlabel p5-field-label"
                        style={{color: "red"}}
                      >
                        {errors.billing_recipient_name}
                      </label>
                    )}
                  </div>
                  <div className="p5-last-name-block">
                    <label className="w-commerce-commercecheckoutlabel p5-field-label">
                      Recipient Mobile No*
                    </label>
                    <input
                      type="text"
                      name="billing_recipient_phone"
                      required=""
                      className="w-commerce-commercecheckoutshippingcity p5-recipient-mobile-no version-2"
                      onChange={handleChange}
                    />
                    {errors.billing_recipient_phone && (
                      <label
                        className="w-commerce-commercecheckoutlabel p5-field-label"
                        style={{color: "red"}}
                      >
                        {errors.billing_recipient_phone}
                      </label>
                    )}
                  </div>
                </div>
                <label className="w-commerce-commercecheckoutlabel p5-field-label">
                  Street Address *
                </label>
                <input
                  type="text"
                  name="billing_line1"
                  required=""
                  value={values.billing_line1}
                  className="w-commerce-commercecheckoutshippingstreetaddress p5-address-line-1-field version-2"
                  onChange={handleChange}
                />
                <input
                  type="text"
                  name="billing_line2"
                  value={values.billing_line2}
                  className="w-commerce-commercecheckoutshippingstreetaddressoptional p5-address-line-2-field version-2"
                  onChange={handleChange}
                />
                {errors.billing_line1 && (
                  <label
                    className="w-commerce-commercecheckoutlabel p5-field-label"
                    style={{color: "red"}}
                  >
                    {errors.billing_line1}
                  </label>
                )}
                <div className="w-commerce-commercecheckoutrow checkout-content-row">
                  <div className="w-commerce-commercecheckoutcolumn">
                    <label className="w-commerce-commercecheckoutlabel p5-field-label">
                      City *
                    </label>
                    <input
                      type="text"
                      name="billing_line3"
                      required=""
                      value={values.billing_line3}
                      className="w-commerce-commercecheckoutshippingcity p5-city-field version-2"
                      onChange={handleChange}
                    />
                    {errors.billing_line3 && (
                      <label
                        className="w-commerce-commercecheckoutlabel p5-field-label"
                        style={{color: "red"}}
                      >
                        {errors.billing_line3}
                      </label>
                    )}
                  </div>
                  <div className="w-commerce-commercecheckoutcolumn">
                    <label className="w-commerce-commercecheckoutlabel p5-field-label">
                      State/Province
                    </label>
                    <input
                      type="text"
                      name="billing_state"
                      value={values.billing_state}
                      className="w-commerce-commercecheckoutshippingstateprovince p5-state-field version-2"
                      onChange={handleChange}
                    />
                    {errors.billing_state && (
                      <label
                        className="w-commerce-commercecheckoutlabel p5-field-label"
                        style={{color: "red"}}
                      >
                        {errors.billing_state}
                      </label>
                    )}
                  </div>
                  <div className="w-commerce-commercecheckoutcolumn">
                    <label className="w-commerce-commercecheckoutlabel p5-field-label">
                      Zip/Postal Code *
                    </label>
                    <input
                      type="text"
                      name="billing_postcode"
                      required=""
                      value={values.billing_postcode}
                      className="w-commerce-commercecheckoutshippingzippostalcode p5-zip-field version-2"
                      onChange={handleChange}
                    />
                    {errors.billing_postcode && (
                      <label
                        className="w-commerce-commercecheckoutlabel p5-field-label"
                        style={{color: "red"}}
                      >
                        {errors.billing_postcode}
                      </label>
                    )}
                  </div>
                </div>
                <label className="w-commerce-commercecheckoutlabel p5-field-label">
                  Country *
                </label>
                <select
                  name="billing_country"
                  className="w-commerce-commercecheckoutshippingcountryselector select-field"
                  onChange={e => setFieldValue('billing_country', e.target.value)}
                >
                  <option disabled>
                    Choose country
                  </option>
                  {countries.map(({url, printable_name}) => (
                    <option
                      value={url}
                      selected={url === defaultCountry}
                    >
                      {printable_name}
                    </option>
                  ))}
                </select>
                {errors.billing_country && (
                  <label
                    className="w-commerce-commercecheckoutlabel p5-field-label"
                    style={{color: "red"}}
                  >
                    {errors.billing_country}
                  </label>
                )}
              </fieldset>
            </>
          ) : null}
          <div className="w-commerce-commercecheckoutblockheader p5-left-block-header">
            <h4 className="p5-checkout-title">Shipping Method</h4>
          </div>
          <div className="p5-shipping-method-wrapper">
            {shippingMethods.map((method) => (
              <div className="p5-shipping-method-block">
                <label className="p5-shipping-radio-field w-radio">
                  <input
                    type="radio"
                    id="radio"
                    name="shipping_method_code"
                    value={`${method.code},${method.price.excl_tax},${method.price.tax}`}
                    required=""
                    className="w-form-formradioinput p5-shipping-radio-icon w-radio-input"
                    onChange={(e) => {
                      const [code, price, tax] = e.target.value.split(",");
                      setFieldValue("shipping_method_code", code);
                      setFieldValue("shipping_price", parseFloat(price));
                      setFieldValue("shipping_price_tax", parseFloat(tax));
                    }}
                    checked={values.shipping_method_code === method.code}
                    disabled
                  />
                  <span className="p5-shipping-price-span w-form-label">
                    {method.name} -
                    <span className="p5-shipping-price-span">
                      £{method.price.excl_tax}
                    </span>
                  </span>
                </label>
              </div>
            ))}
            {errors.shipping_method_code && (
              <label
                className="w-commerce-commercecheckoutlabel p5-field-label"
                style={{color: "red"}}
              >
                {errors.shipping_method_code}
              </label>
            )}
          </div>
          {!isLocal && (
            <>
              <div className="w-commerce-commercecheckoutblockheader p5-import-title">
                <h4 className="p5-checkout-title">Your Local Taxes</h4>
              </div>
              <div className="p5-import-method-wrapper">
                <div className="p5-import-method-block">
                  <label className="p5-import-radio-field w-radio">
                    <input
                      type="radio"
                      data-name="Radio 4"
                      id="radio-4"
                      name="tax_handling_method"
                      value="all_inclusive"
                      required
                      checked={values.tax_handling_method === 'all_inclusive'}
                      onChange={() => onTaxMethodChange(false)}
                      className="w-form-formradioinput p5-shipping-radio-icon w-radio-input"
                    />
                    <span className="p5-import-price-span w-form-label">
                      All Inclusive -
                    <span className="p5-import-price-span-copy">
                      £{basket.import_charges ? basket.import_charges : 0.00}
                    </span>
                    </span>
                  </label>
                  <label className="p5-import-method-description">
                    We calculate &amp; pay all your local Fees, Taxes (VAT) &amp; Duties for your country.
                    <br/>This will display the final price you will pay in the Total.
                    <br/>(Select if you are Not VAT Registered)
                  </label>
                </div>
                <div className="p5-import-method-block">
                  <label className="p5-import-radio-field w-radio">
                    <input
                      type="radio"
                      id="radio-5"
                      name="tax_handling_method"
                      value="self_handling"
                      required
                      className="w-form-formradioinput p5-shipping-radio-icon w-radio-input"
                      onChange={() => onTaxMethodChange(true)}
                    />
                    <span className="p5-import-price-span w-form-label">
                      Self Handling -
                    <span className="p5-import-price-span-copy"> £0.00
                    </span>
                    </span>
                  </label>
                  <label className="p5-import-method-description">
                    We cover delivery to your door, &amp; the delivery company will send you a bill for
                    <br/>Local Taxes (VAT), Duty (If Applicable) &amp; Clearance Costs.
                    <br/>(For those who Are VAT Registered)
                  </label>
                </div>
                
                {selfHandled && (
                  <div className="p5-import-field-wrapper">
                    <div className="p5-import-vat-block">
                      <label className="w-commerce-commercecheckoutlabel p5-field-label">VAT No</label>
                      <input
                        type="text"
                        name="vat_number"
                        required=""
                        value={values.vat_number}
                        className="w-commerce-commercecheckoutshippingcity p5-import-vat-no version-2"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="p5-import-licence-block">
                      <label className="w-commerce-commercecheckoutlabel p5-field-label">EORI No / Import Licence
                        No</label>
                      <input
                        type="text"
                        name="eori_number"
                        required=""
                        className="w-commerce-commercecheckoutshippingcity p5-import-licence-no version-2"
                        onChange={handleChange}
                        value={values.eori_number}
                      />
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          <div className="w-commerce-commercecheckoutblockheader p5-left-block-header">
            <h4 className="p5-checkout-title">Payment Details</h4>
            <div className="muted-text small">* Required</div>
          </div>
          <div id="payment-form" className=" p5-shipping-method-wrapper">
            <div className="p5-shipping-method-block">
              <input name="payment" disabled style={{display: "none"}}/>
              <CardElement
                id="card-element"
                options={{hidePostalCode: true}}
              />
            {/** this is google pay test code not needed for now.
                          {paymentRequest && ( <PaymentRequestButtonElement options={{paymentRequest}}/>)}
            */}
            </div>
          </div>
          {errors.payment && (
            <label
              className="w-commerce-commercecheckoutlabel p5-field-label"
              style={{color: "red"}}
            >
              {errors.payment}
            </label>
          )}
        </div>
        <SummaryCard
          basket={basket}
          isLoading={isSubmitting}
          values={values}
          isLocal={isLocal}
          setFieldValue={setFieldValue}
          showPassword
          account={account}
          toggleCart={toggleCart}
          userCountry={userCountry}
        />
      </div>
    </form>
  );
};

Form.propTypes = {
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isLogging: PropTypes.bool.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export const CheckoutForm = formikEnhancer(Form);

CheckoutForm.propTypes = {
  isLogging: PropTypes.bool.isRequired,
  attemptLogin: PropTypes.func.isRequired,
  account: PropTypes.object,
  toggleCart: PropTypes.func.isRequired,
  setCountry: PropTypes.func.isRequired,
  setTaxMethod: PropTypes.func.isRequired,
  userCountry: PropTypes.string.isRequired,
};
