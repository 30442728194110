import React, {useState} from "react";
import {post} from "../../api/api-caller";
import {END_POINTS} from "../../api";
import {toast} from "react-toastify";

const BrexitCheckout = ({setAccountToken}) => {
  
  
  const resetPassword = () => {
    if (!username) {
      toast.error(
        "Please enter your email address and we'll send you a reset link."
      );
    } else {
      let data = {
        email: username,
      };
      post(END_POINTS.RESET_PASSWORD, data)
        .then((resp) => {
          if (resp) {
            toast.success(`We've emailed you a reset link at ${username}`);
          }
        })
        .catch(() => {});
    }
  };
  const loginAndFetch = (data) => {
    if(data.username && data.password){
      post(END_POINTS.LOGIN, data)
        .then((resp) => {
          if (!resp?.token) {
            toast.error(resp?.error)
          } else {
            setAccountToken(resp);
            window.location.reload();
          }
        })
        .catch();
    }else {
      toast.error("Insert required data!")
    }
    
  }
  
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  
  return(
    <>
      
      <p className="p5-information-text">
        Got an account?&nbsp; Save time by Signing In to Auto fill everything below! &nbsp;&nbsp;
        <a href="#" className="link-4" onClick={resetPassword}> Forgot Password </a>
      </p>
      <div className="p5-login-wrapper">
        <div className="p5-login-left-block">
          <input
            type="email"
            className="p5-login-email-field w-input"
            maxLength="256"
            name="field-3"
            data-name="Field 3"
            placeholder="Email"
            id="field3-email"
            onChange={e => setUsername(e.target.value)}
          />
        </div>
        <div className="p5-login-right-block">
          <input
            type="password"
            className="p5-login-password-field w-input"
            maxLength="256"
            name="field-3"
            data-name="Field 3"
            placeholder="Password"
            id="field3-password"
            onChange={e => setPassword(e.target.value)}
          />
        </div>
        <a href="#" className="p5-sign-in-button w-button" onClick={() => loginAndFetch({username, password, preserve_old_basket: false })} >Sign In</a>
      </div>
    </>
  )
}

export default BrexitCheckout;