import { connect } from 'react-redux';
import { ItemCard } from "./SingleCard";
import {actions} from "../../../actions/ui";

const mapStateToProps = state => ({
    customQuoteModal: state.ui.customQuoteModal,
});

const mapDispatchToProps = {
    setRefresh: actions.setRefreshCart,
    toggleCustomQuoteModal: actions.toggleCustomQuoteModal
};

export const ItemCardContainer = connect(mapStateToProps, mapDispatchToProps)(ItemCard);
