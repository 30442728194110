import React from "react";
import { CartItem } from "./cart-item";
import { ROUTES } from "../../utils/values";
import { Link } from "../ui/Link";


const EmptyCartMessage = () => (
  <div>
    <p>
      No items in the cart
    </p>
    <br />
    <p className="error-state">
      Please enable cookies to add items to the cart.
    </p>
  </div>
)
export const Cart = ({ onClose, basket, setRefresh, toggleCart }) => (
  <div className="p10---cart-modal">
    <div className="p10---modal-block">
      <div className="p10-cart-summary-form-block w-form">
        <div
          id="email-form"
          name="email-form"
          data-name="Email Form"
          className="p10-cart-summary-form"
        >
          <div className="p10-cart-block-copy">
            <div className="div-block-4">
              <h4 className="p10-checkout-title-copy">Cart Summary</h4>
              <a
                data-w-id="9248706e-3a6a-5a5e-9c98-b0190ed3b7ba"
                href="#"
                className="p10---modal-close-link"
              >
                <span className="p10-close-text-span-copy" onClick={onClose}>
                  Close [ X ]
                </span>
              </a>
            </div>
            <div className="p10-cart-summary-block-copy">
              {basket && basket.lines && basket.lines.length
                ? basket.lines.map((line) => (
                    <CartItem
                      basketId={basket.id}
                      item={line}
                      setRefresh={setRefresh}
                    />
                  ))
                : <EmptyCartMessage />
              }
            </div>
            <div className="p5-subtotal-block">
              <div className="p5-order-summary-text">Subtotal</div>
              <div className="p5-subtotal-price">£{basket.total_excl_tax}</div>
            </div>
            <div className="p5-vat-total-block">
              <div className="p5-order-summary-text">VAT</div>
              <div className="p5-vat-price">£{basket.total_tax}</div>
            </div>
            <div className="p5-grand-total-block">
              <div className="p5-grand-total-title">Total</div>
              <div className="p5-grandtotal-price">
                £{basket.total_incl_tax}
              </div>
            </div>
            <Link
              to={ROUTES.CHECKOUT_SUMMARY}
              data-wait="Please wait..."
              className="p10-checkout-button w-button"
            >
              <input
                type="submit"
                value="Checkout Now"
                data-wait="Please wait..."
                className="p10-checkout-button w-button"
                onClick={() => {
                  toggleCart();
                }}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
);
