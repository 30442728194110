import React, {useEffect, useState} from "react";
import {FieldArray, useFormikContext, Formik, Field, Form} from "formik";
import {post} from "../../../api/api-caller";
import {END_POINTS} from "../../../api";
import {object, string} from "yup";
import ErrorMessage from "../error_field";


export const Step4 = ({setActiveStep, sellerId}) => {
  
  const goodCategories = [
    "Agriculture",
    "Baby products",
    "FMCG",
    "Metal",
    "Transport equipment",
    "Hospitality",
    "Hand made",
    "Construction",
    "Apparel",
    "Textiles",
    "Drinks",
    "Office supplies",
    "Electronics",
    "Packaging",
    "Plastics",
    "Printing",
    "Medical supplies",
    "Machinery",
    "Fasteners",
    "Personal care",
    "Health care",
    "Tyres",
    "Educational supplies"
  ].sort();
  
  const step4Schema = object().shape({
    business_type: string()
      .required('Select an option'),
    delivery_type: string()
      .required('Select an option'),
    goods_category: string()
      .required('Select an option'),
    is_uk_based: string()
      .required('Select an option'),
    have_business_insurance: string()
      .required('Select an option'),
    internation_delivery_available: string()
      .required('Select an option'),
    primary_products: string()
      .required('This is a required field'),
  });
  
  const step4 = {
    supplier_lead_id: sellerId,
    business_type: "",
    delivery_type: "",
    goods_category: "",
    is_uk_based: "",
    have_business_insurance: "",
    internation_delivery_available: "",
    primary_products: "",
  }
  
  const handleSubmit = (values) => {
    post(`${END_POINTS.SELLER_SIGNUP}step4`, values)
      .then((resp) => {
        console.log("step4 submitted")
        console.log(values)
        setActiveStep(5);
      })
      .catch()
  }
  
  return (
    <Formik
      initialValues={step4}
      onSubmit={handleSubmit}
      validationSchema={step4Schema}
    >
      {
        ({
           errors,
           handleChange,
           touched
         },
        ) => {
          return (
            <Form>
              <div className="p34-form-c1">
                <div className="p34-form-title-wrapper">
                  <h2 className="p34-c1-h3">What you will be selling
                    <strong className="bold-text-12"><br/></strong>
                  </h2>
                  <div className="p34-progress-wrapper">
                    <div className="p34-progress-bar" style={{width: '75%'}}></div>
                  </div>
                  <div className="p34-form-split-wrapper">
                    <div className="p34-form-c1-left">
                      <div className="p34-form-field-block">
                        <label className="p34-form-field-label">
                          What best describes what your business?
                        </label>
                        <select
                          id="business_type"
                          name="business_type"
                          className="p34-form-dropdown w-select"
                          onChange={handleChange}
                        >
                          <option value="">Select one...</option>
                          <option value="manufacturer">Manufacturer</option>
                          <option value="processor">Processor</option>
                          <option value="wholesaler_distributor">Wholesaler / Distributor</option>
                          <option value="retailer">Retailer</option>
                        </select>
                        <ErrorMessage
                          errors={errors}
                          touched={touched}
                          name="business_type"
                        />
                      </div>
                      <div className="p34-form-field-block">
                        <label htmlFor="name-4" className="p34-form-field-label">
                          Is your Stock based in the UK?
                        </label>
                        <select
                          id="is_uk_based"
                          name="is_uk_based"
                          className="p34-form-dropdown w-select"
                          onChange={handleChange}
                        >
                          <option value="">Select one...</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                        <ErrorMessage
                          errors={errors}
                          touched={touched}
                          name="is_uk_based"
                        />
                      </div>
                      <div className="p34-form-field-block">
                        <label htmlFor="name-4" className="p34-form-field-label">
                          Do you have Business Insurance?
                        </label>
                        <select
                          id="have_business_insurance"
                          name="have_business_insurance"
                          className="p34-form-dropdown w-select"
                          onChange={handleChange}
                        >
                          <option value="">Select one...</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                        <ErrorMessage
                          errors={errors}
                          touched={touched}
                          name="have_business_insurance"
                        />
                      </div>
                    </div>
                    <div className="p34-form-c1-right">
                      <div className="p34-form-field-block">
                        <div className="p34-form-field-block">
                          <label htmlFor="name-4" className="p34-form-field-label">
                            How do you dispatch your goods?
                          </label>
                          <select
                            id="field-2" name="delivery_type" data-name="Field 2"
                            required="" className="p34-form-dropdown w-select"
                            onChange={handleChange}
                          >
                            <option value="">Select one...</option>
                            <option value="fulfilment_center">Through a fulfilment Centre</option>
                            <option value="courier_service">Directly with a courier or Pallet Service</option>
                            <option value="online_broker">Through an Online Broker / Comparison Site</option>
                            <option value="delivery_drivers">Using your own Delivery Drivers</option>
                          </select>
                          <ErrorMessage
                            errors={errors}
                            touched={touched}
                            name="delivery_type"
                          />
                        </div>
                        <div className="p34-form-field-block">
                          <label htmlFor="name-4" className="p34-form-field-label">
                            What Category do your goods fall into?
                          </label>
                          <select
                            id="field-2"
                            name="goods_category"
                            className="p34-form-dropdown w-select"
                            onChange={handleChange}
                          >
                            <option value="">Select one...</option>
                            {goodCategories.map((category) => (
                              <option value={category}>{category}</option>
                            ))}
                          </select>
                          <ErrorMessage
                            errors={errors}
                            touched={touched}
                            name="goods_category"
                          />
                          <div className="p34-form-field-block">
                            <label
                              htmlFor="name-4"
                              className="p34-form-field-label">
                              Do you deliver Internationally?
                            </label>
                            <select
                              id="internation_delivery_available"
                              name="internation_delivery_available"
                              className="p34-form-dropdown w-select"
                              onChange={handleChange}
                            >
                              <option value="">Select one...</option>
                              <option value="First">Yes</option>
                              <option value="Second">No</option>
                              <option value="Third">Yes but only Europe</option>
                            </select>
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              name="internation_delivery_available"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <label htmlFor="name-4" className="p34-form-field-label">What are your 3 Primary
                    Products?
                  </label>
                  <textarea
                    placeholder="Example Text"
                    maxLength="5000"
                    id="primary_products"
                    name="primary_products"
                    className="p34-form-large-field w-input"
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    errors={errors}
                    touched={touched}
                    name="primary_products"
                  />
                </div>
                <button type="submit" disabled={errors.length} className="p34-next-form w-button">Next</button>
              </div>
            </Form>
          )
        }}
    </Formik>
  
  )
}
