import React, {useState} from "react";
import {CustomerSupportForm} from "./form/supportForm";
import {post} from "../../../api/api-caller";
import {END_POINTS} from "../../../api";
import {toast} from "react-toastify";

export const CustomerSupport = () => {
  const [isError, setIsError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  return (
    <>
      <div className="p21-title-block">
        <h4 className="p21-right-wrapper-title">Customer Support</h4>
      </div>
      <div className="p23-address-grid">
        <CustomerSupportForm attemptSubmit={({data, setSubmitting, resetForm}) => {
          post(`${END_POINTS.ACCOUNT}/support/`, data)
            .then((resp) => {
              setSubmitting(false)
              setIsSubmitted(true);
              resetForm({values: {type: "", message: "", reference: "", attachments: [], files: []}});
              resetForm({values: {type: "", message: "", reference: "", attachments: [], files: []}});
              toast.success('Thank you! Your submission has been received!')
            })
            .catch(() => {
              setSubmitting(false)
              setIsError(true);
            })
        }}/>
      </div>

      {isSubmitted && (
        <div className="w-form-done">
          <div>Thank you! Your submission has been received!</div>
        </div>
      )}

      {isError && (
        <div className="w-form-fail">
          <div>Oops! Something went wrong while submitting the form.</div>
        </div>
      )}

    </>
  )
}