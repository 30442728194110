import React from "react";

import _img from "../../../assets/images/1Asset-15-100.jpg";
import { Link, useHistory } from "react-router-dom";
import { Markup } from "interweave";

export const ProductTile = ({ img, title, desc, to, price = null }) => {
  const history = useHistory();
  return (
    <div id="w-node-87729ebcdc70-07ca1e79" className="p8---product-frame">
      <img
        src={img || _img}
        alt=""
        className="image-8"
        onClick={() => {
          history.push(to);
        }}
        style={{ cursor: "pointer" }}
      />
      <Link
        to={to}
        className="p8---product-title"
        style={{ textDecoration: "none" }}
      >
        {title}
      </Link>
      {price && <div className="p8---product-price">from £{price} P/Unit</div>}
    </div>
  );
};
