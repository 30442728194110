import { post } from "../api/api-caller";
import { END_POINTS } from "../api";

export const addToBasket = (options, setIsLoading, onSuccess, item={}) => {
  setIsLoading(true);
  post(END_POINTS.ADD_TO_BASKET, options)
    .then((resp) => {
      onSuccess();
      window.dataLayer.push({...options, ...item, event:'add_to_cart'});
    })
    .finally(() => setIsLoading(false));
};

export const removeFromBasket = (basketId, itemId, setIsLoading, onSuccess, item={}) => {
  setIsLoading(true);
  post(`${END_POINTS.BASKETS}${basketId}/lines/${itemId}/`, null, "DELETE")
    .then((resp) => {
        window.dataLayer.push({...item, event:'remove_from_cart'});
        onSuccess()}
      )
    .finally(() => setIsLoading(false));
};
